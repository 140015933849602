import React, { useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import type { IconItemProps } from '@ww-digital/web-palette-react/dist/components/Item/IconItem/IconItem';
import type { ColumnIconListSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/ColumnIconListSlice/ColumnIconListSlice';
import type { MarketContextType } from '../../../context/market.context';

import { ColumnIconListSlice } from '@ww-digital/web-palette-react/dist/components/Slice/ColumnIconListSlice/ColumnIconListSlice';
import { RecurlyPlansFranchiseContainer } from './RecurlyPlansFranchiseContainer.tsx';
import { MarketContext } from '../../../context/market.context.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { PricingUtility } from '../../Utility/PricingUtility.ts';
import { Counter } from '../../Utility/Counter.ts';

interface ColumnIconListSliceContainerProps {
  daCategory: string;
  slice: ColumnIconListSliceProps;
}

const ItemPricingRecurly = (
  iconItemInit: IconItemProps,
  iconIndex: number,
  itemsPricing: React.MutableRefObject<IconItemProps[]>,
): void => {
  const marketContext = useContext<MarketContextType>(MarketContext);
  const iconItem = _.cloneDeep(iconItemInit);

  // All dynamic pricing and zip code entry is disabled.
  iconItem.textPrice = { text: '', price: '' };

  // If Pricing Enabled, use standard recurly checkout URL.
  if (iconItem.button && iconItem.pricing) {
    iconItem.button.url = PricingUtility.getRecurlyPricingUrl(
      marketContext.country,
      marketContext.language,
    );
  }

  // Clean out any price tokens.
  if (PricingUtility.hasPricingToken(iconItem?.description)) {
    iconItem.description = '';
  }
  if (
    PricingUtility.hasPricingToken(iconItem?.pricingText) ||
    PricingUtility.hasPricingToken(iconItem?.textPrice?.text)
  ) {
    iconItem.pricingText = '';
    iconItem.textPrice = { text: '' };
  }

  itemsPricing.current[iconIndex] = iconItem;
};

export const ColumnIconListSliceContainer = ({
  daCategory,
  slice,
}: ColumnIconListSliceContainerProps): JSX.Element => {
  const location = useLocation();
  const sliceView = _.cloneDeep(slice);
  const sliceIndex = useRef<number>(
    Counter.getCounter('column-icon-list-slice-index'),
  );
  const itemsPricing = useRef<IconItemProps[]>(sliceView?.iconItems || []);

  if (location.pathname === '/us/plans-franchise') {
    return (
      <RecurlyPlansFranchiseContainer daCategory={daCategory} slice={slice} />
    );
  }

  sliceView.index = sliceIndex.current;

  // Pricing for the icon items.
  if (sliceView?.iconItems) {
    sliceView?.iconItems.forEach(
      (iconItem: IconItemProps, iconIndex: number) => {
        ItemPricingRecurly(iconItem, iconIndex, itemsPricing);
      },
    );
    sliceView.iconItems = itemsPricing.current;
  }

  if (sliceView?.iconItems) {
    sliceView.iconItems = sliceView.iconItems.map((iconItem: IconItemProps) => {
      // Analytics tracking for icon item button.
      if (iconItem.button) {
        iconItem.button.attributes = {};
        iconItem.button.attributes['da-category'] = daCategory;
        iconItem.button.attributes['da-action'] = AnalyticsUtility.formatAction(
          'column_icon_list_item_cta',
          iconItem.button.text,
        );
        iconItem.button.attributes['da-label'] = AnalyticsUtility.formatLabel([
          iconItem.headline || '',
          iconItem.description || '',
        ]);
      }

      // Bottom CTA Link
      if (iconItem.cta) {
        iconItem.cta.attributes = {};
        iconItem.cta.attributes['da-category'] = daCategory;
        iconItem.cta.attributes['da-action'] = AnalyticsUtility.formatAction(
          'column_icon_list_item_bottom_link',
          iconItem.cta.text,
        );
        iconItem.cta.attributes['da-label'] = AnalyticsUtility.formatLabel([
          iconItem.headline || '',
          iconItem.description || '',
        ]);
      }

      return iconItem;
    });
  }

  return <ColumnIconListSlice {...sliceView} />;
};
