import { useContext } from 'react';
import XS from '@ww-digital/xs-sdk';

import { MarketUtility } from '../../components/Utility/MarketUtility.ts';
import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import wwUtility from '../../ww.utility.ts';
import GUA from '@ww-digital/xs-plugin-gua';
import type { MarketContextType } from '../../context/market.context.ts';
import { MarketContext } from '../../context/market.context.ts';
import { useLocation } from 'react-router-dom';

export const ExecuteFunctions_GREX154 = (
  pathname: string,
  variant: string,
  variables?: any,
) => {
  const visitorId = Storage.getCookieValue('ww_browser_id', false);
  const gua = new GUA();
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const { search } = useLocation();

  if (variant === 'control') {
    if (pathname === variables?.pathname) {
      (async () => {
        const xs = new XS({
          user: {
            visitorId: typeof visitorId === 'string' ? visitorId : '',
            locale: MarketUtility.getXSLocale(country, language),
          },
          baseUrl: wwUtility.getAPIDomain(),
          plugins: [gua],
        });

        await xs.fetchUserExperiments();

        // Run Exposure for Test
        await xs.exposeExperiment(variables?.testId);
      })();
    }
  }

  if (variant === 'v1') {
    if (pathname === variables?.pathname) {
      // Select the node that will be observed for mutations
      /* Masthead Slice update */
      const slice_one = document.getElementById(variables?.elementId);

      // Options for the observer (which mutations to observe)
      const config = { attributes: true, childList: true, subtree: true };

      // Callback function to execute when mutations are observed
      const callback_one = (mutationList: any, observer_one: any) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            if (
              slice_one !== null &&
              slice_one.querySelectorAll(`${variables?.cssTargetOne}`)[0] !==
                undefined &&
              slice_one
                .querySelectorAll(`${variables?.cssTargetOne}`)[0]
                .getAttribute('id') !== 'grex-154'
            ) {
              // Prevent duplicate Event Listeners from being added to element by running a conditional check against the existence of this ID
              slice_one
                .querySelectorAll(`${variables?.cssTargetOne}`)[0]
                .setAttribute('id', 'grex-154');

              slice_one
                .querySelectorAll(`${variables?.cssTargetOne}`)[0]
                .addEventListener('click', function (e) {
                  e.preventDefault();

                  (async () => {
                    const xs = new XS({
                      user: {
                        visitorId:
                          typeof visitorId === 'string' ? visitorId : '',
                        locale: MarketUtility.getXSLocale(country, language),
                      },
                      baseUrl: wwUtility.getAPIDomain(),
                      plugins: [gua],
                    });

                    await xs.fetchUserExperiments();

                    // Run Exposure for Test
                    await xs
                      .exposeExperiment(variables?.testId)
                      .then(function (e) {
                        if (wwUtility.isBrowser()) {
                          window.location.pathname = `/us/plans${search}`;
                        }
                      });
                  })();
                });
            }
          }
        }
      };

      if (slice_one !== null) {
        // Create an observer instance linked to the callback function
        const observer_one = new MutationObserver(callback_one);

        // Start observing the target node for configured mutations
        observer_one.observe(slice_one, config);
      }
    }
  }
};
