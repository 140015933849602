import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';

import type { SectionContextType } from '../../../context/section.context';

import { SectionContext } from '../../../context/section.context.ts';
import { SlicesContainer } from '../../Slices/SlicesContainer/SlicesContainer.tsx';

import FooterQuery from './graphql/FooterQuery.graphql';

const FooterContainerVisible = (): JSX.Element | null => {
  const section = useContext<SectionContextType>(SectionContext);

  const { loading, error, data } = useQuery(FooterQuery, {
    variables: { section },
  });

  if (loading || error || !data?.section?.footerSliceData) {
    return null;
  }

  return (
    <footer id="footer">
      <SlicesContainer
        slices={JSON.parse(data.section.footerSliceData)}
        type="global"
        region="footer"
      />
    </footer>
  );
};

export const FooterContainer = (): JSX.Element | null => {
  const section = useContext<SectionContextType>(SectionContext);

  return section ? <FooterContainerVisible /> : null;
};
