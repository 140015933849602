import { useContext } from 'react';
import _ from 'lodash';

import type { RawAttribute } from '@ww-digital/xs-sdk';
import type { ExperimentContextType } from '../context/experiment.context';
import type { SliceContainerProps } from '../components/Slices/SliceContainer/SliceContainer';
import type { SlicesContainerProps } from '../components/Slices/SlicesContainer/SlicesContainer';
import type { RedirectsProps } from '../components/Route/ContentRoute/ContentRoute';

import { ExperimentContext } from '../context/experiment.context.ts';
import { useLocation } from 'react-router-dom';

export interface ExperimentType {
  path?: {
    id: string;
    name: string;
    data: RedirectsProps | string;
  };
  slice?: {
    id: string;
    type?: string;
    name: string;
    data: SliceContainerProps['slice'];
  };
  slices?: {
    id: string;
    name: string;
    data: SlicesContainerProps['slices'];
  };
  attributes?: {
    name: string;
    dynamicCTA?: RawAttribute;
  };
  /*augmentingFunctions?: {
    id: string;
    name: string;
    data: string;
  }*/
}

export interface LimiterPagesType {
  page: string;
}

export interface LimiterIdsType {
  id: string;
}

export interface LimiterOptionsType {
  pages: LimiterPagesType[];
  id: string;
  ids: LimiterIdsType[];
  page: string;
}

export interface CalendarType {
  dates: {
    start: string;
    end: string;
  };
  method: 'update' | 'replace' | 'remove';
  update: SliceContainerProps['slice'];
}

const sliceTypeMap: Record<string, string> = {
  bmi_calculator: 'BMICalculatorSlice',
  body: 'BodySlice',
  callout: 'CalloutSlice',
  columns_icon_list: 'ColumnIconListSlice',
  color_block_masthead: 'ColorBlockMastheadSlice',
  content_card_grid_auto: 'ContentCardGridAutoSlice',
  content_card_grid_manual: 'ContentCardGridManualSlice',
  email_capture: 'EmailCaptureSlice',
  faq: 'FAQSlice',
  features_list: 'FeaturesListSlice',
  food_card_grid: 'FoodCardGridSlice',
  footer_legal: 'FooterLegalSlice',
  footer_menu: 'FooterMenuSlice',
  footnote: 'FootnoteSlice',
  gallery_carousel: 'GalleryCarouselSlice',
  icon_card: 'IconCardSlice',
  image: 'ImageSlice',
  immersive_masthead: 'ImmersiveMastheadSlice',
  navigation: 'NavigationSlice',
  large_product_square: 'LargeProductSquareSlice',
  member_carousel: 'MemberCarouselSlice',
  numbered_steps: 'NumberedStepsSlice',
  plans_differentiator: 'PlansDifferentiatorSlice',
  plans_duration: 'PlansDurationSlice',
  promotion_banner: 'PromotionBannerSlice',
  pull_quote: 'PullQuoteSlice',
  small_product_square: 'SmallProductSquareSlice',
  smart_plate: 'SmartPlateSlice',
  sources: 'SourcesSlice',
  table: 'TableSlice',
  tabs_link: 'TabsLinkSlice',
  testimonial_carousel: 'TestimonialCarouselSlice',
  testimonial: 'TestimonialSlice',
  tout: 'ToutSlice',
  two_column: 'TwoColumnSlice',
  video: 'VideoSlice',
  video_grid: 'VideoGridSlice',
  video_masthead: 'VideoMastheadSlice',
  video_slideshow: 'VideoSlideshowSlice',
  masthead_grid: 'MastheadGridSlice',
  weight_health_homepage: 'WeightHealthHomepageSlice',
  weight_health_homepage_bottom: 'WeightHealthHomepageBottomSlice',
};

export const useExperiment = (
  slice?: SliceContainerProps['slice'],
): ExperimentType => {
  const pathname = useLocation().pathname;
  const { experiments: xsExperiments, experimentsHeader: xsExperimentsHeader } =
    useContext<ExperimentContextType>(ExperimentContext);
  const experiments: ExperimentType = {};

  // Get the current active tests on this page from the header.
  const activeTests = xsExperimentsHeader?.split(',');
  if (activeTests && activeTests.length > 0) {
    activeTests.forEach((test) => {
      const [testId = '', testVariation = ''] = test.split(':');

      if (testId && testVariation) {
        const activeTest = Object.values(xsExperiments).find(
          (experiment) => experiment.experimentId === parseInt(testId, 10),
        );

        const activeVariations = activeTest?.variations || {};

        const activeAttributes = Object.values(activeVariations).find(
          (variation) =>
            variation.isActive &&
            variation.variationId === parseInt(testVariation, 10),
        )?.attributes;
        const activeName = activeTest?.experimentTextId || '';

        if (activeAttributes) {
          experiments.attributes = {
            name: activeName,
            ...activeAttributes,
          };

          // For experiments that involve path swapping.
          if (activeAttributes.path) {
            experiments.path = {
              id: testId,
              name: activeName,
              data: activeAttributes.path.attributeValue,
            };
          }

          // Handle Slices Container
          if (slice?.slices && slice?.contentId) {
            const configuration = slice
              ? Object.keys(activeAttributes).find(
                  (attr) => attr === `configuration`,
                )
              : false;

            if (configuration && activeAttributes[configuration]) {
              try {
                const parsedConfig = JSON.parse(
                  `${activeAttributes[configuration].attributeValue}`,
                );

                parsedConfig?.selector?.forEach(
                  (element: {
                    target?: string;
                    targets: { target?: string }[];
                  }) => {
                    if (
                      (slice?.contentId || slice?.type) &&
                      element?.target &&
                      (slice?.contentId === element?.target ||
                        slice?.type === element?.target)
                    ) {
                      experiments.slices = {
                        id: testId,
                        name: activeName,
                        data: element,
                      };
                    } else if (element?.targets) {
                      element?.targets.forEach((targets) => {
                        if (targets?.target === slice.contentId) {
                          experiments.slices = {
                            id: testId,
                            name: activeName,
                            data: element,
                          };
                        }
                      });
                    }
                  },
                );
              } catch (ex) {
                console.warn(ex);
              }
            }
          }

          // For experiments that involve slice swapping based on Slice ID.
          const sliceId = slice
            ? Object.keys(activeAttributes).find(
                (attr) => attr === `slice-id--${slice.id}`,
              )
            : false;
          if (sliceId && activeAttributes[sliceId]) {
            try {
              const data = JSON.parse(
                `${activeAttributes[sliceId].attributeValue}`,
              );
              experiments.slice = {
                id: sliceId,
                name: activeName,
                data: !_.isEmpty(data)
                  ? {
                      id: data.id,
                      __typename: sliceTypeMap[data.type],
                      ...data.data,
                    }
                  : null,
              };
            } catch (ex) {
              console.warn(ex);
            }
          }

          // For experiments that involve slice swapping based on Slice Type.
          const sliceType = slice
            ? Object.keys(activeAttributes).find(
                (attr) => attr === slice.__typename,
              )
            : false;

          if (sliceType && activeAttributes[sliceType]) {
            try {
              const foundSlice = JSON.parse(
                `${activeAttributes[sliceType].attributeValue}`,
              );

              if (foundSlice?.data?.limiterOptions) {
                foundSlice?.data?.limiterOptions.forEach(
                  (limiter: LimiterOptionsType) => {
                    if (limiter?.pages && !limiter?.id) {
                      limiter?.pages.forEach((pages: LimiterPagesType) => {
                        if (pages?.page === pathname) {
                          experiments.slice = {
                            id: slice.id,
                            type: sliceType,
                            name: activeName,
                            data: !_.isEmpty(foundSlice)
                              ? {
                                  id: foundSlice.id,
                                  __typename: sliceTypeMap[foundSlice.type],
                                  ...foundSlice.data,
                                }
                              : null,
                          };
                        }
                      });
                    } else if (limiter?.ids && !limiter?.page) {
                      limiter?.ids.forEach((ids: LimiterIdsType) => {
                        if (ids?.id === slice.id) {
                          experiments.slice = {
                            id: slice.id,
                            type: sliceType,
                            name: activeName,
                            data: !_.isEmpty(foundSlice)
                              ? {
                                  id: foundSlice.id,
                                  __typename: sliceTypeMap[foundSlice.type],
                                  ...foundSlice.data,
                                }
                              : null,
                          };
                        }
                      });
                    } else if (limiter?.id === slice.id && limiter?.pages) {
                      limiter?.pages.forEach((pages: LimiterPagesType) => {
                        if (pages?.page === pathname) {
                          experiments.slice = {
                            id: slice.id,
                            type: sliceType,
                            name: activeName,
                            data: !_.isEmpty(foundSlice)
                              ? {
                                  id: foundSlice.id,
                                  __typename: sliceTypeMap[foundSlice.type],
                                  ...foundSlice.data,
                                }
                              : null,
                          };
                        }
                      });
                    } else if (limiter?.page === pathname && limiter?.ids) {
                      limiter?.ids.forEach((ids: LimiterIdsType) => {
                        if (ids?.id === slice.id) {
                          experiments.slice = {
                            id: slice.id,
                            type: sliceType,
                            name: activeName,
                            data: !_.isEmpty(foundSlice)
                              ? {
                                  id: foundSlice.id,
                                  __typename: sliceTypeMap[foundSlice.type],
                                  ...foundSlice.data,
                                }
                              : null,
                          };
                        }
                      });
                    }
                  },
                );
              }
            } catch (ex) {
              console.warn(ex);
            }
          }
        }
      }
    });
  }

  return experiments;
};
