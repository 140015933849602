import React, { useRef, useContext } from 'react';
import _ from 'lodash';

import type { CardGridSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/CardGridSlice/CardGridSlice';
import type { SmallCardProps } from '@ww-digital/web-palette-react/dist/components/Card/SmallCard/SmallCard';
import type { LargeCardProps } from '@ww-digital/web-palette-react/dist/components/Card/LargeCard/LargeCard';
import type { MarketContextType } from '../../../context/market.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';

import { CardGridSlice } from '@ww-digital/web-palette-react/dist/components/Slice/CardGridSlice/CardGridSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { Counter } from '../../Utility/Counter.ts';

type SmallCardPropsExtended = SmallCardProps & {
  id: string;
  type: 'content' | 'custom';
};

type LargeCardPropsExtended = LargeCardProps & {
  id: string;
  type: 'content' | 'custom';
};

interface ContentCardGridManualSliceContainerProps {
  slice: CardGridSliceProps & {
    cardList: SmallCardPropsExtended[];
    largeCard?: LargeCardPropsExtended;
  };
  daCategory: string;
  topLevelCategory?: string;
}

export const ContentCardGridManualSliceContainer = ({
  slice,
  daCategory,
  topLevelCategory = '',
}: ContentCardGridManualSliceContainerProps): JSX.Element | null => {
  const sliceIndex = useRef<number>(
    Counter.getCounter('card-grids-slice-number'),
  );

  const entitlementContext =
    useContext<EntitlementContextType>(EntitlementContext);
  const marketContext = useContext<MarketContextType>(MarketContext);

  const processCard = (
    card: SmallCardPropsExtended | LargeCardPropsExtended,
    index: number,
    isLargeCard: boolean,
  ) => {
    if (!card) {
      return card;
    }

    card.cardActionAttributes = AnalyticsUtility.formatCardAnalytics({
      type: isLargeCard
        ? AnalyticsUtility.cardTypes.TYPE_CONTENT_CARD_GRID_MANUAL_LARGE
        : AnalyticsUtility.cardTypes.TYPE_CONTENT_CARD_GRID_MANUAL_SMALL,
      pageIndex: 1, // always 1 as there is no pager for this type of slice.
      sliceIndex: sliceIndex.current, // card grid slice index on the page
      cardIndex: index, // card index in slice
      topLevelCategory,
      cardId: card.id,
      cardTitle: card.title,
      entitlement: entitlementContext.entitlement,
    });

    // No need to alter link for custom card.
    // Editors already enter market/entitlement prefixes.
    if (card.type === 'custom') {
      return card;
    }

    const { marketBasePath } = marketContext;
    const { entitlementBasePath } = entitlementContext;

    card.link = `${marketBasePath}${entitlementBasePath}${card.link?.toLowerCase()}`;

    return card;
  };

  if (!_.isArray(slice.cardList)) {
    return null;
  }

  const daAction = AnalyticsUtility.formatAction(
    'content_card_grid_manual_cta',
    slice.button.text,
  );
  const daLabel = AnalyticsUtility.formatLabel(slice.heading?.text);

  slice.button.attributes = {};
  slice.button.attributes['da-category'] = daCategory;
  slice.button.attributes['da-action'] = daAction;
  slice.button.attributes['da-label'] = daLabel;

  const largeCard = processCard(_.cloneDeep(slice.largeCard), 1, true);
  const cardList = _.cloneDeep(slice.cardList).map((smallCard, index) =>
    processCard(smallCard, ++index, false),
  );

  return <CardGridSlice {...slice} cardList={cardList} largeCard={largeCard} />;
};
