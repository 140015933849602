import React from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import type { NavigationSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/NavigationSlice/NavigationSlice';
import type { ExtendedMenuItemType } from '@ww-digital/web-palette-react/dist/components/Navigation/Menu/Menu';

import { NavigationSlice } from '@ww-digital/web-palette-react/dist/components/Slice/NavigationSlice/NavigationSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface NavigationSliceContainerProps {
  daCategory: string;
  slice: NavigationSliceProps;
}

export const NavigationSliceContainer = ({
  daCategory,
  slice,
}: NavigationSliceContainerProps): JSX.Element => {
  const location = useLocation();

  /**
   * Returns the primarySubNav items based on if a link has active
   * or active trail state
   */
  const getPrimarySubNavMenu = (links: NavigationSliceProps['primary']) => {
    if (!links) {
      return null;
    }

    const activeLinks = links.filter((link) => link.activeTrail || link.active);
    if (activeLinks.length) {
      return activeLinks[0].subMenu;
    }

    return null;
  };

  /**
   * return array of links with active trail or active state
   * for link with active path and sets link's parent's active trail if the
   * link has a parent and is the active link.
   */
  const matchActivePath = (
    path: string,
    links: NavigationSliceProps['primary'],
  ) => {
    return links && _.isArray(links)
      ? links.map((link) => {
          const linkUrl = stripDomain(link.url);
          // for when the current menu item's url matches the active path
          if (linkUrl === path) {
            // add active state to currLink
            link.active = true;
            link.activeTrail = true;
          }

          // for when the current menu item has a submenu and the url doesn't match the active path
          if (link.subMenu) {
            link.subMenu = matchActivePath(path, link.subMenu);

            if (
              link.subMenu.filter(
                (subMenuItem: ExtendedMenuItemType) => subMenuItem.active,
              ).length
            ) {
              link.activeTrail = true;
            }
          }
          return link;
        })
      : undefined;
  };

  /**
   * strip the domain off the url
   */
  const stripDomain = (url: string) => url.replace(/^.*\/\/[^/]+/, '');

  // Adds analytics attributes to links.
  const addAnalyticsAttributes = (
    prefix: string,
    links?: ExtendedMenuItemType[],
  ): ExtendedMenuItemType[] | undefined => {
    if (!links) {
      return links;
    }

    return links && _.isArray(links)
      ? links.map((linkData) => {
          // Discard Drupal-entered link.attributes for now (only used by FooterLegalSlice)
          const link = Object.assign({}, linkData);
          delete link.attributes;

          const attributes = {
            'da-category': daCategory,
            'da-action': AnalyticsUtility.formatAction(prefix, link.text),
            'da-label': AnalyticsUtility.formatLabel(link.text),
          };

          return {
            ...link,
            attributes,
            subMenu: addAnalyticsAttributes(prefix, link.subMenu),
          };
        })
      : undefined;
  };

  // Add analytics to CTA and hide on certain urls.
  const updateCTA = (cta: NavigationSliceProps['cta']) => {
    if (!cta) {
      return cta;
    }

    const ctaUrl = stripDomain(cta.url);

    if (location.pathname === ctaUrl) {
      // Hide CTA if we are on the page the CTA links to.
      return null;
    } else {
      const attributes = {
        'da-category': daCategory,
        'da-action': AnalyticsUtility.formatAction('nav_header', cta.text),
        'da-label': AnalyticsUtility.formatLabel(cta.text),
      };

      return {
        ...cta,
        attributes,
        mobileAttributes: {
          ...attributes,
          'da-action': AnalyticsUtility.formatAction('nav_mobile', cta.text),
        },
      };
    }
  };

  // Update account icon fill.
  const updateAccontIcon = (links: NavigationSliceProps['secondary']) => {
    if (!links) {
      return links;
    }

    return links && _.isArray(links)
      ? links.map((link) => {
          if (link.icon === 'account') {
            return {
              ...link,
              noFill: true,
              hideText: true,
            };
          }

          return link;
        })
      : undefined;
  };

  // Add analytics attributes to the menu links.
  let primaryLinks = addAnalyticsAttributes('nav_header', slice.primary);
  primaryLinks = matchActivePath(location.pathname, primaryLinks);

  const primaryLinksSubNav = getPrimarySubNavMenu(primaryLinks);

  const primaryMobileLinks = addAnalyticsAttributes('nav_mobile', primaryLinks);

  let secondaryLinks = addAnalyticsAttributes('nav_header', slice.secondary);
  secondaryLinks = updateAccontIcon(secondaryLinks);

  let secondaryMobileLinks = addAnalyticsAttributes(
    'nav_mobile',
    slice.secondary,
  );
  secondaryMobileLinks = updateAccontIcon(secondaryMobileLinks);

  const navCTA = updateCTA(slice.cta);

  return (
    <NavigationSlice
      key={location.pathname}
      {...slice}
      cta={navCTA}
      primary={primaryLinks}
      primaryMobile={primaryMobileLinks}
      primaryMenuSubNavItems={primaryLinksSubNav}
      secondary={secondaryLinks}
      secondaryMobile={secondaryMobileLinks}
    />
  );
};
