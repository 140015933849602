import React, { useContext, useRef } from 'react';
import _ from 'lodash';

import type { IconItemProps } from '@ww-digital/web-palette-react/dist/components/Item/IconItem/IconItem';
import type { FeaturesListSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/FeaturesListSlice/FeaturesListSlice';
import type { MarketContextType } from '../../../context/market.context';

import { FeaturesListSlice } from '@ww-digital/web-palette-react/dist/components/Slice/FeaturesListSlice/FeaturesListSlice';
import { MarketContext } from '../../../context/market.context.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { PricingUtility } from '../../Utility/PricingUtility.ts';

interface FeaturesListContainerProps {
  daCategory: string;
  slice: FeaturesListSliceProps;
}

const IntroPricingRecurly = (
  introInit: FeaturesListSliceProps['intro'],
  introPricing: React.MutableRefObject<FeaturesListSliceProps['intro']>,
): void => {
  const marketContext = useContext<MarketContextType>(MarketContext);
  const intro = _.cloneDeep(introInit);

  if (!intro) {
    return;
  }

  // If Pricing Enabled, use standard recurly checkout URL.
  if (intro.button && intro.pricing) {
    intro.button.url = PricingUtility.getRecurlyPricingUrl(
      marketContext.country,
      marketContext.language,
    );
  }

  introPricing.current = intro;
};

const ItemPricingRecurly = (
  iconItemInit: IconItemProps,
  iconIndex: number,
  itemsPricing: React.MutableRefObject<IconItemProps[]>,
): void => {
  const marketContext = useContext<MarketContextType>(MarketContext);
  const iconItem = _.cloneDeep(iconItemInit);

  // All dynamic pricing and zip code entry is disabled.
  iconItem.textPrice = { text: '', price: '' };

  // If Pricing Enabled, use standard recurly checkout URL.
  if (iconItem.button && iconItem.pricing) {
    iconItem.button.url = PricingUtility.getRecurlyPricingUrl(
      marketContext.country,
      marketContext.language,
    );
  }

  // Clean out any price tokens.
  if (PricingUtility.hasPricingToken(iconItem?.description)) {
    iconItem.description = '';
  }
  if (
    PricingUtility.hasPricingToken(iconItem?.pricingText) ||
    PricingUtility.hasPricingToken(iconItem?.textPrice?.text)
  ) {
    iconItem.pricingText = '';
    iconItem.textPrice = { text: '' };
  }

  itemsPricing.current[iconIndex] = iconItem;
};

export const FeaturesListSliceContainer = ({
  daCategory,
  slice,
}: FeaturesListContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);
  const introPricing = useRef<FeaturesListSliceProps['intro']>();
  const itemsPricing = useRef<IconItemProps[]>(sliceView.list?.iconItems || []);

  // Pricing for the intro.
  IntroPricingRecurly(sliceView.intro, introPricing);
  sliceView.intro = introPricing.current || sliceView.intro;

  // Pricing for the icon items.
  if (sliceView.list?.iconItems) {
    sliceView.list?.iconItems.forEach((iconItem, iconIndex) => {
      ItemPricingRecurly(iconItem, iconIndex, itemsPricing);
    });
    sliceView.list.iconItems = itemsPricing.current;
  }

  // Other analytics attributes.
  if (sliceView.intro?.button) {
    sliceView.intro.button.attributes = {};
    sliceView.intro.button.attributes['da-category'] = daCategory;
    sliceView.intro.button.attributes['da-action'] =
      AnalyticsUtility.formatAction(
        'features_list_intro_cta',
        sliceView.intro.button.text,
      );
    sliceView.intro.button.attributes['da-label'] =
      AnalyticsUtility.formatLabel([
        sliceView.intro.headline.text || '',
        sliceView.intro.eyebrow?.text || '',
      ]);
  }

  if (sliceView.list?.button) {
    sliceView.list.button.attributes = {};
    sliceView.list.button.attributes['da-category'] = daCategory;
    sliceView.list.button.attributes['da-action'] =
      AnalyticsUtility.formatAction(
        'features_list_list_cta',
        sliceView.list.button.text,
      );
    sliceView.list.button.attributes['da-label'] = AnalyticsUtility.formatLabel(
      sliceView.list.button.text,
    );
  }

  if (sliceView.list?.iconItems) {
    sliceView.list.iconItems = sliceView.list.iconItems.map((iconItem) => {
      // Analytics tracking for icon item button.
      if (iconItem.button) {
        iconItem.button.attributes = {};
        iconItem.button.attributes['da-category'] = daCategory;
        iconItem.button.attributes['da-action'] = AnalyticsUtility.formatAction(
          'features_list_item_cta',
          iconItem.button.text,
        );
        iconItem.button.attributes['da-label'] = AnalyticsUtility.formatLabel([
          iconItem.headline,
          iconItem.description,
        ]);
      }

      // Bottom CTA Link
      if (iconItem.cta) {
        iconItem.cta.attributes = {};
        iconItem.cta.attributes['da-category'] = daCategory;
        iconItem.cta.attributes['da-action'] = AnalyticsUtility.formatAction(
          'features_list_item_bottom_link',
          iconItem.cta.text,
        );
        iconItem.cta.attributes['da-label'] = AnalyticsUtility.formatLabel([
          iconItem.headline,
          iconItem.description,
        ]);
      }

      return iconItem;
    });
  }

  return <FeaturesListSlice {...sliceView} />;
};
