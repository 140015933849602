import { useQuery } from 'react-apollo';
import ZipInfoQuery from './graphql/ZipInfoQuery.graphql';

export interface UseZipOwnerResponse {
  ownerId: string | null;
  loading: boolean;
  error: Error | null;
}

export const useZipOwner = (zip: string): UseZipOwnerResponse => {
  const {
    data,
    error: zipError,
    loading: zipLoading,
  } = useQuery(ZipInfoQuery, {
    variables: { zip },
    skip: zip === '99999',
    fetchPolicy: 'no-cache',
  });

  const newOwnerId = data?.zipInfo ? data.zipInfo.ownerId || '37' : '37';

  return {
    ownerId: newOwnerId,
    loading: zipLoading,
    error: zipError as Error | null,
  };
};
