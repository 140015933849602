import React, { useContext } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import _ from 'lodash';

import type { MarketContextType } from '../../../context/market.context';
import type { ConfigContextType } from '../../../context/config.context';

import { ConfigContext } from '../../../context/config.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { MarketUtility } from '../../Utility/MarketUtility.ts';
import { AppUtility } from '../../Utility/AppUtility.ts';

import { GeoBanner } from '@ww-digital/web-palette-react/dist/components/Banner/GeoBanner/GeoBanner';

import GeoBannerQuery from './graphql/GeoBannerQuery.graphql';
import UpdateGeoBannerClosed from './graphql/UpdateGeoBannerClosed.graphql';
import GeoLocationQuery from './graphql/GeoLocationQuery.graphql';

const GeoBannerContainerVisible = (): JSX.Element | null => {
  const { translations } = useContext<ConfigContextType>(ConfigContext);
  const { country: marketCountry, language: marketLanguage } =
    useContext<MarketContextType>(MarketContext);

  const { loading, error, data } = useQuery(GeoLocationQuery, { ssr: false });
  const [updateGeoBannerClosed] = useMutation(UpdateGeoBannerClosed);

  if (loading || error || !data || !data.userLocation?.country) {
    return null;
  }

  // Physical country.
  const detectedCountry = _.get(data, 'userLocation.country').toLowerCase();
  const detectedCountryInfo = MarketUtility.getCountryInfo(detectedCountry);

  // If physical country doesn't match market country
  // and we have a site for that country, show the banner.
  if (!detectedCountryInfo || detectedCountry === marketCountry) {
    return null;
  }

  const geobannerLinks = MarketUtility.getCountryHrefMap(detectedCountry).map(
    (userHrefLanguage) => {
      // Grab language code from href eg: /ca/fr/ => fr.
      const urlLanguage = detectedCountryInfo.isMultilingual
        ? `/${userHrefLanguage.href.slice(-3, -1)}`
        : '';
      return {
        url: AppUtility.getBaseUrl(detectedCountry) + urlLanguage,
        attributes: {
          'da-action': 'geobanner',
          'da-category': 'navigation',
          'da-label': userHrefLanguage.hreflang.toLowerCase(),
        },
      };
    },
  );

  return (
    <GeoBanner
      closeMessage={translations.GEOBANNER_CLOSE_LABEL}
      siteCountryCode={marketCountry}
      siteCountryLanguage={marketLanguage}
      locationCountryCode={detectedCountry}
      message={translations.GEOBANNER_CONTENT_LABEL}
      onClosePress={() =>
        updateGeoBannerClosed({
          variables: { closed: true },
        })
      }
      separatorLink={translations.GEOBANNER_OR_LABEL}
      geobannerLinks={geobannerLinks}
    />
  );
};

export const GeoBannerContainer = (): JSX.Element | null => {
  const { loading, error, data } = useQuery(GeoBannerQuery, { ssr: false });

  if (loading || error || data.geoBannerClosed) {
    return null;
  }

  return <GeoBannerContainerVisible />;
};
