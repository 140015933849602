import React, { useCallback, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import type { MarketContextType } from '../../context/market.context';
import type { ConfigContextType } from '../../context/config.context';
import type { EntitlementContextType } from '../../context/entitlement.context';

import { MarketContext } from '../../context/market.context.ts';
import { ConfigContext } from '../../context/config.context.ts';
import { EntitlementContext } from '../../context/entitlement.context.ts';
import { FormatUtility } from '../Utility/FormatUtility.ts';
import wwUtility from '../../ww.utility.ts';

interface GTMProps {
  category?: string;
  name?: string;
  prefix?: string;
}

export const GTM = ({ category, name, prefix }: GTMProps): JSX.Element => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { config } = useContext<ConfigContextType>(ConfigContext);
  const location = useLocation();
  const { id, siteLanguage, siteRegion } = config.gtm;

  // Get the page_name prefix for a user type.
  const getUserType = useCallback(() => {
    switch (entitlement) {
      case 'Member':
        return 'subc';
      case 'Registered':
        return 'regi';
      default:
        return 'visi';
    }
  }, [entitlement]);

  // Get the site_section value based on entitlement.
  const getSiteSection = useCallback(() => {
    switch (entitlement) {
      case 'Member':
        return 'subscriber';
      case 'Registered':
        return 'registered';
      default:
        return 'visitor';
    }
  }, [entitlement]);

  const getPathParts = useCallback(() => {
    // Get URL parts after the country, language, and entitlements.
    const partsToRemove = [country, language, 'm', 'r', 'cms'];

    return location.pathname
      .split('/')
      .reduce((acc: string[], part: string) => {
        if (part && !partsToRemove.includes(part)) {
          acc.push(FormatUtility.sanitizeUrl(part));
        }
        return acc;
      }, []);
  }, [country, language, location]);

  // Get the name for this page.
  const getCategory = useCallback(() => {
    const categoryRegEx = /^[A-Za-z_-]+$/;
    // If we have a category that is not empty, use it.
    if (category) {
      return category;
    }

    // Otherwise, use the path to try to build a category.
    const pathParts = getPathParts();

    // If the url has multiple parts, and the first part of the URL is
    // alphabetical and longer than 2 characters, then use it as the category.
    if (
      pathParts.length > 1 &&
      pathParts[0].length > 2 &&
      categoryRegEx.test(pathParts[0])
    ) {
      return pathParts[0];
    }

    // Otherwise, it is uncategorized.
    return 'uncategorized';
  }, [getPathParts, category]);

  // Get the name suffix for this page.
  const getNameSuffix = useCallback(() => {
    // If we have a name that is not empty, use it.
    if (name) {
      return name;
    }

    // Otherwise build a name from the URL path.
    const pathParts = getPathParts();

    return pathParts.join('-');
  }, [getPathParts, name]);

  // Get the name prefix for this page.
  const getNamePrefix = useCallback(() => {
    // Return the prefix if it is not empty, otherwise use the user type.
    return prefix || getUserType();
  }, [getUserType, prefix]);

  // Get the page_name value with the prefix and region key added.
  const getName = useCallback(() => {
    const prefix = getNamePrefix();
    const suffix = getNameSuffix();

    return `${prefix}:${siteRegion}:${suffix}`;
  }, [getNamePrefix, getNameSuffix, siteRegion]);

  const getDigitalData = useCallback(() => {
    return {
      site_platform: 'internal_rackspace',
      site_section: getSiteSection(),
      site_system: 'cms',
      site_version: '2.0',
      site_language: siteLanguage,
      site_region: siteRegion,
      page_name: getName(),
      page_category: getCategory(),
    };
  }, [getSiteSection, getName, getCategory, siteLanguage, siteRegion]);

  useEffect(() => {
    if (window.dataLayer) {
      window.digital_data = window.digital_data
        ? { ...window.digital_data, ...getDigitalData() }
        : getDigitalData();
      window.dataLayer.push({ event: 'view_changed' });
    }
  }, [getDigitalData]);

  const digitalData = getDigitalData();
  const apiDomain = wwUtility.getAPIDomain();
  const globalId = 'GTM-MSJ7L7S';

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
  if (!window.google_tag_manager) {
    window.dataLayer = window.dataLayer || [];
    window.digital_data = ${JSON.stringify(digitalData)};
    window.GTM_ID = '${id}';
    window.GTM_GA4_ID = '${globalId}';
  }
`}
        </script>
        <script
          async
          type="application/javascript"
          src={`${apiDomain}/privacy-control/js/privacy.min.js`}
        />
      </Helmet>
    </>
  );
};
