import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import type { ConfigContextType } from '../../../context/config.context';
import type { MarketContextType } from '../../../context/market.context';
import type { QueryStringContextType } from '../../../context/querystring.context';

import { Counter } from '../../Utility/Counter.ts';
import { ProductsUtility } from '../../Utility/ProductsUtility.ts';
import { SliceContainer } from '../SliceContainer/SliceContainer.tsx';
import { ConfigContext } from '../../../context/config.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { QueryStringContext } from '../../../context/querystring.context.ts';
import { useExperiment } from '../../../hooks/useExperiment.tsx';
import { ExperimentContext } from '../../../context/experiment.context.ts';
import { ExperimentContextType } from '../../../context/experiment.context.ts';
import wwUtility from '../../../ww.utility.ts';
import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import { getAlteredSlices } from '../../Utility/xsUtility/getAlteredSlices.ts';
export interface SlicesContainerProps {
  slices: any;
  contentId?: string;
  category?: string;
  topLevelCategory?: string;
  region?: string;
  type: string;
}

export const SlicesContainer = ({
  slices: slicesInit,
  contentId = '',
  region,
  ...rest
}: SlicesContainerProps): JSX.Element | null => {
  const { translations } = useContext<ConfigContextType>(ConfigContext);
  const { pathname } = useLocation();
  const marketContext = useContext<MarketContextType>(MarketContext);
  const queryArgs = useContext<QueryStringContextType>(QueryStringContext);
  const { bucketedExperiments: experiments } =
    useContext<ExperimentContextType>(ExperimentContext);

  let slices = _.cloneDeep(slicesInit);
  const experimentData: ExperimentContextType = useContext(ExperimentContext);
  let enterTest = false;
  const searchParam = useLocation().search;
  const market = `${marketContext.country}-${marketContext.language}`;

  const xsSliceList = {
    slices: slices,
    contentId: contentId,
  };

  // Pass ContentID
  const { slices: xsSlices } = useExperiment(xsSliceList);

  // If there are no slices, render nothing.
  if (!slicesInit || slicesInit.length <= 0) {
    return null;
  }

  // Alter slices based on XS experiments
  if (Object.keys(experiments).length) {
    for (const experiment in experiments) {
      const { attributes, configuration, experimentTextId } =
        experiments[experiment];
      // skip experiment if it's not in the region
      if (
        region &&
        configuration?.regions &&
        !configuration.regions.includes(region as 'header' | 'footer' | 'body')
      ) {
        continue;
      }

      slices = getAlteredSlices(
        slices,
        attributes,
        configuration,
        experimentTextId,
        pathname,
        market,
        region,
      );
    }
  }

  // On Checkout Page, filter the slices
  if (
    ProductsUtility.isCheckoutPage(
      queryArgs,
      pathname,
      translations,
      marketContext.country,
      marketContext.language,
    )
  ) {
    switch (region) {
      // In header, do nothing
      case 'header':
        break;

      // In footer, remove all slices except FooterLegalSlice
      case 'footer':
        slices = slices.filter(
          (slice: SlicesContainerProps['slices']) =>
            slice.__typename === 'FooterLegalSlice',
        );
        break;

      // In body, remove all slices except PlansDurationSlice
      case 'body':
        slices = slices.filter(
          (slice: SlicesContainerProps['slices']) =>
            slice.__typename === 'PlansDurationSlice',
        );
        break;

      default:
      // Do nothing;
    }
  }

  // If XS Slice update matches slice found on page
  if (xsSlices?.data?.slices) {
    xsSlices.data.slices.forEach(
      (element: {
        slice: string;
        method: { methodType: string; position?: string; index?: number };
        content: object;
        audience: {
          queryParam: string;
          queryKey: string;
          funneledEntry: boolean;
        };
      }) => {
        if (element?.audience) {
          const xsAudienceCookies = Storage.getCookieValue(
            'xsAudienceCookies',
            false,
          );

          if (element?.audience?.queryParam) {
            const regex = new RegExp(element?.audience?.queryParam);
            enterTest = regex.test(searchParam);
          } else if (element?.audience?.funneledEntry) {
            enterTest = element?.audience?.funneledEntry;
          } else if (
            element?.audience?.queryKey &&
            xsAudienceCookies !== null &&
            typeof xsAudienceCookies === 'string'
          ) {
            enterTest = !!xsAudienceCookies.includes(
              element?.audience?.queryKey,
            );
          }

          //  Add test to xsAudienceCookie if we're in browser, we have query params present, and the item isn't already in the xsAudienceCookie list
          if (
            wwUtility.isBrowser() &&
            enterTest &&
            typeof xsAudienceCookies === 'string' &&
            !xsAudienceCookies.includes(element?.audience?.queryKey)
          ) {
            const xsCookiesListGeneration =
              xsAudienceCookies + '.' + element?.audience?.queryKey;
            document.cookie = `xsAudienceCookies=${xsCookiesListGeneration};expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
          }

          if (
            wwUtility.isBrowser() &&
            enterTest &&
            xsAudienceCookies === null
          ) {
            document.cookie = `xsAudienceCookies=${element?.audience?.queryKey};expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
          }

          if (
            experimentData.cookies &&
            (new RegExp(
              '^(?=.*xsAudienceCookies=)(?=.*' +
                element?.audience?.queryKey +
                '(.|;)).*$',
            ).test(experimentData.cookies) ||
              new RegExp(
                '^(?=.*xsAudienceCookiesTemp=)(?=.*' +
                  element?.audience?.queryKey +
                  '(.|;)).*$',
              ).test(experimentData.cookies))
          ) {
            enterTest = true;
          }
        }

        // Find ID and inject new slice either above or below index of provided slice...
        if (
          _.find(slices, (slice) => slice.id === element.slice) &&
          (!element?.audience || enterTest)
        ) {
          const sliceIndex = slices.findIndex((subElement: { id: string }) => {
            return subElement.id === element.slice;
          });

          if (sliceIndex && sliceIndex !== -1) {
            if (
              element?.method?.methodType &&
              element?.method?.methodType === 'inject'
            ) {
              if (
                element?.method?.position &&
                element?.method?.position === 'above'
              ) {
                slices.splice(sliceIndex, 0, element.content);
              } else {
                slices.splice(sliceIndex + 1, 0, element.content);
              }
            }
          }
        }

        // Find Index of the given Container ID and inject new slice at that position...
        if (element?.method?.index && (!element?.audience || enterTest)) {
          if (
            slices &&
            element?.content &&
            element?.method?.methodType &&
            element?.method?.methodType === 'inject'
          ) {
            slices.splice(element?.method?.index, 0, element.content);
          }
        }
      },
    );
  }

  // If there is a PlansDurationSlice, add a PlansDisclaimerSlice to the end.
  if (_.find(slices, (slice) => slice.__typename === 'PlansDurationSlice')) {
    const id = `plans-disclaimer-slice-${Counter.getCounter(
      'plans-disclaimer-slice',
    )}`;
    slices.push({
      id,
      __typename: 'PlansDisclaimerSlice',
    });
  }

  const sliceContainer = (slice: SlicesContainerProps['slices']) => {
    return (
      <SliceContainer
        key={slice.id}
        slice={slice}
        contentId={contentId}
        region={region}
        xsExperimentTextId={slice.xsExperimentTextId}
        {...rest}
      />
    );
  };

  const sliceContainerWithId = (slice: SlicesContainerProps['slices']) => {
    return slice.__typename === 'NavigationSlice' && slice.sticky ? (
      sliceContainer(slice)
    ) : (
      <div id={`slice-id--${slice.id}`}>{sliceContainer(slice)}</div>
    );
  };

  return (
    <>
      {slices.map((slice: SlicesContainerProps['slices']) => (
        <React.Fragment key={slice.id}>
          {sliceContainerWithId(slice)}
        </React.Fragment>
      ))}
    </>
  );
};
