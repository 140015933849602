import React from 'react';
import _ from 'lodash';

import type { SmartPlateSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/SmartPlateSlice/SmartPlateSlice';

import { SmartPlateSlice } from '@ww-digital/web-palette-react/dist/components/Slice/SmartPlateSlice/SmartPlateSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface SmartPlateSliceContainerProps {
  daCategory: string;
  slice: SmartPlateSliceProps;
}

export const SmartPlateSliceContainer = ({
  daCategory,
  slice,
}: SmartPlateSliceContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);

  if (sliceView?.plates) {
    sliceView.plates.forEach((plate) => {
      plate.plateAttributes = {
        'da-category': daCategory,
        'da-action': AnalyticsUtility.formatAction(
          'smart_plate_plate',
          plate.plateName,
        ),
        'da-label': AnalyticsUtility.formatLabel(plate.plateName),
      };

      if (plate.grid?.cta) {
        plate.grid.cta.gridAttributes = {
          'da-category': daCategory,
          'da-action': AnalyticsUtility.formatAction(
            'smart_plate_grid_cta',
            plate.grid.cta.text,
          ),
          'da-label': AnalyticsUtility.formatLabel(plate.grid.cta.text),
        };

        plate.grid.dishes?.forEach((dish) => {
          dish.dishAttributes = {
            'da-category': daCategory,
            'da-action': AnalyticsUtility.formatAction(
              'smart_plate_grid_dish',
              dish.dishName,
            ),
            'da-label': AnalyticsUtility.formatLabel(dish.dishName),
          };
        });
      }
    });
  }

  return <SmartPlateSlice {...sliceView} />;
};
