import React, { ReactNode } from 'react';
import _ from 'lodash';

import { RetinaProvider } from '@ww-digital/web-palette-react/dist/components/Image/Retina/RetinaProvider';

export const ConfigRetina = {
  process: (
    data: Record<string, Record<string, boolean>>,
    children: ReactNode,
  ) => {
    const retinaEnabled = _.get(
      data,
      'config.retina.enabled',
      false,
    ) as boolean;

    return (
      <RetinaProvider retinaEnabled={retinaEnabled}>{children}</RetinaProvider>
    );
  },
};
