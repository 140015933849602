import React, { ReactNode, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import type { MarketContextType } from '../../../context/market.context';

import { MarketContext } from '../../../context/market.context.ts';
import { AppUtility } from '../../Utility/AppUtility.ts';

export interface RouteMetadataProps {
  children?: ReactNode;
  description?: string;
  image?: string;
  og?: {
    description?: string;
    image?: string;
    title?: string;
    type?: string;
    image_alt?: string;
    image_type?: string;
    image_width?: string;
    image_height?: string;
  };
  robots?: string;
  title?: string;
  canonicalUrl?: string;
}

export const RouteMetadata = ({
  children,
  description = '',
  image = '',
  robots = '',
  title = '',
  canonicalUrl,
  og,
}: RouteMetadataProps): JSX.Element => {
  const { country } = useContext<MarketContextType>(MarketContext);
  const location = useLocation();

  // Fallback to default title, description, and image if there are not og specific ones.
  const defaultTitle = 'Weight Loss Program, Recipes & Help';
  const ogTitle = og?.title || title;
  const ogDescription = og?.description || description;
  const ogImage = og?.image || image;
  const canonical =
    canonicalUrl ??
    `${AppUtility.getDomain(country, true)}${location.pathname}`;

  return (
    <Helmet defaultTitle={defaultTitle}>
      {robots && <meta name="robots" content={robots} />}
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {image && <link rel="image_src" href={image} />}
      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && (
        <meta property="og:description" content={ogDescription} />
      )}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {og?.image_alt && <meta property="og:image:alt" content={og.image_alt} />}
      {og?.image_type && (
        <meta property="og:image:type" content={og.image_type} />
      )}
      {og?.image_width && (
        <meta property="og:image:width" content={og.image_width} />
      )}
      {og?.image_height && (
        <meta property="og:image:height" content={og.image_height} />
      )}
      {og?.type && <meta property="og:type" content={og.type} />}
      <link rel="canonical" href={canonical} />
      {children}
    </Helmet>
  );
};
