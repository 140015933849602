import React, { useState, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import type { MarketContextType } from '../../../context/market.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';

import { MarketContext } from '../../../context/market.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { FoodUtility } from '../../Utility/FoodUtility.ts';

import { Container } from '@ww-digital/web-palette-react/dist/components/Container/Container/Container';
import { InputGroup } from '@ww-digital/web-palette-react/dist/components/Input/InputGroup/InputGroup';

import FoodSearchQuery from './graphql/FoodSearchQuery.graphql';

export const FoodRoute = (): JSX.Element | null => {
  const [searchText, setSearchText] = useState<string>('');
  const { country, language, marketBasePath } =
    useContext<MarketContextType>(MarketContext);
  const { entitlementBasePath } =
    useContext<EntitlementContextType>(EntitlementContext);

  const { loading, error, data } = useQuery(FoodSearchQuery, {
    variables: { searchText },
  });

  const onSubmit = (searchText: string) => {
    setSearchText(searchText);
  };

  if (error || loading) {
    return null;
  }

  let resultList;
  const { results } = data?.foodSearch || {};

  if (results.length) {
    resultList = _.map(results, (food) => {
      const url = FoodUtility.getURL(food, country, language);
      return (
        <div key={url}>
          <Link to={`${marketBasePath}${entitlementBasePath}${url}`}>
            {food.displayName} ({food.type})
          </Link>
        </div>
      );
    });
  } else {
    resultList = <div>No results found.</div>;
  }

  return (
    <Container>
      <InputGroup
        // @ts-expect-error TODO
        autoFocus
        buttonText="Submit"
        hasError={error}
        loading={loading}
        onSubmit={onSubmit}
        placeholder="Enter a food name to search for"
        text={searchText}
      />
      {resultList}
    </Container>
  );
};
