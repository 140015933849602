import React from 'react';

export type DisclaimerContextType = {
  setDisclaimer: React.Dispatch<React.SetStateAction<string>>;
  disclaimer: string;
};

export const defaultDisclaimerContext: DisclaimerContextType = {
  setDisclaimer: () => {},
  disclaimer: '',
};

export const DisclaimerContext = React.createContext<DisclaimerContextType>(
  defaultDisclaimerContext,
);
