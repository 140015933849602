import React, { useContext } from 'react';
import _ from 'lodash';

import type { ConfigContextType } from '../../../context/config.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { AnalyticsProps, MetaProps } from '../ContentRoute/ContentRoute';
import type { HreflangType } from '../../Metadata/Hreflang/Hreflang';

import { GTM } from '../../GTM/GTM.tsx';
import { JSONLD } from '../../Metadata/JSONLD/JSONLD.tsx';
import { Hreflang } from '../../Metadata/Hreflang/Hreflang.tsx';
import { RouteMetadata } from '../../Metadata/RouteMetadata/RouteMetadata.tsx';
import { SlicesContainer } from '../../Slices/SlicesContainer/SlicesContainer.tsx';
import { MarketUtility } from '../../Utility/MarketUtility.ts';
import { AppUtility } from '../../Utility/AppUtility.ts';
import { ConfigContext } from '../../../context/config.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';

import styles from './PageRoute.module.scss';

interface PageRouteProps {
  data: {
    content: {
      id: string;
      sliceData: string;
      analytics: AnalyticsProps;
      meta: MetaProps;
      hreflangs: HreflangType[];
      contentEntitlement: EntitlementContextType['entitlement'];
    };
  };
  path: string;
}

export const PageRoute = ({
  data: { content },
  path,
}: PageRouteProps): JSX.Element => {
  const { config } = useContext<ConfigContextType>(ConfigContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const isHomepage = path === '' || path === '/';
  const title = _.get(content, 'meta.title');
  const sliceData = content.sliceData ? JSON.parse(content.sliceData) : null;

  const getDigitalData = (isHomepage: boolean) => {
    // Override the default analytics info for the homepage.
    if (isHomepage) {
      return {
        category: 'home',
        name: 'home',
      };
    }

    const { topLevelCategory, ...analytics } = content.analytics;

    return analytics;
  };

  const getJSONLD = (isHomepage: boolean) => {
    // Set some special JSON LD data for the homepage only.
    if (isHomepage) {
      // Get links to all of the enabled social networks for this market.
      const sameAs = _.chain(config.socialNetworks)
        .filter((socialNetwork) => socialNetwork.enabled)
        .mapValues((socialNetwork) => socialNetwork.link)
        .values()
        .value();

      const data = {
        sameAs,
      };

      return data;
    }

    // No JSON LD for other random pages.
    return null;
  };

  const getHomepageHreflangs = () => {
    return MarketUtility.getHrefMap().map((h) => ({
      code: h.hreflang,
      url: `${AppUtility.getDomain(h.country, true)}${h.href}`,
    }));
  };

  const digitalData = getDigitalData(isHomepage);
  const topLevelCategory = content.analytics?.topLevelCategory || '';
  const jsonLD = getJSONLD(isHomepage);
  const hreflangs = isHomepage
    ? getHomepageHreflangs()
    : entitlement === 'Guest'
      ? content.hreflangs
      : null;

  return (
    <>
      <h1 className={styles.hidden}>{title}</h1>
      <GTM key={`gtm-${content.id}`} {...digitalData} />
      <RouteMetadata {...content.meta} />
      <Hreflang hreflangs={hreflangs} />
      <JSONLD key="jsonLd" content={jsonLD} />
      <SlicesContainer
        slices={sliceData}
        type="page"
        region="body"
        category={digitalData.category}
        topLevelCategory={topLevelCategory}
        contentId={content.id}
      />
    </>
  );
};
