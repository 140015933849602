import React from 'react';

import type { ImmersiveMastheadSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/ImmersiveMastheadSlice/ImmersiveMastheadSlice';

import { ImmersiveMastheadSlice } from '@ww-digital/web-palette-react/dist/components/Slice/ImmersiveMastheadSlice/ImmersiveMastheadSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface ImmersiveMastheadSliceContainerProps {
  daCategory: string;
  slice: ImmersiveMastheadSliceProps;
}

export const ImmersiveMastheadSliceContainer = ({
  slice,
  daCategory,
}: ImmersiveMastheadSliceContainerProps): JSX.Element => {
  if (slice?.promoBanner.cta) {
    slice.promoBanner.cta.attributes = {};
    slice.promoBanner.cta.attributes['da-category'] = daCategory;
    slice.promoBanner.cta.attributes['da-action'] =
      AnalyticsUtility.formatAction(
        'immersive_masthead_promotion_banner_cta',
        slice.promoBanner.cta.text,
      );
    slice.promoBanner.cta.attributes['da-label'] = AnalyticsUtility.formatLabel(
      slice.promoBanner.cta.text,
    );
  }
  return <ImmersiveMastheadSlice {...slice} />;
};
