import React, { ReactNode, useContext } from 'react';
import { ApolloProvider as RAApolloProvider } from 'react-apollo';
import { useLocation } from 'react-router-dom';

import type { MarketContextType } from '../../../context/market.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { QueryStringContextType } from '../../../context/querystring.context';
import type { ApolloManagerProps } from './ApolloManager';

import { MarketContext } from '../../../context/market.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { QueryStringContext } from '../../../context/querystring.context.ts';

export interface ApolloProviderProps {
  children: ReactNode;
  manager: ApolloManagerProps;
}

export const ApolloProvider = ({
  children,
  manager,
}: ApolloProviderProps): JSX.Element => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { sid } = useContext<QueryStringContextType>(QueryStringContext);
  const location = useLocation();

  const client = manager.getClient(
    country,
    language,
    entitlement,
    location.pathname,
    sid || '',
  );

  // @ts-expect-error TODO
  return <RAApolloProvider client={client}>{children}</RAApolloProvider>;
};
