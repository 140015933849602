import wwUtility from '../../ww.utility.ts';
import { headerContainerType } from '../Regions/HeaderContainer/HeaderContainer.tsx';
import { AnalyticsUtility } from './AnalyticsUtility.ts';

import type { MarketContextType } from '../../context/market.context';
import type { EntitlementContextType } from '../../context/entitlement.context';
import type { ExtendedMenuItemType } from '@ww-digital/web-palette-react/dist/components/Navigation/Menu/Menu';

interface BreadcrumbProps {
  name: string;
  slug: string;
}

export const getBlogRoute = (marketContext: MarketContextType) => {
  const { country, language } = marketContext;
  return wwUtility.getMarketField(country, language, 'blog');
};

const getLink = (
  slug: string,
  marketContext: MarketContextType,
  entitlementContext: EntitlementContextType,
  forcedSlug = false,
) => {
  if (!slug && !forcedSlug) {
    return '';
  }

  const blogRoute = getBlogRoute(marketContext);
  const { marketBasePath } = marketContext;
  const { entitlementBasePath } = entitlementContext;

  return `${marketBasePath}${entitlementBasePath}/${blogRoute}${
    slug ? '/' + slug : ''
  }`.toLowerCase();
};

export const getBreadcrumb = (
  breadcrumbData: BreadcrumbProps[],
  blogTitle: string,
  marketContext: MarketContextType,
  entitlementContext: EntitlementContextType,
) => {
  const result = {
    variant: 'breadcrumbs',
    linksArray: [
      {
        text: blogTitle,
        url: getLink('', marketContext, entitlementContext, true),
      },
    ],
  };

  if (!(breadcrumbData && breadcrumbData.length > 0)) {
    return result;
  }

  breadcrumbData.forEach((crumb) =>
    result.linksArray.push({
      text: crumb.name,
      url: getLink(crumb.slug, marketContext, entitlementContext),
    }),
  );

  return result;
};

export const getSubmenu = (
  submenuData: BreadcrumbProps[],
  marketContext: MarketContextType,
  entitlementContext: EntitlementContextType,
) => {
  if (!(submenuData && submenuData.length > 0)) {
    return null;
  }

  const linksArray = submenuData.map((crumb) => ({
    text: crumb.name,
    url: getLink(crumb.slug, marketContext, entitlementContext),
  }));

  return {
    variant: 'navigation',
    linksArray: linksArray.length ? linksArray : null,
  };
};

export const getSiblings = (
  siblingsData: BreadcrumbProps[],
  marketContext: MarketContextType,
  entitlementContext: EntitlementContextType,
  currentSlug: string,
): ExtendedMenuItemType[] => {
  if (!(siblingsData && siblingsData.length > 0)) {
    return [];
  }

  const daCategory = AnalyticsUtility.formatCategory(
    entitlementContext.entitlement,
    headerContainerType,
  );

  return siblingsData.map((crumb) => {
    const processedData: ExtendedMenuItemType = {
      text: crumb.name,
      title: null,
      url: getLink(crumb.slug, marketContext, entitlementContext),
      attributes: {
        'da-category': daCategory,
        'da-action': AnalyticsUtility.formatAction('nav_header', crumb.name),
        'da-label': AnalyticsUtility.formatLabel(crumb.name),
      },
    };

    if (`/${crumb.slug}` === currentSlug) {
      processedData.active = true;
      processedData.activeTrail = true;
    }

    return processedData;
  });
};
