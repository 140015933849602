import React from 'react';
import _ from 'lodash';

import type { SectionFourProps } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionFour/SectionFour';
import type { SectionFiveProps } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionFive/SectionFive';
import type { SectionSixProps } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionSix/SectionSix';
import type { SectionSevenProps } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionSeven/SectionSeven';
import type { SectionEightProps } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionEight/SectionEight';

import { SectionFour } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionFour/SectionFour';
import { SectionFive } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionFive/SectionFive';
import { SectionSix } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionSix/SectionSix';
import { SectionSeven } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionSeven/SectionSeven';
import { SectionEight } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionEight/SectionEight';
import { weightHealthHomepageData } from './weightHealthHomepageSliceData.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface WeightHealthHomepageBottomSliceProps {
  daCategory: string;
  slice: {
    sectionFour?: Partial<SectionFourProps>;
    sectionFive?: Partial<SectionFiveProps>;
    sectionSix?: Partial<SectionSixProps>;
    sectionSeven?: Partial<SectionSevenProps>;
    sectionEight?: Partial<SectionEightProps>;
  };
}

export const WeightHealthHomepageBottomSliceContainer = ({
  daCategory,
  slice,
}: WeightHealthHomepageBottomSliceProps): JSX.Element => {
  const sliceView = _.merge({}, weightHealthHomepageData, slice);

  // Button analytics
  if (sliceView.sectionThree?.button) {
    sliceView.sectionThree.button.attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        'tout_cta',
        sliceView.sectionThree.button.children,
      ),
      'da-label': AnalyticsUtility.formatLabel([
        sliceView.sectionThree.heading.text,
      ]),
    };
  }
  if (sliceView.sectionFour?.button) {
    sliceView.sectionFour.button.attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        'tout_cta',
        sliceView.sectionFour.button.children,
      ),
      'da-label': AnalyticsUtility.formatLabel([
        sliceView.sectionFour.heading.text,
      ]),
    };
  }

  // Slices themselves will be added later.
  if (sliceView.sectionSeven?.button) {
    sliceView.sectionSeven.button.attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        'tout_cta',
        sliceView.sectionSeven.button.children,
      ),
      'da-label': AnalyticsUtility.formatLabel([
        sliceView.sectionSeven.heading.text,
      ]),
    };
  }
  if (sliceView.sectionEight?.button) {
    sliceView.sectionEight.button.attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        'tout_cta',
        sliceView.sectionEight.button.children,
      ),
      'da-label': AnalyticsUtility.formatLabel([
        sliceView.sectionEight.heading.text,
      ]),
    };
  }

  return (
    <>
      {sliceView.sectionFour && <SectionFour {...sliceView.sectionFour} />}
      {sliceView.sectionFive && <SectionFive {...sliceView.sectionFive} />}
      {sliceView.sectionSix && <SectionSix {...sliceView.sectionSix} />}
      {sliceView.sectionSeven && <SectionSeven {...sliceView.sectionSeven} />}
      {sliceView.sectionEight && <SectionEight {...sliceView.sectionEight} />}
    </>
  );
};
