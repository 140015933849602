import React from 'react';

import type { TwoColumnProps } from '@ww-digital/web-palette-react/dist/components/TwoColumn/TwoColumn';
import type { SlicesContainerProps } from '../SlicesContainer/SlicesContainer';

import { TwoColumnSlice } from '@ww-digital/web-palette-react/dist/components/Slice/TwoColumnSlice/TwoColumnSlice';
import { SlicesContainer } from '../SlicesContainer/SlicesContainer.tsx';

interface TwoColumnSliceContainerProps {
  slice: TwoColumnProps & {
    columnOne: SlicesContainerProps['slices'];
    columnTwo: SlicesContainerProps['slices'];
  };
  category?: string;
  region?: string;
  type: string;
}

export const TwoColumnSliceContainer = ({
  slice,
  category = '',
  region,
  type,
}: TwoColumnSliceContainerProps): JSX.Element => {
  const { columnOne, columnTwo, ...otherSliceData } = slice;

  return (
    <TwoColumnSlice {...otherSliceData}>
      <SlicesContainer
        slices={columnOne}
        category={category}
        type={type}
        region={region}
      />
      <SlicesContainer
        slices={columnTwo}
        category={category}
        type={type}
        region={region}
      />
    </TwoColumnSlice>
  );
};

export default TwoColumnSliceContainer;
