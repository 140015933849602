import React, { useContext, useMemo } from 'react';
import _ from 'lodash';

import type { ConfigContextType } from '../../../context/config.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';

import { CountryLink } from '@ww-digital/web-palette-react/dist/components/CountryLink/CountryLink';
import { DirectorySlice } from '@ww-digital/web-palette-react/dist/components/Slice/DirectorySlice/DirectorySlice';
import { Market } from '@ww-digital/web-palette-react/dist/components/Utility/Market';
import { ContentRoute } from '../ContentRoute/ContentRoute.tsx';
import { AppUtility } from '../../Utility/AppUtility.ts';
import { ConfigContext } from '../../../context/config.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';

interface Franchise {
  country: string;
  url: string;
}

const franchises: Franchise[] = [
  { country: 'at', url: 'https://www.weightwatchers.at/' },
  { country: 'bs', url: 'https://www.weightwatchersbahamas.com/' },
  { country: 'il', url: 'https://www.shomreymishkal.co.il/' },
];

interface MultilingualSite {
  country: string;
}

const multilingualSites: MultilingualSite[] = [
  { country: 'be' },
  { country: 'ca' },
  { country: 'ch' },
];

export const InternationalPageRoute = () => {
  const { translations } = useContext<ConfigContextType>(ConfigContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);

  const generateURL = useMemo(
    () => (country: string, subs: string[]) => {
      const urlString = `${AppUtility.getDomain(country, true)}/`;
      const franchise = franchises.find((f) => f.country === country);

      if (franchise) {
        return franchise.url;
      }
      const multilingualSite = multilingualSites.find(
        (ms) => ms.country === country,
      );
      if (multilingualSite) {
        return urlString + `${subs[1]}/${subs[0]}/`;
      }
      return urlString + country + '/';
    },
    [],
  );

  const getAllCountryData = () => {
    const countries = Object.entries(Market.countries).map((res) => {
      return {
        code: res[0],
        langs: res[1],
      };
    });

    return _.flatMap(
      countries.map((country) => {
        return Object.values(country.langs).map((lang) => {
          return buildCountryLinkObject(country.code, lang);
        });
      }),
    ).sort((a, b) => a.text.localeCompare(b.text));
  };

  const buildCountryLinkObject = (country: string, lang: any) => {
    const label = lang.hrefLang.toLowerCase();
    const urlSubs = label.split('-');

    return {
      flag: {
        countryCode: country,
        label: lang.countryName,
        size: 'medium',
      },
      link: {
        url: generateURL(country, urlSubs),
        attributes: {
          'da-action': 'international_page_click',
          'da-category': 'navigation',
          'da-label': label,
        },
        lang: lang.hrefLang,
      },
      text: lang.countryName,
    };
  };

  const directoryData = {
    heading: {
      text: translations.INTERNATIONAL_PAGE_HEADING,
      typog: 'headline3',
      ariaLevel: 2,
      variant: 'default',
    },
    footnote: translations.INTERNATIONAL_PAGE_FOOTNOTE,
    ListComponent: CountryLink,
    directory: getAllCountryData(),
  };

  return (
    <>
      {entitlement === 'Guest' && <DirectorySlice {...directoryData} />}
      <ContentRoute path="international" slicesRequired={false} />
    </>
  );
};
