export const executeFunctions_GREX9999 = (
  pathname: string,
  variant: string,
) => {
  if (variant === 'control') {
    if (pathname === '/us/') {
      console.log(`WW 000: This function runs on ${pathname} for ${variant}`);
    } else if (pathname === '/us/plans') {
      console.log(`WW 000: This function runs on ${pathname} for ${variant}`);
    } else if (pathname === '/us/how-it-works') {
      console.log(`WW 000: This function runs on ${pathname} for ${variant}`);
    }
  } else if (variant === 'v1') {
    if (pathname === '/us/') {
      console.log(`WW 000: This function runs on ${pathname} for ${variant}`);
    } else if (pathname === '/us/plans') {
      console.log(`WW 000: This function runs on ${pathname} for ${variant}`);
    } else if (pathname === '/us/how-it-works') {
      console.log(`WW 000: This function runs on ${pathname} for ${variant}`);
    }
  }
};
