import React, { useContext, useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { ConfigContextType } from '../../../context/config.context';

import { EntitlementUtility } from '../../Utility/EntitlementUtility.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { ConfigContext } from '../../../context/config.context.ts';

import { MeteredContentModal } from '@ww-digital/web-palette-react/dist/components/Modal/MeteredContentModal/MeteredContentModal';

import UserAuthQuery from '../../Redirects/EntitlementRedirect/graphql/UserAuthQuery.graphql';
import MeteredContentClientMutation from './graphql/MeteredContentClientMutation.graphql';

interface MeteredContentContainerProps {
  type: string;
  id: string;
}

const MeteredContentContainerVisible = ({
  type,
  id,
}: MeteredContentContainerProps): JSX.Element | null => {
  const { translations } = useContext<ConfigContextType>(ConfigContext);

  //const { limit, ctaUrl, loginUrl } = config.meteredContent || {};
  const ctaUrl = '';
  const loginUrl = '';
  const limit = 10;

  const { loading, error, data } = useQuery(UserAuthQuery, { ssr: false });
  const [
    updateMeteredContentItems,
    { data: dataMutation, loading: loadingMutation, error: errorMutation },
  ] = useMutation(MeteredContentClientMutation, {
    variables: {
      type,
      id,
      limit,
    },
  });

  useEffect(() => {
    updateMeteredContentItems();
  }, [updateMeteredContentItems]);

  // Only show metered content if userAuth is empty (user is not logged in)
  if (
    loading ||
    error ||
    data?.userAuth ||
    loadingMutation ||
    errorMutation ||
    !dataMutation
  ) {
    return null;
  }

  const { updateMeteredContentItems: count } = dataMutation;
  const open = count > limit;

  if (!open) {
    return null;
  }

  return (
    <MeteredContentModal
      meteredContent={{
        asideText: translations.METERED_CONTENT_ASIDE_TEXT,
        cta: {
          text: translations.METERED_CONTENT_CTA_TEXT,
          url: ctaUrl,
        },
        footnote: translations.METERED_CONTENT_FOOTNOTE,
        headline: translations.METERED_CONTENT_HEADLINE,
        login: {
          text: translations.METERED_CONTENT_LOGIN_TEXT,
          url: loginUrl,
        },
        primaryText: translations.METERED_CONTENT_PRIMARY_LABEL,
      }}
    />
  );
};

export const MeteredContentContainer = ({
  type,
  id,
}: MeteredContentContainerProps): JSX.Element | null => {
  const { config } = useContext<ConfigContextType>(ConfigContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { enabled } = config.meteredContent || {};
  const isVisible =
    enabled &&
    entitlement &&
    entitlement === EntitlementUtility.entitlements.guest;

  return isVisible ? (
    <MeteredContentContainerVisible type={type} id={id} />
  ) : null;
};
