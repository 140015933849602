import React, { useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import type { MarketContextType } from '../../../context/market.context';
import type { IconItemProps } from '@ww-digital/web-palette-react/dist/components/Item/IconItem/IconItem';
import type { ColumnIconListSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/ColumnIconListSlice/ColumnIconListSlice';
import type { ConfigContextType } from '../../../context/config.context';
import type { QueryStringContextType } from '../../../context/querystring.context';
import type { OfferBundle } from '../../../hooks/useProducts';
import type { ConfigTranslationsLocale } from '../../Config/ConfigTranslations/ConfigTranslations';

import { ColumnIconListSlice } from '@ww-digital/web-palette-react/dist/components/Slice/ColumnIconListSlice/ColumnIconListSlice';

import { ConfigContext } from '../../../context/config.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { usePostalCodeAndLocation } from '../../../hooks/usePostalCodeAndLocation.ts';
import { useProducts } from '../../../hooks/useProducts.ts';
import { useZipOwner } from '../../../hooks/useZipOwner.ts';
import { useHasLocations } from '../../../hooks/useHasLocations.ts';
import { PricingUtility } from '../../Utility/PricingUtility.ts';
import { ProductsUtility } from '../../Utility/ProductsUtility.ts';
import { QueryStringContext } from '../../../context/querystring.context.ts';
import { Counter } from '../../Utility/Counter.ts';

interface ColumnIconListSliceContainerProps {
  daCategory: string;
  slice: ColumnIconListSliceProps;
}

interface IconItemPropsExtended extends IconItemProps {
  offerBundleId?: string;
}

// Recurly Item (only for /us/plans-franchise)
const ItemPricing = (
  iconItemInit: IconItemPropsExtended,
  iconIndex: number,
  itemsPricing: React.MutableRefObject<IconItemProps[]>,
  translations: ConfigTranslationsLocale,
  ownerId: string,
  offerBundles: OfferBundle[],
  offerBundlesLoading: boolean,
  postalCode: string,
  updateUserPostalCode: ({
    variables,
  }: {
    variables: { postalCode: string };
  }) => void,
  zipEntered: boolean,
  setZipEntered: (zip: boolean) => void,
  zipLoading: boolean,
  hasLocations: boolean,
): void => {
  const marketContext = useContext<MarketContextType>(MarketContext);
  const iconItem = _.cloneDeep(iconItemInit);
  const noZip = !postalCode;

  const pricingUrl = PricingUtility.getRecurlyPricingUrl(
    marketContext.country,
    marketContext.language,
  );

  const supportsLocation = () => {
    return marketContext.country === 'us';
  };

  const getLocationAware = (subscriptionType: string) => {
    const isLocationAware =
      supportsLocation() &&
      ProductsUtility.stEquals(subscriptionType, 'premium');
    return isLocationAware;
  };

  if (offerBundlesLoading || !offerBundles) {
    // Reset priceText and CTA button URL for SSR.
    iconItem.textPrice.text = '';
    if (iconItem.button) {
      iconItem.button.url = pricingUrl;
    }
    itemsPricing.current[iconIndex] = iconItem;
    return;
  }

  const offerBundle: OfferBundle | null =
    (offerBundles &&
      iconItem?.pricing &&
      ProductsUtility.getOfferBundle(
        offerBundles,
        iconItem.offerBundleId,
        iconItem.pricing.subscriptionType,
      )) ||
    null;

  const pricingInput = {
    buttonText: translations.PRICING_INPUT_BUTTON_TEXT,
    onSubmit: async (newPostalCode: string) => {
      await updateUserPostalCode({
        variables: { postalCode: newPostalCode },
      });
      setZipEntered(true);
    },
    placeholder: translations.PRICING_INPUT_PLACEHOLDER,
    regex: PricingUtility.zipcodeRegExp('us'),
    regexErrorMessage: translations.PRICING_INPUT_ERROR,
    loading: zipEntered && zipLoading,
  };

  if (offerBundle) {
    const subscriptionType = offerBundle.subscriptionType;

    iconItem.textPrice.text = ProductsUtility.getContentByKey(
      offerBundle.contents,
      'STARTING_AT_PRICE',
    );
    // Palette needs some non-blank value in order to display textPrice.
    iconItem.textPrice.price = ' ';

    iconItem.pricing.input = pricingInput;
    iconItem.pricing.zipText =
      ProductsUtility.stEquals(subscriptionType, 'premium') && !noZip
        ? translations.PRICING_ZIP_TEXT
        : '';
    iconItem.pricing.zipOrLocation = postalCode;
    if (iconItem.button) {
      // Pricing Url passes specific OfferBundleId if configured, otherwise SubscriptionType + OwnerID
      const queryArgs = iconItem.offerBundleId
        ? `ob=${iconItem.offerBundleId}`
        : `st=${ProductsUtility.subscriptionType(
            subscriptionType,
          )}&own=${ownerId}`;
      iconItem.button.url = `${pricingUrl}?${queryArgs}`;
    }

    // Error text when Studio not found on Premium
    const errorText =
      !hasLocations && ProductsUtility.stEquals(subscriptionType, 'premium')
        ? translations.PRICING_LOCATION_UNAVAILABLE_LABEL
        : '';

    iconItem.errorMessage = {
      text: errorText,
      red: false,
    };

    iconItem.showPricingForm =
      getLocationAware(subscriptionType) &&
      (zipLoading || noZip || !!errorText);

    // When Studios not founds, remove pricing text and zipcode button.
    if (errorText) {
      iconItem.textPrice = { text: '' };
      iconItem.pricing.zipText = '';
    }
  } else {
    // Initialization when no OfferBundle is returned.
    if (iconItem.button) {
      iconItem.button.url = pricingUrl;
    }
    if (
      iconItem.pricing &&
      getLocationAware(iconItem?.pricing?.subscriptionType) &&
      !noZip
    ) {
      iconItem.textPrice = { text: '' };
      iconItem.pricing.zipOrLocation = postalCode;
      iconItem.pricing.input = pricingInput;
      iconItem.pricing.zipText = translations.PRICING_ZIP_TEXT;
      iconItem.showPricingForm =
        getLocationAware(iconItem?.pricing?.subscriptionType) && zipLoading;
    }
  }
  itemsPricing.current[iconIndex] = iconItem;
};

export const RecurlyPlansFranchiseContainer = ({
  daCategory,
  slice,
}: ColumnIconListSliceContainerProps): JSX.Element => {
  const { sid, vid } = useContext<QueryStringContextType>(QueryStringContext);
  const { translations } = useContext<ConfigContextType>(ConfigContext);

  const sliceView = _.cloneDeep(slice);
  const sliceIndex = useRef<number>(
    Counter.getCounter('column-icon-list-slice-index'),
  );
  const itemsPricing = useRef<IconItemProps[]>(sliceView?.iconItems || []);

  sliceView.index = sliceIndex.current;

  // Will retrieve OfferBundle per IconItem, based on either offerBundleId or subscriptionType.
  const offerBundleInputs = slice.iconItems.map(
    (item: IconItemPropsExtended) => ({
      offerBundleId: item.offerBundleId || undefined,
      subscriptionType: item.offerBundleId
        ? undefined
        : item.pricing?.subscriptionType,
    }),
  );

  // @TODO also handle error return value
  const {
    loading: locationLoading,
    postalCode,
    updateUserPostalCode,
  } = usePostalCodeAndLocation();
  const { ownerId, loading: zipOwnerLoading } = useZipOwner(postalCode);
  const { hasLocations, loading: hasLocationsLoading } =
    useHasLocations(postalCode);
  const stg = !!sid && !!vid;
  const { offerBundles, loading: offerBundlesLoading } = useProducts(
    offerBundleInputs,
    ownerId,
    stg,
  );

  const [zipEntered, setZipEntered] = useState<boolean>(false);
  const [zipLoading, setZipLoading] = useState<boolean>(false);

  useEffect(() => {
    setZipLoading(
      locationLoading ||
        zipOwnerLoading ||
        hasLocationsLoading ||
        offerBundlesLoading,
    );
  }, [
    locationLoading,
    zipOwnerLoading,
    hasLocationsLoading,
    offerBundlesLoading,
  ]);

  // Pricing for the icon items.
  if (sliceView?.iconItems) {
    sliceView?.iconItems.forEach(
      (iconItem: IconItemProps, iconIndex: number) => {
        ItemPricing(
          iconItem,
          iconIndex,
          itemsPricing,
          translations,
          ownerId || '',
          offerBundles,
          offerBundlesLoading,
          postalCode,
          updateUserPostalCode,
          zipEntered,
          setZipEntered,
          zipLoading,
          hasLocations,
        );
      },
    );
    sliceView.iconItems = itemsPricing.current;
  }

  if (sliceView?.iconItems) {
    sliceView.iconItems = sliceView.iconItems.map((iconItem: IconItemProps) => {
      // Analytics tracking for icon item button.
      if (iconItem.button) {
        iconItem.button.attributes = {};
        iconItem.button.attributes['da-category'] = daCategory;
        iconItem.button.attributes['da-action'] = AnalyticsUtility.formatAction(
          'column_icon_list_item_cta',
          iconItem.button.text,
        );
        iconItem.button.attributes['da-label'] = AnalyticsUtility.formatLabel([
          iconItem.headline || '',
          iconItem.description || '',
        ]);
      }

      // Bottom CTA Link
      if (iconItem.cta) {
        iconItem.cta.attributes = {};
        iconItem.cta.attributes['da-category'] = daCategory;
        iconItem.cta.attributes['da-action'] = AnalyticsUtility.formatAction(
          'column_icon_list_item_bottom_link',
          iconItem.cta.text,
        );
        iconItem.cta.attributes['da-label'] = AnalyticsUtility.formatLabel([
          iconItem.headline || '',
          iconItem.description || '',
        ]);
      }

      return iconItem;
    });
  }

  return <ColumnIconListSlice {...sliceView} />;
};
