import GUA from '@ww-digital/xs-plugin-gua';
import XS from '@ww-digital/xs-sdk';
import {
  MarketContext,
  MarketContextType,
} from '../../context/market.context.ts';
import { useContext } from 'react';
import { MarketUtility } from '../../components/Utility/MarketUtility.ts';
import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import wwUtility from '../../ww.utility.ts';

export const FireExposure_GREX278 = (testId: string) => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const visitorId = Storage.getCookieValue('ww_browser_id', false);
  (async () => {
    const xs = new XS({
      user: {
        visitorId: typeof visitorId === 'string' ? visitorId : '',
        locale: MarketUtility.getXSLocale(country, language),
      },
      baseUrl: wwUtility.getAPIDomain(),
      plugins: [GUA],
    });

    await xs.fetchUserExperiments();

    // Run Exposure for Test
    await xs.exposeExperiment(testId);
  })();
};
