import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import _ from 'lodash';

import type { EmailCaptureSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/EmailCaptureSlice/EmailCaptureSlice';
import type { MarketContextType } from '../../../context/market.context';
import type { ConfigContextType } from '../../../context/config.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';

import { MarketContext } from '../../../context/market.context.ts';
import { ConfigContext } from '../../../context/config.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { MarketUtility } from '../../Utility/MarketUtility.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { PricingUtility } from '../../Utility/PricingUtility.ts';

import { EmailCaptureSlice } from '@ww-digital/web-palette-react/dist/components/Slice/EmailCaptureSlice/EmailCaptureSlice';

import EmailCaptureSliceSubscribeMutation from './graphql/EmailCaptureSliceSubscribeMutation.graphql';

interface EmailCaptureSliceContainerProps {
  slice: EmailCaptureSliceProps;
}

interface EmailCaptureSliceCheckboxes {
  optin: boolean;
  age: boolean;
  third: boolean;
}

interface EmailCaptureSliceForm {
  firstName?: string;
  lastName?: string;
  email?: string;
  telephone?: string;
  zip?: string;
}

interface EmailCaptureSliceFieldErrors {
  optinCheckbox?: string;
  ageCheckbox?: string;
  thirdCheckbox?: string;
  firstNameInput?: string;
  lastNameInput?: string;
  telephoneInput?: string;
  zipInput?: string;
  emailInput?: string;
}

interface EmailCaptureSliceMutationData {
  subscribeToNewsletter: {
    success: boolean;
    alreadySubscribed: boolean;
    invalidEmail: boolean;
  };
}

export const EmailCaptureSliceContainer = ({
  slice,
}: EmailCaptureSliceContainerProps): JSX.Element => {
  const { config, translations } = useContext<ConfigContextType>(ConfigContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const [form, setForm] = useState<EmailCaptureSliceForm>({});
  const [checkboxes, setCheckboxes] = useState<EmailCaptureSliceCheckboxes>({
    optin: false,
    age: false,
    third: false,
  });
  const [fieldErrors, setFieldErrors] = useState<EmailCaptureSliceFieldErrors>(
    {},
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // Get the error message to display for the email form based on the result.
  const getErrorMessage = (
    error: boolean,
    data: EmailCaptureSliceMutationData,
  ) => {
    if (error) {
      return translations.NEWSLETTER_SYSTEM_UNAVAILABLE_LABEL;
    } else if (data) {
      if (data.subscribeToNewsletter.invalidEmail) {
        return translations.NEWSLETTER_EMAIL_INVALID_LABEL;
      } else if (!data.subscribeToNewsletter.success) {
        return translations.NEWSLETTER_SYSTEM_UNAVAILABLE_LABEL;
      }
    }

    return '';
  };

  // Fire an analytics event.
  const fireEvent = (category: string, action: string, label = '') => {
    AnalyticsUtility.fireEvent(
      category,
      `email_capture_slice_${action}`,
      label,
    );
  };

  const daCategory = AnalyticsUtility.formatCategory(
    entitlement,
    'email_capture',
  );

  const getDALabel = (campaignid: string) => {
    return campaignid
      ? `allemailoptin:${AnalyticsUtility.formatLabel(campaignid)
          .toLowerCase()
          .trim()}`
      : 'allemailoptin';
  };

  const handleChange = (id: keyof EmailCaptureSliceForm, value: string) => {
    const formData = {
      ...form,
      [id]: value,
    };

    const field: keyof EmailCaptureSliceFieldErrors = `${id}Input`;
    const newFieldErrors = _.cloneDeep(fieldErrors);
    delete newFieldErrors[field];

    setForm(formData);
    setFieldErrors(newFieldErrors);
  };

  const toggleCheckmark = (id: keyof EmailCaptureSliceCheckboxes) => {
    const field: keyof EmailCaptureSliceFieldErrors = `${id}Checkbox`;
    const newFieldErrors = _.cloneDeep(fieldErrors);
    delete newFieldErrors[field];

    setCheckboxes((prevState) => {
      return Object.assign({}, prevState, {
        [id]: !prevState[id],
      });
    });

    setFieldErrors(newFieldErrors);
  };

  const validateField = (
    sliceView: EmailCaptureSliceProps,
    field: keyof EmailCaptureSliceProps,
  ) => {
    // If field not in form, it validates
    if (!sliceView[field] || !sliceView[field].fieldId) {
      return true;
    }

    const id: keyof EmailCaptureSliceCheckboxes = sliceView[field].fieldId;

    // Each checkbox on form must be checked ON.
    if (id === 'optin' || id === 'age' || id === 'third') {
      return checkboxes[id];
    }

    // Each input field on form must pass its Regex
    const regex = sliceView[field].regex;
    if (!regex) {
      return true;
    }
    return regex.test(form[id] || '');
  };

  const fieldError = (field: keyof EmailCaptureSliceFieldErrors) => {
    if (
      field === 'optinCheckbox' ||
      field === 'ageCheckbox' ||
      field === 'thirdCheckbox'
    ) {
      return translations.EMAIL_CAPTURE_CHECKBOX_ERROR;
    }

    if (field === 'firstNameInput' || field === 'lastNameInput') {
      return translations.EMAIL_CAPTURE_NAME_ERROR;
    }

    if (field === 'telephoneInput') {
      return translations.EMAIL_CAPTURE_TELEPHONE_ERROR;
    }

    if (field === 'zipInput') {
      return translations.PRICING_INPUT_ERROR;
    }

    if (field === 'emailInput') {
      return translations.EMAIL_CAPTURE_EMAIL_ADDRESS_ERROR_VALID;
    }

    return '';
  };

  const validateFields = (sliceView: EmailCaptureSliceProps) => {
    let isValid = true;
    const newFieldErrors: EmailCaptureSliceFieldErrors = {};
    Object.keys(sliceView).forEach((field) => {
      if (!validateField(sliceView, field as keyof EmailCaptureSliceProps)) {
        isValid = false;
        newFieldErrors[field as keyof EmailCaptureSliceFieldErrors] =
          fieldError(field as keyof EmailCaptureSliceFieldErrors);
      }
    });

    setFieldErrors(newFieldErrors);

    return isValid;
  };

  const prepareSliceView = () => {
    const rules = MarketUtility.localeRules(country, language);

    const sliceView = _.cloneDeep(slice);

    // Restore expected twoColumn ON setting as set by Editor,
    // rather than an object populated with null fields via GQL.
    if (sliceView.twoColumn !== null) {
      sliceView.twoColumn = {};
    }

    // Add localized settings to input fields.
    const hasBothNames =
      _.get(sliceView, 'firstNameInput.show') &&
      _.get(sliceView, 'lastNameInput.show');

    Object.keys(sliceView).forEach((field) => {
      if (field === 'firstNameInput') {
        sliceView[field] = sliceView[field].show
          ? {
              fieldId: 'firstName',
              regex: new RegExp(
                _.get(rules, 'validations.firstName.regex', ''),
              ),
              placeholder: hasBothNames
                ? translations.EMAIL_CAPTURE_FIRST_NAME_PLACEHOLDER
                : translations.EMAIL_CAPTURE_NAME_PLACEHOLDER,
              regexErrorMessage: translations.EMAIL_CAPTURE_NAME_ERROR,
              errorMessage: fieldErrors[field],
            }
          : null;
      }
      if (field === 'lastNameInput') {
        sliceView[field] = sliceView[field].show
          ? {
              fieldId: 'lastName',
              regex: new RegExp(_.get(rules, 'validations.lastName.regex'), ''),
              placeholder: hasBothNames
                ? translations.EMAIL_CAPTURE_LAST_NAME_PLACEHOLDER
                : translations.EMAIL_CAPTURE_NAME_PLACEHOLDER,
              regexErrorMessage: translations.EMAIL_CAPTURE_NAME_ERROR,
              errorMessage: fieldErrors[field],
            }
          : null;
      }
      if (field === 'emailInput') {
        sliceView[field] = sliceView[field].show
          ? {
              fieldId: 'email',
              regex: new RegExp(_.get(rules, 'validations.email.regex'), ''),
              placeholder: translations.EMAIL_CAPTURE_EMAIL_ADDRESS_PLACEHOLDER,
              regexErrorMessage:
                translations.EMAIL_CAPTURE_EMAIL_ADDRESS_ERROR_VALID,
              errorMessage: fieldErrors[field],
            }
          : null;
      }
      if (field === 'telephoneInput') {
        sliceView[field] = sliceView[field].show
          ? {
              fieldId: 'telephone',
              regex: new RegExp(_.get(rules, 'validations.phoneNo.regex'), ''),
              placeholder: translations.EMAIL_CAPTURE_TELEPHONE_PLACEHOLDER,
              regexErrorMessage: translations.EMAIL_CAPTURE_TELEPHONE_ERROR,
              errorMessage: fieldErrors[field],
            }
          : null;
      }
      if (field === 'zipInput') {
        sliceView[field] = sliceView[field].show
          ? {
              fieldId: 'zip',
              regex: new RegExp(PricingUtility.zipcodeRegExp(country)),
              placeholder: translations.PRICING_INPUT_PLACEHOLDER,
              regexErrorMessage: translations.PRICING_INPUT_ERROR,
              errorMessage: fieldErrors[field],
            }
          : null;
      }
      if (field === 'optinCheckbox') {
        sliceView[field] = sliceView[field].show
          ? {
              fieldId: 'optin',
              label: sliceView[field].label
                ? sliceView[field].label
                : config.emailCaptureModal.optin_checkbox_label,
              errorMessage: fieldErrors[field],
            }
          : null;
      }
      if (field === 'ageCheckbox') {
        sliceView[field] = sliceView[field].show
          ? {
              fieldId: 'age',
              label: sliceView[field].label
                ? sliceView[field].label
                : config.emailCaptureModal.age_checkbox_label,
              errorMessage: fieldErrors[field],
            }
          : null;
      }
      if (field === 'thirdCheckbox') {
        sliceView[field] =
          sliceView[field].show && sliceView[field].label
            ? {
                fieldId: 'third',
                label: sliceView[field].label,
                errorMessage: fieldErrors[field],
              }
            : null;
      }
    });

    _.set(sliceView, 'form.loading', loading);
    _.set(sliceView, 'form.success', success);
    _.set(sliceView, 'form.errorMessage', errorMessage);

    return sliceView;
  };

  const sliceView = prepareSliceView();

  const [
    subscribeToNewsletterMutation,
    { loading: loadingMutation, error: errorMutation, data: dataMutation },
  ] = useMutation(EmailCaptureSliceSubscribeMutation, { errorPolicy: 'all' });

  const mutationErrorMessage = getErrorMessage(!!errorMutation, dataMutation);

  useEffect(() => {
    if (errorMutation) {
      setLoading(loadingMutation);
      setErrorMessage(mutationErrorMessage);
    } else {
      setLoading(loadingMutation);
    }
  }, [errorMutation, loadingMutation, mutationErrorMessage]);

  const onSubmit = () => {
    const { email, firstName, lastName, telephone: phone, zip: zipcode } = form;
    const campaign = _.get(sliceView, 'form.campaignId') || undefined;

    if (!validateFields(sliceView)) {
      return;
    }

    const daLabel = getDALabel(campaign);

    fireEvent(daCategory, 'submit', daLabel);

    subscribeToNewsletterMutation({
      variables: { email, campaign, firstName, lastName, phone, zipcode },
    })
      .then(({ data: dataSubscribe, errors: errorsSubscribe }) => {
        const firstError =
          errorsSubscribe && errorsSubscribe.length > 0
            ? !!errorsSubscribe[0]
            : false;
        const subscribeErrorMessage = getErrorMessage(
          firstError,
          dataSubscribe,
        );

        if (firstError) {
          // Log an event to analytics for API failure.
          fireEvent(daCategory, 'api_down', daLabel);
        } else if (dataSubscribe) {
          if (dataSubscribe.subscribeToNewsletter.success) {
            fireEvent(daCategory, 'success', daLabel);

            fireEvent('registration', 'email_registration', email);

            setSuccess(dataSubscribe.subscribeToNewsletter.success);
          } else if (dataSubscribe.subscribeToNewsletter.invalidEmail) {
            fireEvent(daCategory, 'invalid_email', daLabel);

            setErrorMessage(subscribeErrorMessage);
          } else {
            // Fallback when success is false with no specific error code.
            fireEvent(daCategory, 'api_down', daLabel);

            setErrorMessage(subscribeErrorMessage);
          }
        }
      })
      .catch((error) => {
        // Log an event to analytics for API failure.
        fireEvent(daCategory, 'api_down', daLabel);
      });
  };

  if (sliceView.cta && !sliceView.ctaWithEmailInput) {
    sliceView.cta.onClick = onSubmit;
  }
  if (sliceView.firstNameInput) {
    sliceView.firstNameInput.onChangeText = (value: string) =>
      handleChange('firstName', value);
  }
  if (sliceView.lastNameInput) {
    sliceView.lastNameInput.onChangeText = (value: string) =>
      handleChange('lastName', value);
  }
  if (sliceView.emailInput) {
    sliceView.emailInput.onChangeText = (value: string) =>
      handleChange('email', value);
    if (sliceView.ctaWithEmailInput) {
      sliceView.emailInput.onSubmit = onSubmit;
    }
  }
  if (sliceView.telephoneInput) {
    sliceView.telephoneInput.onChangeText = (value: string) =>
      handleChange('telephone', value);
  }
  if (sliceView.zipInput) {
    sliceView.zipInput.onChangeText = (value: string) =>
      handleChange('zip', value);
  }
  if (sliceView.optinCheckbox) {
    sliceView.optinCheckbox.onChange = () => toggleCheckmark('optin');
  }
  if (sliceView.ageCheckbox) {
    sliceView.ageCheckbox.onChange = () => toggleCheckmark('age');
  }
  if (sliceView.thirdCheckbox) {
    sliceView.thirdCheckbox.onChange = () => toggleCheckmark('third');
  }

  return <EmailCaptureSlice {...sliceView} />;
};
