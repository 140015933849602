import type { ApolloError } from 'apollo-client';

import wwUtility from '../../ww.utility.ts';

export class AppUtility {
  static getEnv(): string {
    return wwUtility.getEnv();
  }

  static getDomain(
    country: string,
    isPublic?: boolean,
    forceProd = false,
  ): string {
    const env = wwUtility.getEnv();
    const domain = wwUtility.getVSDomain();

    // Local and AWS URLs.
    if (!forceProd && (env === 'local' || env === 'dev' || !isPublic)) {
      return domain;
    }

    // QA or Production.
    const envSubdomain = forceProd === true ? '' : AppUtility.getSubdomain();

    switch (country) {
      case 'se':
        return `https://www${envSubdomain}.viktvaktarna.se`;
      default:
        return `https://www${envSubdomain}.weightwatchers.com`;
    }
  }

  static getSubdomain() {
    const env = wwUtility.getEnv();

    switch (env) {
      case 'stg':
        return '.qat2';
      case 'stg2':
        return '.qat2';
      case 'stg3':
        return '.qat3';
      default:
        return '';
    }
  }

  static getBaseUrl(country: string, isPublic = true) {
    const domain = AppUtility.getDomain(country, isPublic);

    return `${domain}/${country}`;
  }

  static isPublicUrl(url: URL | Location) {
    const currHost = url.origin;
    const env = wwUtility.getEnv();

    switch (env) {
      case 'local':
        return false;
      default:
        return currHost ? currHost.search('//cmsio.') < 0 : false;
    }
  }

  static matchActivePath = (path: string, url: string) => {
    const stripDomain = (url: string) => url.replace(/^.*\/\/[^/]+/, '');
    const linkUrl = stripDomain(url);

    return linkUrl === path;
  };

  static getUrlFromUrlObject = (
    urlObject: URL,
    search = '',
    getSearchFromUrlIfEmpty = true,
    hash = '',
  ) => {
    return `${urlObject.origin}${urlObject.pathname}${
      !search && getSearchFromUrlIfEmpty ? urlObject.search : search
    }${!hash ? urlObject.hash : hash}`;
  };

  static isNetworkError = (error: ApolloError) => {
    if (error.graphQLErrors && error.graphQLErrors.length) {
      const firstError = error.graphQLErrors[0];
      if (firstError.message && firstError.message === 'Network timeout.') {
        return true;
      }
    }

    return false;
  };
}
