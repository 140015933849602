import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import type { MarketContextType } from '../../context/market.context';
import type { EntitlementContextType } from '../../context/entitlement.context';
import type { ConfigContextType } from '../../context/config.context';

import { ConfigContext } from '../../context/config.context.ts';
import { MarketContext } from '../../context/market.context.ts';
import { EntitlementContext } from '../../context/entitlement.context.ts';

import './ChatContainer.scss';

// Wrapper around https://github.com/seeden/react-google-publisher-tag
export const ChatContainer = (): JSX.Element | null => {
  const { pathname } = useLocation();
  const { country, marketBasePath } =
    useContext<MarketContextType>(MarketContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { config } = useContext<ConfigContextType>(ConfigContext);

  // Hide chat for Members, except if Sweden.
  const showChat = () => {
    const excludePaths = ['/find-a-workshop/browse-coaches'].map(
      (path) => `${marketBasePath}${path}`,
    );
    return (
      (entitlement !== 'Member' || country === 'se') &&
      !excludePaths.includes(pathname)
    );
  };

  if (showChat() && config.chat.enabled) {
    if (config.chat.service === 'livechat') {
      return (
        <Helmet>
          <script type="text/javascript">
            {`
                      window.__lc = window.__lc || {};
                      window.__lc.license = '${config.chat.licenseId}'
                      `}
          </script>
          <script
            async
            type="text/javascript"
            src="//cdn.livechatinc.com/tracking.js"
          />
        </Helmet>
      );
    }
    if (config.chat.service === 'boldchat') {
      return (
        <Helmet>
          <script type="text/javascript">
            {`
                    window._bcChatWindowUrl = '/static/boldchat/ww-basic-io/';
                    window._bcSessionApiKey = '${config.chat.licenseId}';
                    window._bcButtonId = '${config.chat.buttonId}';
                    `}
          </script>
          <script
            type="text/javascript"
            src="/static/boldchat/bc-start-scripts.min.js"
          />
        </Helmet>
      );
    }
  }

  return null;
};
