import React from 'react';

export type MarketContextType = {
  country: string;
  language: string;
  marketBasePath: string;
};

export const defaultMarketContext: MarketContextType = {
  country: 'us',
  language: 'en',
  marketBasePath: '/us',
};

export const MarketContext =
  React.createContext<MarketContextType>(defaultMarketContext);
