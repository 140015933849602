import React, { useContext, useEffect, useRef, useState } from 'react';
import { QueryResult, useQuery } from 'react-apollo';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import type { CardGridSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/CardGridSlice/CardGridSlice';
import type { ConfigContextType } from '../../../context/config.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { MarketContextType } from '../../../context/market.context';
import type { QueryStringContextType } from '../../../context/querystring.context';

import { ConfigContext } from '../../../context/config.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { QueryStringContext } from '../../../context/querystring.context.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { Counter } from '../../Utility/Counter.ts';
import { AppUtility } from '../../Utility/AppUtility.ts';

import { CardGridSlice } from '@ww-digital/web-palette-react/dist/components/Slice/CardGridSlice/CardGridSlice';
import { LoadingIndicator } from '@ww-digital/web-palette-react/dist/components/Indicator/LoadingIndicator/LoadingIndicator';
import { SmallCardProps } from '@ww-digital/web-palette-react/dist/components/Card/SmallCard/SmallCard';

import ContentCardGridAutoSliceContainerQuery from './graphql/ContentCardGridAutoSliceContainerQuery.graphql';

import styles from './ContentCardGridAutoSliceContainer.module.scss';

type SmallCardPropsExtended = {
  id: string;
  title: SmallCardProps['title'];
  link: SmallCardProps['link'];
  image?: SmallCardProps['image'];
  cardActionAttributes?: SmallCardProps['cardActionAttributes'];
};

type OtherParamsType = {
  [key: string]: string | boolean | number | undefined;
};

type AnalyticsDataType = {
  functionName: 'formatCategoryPageCardAnalytics';
  otherParams?: OtherParamsType;
};

interface ContentCardGridAutoSliceContainerProps {
  slice?: CardGridSliceProps & {
    cardList: SmallCardPropsExtended[];
    totalPages?: number;
    tids?: number[];
    excludedNids?: number[];
    analyticsData?: AnalyticsDataType;
    sort?: string;
    forcedLimit?: number;
    limit?: string;
  };
  daCategory?: string;
  topLevelCategory?: string;
  contentId?: string;
  queryData?: QueryResult;
}

const ContentCardGridAutoSliceNextPage = (
  props: ContentCardGridAutoSliceContainerProps,
) => {
  const { page } = useContext<QueryStringContextType>(QueryStringContext);

  const LIMIT_SHOW_ALL = props.slice?.forcedLimit
    ? props.slice.forcedLimit
    : 12;
  const isShowAll = props.slice?.totalPages
    ? props.slice.totalPages > 1
    : false;
  const activePage =
    isShowAll && page && Number.parseInt(page, 10)
      ? Number.parseInt(page, 10)
      : 0;

  const variables = {
    tids: props.slice?.tids,
    excludedNids: props.slice?.excludedNids ? props.slice.excludedNids : [],
    limit: LIMIT_SHOW_ALL,
    page: activePage,
    sort: props.slice?.sort,
  };

  const queryData = useQuery(ContentCardGridAutoSliceContainerQuery, {
    variables,
  });

  return <ContentCardGridAutoSlicePage {...props} queryData={queryData} />;
};

const ContentCardGridAutoSlicePage = ({
  slice,
  daCategory = '',
  topLevelCategory = '',
  queryData,
}: ContentCardGridAutoSliceContainerProps): JSX.Element | null => {
  const { country, marketBasePath } =
    useContext<MarketContextType>(MarketContext);
  const { entitlement, entitlementBasePath } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { translations } = useContext<ConfigContextType>(ConfigContext);
  const { page } = useContext<QueryStringContextType>(QueryStringContext);
  const location = useLocation();

  const sliceIndex = useRef<number>(
    Counter.getCounter('card-grids-slice-number'),
  );
  const wrapperRef = useRef<HTMLDivElement>(null);

  const domain = AppUtility.getDomain(country, true);

  const isShowAll = slice?.totalPages ? slice.totalPages > 1 : false;
  const activePage =
    isShowAll && page && Number.parseInt(page, 10)
      ? Number.parseInt(page, 10)
      : 0;

  const wrapperHeight = wrapperRef.current?.clientHeight || 0;
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (wrapperHeight > 0) {
      setHeight(wrapperHeight);
    }
  }, [wrapperHeight]);

  if (!slice) {
    return null;
  }

  if (queryData?.loading || queryData?.error) {
    return (
      <div
        style={{
          height: height !== 0 ? height + 'px' : 'auto',
        }}
        className={styles.loading}
      >
        <LoadingIndicator />
      </div>
    );
  }

  const processCard = (
    card: SmallCardPropsExtended,
    activePage: number,
    index: number,
    isLargeCard: boolean,
    analyticsData?: AnalyticsDataType,
  ) => {
    const formatCardAnalyticsParams = {
      type: isLargeCard
        ? AnalyticsUtility.cardTypes.TYPE_CONTENT_CARD_GRID_AUTO_LARGE
        : AnalyticsUtility.cardTypes.TYPE_CONTENT_CARD_GRID_AUTO,
      pageIndex: activePage, // active page within card grid
      sliceIndex: sliceIndex.current, // card grid slice index on the page
      cardIndex: index, // card index in slice
      topLevelCategory,
      cardId: card.id,
      cardTitle: card.title,
      entitlement,
    };

    if (
      analyticsData?.functionName &&
      AnalyticsUtility[analyticsData?.functionName]
    ) {
      card.cardActionAttributes = AnalyticsUtility[analyticsData?.functionName](
        {
          ...formatCardAnalyticsParams,
          otherParams: analyticsData.otherParams,
        },
      );
    } else {
      card.cardActionAttributes = AnalyticsUtility.formatCardAnalytics(
        formatCardAnalyticsParams,
      );
    }

    card.link = `${marketBasePath}${entitlementBasePath}${(
      card.link || ''
    ).toLowerCase()}`;

    return card;
  };

  const daAction = AnalyticsUtility.formatAction(
    'content_card_grid_auto_cta',
    slice.button.text,
  );

  const daLabel = AnalyticsUtility.formatLabel(slice?.heading?.text);

  slice.button.attributes = {};
  slice.button.attributes['da-category'] = daCategory || '';
  slice.button.attributes['da-action'] = daAction;
  slice.button.attributes['da-label'] = daLabel;

  const sliceCopy = { ...slice };
  delete sliceCopy.totalPages;
  delete sliceCopy.tids;
  delete sliceCopy.sort;
  if (sliceCopy.forcedLimit) {
    delete sliceCopy.forcedLimit;
  }

  const showLargeCard = (limit?: string) => {
    if (limit === undefined) return false;
    return ['4', '7', '10', '13'].includes(limit);
  };

  const [large, ...rest] = slice.cardList;

  sliceCopy.largeCard = showLargeCard(slice?.limit)
    ? processCard(large, activePage + 1, 0, true, sliceCopy?.analyticsData)
    : null;
  sliceCopy.cardList = showLargeCard(slice?.limit)
    ? rest.map((card: SmallCardPropsExtended, index: number) =>
        processCard(
          card,
          activePage + 1,
          ++index,
          false,
          sliceCopy?.analyticsData,
        ),
      )
    : _.cloneDeep(queryData?.data.cardsByTerm || slice.cardList).map(
        (card: SmallCardPropsExtended, index: number) =>
          processCard(
            card,
            activePage + 1,
            ++index,
            false,
            sliceCopy?.analyticsData,
          ),
      );

  if (sliceCopy.analyticsData) {
    delete sliceCopy.analyticsData;
  }

  const pagination: CardGridSliceProps['pagination'] = {};
  if (isShowAll) {
    pagination.pageUrl = `${domain}${location.pathname}${location.search}`;
    pagination.activePage = activePage + 1;
    pagination.totalPages = slice.totalPages;
    pagination.labels = {
      prevLabel: translations.NAVIGATION_PREV_LABEL,
      nextLabel: translations.NAVIGATION_CLOSE_LABEL,
      prevTitle: translations.NAVIGATION_PREV_TITLE,
      nextTitle: translations.NAVIGATION_NEXT_TITLE,
      itemTitle: translations.NAVIGATION_ITEM_TITLE,
    };
  }

  return (
    <div ref={wrapperRef}>
      <CardGridSlice {...sliceCopy} pagination={pagination} />
    </div>
  );
};

export const ContentCardGridAutoSliceContainer = (
  props: ContentCardGridAutoSliceContainerProps,
): JSX.Element | null => {
  const { page } = useContext<QueryStringContextType>(QueryStringContext);
  const isShowAll = props.slice?.totalPages
    ? props.slice.totalPages > 1
    : false;
  const activePage =
    isShowAll && page && Number.parseInt(page, 10)
      ? Number.parseInt(page, 10)
      : 0;

  // First page cards are already in slice loaded.
  // But if "Show all with pager" option is selected
  // and we need to show not 1st page and such page exists,
  // we need to send query to get that page's cards.
  if (isShowAll && activePage && activePage < (props.slice?.totalPages || 0)) {
    return <ContentCardGridAutoSliceNextPage {...props} />;
  }

  return <ContentCardGridAutoSlicePage {...props} />;
};
