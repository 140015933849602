import React, { ReactNode } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import _ from 'lodash';

import {
  MarketContext,
  defaultMarketContext,
} from '../../context/market.context.ts';
import { Domain } from '../Utility/Domain.ts';
import { AppUtility } from '../Utility/AppUtility.ts';
import { MarketUtility } from '../Utility/MarketUtility.ts';

interface MarketProviderProps {
  children: ReactNode;
  url?: {
    hostname?: string;
  };
}

export const MarketProvider = ({
  url,
  children,
}: MarketProviderProps): JSX.Element | null => {
  const location = useLocation();

  const getHrefFromHostname = () => {
    const hostname = url ? url.hostname : null;
    const subdomain = `www${AppUtility.getSubdomain()}.`;

    for (const country of Object.keys(Domain.domainMap)) {
      for (const language of Object.keys(Domain.domainMap[country])) {
        const data = Domain.domainMap[country][language];

        if (hostname && `${subdomain}${data}` === hostname) {
          return _.get(MarketUtility.markets, `${country}.${language}.href`);
        }
      }
    }

    // Assume US as fallback.
    return '/us/';
  };

  const hostnameHref = getHrefFromHostname();

  // Build a regex for all valid country codes.
  const allCountries = Object.keys(MarketUtility.markets);
  const countryRegex = allCountries.join('|');

  const path = location.pathname ? location.pathname.replace(/^\/+/, '') : '';

  // Define the country route.
  return (
    <Switch>
      <Route
        path={`/:country(${countryRegex})`}
        render={({ match: countryMatch }) => {
          const { country } = countryMatch.params;

          // Gather information about the languages for this country.
          const countryInfo = MarketUtility.getCountryInfo(
            country.toLowerCase(),
          );

          // Render the language routes for this country.
          let languagePath = countryMatch.path;

          // If it is a multilingual country, add the language to the URL path.
          const languageRegex = countryInfo?.languages.join('|');
          if (countryInfo?.isMultilingual) {
            languagePath = `${languagePath}/:language(${languageRegex})`;
          }

          // Render the language route.
          return (
            <Switch>
              {/* If there is no trailing slash for the homepage, redirect to add a trailing slash. */}
              <Route
                exact
                strict
                path={languagePath}
                render={({ match: languageMatch }) => (
                  <Redirect
                    to={{
                      pathname: `${languageMatch.url}/`,
                      search: location.search,
                    }}
                  />
                )}
              />
              {/* Check if the language path matches the market. */}
              <Route
                path={languagePath}
                render={({ match: languageMatch }) => {
                  const { language } = languageMatch.params;

                  // Determine the market information to use as the context.

                  const requestLanguage =
                    language || countryInfo?.defaultLanguage;
                  const marketInfo = MarketUtility.getMarketInfo(
                    country.toLowerCase(),
                    requestLanguage?.toLowerCase() || '',
                  );

                  // If country or language is not lowercase, redirect
                  const regex = countryInfo?.isMultilingual
                    ? new RegExp(
                        `^/(?:${countryRegex})/(?:${languageRegex})`,
                        'i',
                      )
                    : new RegExp(`^/(?:${countryRegex})`, 'i');
                  const pathname = location.pathname.replace(
                    regex,
                    marketInfo?.marketBasePath || '',
                  );

                  if (location.pathname !== pathname) {
                    return (
                      <Redirect
                        to={{
                          pathname,
                          search: location.search,
                        }}
                      />
                    );
                  }

                  return (
                    <MarketContext.Provider
                      value={marketInfo || defaultMarketContext}
                    >
                      {children}
                    </MarketContext.Provider>
                  );
                }}
              />
              {/* If no language is specified or the language is invalid for a multilingual market, redirect to the default language for this country. */}
              <Redirect
                to={{
                  pathname: `/${country}/${
                    countryInfo?.defaultLanguage || ''
                  }/`,
                  search: location.search,
                }}
              />
            </Switch>
          );
        }}
      />
      <Route
        render={() => {
          // If no country is specified or the country is invalid, redirect based on hostname
          return (
            <Redirect
              to={{
                pathname: `${hostnameHref}${path}`,
                search: location.search,
              }}
            />
          );
        }}
      />
    </Switch>
  );
};
