import React from 'react';
import _ from 'lodash';

import type { TableSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/TableSlice/TableSlice';

import { TableSlice } from '@ww-digital/web-palette-react/dist/components/Slice/TableSlice/TableSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface TableSliceContainerProps {
  daCategory: string;
  slice: TableSliceProps;
}

export const TableSliceContainer = ({
  daCategory,
  slice,
}: TableSliceContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);

  const daAction = AnalyticsUtility.formatAction(
    'table_cta',
    _.get(slice, 'intro.cta.text'),
  );
  const daLabel = AnalyticsUtility.formatLabel(
    _.get(slice, 'intro.heading.text'),
  );

  if (sliceView?.intro?.cta) {
    sliceView.intro.cta['da-category'] = daCategory;
    sliceView.intro.cta['da-action'] = daAction;
    sliceView.intro.cta['da-label'] = daLabel;
  }

  return <TableSlice {...sliceView} />;
};
