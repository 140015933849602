import _ from 'lodash';

export interface AdsConfig {
  enabledGuest: boolean;
  enabledMember: boolean;
  enabledRegistered: boolean;
}

export interface BlogConfig {
  enabledCategories: boolean;
}

export interface ChatConfig {
  enabled: boolean;
  licenseId: string;
  buttonId: string;
  service: string;
}

export interface ContributorConfig {
  authorOverviewLink: string;
  enabledContributors: boolean;
  reviewerOverviewLink: string;
}

export interface EmailCaptureModalConfig {
  enabled: boolean;
  body: string;
  disclaimer: string;
  headline: string;
  imageURL: string;
  optin_checkbox_label: string;
  age_checkbox_label: string;
}

export interface GdprConfig {
  enabled: boolean;
  disagreeButtonUrl: string;
  showHeadingText: boolean;
}

export interface GtmConfig {
  id: string;
  siteLanguage: string;
  siteRegion: string;
}

export interface JsonldConfig {
  logo: string;
}

export interface LatestContentConfig {
  status: boolean;
  pathsExclude: string[];
}

export interface MeteredContentConfig {
  enabled: boolean;
}

export interface PointsConfig {
  tooltipLink: string;
}

export interface PricingConfig {
  enableChangeMembershipSwitch: boolean;
  changeMembershipButtonTextOverride: string;
}

export interface RetinaConfig {
  enabled: boolean;
}

export interface RoutesConfig {
  externalPaths: string[];
}

export interface SocialNetworkConfig {
  enabled: boolean;
  label: string;
  link: string;
}

export interface SocialNetworksConfig {
  facebook: SocialNetworkConfig;
  twitter: SocialNetworkConfig;
  youtube: SocialNetworkConfig;
  pinterest: SocialNetworkConfig;
  instagram: SocialNetworkConfig;
  linkedin: SocialNetworkConfig;
}

export interface ConfigType {
  ads: AdsConfig;
  blog: BlogConfig;
  chat: ChatConfig;
  contributor: ContributorConfig;
  emailCaptureModal: EmailCaptureModalConfig;
  gdpr: GdprConfig;
  gtm: GtmConfig;
  jsonLD: JsonldConfig;
  latestContent: LatestContentConfig;
  meteredContent: MeteredContentConfig;
  points: PointsConfig;
  pricing: PricingConfig;
  retina: RetinaConfig;
  routes: RoutesConfig;
  socialNetworks: SocialNetworksConfig;
}

export const Config = {
  getDefaultValue: () => ({}),
  getValue: (data: { config: ConfigType }) => {
    return _.get(data, 'config');
  },
};
