import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { DataProxy } from 'apollo-cache';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
  IdGetterObj,
} from 'apollo-cache-inmemory';
import 'cross-fetch/polyfill';

import Browser from '@ww-digital/web-palette-react/dist/components/Utility/Browser';
import resolvers from './resolvers.ts';
import introspectionQueryResultData from './fragmentTypes.json';
import wwUtility from '../../../ww.utility.ts';

export interface ApolloManagerProps {
  getClient: (
    country: string,
    language: string,
    entitlement: string,
    pathname: string,
    sid: string,
  ) => DataProxy | null;
}

export interface ApolloManagerContext {
  country: string;
  language: string;
  entitlement: string;
  pathname: string;
  sid: string;
}

export class ApolloManager implements ApolloManagerProps {
  ssr: boolean;
  gqlDomainOverride: string;
  context?: ApolloManagerContext;
  client?: DataProxy | null;

  // Setup the cache.
  static getCache() {
    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    });

    const options = {
      dataIdFromObject: (object: IdGetterObj) => {
        switch (object.__typename) {
          case 'CommerceProductBundle':
            return null; // disable bundle.id
          default:
            return defaultDataIdFromObject(object); // fall back to default handling
        }
      },
      fragmentMatcher,
    };

    const cache = new InMemoryCache(options);

    // If we are in a browser, initialize the cache state from the server cache state.
    if (Browser.isBrowser()) {
      cache.restore((window as any).__APOLLO_STATE__);
    }

    return cache;
  }

  // Setup the error link.
  static getErrorLink() {
    const link = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        );
        console.error({ graphQLErrors });
      }
      if (networkError) {
        console.warn(`[Network error]: ${networkError}`);
        console.warn({ networkError });
      }
    });
    return link;
  }

  // Setup the persistent query link.
  static getPersistedQueryLink() {
    // Build the link.
    return createPersistedQueryLink({ useGETForHashedQueries: true });
  }

  // Setup the http link.
  static getHttpLink(context: ApolloManagerContext, gqlDomainOverride: string) {
    const options = {
      uri: wwUtility.getGQLUri(
        context.country,
        context.language,
        gqlDomainOverride,
      ),
    };

    // Build the link.
    return new HttpLink(options);
  }

  constructor(ssr: boolean, gqlDomainOverride: string) {
    // Determine whether or not this is for server side rendering.
    this.ssr = ssr;
    this.gqlDomainOverride = gqlDomainOverride;

    // We will build a client once someone asks for one with getClient.
    this.client = null;
  }

  // Setup the auth link.
  getAuthLink() {
    return setContext(() => ({
      headers: {
        entitlement: this.context?.entitlement,
        pathname: encodeURI(this.context?.pathname || ''),
        sid: this.context?.sid,
      },
    }));
  }

  // Get an ApolloClient to use for a country, language, entitlement.
  // Or get the current active client if you call this with no parameters.
  getClient(
    country: string,
    language: string,
    entitlement: string,
    pathname: string,
    sid: string,
  ) {
    // If no params were passed, just return the current client if we have one.
    if (!country) {
      // If we don't have a client, log an error. This should never occur if this function
      // is used correctly.
      if (!this.client) {
        console.error('No ApolloClient has been created yet.');

        return null;
      }

      return this.client;
    }

    const { country: oldCountry, language: oldLanguage } = this.context || {};

    // Update the context with the new data.
    this.context = {
      country,
      language,
      entitlement,
      pathname,
      sid,
    };

    // If we have an existing client for this context, either return it or clean it up.
    if (this.client) {
      // If this client is for the right context, just return it.
      if (country === oldCountry && language === oldLanguage) {
        return this.client;
      }

      // If not, clean it up and we will create a new one.
      // This approach was taken over using client.resetStore due to issues described in
      // https://github.com/apollographql/apollo-client/issues/2919#issuecomment-435292645
      delete this.client;
    }

    // Setup the cache.
    const cache = ApolloManager.getCache();

    // Setup the links.
    const errorLink = ApolloManager.getErrorLink();
    const authLink = this.getAuthLink();
    const persistedQueryLink = ApolloManager.getPersistedQueryLink();
    const httpLink = ApolloManager.getHttpLink(
      this.context,
      this.gqlDomainOverride,
    );

    // Create the client.
    this.client = new ApolloClient({
      cache,
      link: ApolloLink.from([
        errorLink,
        authLink,
        persistedQueryLink,
        httpLink,
      ]),
      resolvers: resolvers(this.context),
      ssrMode: this.ssr,
    });

    return this.client;
  }
}
