import React from 'react';

import type { HomepageCarouselSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/HomepageCarouselSlice/HomepageCarouselSlice';

import { HomepageCarouselSlice } from '@ww-digital/web-palette-react/dist/components/Slice/HomepageCarouselSlice/HomepageCarouselSlice';

interface TestimonialCarouselSliceContainerProps {
  daCategory: string;
  slice: HomepageCarouselSliceProps;
}

export const TestimonialCarouselSliceContainer = ({
  slice,
}: TestimonialCarouselSliceContainerProps): JSX.Element => {
  return <HomepageCarouselSlice {...slice} />;
};
