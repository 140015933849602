import React, { useContext } from 'react';
import { __RouterContext } from 'react-router';
import { Route, Redirect as RRRedirect, useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import _ from 'lodash';
import { Experiment } from '@ww-digital/xs-sdk-react';

import type { ExperimentContextType } from '../../../context/experiment.context';
import type { QueryStringContextType } from '../../../context/querystring.context';
import type { MarketContextType } from '../../../context/market.context';
import type { ConfigContextType } from '../../../context/config.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { TestItemType, VariationItemType } from '../../../ww.tests';

import { ExperimentContext } from '../../../context/experiment.context.ts';
import { QueryStringContext } from '../../../context/querystring.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { ConfigContext } from '../../../context/config.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { Redirect } from '../../Redirects/Redirect/Redirect.tsx';
import { PageRoute } from '../PageRoute/PageRoute.tsx';
import { ArticleRoute } from '../ArticleRoute/ArticleRoute.tsx';
import { ContributorRoute } from '../ContributorRoute/ContributorRoute.tsx';
import { CategoryRoute } from '../CategoryRoute/CategoryRoute.tsx';
import { FourOhFourRoute } from '../FourOhFourRoute/FourOhFourRoute.tsx';
import { AppUtility } from '../../Utility/AppUtility.ts';
import { FoodUtility } from '../../Utility/FoodUtility.ts';
import { QueryStringUtility } from '../../Utility/QueryStringUtility.ts';
import { useExperiment } from '../../../hooks/useExperiment.tsx';
import { MarketUtility } from '../../Utility/MarketUtility.ts';
import { LoadingIndicator } from '@ww-digital/web-palette-react/dist/components/Indicator/LoadingIndicator/LoadingIndicator';
import wwUtility from '../../../ww.utility.ts';
import { FormatUtility } from '../../Utility/FormatUtility.ts';
import { useLocation, useHistory } from 'react-router-dom';

import ContributorQuery from './graphql/ContributorQuery.graphql';
import ContentQuery from './graphql/ContentQuery.graphql';

import styles from './ContentRoute.module.scss';

export interface AnalyticsProps {
  category: string;
  topLevelCategory: string;
  name: string;
  prefix: string;
}

export interface RedirectDataProps {
  path: string;
  redirect: string;
  keepOriginalURL: boolean;
  disableRedirectExposure: boolean;
}

export interface RedirectsProps {
  redirects: RedirectDataProps[];
}

export interface MetaProps {
  title: string;
  description: string;
  canonicalUrl: string;
  robots: string;
  imageSrc: string;
  og: {
    type: string;
    url: string;
    title: string;
    description: string;
    image: string;
    image_alt: string;
    image_type: string;
    image_width: string;
    image_height: string;
  };
}

interface ContentRouteProps {
  path?: string;
  slicesRequired?: boolean;
  isContributorRoute?: boolean;
  contributorType?: string;
}

interface ContentParams {
  path: string;
  contributorSlug: string;
}

const ContentRouteVisible = ({
  path: initPath,
  slicesRequired = true,
  isContributorRoute = false,
  contributorType,
}: ContentRouteProps): JSX.Element => {
  const queryStringContext =
    useContext<QueryStringContextType>(QueryStringContext);
  const queryStringOld = _.omit(queryStringContext, ['sid', 'vid']);
  const queryString = QueryStringUtility.getQueryString(queryStringOld);
  const queryStringNoSidVid = queryString ? `?${queryString}` : '';

  const { staticContext } = useContext(__RouterContext);
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const { entitlement, entitlementBasePath } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { config, paths } = useContext<ConfigContextType>(ConfigContext);
  const { path: paramPath, contributorSlug: paramContributorSlug } =
    useParams<ContentParams>() || {};
  const { tests: wwTests } =
    useContext<ExperimentContextType>(ExperimentContext);
  const { bucketedExperiments: experiments } =
    useContext<ExperimentContextType>(ExperimentContext);

  const urlPath = useLocation().pathname;
  const urlSearch = useLocation().search;
  const navigate = useHistory();
  let xsPathDataParsed: string | RedirectsProps;

  // If we have no path, then it must just be the homepage.
  let path = initPath || FormatUtility.sanitizeUrl(paramPath) || '/';
  let pathRedirect = `${entitlementBasePath}/${path}${queryStringNoSidVid}`;

  // Contributor slug
  const contributorSlug = paramContributorSlug || '';

  // Special case of entitlements on homepage.
  if (path === 'r/cms') {
    path = '/';
    pathRedirect = '/';
  }

  const getRedirectPathAndQueryWithSid = (redirectPath: string) => {
    // Function splits up string to path and query.
    // Then adds "sid" param to query string if it's in URL.
    // Then returns path and query ready for redirect.
    const result = {
      pathname: '',
      search: '',
    };

    // Base URL is not used in formatting result.
    // It's just used to create URL object.
    const baseUrl = 'https://www.weightwatchers.com';
    const url = new URL(redirectPath, baseUrl);

    const queryStringParsed = QueryStringUtility.getQueryParams(url.search);
    if (queryStringContext.sid) {
      queryStringParsed.sid = queryStringContext.sid;
    }

    const queryStringWithSid =
      QueryStringUtility.getQueryString(queryStringParsed);
    if (queryStringWithSid) {
      result.search = '?' + queryStringWithSid;
    }

    result.pathname = url.pathname;

    return result;
  };

  const getComponentByType = (type: string) => {
    switch (type) {
      case 'Article':
        return ArticleRoute;

      case 'Page':
        return PageRoute;

      default:
        return null;
    }
  };

  const renderFourOhFourPage = () => {
    // If we fail while trying to render the 404 page, then just return some text.
    if (path === '404') {
      return <>404</>;
    }

    return <Route component={FourOhFourRoute} />;
  };

  const hasSlices = (data: Record<string, Record<string, string>>) => {
    // Returns TRUE if slices are found for this entitlement.
    return (
      (data.content.sliceData &&
        JSON.parse(data.content.sliceData).length > 0) ||
      (data.content.footerSliceData &&
        JSON.parse(data.content.footerSliceData).length > 0)
    );
  };

  const contentSupportEntitlement = (
    data: Record<string, Record<string, Record<string, string>>>,
  ) => {
    // No need to check entitlement for 404 page.
    if (path === '404') {
      return true;
    }

    // Check whether viewed page entitlement is allowed
    // comparing with union of entitlements across all SG,
    // and not using RSG entitlements at all.
    return data.content.contentEntitlement[entitlement];
  };

  // Find if a given path is in the tests file and is not a preview.
  const findInTestPaths = () => {
    if (!(wwTests[country][language].length > 0)) {
      return false;
    }

    const inTests = wwTests[country][language].find((test: TestItemType) => {
      if (test.tests && test.tests.length > 0) {
        return test.tests.find(
          (variation: VariationItemType) => variation.path === path,
        );
      }

      return false;
    });

    const isPreview = queryStringContext.sid && queryStringContext.vid;

    return !!inTests && !isPreview;
  };

  const getContributorRedirectPath = (
    contributorSlug: string,
    contributorType?: string,
  ) => {
    const contributorBasePath =
      contributorType &&
      MarketUtility.getContributorPath(contributorType, country, language);

    return `${entitlementBasePath}${contributorBasePath}/${contributorSlug}${queryStringNoSidVid}`;
  };

  // Check the test file for active tests.
  let testPath;

  if (wwUtility.isBrowser() && wwTests[country][language].length > 0) {
    const testObj = wwTests[country][language].find(
      (test: TestItemType) => test.path === path,
    );
    const testStorage = sessionStorage.getItem(`ww_tests_${path}`);

    if (testStorage) {
      const testIdx = parseInt(testStorage, 10);

      if (testObj?.tests && testObj.tests[testIdx]) {
        if (testObj.tests[testIdx].path) {
          testPath = testObj.tests[testIdx].path;
        }
      }
    }
  }

  // Save UTM code to session storage.
  if (wwUtility.isBrowser() && window.location.href.includes('utm_campaign')) {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const utmParameter = url.searchParams.get('utm_campaign');
    let sessionItemName;
    if (utmParameter && utmParameter.includes('recruit-quiz-cta-crm-of')) {
      sessionItemName = 'crmUserOf';
    }
    if (
      utmParameter &&
      (utmParameter.includes('crm-recruit') ||
        utmParameter.includes('recruit-assessment-cta-crm'))
    ) {
      sessionItemName = 'crmUser';
    }
    if (sessionItemName && utmParameter) {
      sessionStorage.setItem(sessionItemName, utmParameter);
    }
  }

  // Check XS tests for path. Manage multiple redirects IF keeping original URL, otherwise, run single redirect...
  const { path: xsPath } = useExperiment();
  let exposure = <></>;
  if (xsPath?.id) {
    if (typeof xsPath?.data === 'string') {
      try {
        xsPathDataParsed = JSON.parse(xsPath.data);
      } catch (ex) {
        xsPathDataParsed = xsPath.data;
      }
      if (typeof xsPathDataParsed === 'object' && xsPathDataParsed?.redirects) {
        xsPathDataParsed?.redirects.forEach(
          (redirectData: RedirectDataProps) => {
            if (redirectData?.path === urlPath) {
              if (redirectData?.keepOriginalURL === true) {
                testPath = redirectData?.redirect;
              } else if (
                redirectData?.keepOriginalURL === false &&
                wwUtility.isBrowser()
              ) {
                navigate.push(redirectData?.redirect + urlSearch);
              }
            }

            if (
              !Object.prototype.hasOwnProperty.call(
                redirectData,
                'disableRedirectExposure',
              ) ||
              redirectData?.disableRedirectExposure === false
            ) {
              exposure = (
                <Experiment name={xsPath.name}>
                  <></>
                </Experiment>
              );
            }
          },
        );
      } else if (typeof xsPathDataParsed === 'string') {
        testPath = xsPath.data;

        exposure = (
          <Experiment name={xsPath.name}>
            <></>
          </Experiment>
        );
      }
    }
  }

  // XS Experiments
  if (Object.keys(experiments).length) {
    for (const experiment in experiments) {
      const {
        forceExposure,
        redirects,
        attributes: { proxy },
      } = experiments[experiment];
      const searchParams = new URLSearchParams(urlSearch);
      const fireProxyExposure =
        proxy && searchParams.get('proxy') === proxy.toString();

      // Redirects
      if (redirects && redirects[urlPath]) {
        const redirectData = redirects[urlPath];

        if (redirectData.keepOriginalUrl) {
          testPath = redirectData.to;
          if (!redirectData.disableRedirectExposure) {
            exposure = (
              <Experiment name={experiment}>
                <></>
              </Experiment>
            );
          }
        } else if (
          redirectData.keepOriginalUrl === false &&
          wwUtility.isBrowser()
        ) {
          let redirectUrlSearch = decodeURIComponent(
            `?${searchParams.toString()}`,
          );
          if (redirectData.queryParams) {
            const queryParams = redirectData.queryParams;
            for (const key in queryParams) {
              searchParams.set(key, queryParams[key]);
            }
            redirectUrlSearch = decodeURIComponent(
              `?${searchParams.toString()}`,
            );
          }
          if (redirectUrlSearch === '?') {
            redirectUrlSearch = '';
          }
          if (redirectData.crossSite) {
            window.location.href = redirectData.to + redirectUrlSearch;
          } else {
            navigate.push(redirectData.to + redirectUrlSearch, {
              from: urlPath,
            });
          }
        }
      }

      // handle exposures for redirects with keepOriginalUrl set to false.
      const previousPath = (navigate.location.state as { from: string })?.from;
      const previousRedirect = redirects?.[previousPath];
      const isRedirectExposureRequired =
        previousRedirect?.to === urlPath &&
        !previousRedirect?.disableRedirectExposure;

      // Exposure
      if (forceExposure || fireProxyExposure || isRedirectExposureRequired) {
        exposure = (
          <Experiment name={experiment}>
            <></>
          </Experiment>
        );
      }
    }
  }

  testPath = testPath?.replace(`/${country}/`, '').replace(`${language}/`, '');

  if (testPath === '') {
    testPath = '/';
  }

  const pathToLookUp = testPath || path;

  const { loading, error, data } = useQuery(
    isContributorRoute ? ContributorQuery : ContentQuery,
    {
      variables: isContributorRoute
        ? {
            slug: contributorSlug,
            type: contributorType,
            pathRedirect: getContributorRedirectPath(
              contributorSlug,
              contributorType,
            ),
          }
        : {
            path: pathToLookUp,
            pathRedirect: pathRedirect,
            vid: queryStringContext?.vid,
          },
      errorPolicy: 'all',
    },
  );

  if (error) {
    if (AppUtility.isNetworkError(error)) {
      throw new Error('Content network timeout.');
    }
  }

  if (loading) {
    return (
      <div className={styles.loading}>
        <LoadingIndicator />
      </div>
    );
  }

  if (data?.redirect?.path && paths && path !== '404') {
    const isApplicationUrl = paths.isAppURL(
      country,
      language,
      data.redirect.path,
    );

    if (paths.isAbsoluteURL(data.redirect.path)) {
      // On brower, handle internal/external absolute URL
      if (wwUtility.isBrowser()) {
        if (isApplicationUrl) {
          // Internal absolute URL: react redirect to path portion of URL
          return (
            <RRRedirect
              to={getRedirectPathAndQueryWithSid(
                data.redirect.path.replace(paths.domainRegex, ''),
              )}
            />
          );
        } else {
          // External absolute URL: window.location to full external URL
          return (
            <Route
              component={() => {
                window.location = data.redirect.path;
                return null;
              }}
            />
          );
        }
      }

      // Server side.
      if (isApplicationUrl) {
        // Server side: internal absolute URL.
        return (
          <RRRedirect
            to={getRedirectPathAndQueryWithSid(
              data.redirect.path.replace(paths.domainRegex, ''),
            )}
          />
        );
      } else {
        // On server side, do simple redirect to full external URL
        return (
          <RRRedirect
            to={{
              pathname: data.redirect.path,
            }}
          />
        );
      }
    }

    if (staticContext && data.redirect.statusCode) {
      staticContext.statusCode = data.redirect.statusCode;
    }

    const pathAndQuery = getRedirectPathAndQueryWithSid(data.redirect.path);

    // Internal path redirect.
    return (
      <Redirect
        to={pathAndQuery.pathname}
        queryString={pathAndQuery.search}
        pathWithEntitlement
      />
    );
  }

  if (config.blog.enabledCategories && data?.category) {
    return <CategoryRoute category={data.category} path={pathToLookUp} />;
  }

  if (isContributorRoute) {
    return <ContributorRoute data={data.contributor} slug={contributorSlug} />;
  }

  // If there is no data, render the 404 page.
  if (
    !data?.content ||
    (slicesRequired && !hasSlices(data)) ||
    !contentSupportEntitlement(data) ||
    findInTestPaths()
  ) {
    return renderFourOhFourPage();
  }

  // Get the component to use for this type of content.
  const RouteComponent = getComponentByType(data.content.__typename);

  // If we support this type of content, render it with that component.
  if (RouteComponent) {
    return (
      <>
        {exposure}
        <RouteComponent path={path} data={data} />
      </>
    );
  }

  // If the url is invalid or we don't know to handle it, render the 404 page.
  return renderFourOhFourPage();
};

export const ContentRoute = (props: ContentRouteProps): JSX.Element => {
  const { country, language } = useContext<MarketContextType>(MarketContext);

  // Special case of entitlements on homepage.
  if (props.path === 'm/cms') {
    const environment = AppUtility.getEnv();
    const domain = FoodUtility.cmxDomain.getEndpoint(
      environment,
      country,
      language,
    );
    return (
      <RRRedirect
        to={{
          pathname: domain,
        }}
      />
    );
  }

  return <ContentRouteVisible {...props} />;
};
