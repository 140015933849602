import React, { useContext } from 'react';
import AD from 'react-google-publisher-tag';
import { useLocation } from 'react-router-dom';

import type { MarketContextType } from '../../context/market.context';
import type { EntitlementContextType } from '../../context/entitlement.context';

import { MarketContext } from '../../context/market.context.ts';
import { EntitlementContext } from '../../context/entitlement.context.ts';
import { Breakpoint } from '@ww-digital/web-palette-react/dist/components/Responsive/Breakpoint/Breakpoint';

import styles from './AdsContainer.module.scss';

interface AdsContainerProps {
  content: {
    id: string;
    type: string;
    tags: string[];
  };
}

interface AdSlot {
  slot: string;
  dimensions: [number, number][];
}

interface Slots {
  mobile: AdSlot[];
  desktop: AdSlot[];
}

// Wrapper around https://github.com/seeden/react-google-publisher-tag
export const AdsContainer = ({ content }: AdsContainerProps): JSX.Element => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const location = useLocation();

  // The DFP network id.
  const networkId = '3105';

  // Just used as part of the ad path. Not meant to match the actual URL domain.
  const domain = 'weightwatchers.com';

  // Definition of the slots for mobile and desktop.
  const slots: Slots = {
    mobile: [
      {
        slot: 'mobile-slot-a',
        dimensions: [[300, 250]],
      },
    ],
    desktop: [
      {
        slot: 'slot-a',
        dimensions: [
          [300, 250],
          [300, 600],
        ],
      },
      {
        slot: 'slot-b',
        dimensions: [
          [300, 250],
          [300, 600],
        ],
      },
    ],
  };

  // Render an individual ad slot.
  const renderAdSlot = (item: AdSlot, key: number) => {
    const path = `/${networkId}/${domain}${location.pathname}`;

    const slotTargeting = {
      slot: item.slot,
      'country-code': country,
      language,
      ut: entitlement.toLowerCase(),
      contentid: content.id,
      ct: content.type,
      taxonomy: content.tags ? content.tags.join(',') : '',
    };

    return (
      <div className={styles.ad} key={key} data-e2e-name="dfp_ads_ad">
        <AD
          path={path}
          dimensions={item.dimensions}
          targeting={slotTargeting}
          enableSingleRequest
          collapseEmpty
        />
      </div>
    );
  };

  const renderSlotGroup = (display: 'mobile' | 'desktop') => {
    return <div>{slots[display].map(renderAdSlot)}</div>;
  };

  return (
    <div className={styles.wrapper} data-e2e-name="dfp_ads_wrapper">
      <Breakpoint breakpoint="medium">
        {renderSlotGroup('mobile')}
        {renderSlotGroup('desktop')}
      </Breakpoint>
    </div>
  );
};
