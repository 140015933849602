import React, { useRef, useContext } from 'react';
import _ from 'lodash';

import type { CardGridSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/CardGridSlice/CardGridSlice';
import type { SmallCardProps } from '@ww-digital/web-palette-react/dist/components/Card/SmallCard/SmallCard';
import type { ConfigContextType } from '../../../context/config.context';
import type { MarketContextType } from '../../../context/market.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';

import { CardGridSlice } from '@ww-digital/web-palette-react/dist/components/Slice/CardGridSlice/CardGridSlice';

import { ConfigContext as ConfigContextImport } from '../../../context/config.context.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { FoodUtility } from '../../Utility/FoodUtility.ts';
import { Counter } from '../../Utility/Counter.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';

type SmallCardPropsExtended = SmallCardProps & {
  id: string;
  pointsInfo: {
    maxPointsHigh: number | null;
    maxPointsLow: number | null;
  };
  points: number;
  type: 'Recipe' | 'Meal';
};

interface FoodCardGridSliceContainerProps {
  slice: CardGridSliceProps & {
    cardList: SmallCardPropsExtended[];
  };
  daCategory: string;
  topLevelCategory?: string;
}

export const FoodCardGridSliceContainer = ({
  slice,
  daCategory,
  topLevelCategory = '',
}: FoodCardGridSliceContainerProps): JSX.Element => {
  const sliceIndex = useRef<number>(
    Counter.getCounter('card-grids-slice-number'),
  );

  const { marketBasePath, country, language } =
    useContext<MarketContextType>(MarketContext);
  const { entitlement, entitlementBasePath } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { translations } = useContext<ConfigContextType>(ConfigContextImport);

  const processCard = (card: SmallCardPropsExtended, index: number) => {
    if (!card) {
      return card;
    }

    card.cardActionAttributes = AnalyticsUtility.formatCardAnalytics({
      type: AnalyticsUtility.cardTypes.TYPE_FOOD_CARD_GRID,
      pageIndex: 1, // always 1 as there is no pager for this type of slice.
      sliceIndex: sliceIndex.current, // card grid slice index on the page
      cardIndex: index, // card index in slice
      topLevelCategory,
      cardId: card.id ? card.id : '',
      cardTitle: card.title ? card.title : '',
      entitlement,
    });

    const pointsValues = { points: card.points };

    const pointsAriaLabels = {
      valueLabel: translations.POINTS_POINTS_COIN_ARIA_LABEL,
    };

    // Get Food Points Values and Labels
    card.personalPoints = FoodUtility.getPointsData(
      pointsValues,
      pointsAriaLabels,
    );

    card.link =
      entitlement === 'Member'
        ? (card.link = FoodUtility.getMemberURL(
            {
              sourceType: card.type === 'Recipe' ? 'WWRECIPE' : 'WWMEAL',
              id: card.id,
            },
            country,
            language,
          ))
        : `${marketBasePath}${entitlementBasePath}${FoodUtility.getURL(
            card,
            country,
            language,
          )}`;

    return card;
  };

  const cardList = _.cloneDeep(slice.cardList).map((card, index) =>
    processCard(card, ++index),
  );

  const daAction = AnalyticsUtility.formatAction(
    'food_card_grid_cta',
    slice.button.text,
  );
  const daLabel = AnalyticsUtility.formatLabel(slice.heading?.text);

  slice.button.attributes = {};
  slice.button.attributes['da-category'] = daCategory;
  slice.button.attributes['da-action'] = daAction;
  slice.button.attributes['da-label'] = daLabel;

  return <CardGridSlice type="food" {...slice} cardList={cardList} />;
};
