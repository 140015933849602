import React, { useEffect, useState, useContext } from 'react';
import { useMutation } from 'react-apollo';
import _ from 'lodash';

import type { ConfigContextType } from '../../../context/config.context';

import { CancellationForm } from '@ww-digital/web-palette-react/dist/components/CancellationForm/CancellationForm';
import type { CancellationFormProps } from '@ww-digital/web-palette-react/dist/components/CancellationForm/CancellationForm';

import {
  formatTimestamp,
  cancellationFormData as data,
} from './cancellationFormData.ts';
import { MarketUtility } from '../../Utility/MarketUtility.ts';
import { ConfigContext } from '../../../context/config.context.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

import CancellationFormRequestMutation from './graphql/CancellationFormRequestMutation.graphql';

interface CancellationFormData {
  email?: string;
  firstName?: string;
  lastName?: string;
  memberId?: string;
  telephone?: string;
  cancellationTime?: string;
  cancellationType?: string;
  cancellationReason?: string;
}

interface CancellationFormFieldErrors {
  ordinaryCheckbox?: string;
  extraordinaryCheckbox?: string;
  earliestPossibleCheckbox?: string;
  chooseDateCheckbox?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  memberId?: string;
  telephone?: string;
  cancellationTime?: string;
  cancellationType?: string;
  cancellationReason?: string;
}

interface CancellationFormMutationData {
  submitCancelMembership: {
    success: boolean;
    badRequest: boolean;
  };
}

export const CancellationPageRoute = () => {
  const { translations } = useContext<ConfigContextType>(ConfigContext);
  const [form, setForm] = useState<CancellationFormData>({
    cancellationType: 'Reguläre Kündigung',
    cancellationTime: 'Schnellstmöglicher Termin',
  });
  const [fieldErrors, setFieldErrors] = useState<CancellationFormFieldErrors>(
    {},
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [timestamp, setTimestamp] = useState<string>('');

  // Get the error message to display for the form based on the result.
  const getErrorMessage = (
    error: boolean,
    data: CancellationFormMutationData,
  ) => {
    const errorMessage = 'Verbindungsfehler - Bitte aktualisiere diese Seite';
    if (error || (data && !data.submitCancelMembership.success)) {
      return errorMessage;
    }
    return '';
  };

  // Fire an analytics event.
  const fireEvent = (category: string, action: string, label = '') => {
    AnalyticsUtility.fireEvent(category, `cancellation_form_${action}`, label);
  };

  const handleChange = (id: keyof CancellationFormData, value: string) => {
    const formData = Object.assign(form, {
      [id]: value,
    });

    const field: keyof CancellationFormFieldErrors = `${id}`;
    const newFieldErrors = _.cloneDeep(fieldErrors);
    delete newFieldErrors[field];

    setForm(formData);
    setFieldErrors(newFieldErrors);
  };

  const prepareDataView = () => {
    const rules = MarketUtility.localeRules('de', 'de');

    const dataView = _.cloneDeep(data);

    Object.keys(dataView.form.inputs).forEach((field) => {
      if (field === 'firstName') {
        dataView.form.inputs[field] = {
          fieldId: 'firstName',
          regex: new RegExp(_.get(rules, 'validations.firstName.regex', '')),
          placeholder: data.form.inputs[field].placeholder,
          regexErrorMessage: translations.EMAIL_CAPTURE_NAME_ERROR,
          errorMessage: fieldErrors[field],
        };
      }
      if (field === 'lastName') {
        dataView.form.inputs[field] = {
          fieldId: 'lastName',
          regex: new RegExp(_.get(rules, 'validations.lastName.regex'), ''),
          placeholder: data.form.inputs[field].placeholder,
          regexErrorMessage: translations.EMAIL_CAPTURE_NAME_ERROR,
          errorMessage: fieldErrors[field],
        };
      }
      if (field === 'email') {
        dataView.form.inputs[field] = {
          fieldId: 'email',
          regex: new RegExp(_.get(rules, 'validations.email.regex'), ''),
          placeholder: data.form.inputs[field].placeholder,
          regexErrorMessage:
            translations.EMAIL_CAPTURE_EMAIL_ADDRESS_ERROR_VALID,
          errorMessage: fieldErrors[field],
        };
      }
      if (field === 'telephone') {
        dataView.form.inputs[field] = {
          fieldId: 'telephone',
          regex: new RegExp(_.get(rules, 'validations.phoneNo.regex'), ''),
          placeholder: data.form.inputs[field].placeholder,
          regexErrorMessage: translations.EMAIL_CAPTURE_TELEPHONE_ERROR,
          errorMessage: fieldErrors[field],
        };
      }
      if (field === 'memberId') {
        dataView.form.inputs[field] = {
          fieldId: 'memberId',
          regex: data.form.inputs[field].regex,
          placeholder: data.form.inputs[field].placeholder,
          errorMessage: fieldErrors[field],
        };
      }
      if (field === 'ordinaryCheckbox') {
        dataView.form.inputs[field] = {
          fieldId: 'ordinaryCheckbox',
          label: dataView.form.inputs[field].label,
          errorMessage: fieldErrors[field],
        };
      }
      if (field === 'extraordinaryCheckbox') {
        dataView.form.inputs[field] = {
          fieldId: 'extraordinaryCheckbox',
          label: dataView.form.inputs[field].label,
          errorMessage: fieldErrors[field],
        };
      }
      if (field === 'earliestPossibleCheckbox') {
        dataView.form.inputs[field] = {
          fieldId: 'earliestPossibleCheckbox',
          label: dataView.form.inputs[field].label,
          errorMessage: fieldErrors[field],
        };
      }
      if (field === 'chooseDateCheckbox') {
        dataView.form.inputs[field] = {
          fieldId: 'chooseDateCheckbox',
          label: dataView.form.inputs[field].label,
          errorMessage: fieldErrors[field],
        };
      }
    });

    return dataView;
  };

  const dataView = prepareDataView();

  const fieldError = (field: keyof CancellationFormFieldErrors) => {
    // @ts-expect-error TODO
    return dataView.form.inputs[field].regexErrorMessage;
  };

  const validateField = (
    inputs: CancellationFormProps['form']['inputs'],
    field: keyof CancellationFormProps['form']['inputs'],
  ) => {
    if (
      field === 'ordinaryCheckbox' ||
      field === 'extraordinaryCheckbox' ||
      field === 'earliestPossibleCheckbox' ||
      field === 'chooseDateCheckbox' ||
      field === 'memberId' ||
      (field === 'telephone' && !form.telephone)
    ) {
      return true;
    }
    const regex =
      inputs[field as keyof CancellationFormProps['form']['inputs']]?.regex;

    if (!regex) {
      return true;
    }

    if (field === 'date') {
      if (form.cancellationTime !== 'Schnellstmöglicher Termin') {
        return regex.test(form.cancellationTime || '');
      } else {
        return true;
      }
    }

    return regex.test(form[field] || '');
  };

  const validateFields = (inputs: CancellationFormProps['form']['inputs']) => {
    let isValid = true;
    const newFieldErrors: CancellationFormFieldErrors = {};
    Object.keys(inputs).forEach((field) => {
      if (
        !validateField(
          inputs,
          field as keyof CancellationFormProps['form']['inputs'],
        )
      ) {
        isValid = false;
        newFieldErrors[field as keyof CancellationFormFieldErrors] = fieldError(
          field as keyof CancellationFormFieldErrors,
        );
      }
    });

    setFieldErrors(newFieldErrors);

    return isValid;
  };

  const [
    cancellationRequestMutation,
    { error: errorMutation, data: dataMutation },
  ] = useMutation(CancellationFormRequestMutation, { errorPolicy: 'all' });

  const mutationErrorMessage = getErrorMessage(!!errorMutation, dataMutation);

  useEffect(() => {
    if (errorMutation) {
      setErrorMessage(mutationErrorMessage);
    }
    if (dataMutation) {
      setTimestamp(formatTimestamp());
      setSuccess(dataMutation.submitCancelMembership.success);
      dataMutation.submitCancelMembership.success && window.scrollTo(0, 0);
    }
  }, [
    errorMutation,
    mutationErrorMessage,
    dataMutation,
    dataView.confirmation.receipt,
  ]);

  const onSubmit = _.debounce(() => {
    const requestTimestamp = formatTimestamp();
    const {
      email,
      firstName,
      lastName,
      telephone,
      memberId,
      cancellationTime,
      cancellationType,
      cancellationReason,
    } = form;

    if (!validateFields(dataView.form.inputs)) {
      return;
    }

    cancellationRequestMutation({
      variables: {
        email,
        firstName,
        lastName,
        telephone,
        memberId,
        cancellationTime,
        cancellationType,
        cancellationReason,
        requestTimestamp,
      },
    })
      .then(({ data: formData, errors }) => {
        const firstError = errors && errors.length > 0 ? !!errors[0] : false;
        const submissionErrorMessage = getErrorMessage(firstError, formData);

        if (firstError) {
          setErrorMessage(submissionErrorMessage);
          // Log an event to analytics for API failure.
          fireEvent('cancellation_form', 'api_down');
        } else if (formData) {
          if (formData.submitCancelMembership.success) {
            fireEvent('cancellation_form', 'success');
          } else {
            // Fallback when success is false with no specific error code.
            fireEvent('cancellation_form', 'api_down');
            setErrorMessage(submissionErrorMessage);
          }
        }
      })
      .catch((error) => {
        // Log an event to analytics for API failure.
        fireEvent('cancellation_form', 'api_down');
      });
  }, 500);

  dataView.form.submitCta.onClick = onSubmit;
  dataView.form.inputs.firstName.onChangeText = (value: string) =>
    handleChange('firstName', value);
  dataView.form.inputs.lastName.onChangeText = (value: string) =>
    handleChange('lastName', value);
  dataView.form.inputs.email.onChangeText = (value: string) =>
    handleChange('email', value);
  dataView.form.inputs.telephone.onChangeText = (value: string) =>
    handleChange('telephone', value);
  dataView.form.inputs.memberId.onChangeText = (value: string) =>
    handleChange('memberId', value);
  dataView.form.inputs.ordinaryCheckbox.onChange = () =>
    handleChange('cancellationType', 'Reguläre Kündigung');
  dataView.form.inputs.extraordinaryCheckbox.onChange = () =>
    handleChange('cancellationType', 'Außerordentliche Kündigung');
  dataView.form.inputs.earliestPossibleCheckbox.onChange = () =>
    handleChange('cancellationTime', 'Schnellstmöglicher Termin');
  dataView.form.cancellationType.reason.onChange = (value: string) => {
    handleChange('cancellationReason', value);
  };
  dataView.form.inputs.date.onChangeText = (value: string) =>
    handleChange('cancellationTime', value);

  dataView.confirmation.receipt.requestTimestamp = timestamp;
  dataView.status.success = success;
  dataView.status.errorMessage = errorMessage;

  return <CancellationForm {...dataView} />;
};
