import React, { ReactNode, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import type { MarketContextType } from '../../../context/market.context';

import { MarketContext } from '../../../context/market.context.ts';

import browserconfig from './favicons/browserconfig.xml';
import favicons from './favicons/index.ts';

export interface GlobalMetadataProps {
  children?: ReactNode;
}

export const GlobalMetadata = ({
  children,
}: GlobalMetadataProps): JSX.Element => {
  const { language } = useContext<MarketContextType>(MarketContext);

  const attrs = {
    lang: language,
    xmlns: 'http://www.w3.org/1999/xhtml',
    'xmlns:og': 'http://opengraphprotocol.org/schema/',
    'xml:lang': language,
    version: 'XHTML+RDFa 1.0',
    dir: 'ltr',
    'xmlns:article': 'http://ogp.me/ns/article#',
    'xmlns:book': 'http://ogp.me/ns/book#',
    'xmlns:profile': 'http://ogp.me/ns/profile#',
    'xmlns:video': 'http://ogp.me/ns/video#',
    'xmlns:product': 'http://ogp.me/ns/product#',
  };

  return (
    <Helmet>
      <meta name="robots" content="noodp, noydir" />
      {favicons.map((icon, key) => (
        <link {...icon} key={key} />
      ))}
      <meta name="msapplication-config" content={browserconfig} />
      <meta name="msapplication-TileColor" content="#322DA0" />
      <meta
        name="msapplication-TileImage"
        content="/msicons/mstile-144x144.png"
      />
      <meta name="theme-color" content="#ffffff" />
      <html {...attrs} />
      {children}
    </Helmet>
  );
};
