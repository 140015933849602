import { useContext } from 'react';
import XS from '@ww-digital/xs-sdk';

import { MarketUtility } from '../../components/Utility/MarketUtility.ts';
import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import wwUtility from '../../ww.utility.ts';
import GUA from '@ww-digital/xs-plugin-gua';
import type { MarketContextType } from '../../context/market.context.ts';
import { MarketContext } from '../../context/market.context.ts';

export const ScrollExposure = (cssSelector: string, variables: any) => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const visitorId = Storage.getCookieValue('ww_browser_id', false);
  const gua = new GUA();

  const recheckHeight = 0,
    originalPageHeight = 0,
    originalHeightChecks = 0;

  // Select the node that will be observed for mutations
  /* Masthead Slice update */
  const slice_one = document.getElementById(variables?.elementId);

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true };

  let observer_one: MutationObserver;

  // Variables to store scroll depth information
  let pageHeight: number;
  let disableExposure = false;

  function RunExposure() {
    const windowHeight = window.outerHeight;
    const scrollTop = window.scrollY;
    const scrollBottom = scrollTop + windowHeight;

    if (scrollBottom >= pageHeight / 2 && !disableExposure) {
      disableExposure = true;

      (async () => {
        const xs = new XS({
          user: {
            visitorId: typeof visitorId === 'string' ? visitorId : '',
            locale: MarketUtility.getXSLocale(country, language),
          },
          baseUrl: wwUtility.getAPIDomain(),
          plugins: [gua],
        });

        await xs.fetchUserExperiments();

        // Run Exposure for Test
        await xs.exposeExperiment(variables?.testId);
      })();
    } else if (disableExposure) {
      // Remove the Scroll event once the point on the page is hit
      window.removeEventListener('scroll', RunExposure);
      observer_one.disconnect();
    }
  }

  const originalHeightCheck = (
    recheckHeight: number,
    originalPageHeight: number,
    originalHeightChecks: number,
  ) => {
    if (
      originalPageHeight ===
        document.querySelectorAll('body')[0].getBoundingClientRect().height &&
      recheckHeight === 5
    ) {
      pageHeight = document
        .querySelectorAll('body')[0]
        .getBoundingClientRect().height;
      window.addEventListener('scroll', RunExposure);
    } else {
      if (originalHeightChecks <= 50) {
        setTimeout(function () {
          originalPageHeight = document
            .querySelectorAll('body')[0]
            .getBoundingClientRect().height;
          recheckHeight++;
          originalHeightChecks++;
          originalHeightCheck(
            recheckHeight,
            originalPageHeight,
            originalHeightChecks,
          );
        }, 100);
      } else {
        return;
      }
    }
  };

  // Callback function to execute when mutations are observed
  const callback_one = (mutationList: any) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes') {
        if (
          slice_one !== null &&
          slice_one.querySelectorAll(`${cssSelector}`).length > 0 &&
          slice_one.querySelectorAll(`${cssSelector}`)[0].getAttribute('id') !==
            'grex-204'
        ) {
          // Removes Lazy loading from images so the real height of the page can be grabbed and manipulated
          document.querySelectorAll('img[loading=lazy]').forEach((element) => {
            element.removeAttribute('loading');
          });

          originalHeightCheck(
            recheckHeight,
            originalPageHeight,
            originalHeightChecks,
          );

          // Prevent duplicate Event Listeners from being added to element by running a conditional check against the existence of this ID
          if (document.querySelectorAll('img[loading=lazy]').length === 0) {
            slice_one
              .querySelectorAll(`${cssSelector}`)[0]
              .setAttribute('id', 'grex-204');
          }
        }
      }
    }
  };

  if (slice_one !== null) {
    // Create an observer instance linked to the callback function
    observer_one = new MutationObserver(callback_one);

    // Start observing the target node for configured mutations
    observer_one.observe(slice_one, config);
  }

  return;
};

export const ExecuteFunctions_GREX204 = (
  pathname: string,
  variant: string,
  variables?: any,
) => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const visitorId = Storage.getCookieValue('ww_browser_id', false);
  const gua = new GUA();

  if (variant === 'control') {
    if (pathname === variables?.pathname) {
      (async () => {
        const xs = new XS({
          user: {
            visitorId: typeof visitorId === 'string' ? visitorId : '',
            locale: MarketUtility.getXSLocale(country, language),
          },
          baseUrl: wwUtility.getAPIDomain(),
          plugins: [gua],
        });

        await xs.fetchUserExperiments();

        // Run Exposure for Test
        await xs.exposeExperiment(variables?.testId);
      })();
    }
  }

  if (variant === 'v1') {
    if (pathname === variables?.pathname) {
      ScrollExposure(variables?.cssTarget, variables);
    }
  }
};
