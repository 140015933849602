import React, { useContext } from 'react';
import { __RouterContext } from 'react-router';
import { Route, useLocation, Redirect as RRRedirect } from 'react-router-dom';
import * as ApplangaJS from '@ww-digital/applanga-js';

import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { QueryStringContextType } from '../../../context/querystring.context';
import type { ConfigContextType } from '../../../context/config.context';
import type { SmallCardProps } from '@ww-digital/web-palette-react/dist/components/Card/SmallCard/SmallCard';
import type { AuthorMastheadProps } from '@ww-digital/web-palette-react/dist/components/Masthead/AuthorMasthead/AuthorMasthead';
import type { HreflangType } from '../../Metadata/Hreflang/Hreflang';

import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { RouteMetadata } from '../../Metadata/RouteMetadata/RouteMetadata.tsx';
import { GTM } from '../../GTM/GTM.tsx';
import { JSONLD } from '../../Metadata/JSONLD/JSONLD.tsx';
import { Hreflang } from '../../Metadata/Hreflang/Hreflang.tsx';
import { FourOhFourRoute } from '../../Route/FourOhFourRoute/FourOhFourRoute.tsx';
import { SlicesContainer } from '../../Slices/SlicesContainer/SlicesContainer.tsx';
import { AuthorMasthead } from '@ww-digital/web-palette-react/dist/components/Masthead/AuthorMasthead/AuthorMasthead';
import { LinksBar } from '@ww-digital/web-palette-react/dist/components/LinksBar/LinksBar';
import { Container } from '@ww-digital/web-palette-react/dist/components/Container/Container/Container';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { QueryStringContext } from '../../../context/querystring.context.ts';
import { ConfigContext } from '../../../context/config.context.ts';

import styles from './styles/ContributorRoute.module.scss';

interface ContributorData extends AuthorMastheadProps {
  footerSliceData: string;
  formattedName: string;
  gridAuto: {
    cardList: SmallCardProps[];
    totalPages: number;
  };
  hreflangs: HreflangType[];
  id: string;
  meta: {
    og: {
      type: string;
    };
  };
  sliceData: string;
  socialLinks: string[];
  subtitle: string;
  type: 'author' | 'reviewer';
  firstName: string;
  lastName: string;
  honorificSuffix: string;
  linkWebsite: string;
}

interface ContributorRouteProps {
  data?: ContributorData;
  slug: string;
}

export const ContributorRoute = ({
  data,
  slug,
}: ContributorRouteProps): JSX.Element => {
  const location = useLocation();
  const { staticContext } = useContext(__RouterContext);
  const { page } = useContext<QueryStringContextType>(QueryStringContext);
  const { translations, config } = useContext<ConfigContextType>(ConfigContext);
  const entitlementContext =
    useContext<EntitlementContextType>(EntitlementContext);
  const activePage = Number.parseInt(page || '', 10) || 0;
  const headingText =
    data?.type === 'author'
      ? translations.CONTRIBUTOR_GRID_TITLE
      : translations.CONTRIBUTOR_GRID_TITLE_REVIEWER;

  const gridAutoProps = {
    id: 'contributor-page-card-grid',
    __typename: 'ContentCardGridAutoSlice',
    tids: [data?.id],
    excludedNids: [],
    forcedLimit: 24,
    sort: 'created_desc',
    analyticsData: {
      functionName: 'formatCategoryPageCardAnalytics',
      otherParams: {
        slug: slug,
        type: AnalyticsUtility.cardTypes
          .TYPE_CONTENT_CARD_GRID_CONTRIBUTOR_PAGE,
      },
    },
    align: 'left',
    button: {
      type: 'primary',
      url: '',
      text: 'CTA',
    },
    heading: {
      align: 'left',
      ariaLevel: 2,
      text: ApplangaJS.get(headingText || '', {
        name: data?.formattedName,
      }),
      typog: 'headline4',
      variant: 'default',
    },
    subTitle: '',
    cardList: data?.gridAuto?.cardList,
    totalPages: data?.gridAuto?.totalPages,
    background: {
      color: 'none',
      mobile: {
        image: {
          image1x: '',
          image2x: '',
          image2xHeight: '',
          padding: 'none',
          position: 'bottom',
        },
      },
      desktop: {
        image: {
          image1x: '',
          image2x: '',
          image2xHeight: '',
          position: {
            x: '',
            y: '',
          },
        },
      },
      alt: '',
      height: 0,
      theme: 'lightBackground',
    },
  };

  const jsonLD = {
    '@type': 'Person',
    jobTitle: data?.type === 'author' ? 'Author' : 'Reviewer',
    ...(data?.firstName && {
      givenName: data?.firstName,
    }),
    ...(data?.lastName && {
      familyName: data?.lastName,
    }),
    ...(data?.honorificSuffix && {
      honorificSuffix: data?.honorificSuffix,
    }),
    ...(data?.bio && {
      description: data.bio,
    }),
    ...(data?.photo && {
      image: {
        '@type': 'URL',
        url: data?.photo,
      },
    }),
    ...(data?.linkWebsite && {
      url: {
        '@type': 'URL',
        url: data?.linkWebsite,
      },
    }),
  };

  const pathLowerCased = location.pathname.toLowerCase();
  if (location.pathname !== pathLowerCased) {
    if (staticContext) {
      staticContext.statusCode = 301;
    }

    return (
      <RRRedirect
        to={{
          pathname: pathLowerCased,
          search: location.search,
        }}
      />
    );
  }

  if (!data) {
    return <Route component={FourOhFourRoute} />;
  }

  // Prepare slices.
  const slices = [];

  if (activePage === 0 && data.sliceData) {
    const sliceData: [] = JSON.parse(data.sliceData);
    slices.push(...sliceData);
  }

  if (data?.gridAuto?.cardList.length && data.gridAuto.totalPages) {
    slices.push(gridAutoProps);
  }

  if (activePage === 0 && data.footerSliceData) {
    const footerSliceData: [] = JSON.parse(data.footerSliceData);
    slices.push(...footerSliceData);
  }

  if (data?.meta?.og) {
    // "author" or "reviewer"
    data.meta.og.type = data.type;
  }

  const contributorOverviewLink =
    config?.contributor?.[`${data.type}OverviewLink`];
  const contributorOverviewLinkText =
    data.type === 'author'
      ? translations.CONTRIBUTOR_AUTHOR_OVERVIEW_LINK_TEXT
      : translations.CONTRIBUTOR_REVIEWER_OVERVIEW_LINK_TEXT;

  return (
    <>
      <GTM key={`gtm-${data.id}`} category={data.type} />
      <RouteMetadata {...(data.meta ? data.meta : {})} />
      {entitlementContext.entitlement === 'Guest' && (
        <Hreflang hreflangs={data.hreflangs} />
      )}
      <JSONLD key="jsonLd" nestedKey="worksFor" content={jsonLD} />
      <AuthorMasthead
        name={data.formattedName}
        jobTitle={data.subtitle}
        photo={data.photo}
        bio={data.bio}
        social={data.socialLinks}
      />
      {contributorOverviewLink && (
        <Container className={styles.breadcrumbContainer}>
          <LinksBar
            linksArray={[
              {
                accessibilityLabel: '',
                newWindow: true,
                text: contributorOverviewLinkText,
                url: contributorOverviewLink,
              },
            ]}
            variant="breadcrumbs"
          />
        </Container>
      )}
      <SlicesContainer slices={slices} type="contributor" region="body" />
    </>
  );
};
