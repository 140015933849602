import 'url-search-params-polyfill';
import ReactDOM from 'react-dom';
import ReactModal2 from 'react-modal2';

import '@ww-digital/web-palette-react/dist/styles/normalize-base.scss';
import '@ww-digital/web-palette-react/dist/styles/globals/_globals.scss';
import './styles/styles.scss';
import './styles/onetrust/styles.scss';

import type { ConfigTranslationsLocale } from './components/Config/ConfigTranslations/ConfigTranslations.tsx';
import { App } from './components/App.tsx';
import { ApolloManager } from './components/Provider/Apollo/ApolloManager.ts';
import wwUtility from '../src/ww.utility.ts';

// After 2 hours from the initial page load, hard reload the page to prevent stale JS assets.
// Only do this when the page is focused to prevent zombie browsers from pummeling the server.
const HARD_RELOAD_DELAY = 2 * 60 * 60 * 1000;
let isFocused = true;
let reloadOnFocus = false;

const isWebView =
  window.navigator &&
  window.navigator.userAgent &&
  !!window.navigator.userAgent.match(/WWApp|WWMobile|WW_Mobile_Android/);
if (isWebView && document && document.body) {
  document.body.classList.add('webview');
}

window.addEventListener('focus', function () {
  isFocused = true;

  if (reloadOnFocus) {
    (window.location.reload as (cache: boolean) => void)(true);
  }
});

window.addEventListener('blur', function () {
  isFocused = false;
});

setTimeout(() => {
  // If the window is focused, trigger a reload. Otherwise, schedule the reload for once the window is focused.
  if (isFocused) {
    (window.location.reload as (cache: boolean) => void)(true);
  } else {
    reloadOnFocus = true;
  }
}, HARD_RELOAD_DELAY);

// For accessibility support: https://github.com/cloudflare/react-modal2#accessibility
ReactModal2.getApplicationElement = () => document.getElementById('root');

// If there was a server error set, and it is true.  Then do not attempt to start the app.
if (window.error !== true) {
  const searchParams = new URLSearchParams(window.location.search);
  const env = searchParams.get('env');
  const prodGQL = wwUtility.getProdGQLDomain();
  const gqlDomainOverride =
    window.environment !== 'prod' && env && env === 'prod' ? prodGQL : '';
  const apolloManager = new ApolloManager(false, gqlDomainOverride);
  const experimentsHeader = window.experiments_header || '';
  const experimentsFranchise = window.experiments_franchise || '';
  const translations = window.applanga_translations || '';

  ReactDOM.hydrate(
    <App
      apolloManager={apolloManager}
      experimentsHeader={experimentsHeader}
      experimentsFranchise={experimentsFranchise}
      translations={translations as ConfigTranslationsLocale}
      url={window.location}
      cookies={document.cookie}
    />,
    document.getElementById('root'),
  );
}
