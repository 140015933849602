import React, { useContext, useRef } from 'react';
import _ from 'lodash';

import type { PlanSquareProps } from '@ww-digital/web-palette-react/dist/components/Square/PlanSquare/PlanSquare';
import type { PlansDifferentiatorSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/PlansDifferentiatorSlice/PlansDifferentiatorSlice';
import type { MarketContextType } from '../../../context/market.context';

import { PlansDifferentiatorSlice } from '@ww-digital/web-palette-react/dist/components/Slice/PlansDifferentiatorSlice/PlansDifferentiatorSlice';

import { MarketContext } from '../../../context/market.context.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { PricingUtility } from '../../Utility/PricingUtility.ts';

interface PlansDifferentiatorSliceContainerProps {
  daCategory: string;
  slice: PlansDifferentiatorSliceProps;
}

const PlanPricingRecurly = (
  planInit: PlanSquareProps,
  planIndex: number,
  plansPricing: React.MutableRefObject<PlanSquareProps[]>,
): void => {
  const marketContext = useContext<MarketContextType>(MarketContext);
  const plan = _.cloneDeep(planInit);

  // All dynamic pricing and zip code entry is disabled.
  plan.textPrice = { text: '', price: '' };

  // If Pricing Enabled, use standard recurly checkout URL.
  if (plan.cta && plan.pricing) {
    plan.cta.url = PricingUtility.getRecurlyPricingUrl(
      marketContext.country,
      marketContext.language,
    );
  }

  plansPricing.current[planIndex] = plan;
};

export const PlansDifferentiatorSliceContainer = ({
  daCategory,
  slice,
}: PlansDifferentiatorSliceContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);
  const plansPricing = useRef<PlanSquareProps[]>(sliceView.plans || []);

  if (sliceView.plans) {
    sliceView.plans.forEach((plan, planIndex) => {
      PlanPricingRecurly(plan, planIndex, plansPricing);

      if (plan.cta) {
        // Analytics tracking for CTA buttons.
        plan.cta.attributes = {};
        plan.cta.attributes['da-category'] = daCategory;
        plan.cta.attributes['da-action'] = AnalyticsUtility.formatAction(
          'plan_differentiator_plan_cta',
          plan.cta.text,
        );
        plan.cta.attributes['da-label'] = AnalyticsUtility.formatLabel(
          plan.title,
        );
      }
    });

    sliceView.plans = plansPricing.current;
  }

  return <PlansDifferentiatorSlice {...sliceView} />;
};
