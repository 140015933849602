// Generating and maintaining unique counters - each for "groupId" group.
let counter: Record<string, number> = {};

function resetCounter() {
  counter = {};
}

function getCounter(groupId: string): number {
  if (!counter[groupId]) {
    counter[groupId] = 1;
  } else {
    counter[groupId] = (counter[groupId] + 1) % Number.MAX_SAFE_INTEGER; // Prevent crazy overflows by wrapping.
  }
  return counter[groupId];
}

export const Counter = {
  resetCounter,
  getCounter,
};
