export class QueryStringUtility {
  static getQueryParams(queryString = '') {
    const searchParams = new URLSearchParams(queryString);
    const result: Record<string, string> = {};

    for (const param of searchParams) {
      result[param[0]] = param[1];
    }

    return result;
  }

  static getQueryString(queryObject: Record<string, string>) {
    return new URLSearchParams(queryObject).toString();
  }
}
