import { CancellationFormProps } from '@ww-digital/web-palette-react/dist/components/CancellationForm/CancellationForm';

const loginUrl = 'https://auth.weightwatchers.de/v2/login/';
const dateRegex =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
const allowAllRegex = /[\s\S]*/;

export const formatTimestamp = () => {
  const timestamp = new Date().toLocaleString('de-de', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  const formatted = timestamp.replace(/\./g, '/');

  return formatted;
};

export const cancellationFormData: CancellationFormProps = {
  headline: 'Schade, dass du uns verlassen willst',
  form: {
    login: {
      headline: 'Meine Mitgliedschaft kündigen',
      body: 'Für einen schnelleren Kündigungsprozess im Falle einer regulären Kündigung empfehlen wir dir, dich einzuloggen und unseren automatisierten Kündigungsprozess zu nutzen.',
      cta: {
        accessibilityLabel: 'Log-In',
        text: 'Log-In',
        onClick: () => window.open(loginUrl),
      },
    },
    separatorText: 'Oder',
    inputFieldSubheading:
      'Bitte Formular ausfüllen. Wir benötigen die Angaben, um die Kündigung zeitnah bearbeiten zu können. Bitte gib die E-Mail-Adresse an, die du bei deiner Mitgliedschaft bei WW verwendest.',
    inputFieldHeading:
      'Wenn du dich nicht einloggen kannst oder möchtest, fülle bitte das folgende Formular vollständig aus. Ein Mitarbeiter unseres Service-Teams wird deinen Kündigungswunsch bearbeiten und sich ggfs. mit Rückfragen bei dir melden.',
    inputs: {
      memberId: {
        placeholder: 'Mitglieds-Nummer (optional)',
        regex: allowAllRegex,
      },
      firstName: {
        placeholder: 'Vorname*',
      },
      lastName: {
        placeholder: 'Nachname*',
      },
      email: {
        placeholder: 'E-mail*',
      },
      telephone: {
        placeholder: 'Telefonnummer (optional)',
      },
      ordinaryCheckbox: {
        label: 'Reguläre Kündigung',
      },
      extraordinaryCheckbox: {
        label: 'Außerordentliche Kündigung',
      },
      earliestPossibleCheckbox: {
        label: 'Schnellstmöglicher Termin',
      },
      chooseDateCheckbox: {
        label: 'Anderes Datum wählen:',
      },
      date: {
        placeholder: 'TT/MM/JJJJ',
        regex: dateRegex,
        regexErrorMessage:
          'Bitte das Datum im richtigen Format (TT/MM/JJJJ) angeben.',
      },
    },
    cancellationType: {
      headline: 'Art der Kündigung',
      options: [
        {
          label: 'Reguläre Kündigung',
          text: 'Beendet deine Mitgliedschaft zum Ende der aktuellen Laufzeit deiner Mitgliedschaft',
        },
        {
          label: 'Außerordentliche Kündigung',
          text: 'Beendet deine Mitgliedschaft sofort (Kündigungsgrund und Nachweis benötigt)',
        },
      ],
      reason: {
        headline: 'Grund für außerordentliche Kündigung:',
        criteria:
          'Achtung: Bitte beachte, dass nur bestimmte Gründe eine außerordentliche Kündigung rechtfertigen und von uns akzeptiert werden. Ein Mitarbeiter unseres Service-Teams wird deinen Wunsch für eine außerordentliche Kündigung prüfen und sich gegebenenfalls mit Rückfragen bei dir melden.',
        placeholder: 'Grund',
      },
    },
    cancellationTime: {
      headline: 'Wunschdatum für das Ende deiner Mitgliedschaft',
      body: 'Wir bemühen uns, dein Wunschdatum bei der Bearbeitung zu berücksichtigen. Ein Mitarbeiter unseres Service-Teams wird deine aktuelle Mitgliedschaft und deinen Wunschtermin prüfen.',
      options: [
        {
          label: 'Schnellstmöglicher Termin',
          text: '',
        },
        {
          label: 'Anderes Datum wählen:',
          text: 'Wenn kein konkretes Datum ausgewählt wird, werden wir das Datum zum Ende deiner aktuellen Laufzeit im Kündigungsprozess berücksichtigen.',
        },
      ],
    },
    submitCta: {
      accessibilityLabel: 'Jetzt kündigen',
      text: 'Jetzt kündigen',
    },
  },
  confirmation: {
    headline: 'Kündigung',
    body: 'Wir haben deine Kündigung erhalten. Wir senden dir separat an die von dir angegebene E-Mail-Adresse eine Bestätigung mit den Informationen zu deiner Kündigung. Ein Mitarbeiter unseres Service-Teams wird deine Kündigung prüfen und sich ggfs. mit Rückfragen bei dir melden.',
    receipt: {
      headline:
        'Wir senden dir parallel eine Kopie dieser Bestätigung per E-Mail.',
      printCta: {
        accessibilityLabel: 'Zum Drucken hier klicken',
        text: 'Zum Drucken hier klicken',
        onClick: () => window.print(),
      },
      membershipEnd: 'Wunschdatum für das Ende deiner Mitgliedschaft:',
      requestReceived: 'Kündigungsantrag gesendet am:',
      requestTimestamp: formatTimestamp(),
    },
  },
  status: {
    success: false,
    errorMessage: '',
  },
};
