import React from 'react';

export type QueryStringContextType = {
  [key: string]: string;
};

export const defaultQueryStringContext: QueryStringContextType = {};

export const QueryStringContext = React.createContext<QueryStringContextType>(
  defaultQueryStringContext,
);
