import React, { ReactNode, useContext } from 'react';
import {
  Redirect as RRRedirect,
  RedirectProps as RRRedirectProps,
  useLocation,
} from 'react-router-dom';

import type { MarketContextType } from '../../../context/market.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';

import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { EntitlementUtility } from '../../Utility/EntitlementUtility.ts';

type RedirectProps = RRRedirectProps & {
  children?: ReactNode;
  entitlement?: EntitlementContextType['entitlement'];
  pathWithEntitlement?: boolean;
  queryString?: string;
};

export const Redirect = ({
  entitlement,
  pathWithEntitlement = false,
  queryString = '',
  ...otherProps
}: RedirectProps): JSX.Element => {
  const { search } = useLocation();
  const { marketBasePath } = useContext<MarketContextType>(MarketContext);
  const { entitlementBasePath: initEntitlementBasePath } =
    useContext<EntitlementContextType>(EntitlementContext);
  const entitlementBasePath = pathWithEntitlement
    ? ''
    : entitlement
      ? EntitlementUtility.getEntitlementBasePath(entitlement)
      : initEntitlementBasePath;

  const { children, to, ...rest } = otherProps;

  return (
    <RRRedirect
      to={{
        pathname: `${marketBasePath}${entitlementBasePath}${to}`,
        search: queryString || search,
      }}
      {...rest}
    />
  );
};
