import { Domain } from './Domain.ts';
import { AppUtility } from './AppUtility.ts';

interface Requests {
  [key: string]: {
    [key: string]: Promise<unknown>;
  };
}

export class Authenticator {
  requests: Requests;

  static domain = new Domain({
    subdomain: 'cmx',
  });

  constructor() {
    this.requests = {};
  }

  getEndpoint(country: string, language: string) {
    const environment = AppUtility.getEnv();

    const authDomain = Authenticator.domain.getEndpoint(
      environment,
      country,
      language,
    );

    return `${authDomain}/api/v2/cmx/members/~`;
  }

  // Authenticate the user in a country and language.
  authenticate(country: string, language: string) {
    // If we have not requested this info yet, request it.
    if (!this.requests[country] || !this.requests[country][language]) {
      const endpoint = this.getEndpoint(country, language);

      // Init a country object if we don't have one yet.
      this.requests[country] = this.requests[country] || {};

      // Make the auth request.
      this.requests[country][language] = fetch(endpoint, {
        credentials: 'include',
        mode: 'no-cors',
      })
        .then((data) => data.json())
        .then((data) => {
          if (!data || data.errorCode) {
            return null;
          }

          return data;
        })
        .catch(() => null);
    }

    // Return the request promise.
    return this.requests[country][language];
  }
}
