import React from 'react';
import _ from 'lodash';

import type { BentoBoxHomepageProps } from '@ww-digital/web-palette-react/dist/components/BentoBoxHomepage/BentoBoxHomepage';

import { BentoBoxHomepage } from '@ww-digital/web-palette-react/dist/components/BentoBoxHomepage/BentoBoxHomepage';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { bentoBoxHomepageData as bentoBoxHomepageDataV1 } from './BentoBoxSliceData.ts';
import { bentoBoxHomepageData as bentoBoxHomepageDataV2 } from './BentoBoxSliceDataV2.ts';

interface BentoBoxSliceProps {
  daCategory: string;
  slice: BentoBoxHomepageProps;
}

export const BentoBoxSliceContainer = ({
  daCategory,
  slice,
}: BentoBoxSliceProps): JSX.Element => {
  const data =
    slice?.version === 'v2' ? bentoBoxHomepageDataV2 : bentoBoxHomepageDataV1;

  const sliceView = _.merge({}, data, slice);

  // Set up DA actions for 3-card and 4-card versions
  const daActions = {
    primaryCardOne: 'masthead_bento_card1_points',
    primaryCardTwo: 'masthead_bento_card2_clinic',
    primaryCardThree: 'masthead_bento_card3_workshops',
    primaryCardFour: '',
  };
  if (slice?.version === 'v3') {
    daActions.primaryCardThree = 'masthead_bento_card3_registereddietitians';
    daActions.primaryCardFour = 'masthead_bento_card4_workshops';
  }

  // Add Button analytics
  sliceView.footerSection?.button &&
    (sliceView.footerSection.button.attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        'masthead_bento_cta_take-our-quiz',
      ),
      'da-label': AnalyticsUtility.formatLabel(
        sliceView.footerSection.button.children,
      ),
    });

  sliceView.footerSection?.secondaryButton &&
    (sliceView.footerSection.secondaryButton.attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        'masthead_bento_cta_view-pricing',
      ),
      'da-label': AnalyticsUtility.formatLabel(
        sliceView.footerSection.secondaryButton.children,
      ),
    });

  const section =
    slice?.version === 'v2'
      ? sliceView.bentoBoxSectionV2
      : sliceView.bentoBoxSection;

  section?.primaryCardOne?.link &&
    (section.primaryCardOne.link.attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(daActions.primaryCardOne),
      'da-label': AnalyticsUtility.formatLabel(
        section.primaryCardOne.heading?.text,
      ),
    });

  section?.primaryCardTwo?.link &&
    (section.primaryCardTwo.link.attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(daActions.primaryCardTwo),
      'da-label': AnalyticsUtility.formatLabel(
        section.primaryCardTwo.heading?.text,
      ),
    });

  if (slice?.version !== 'v2') {
    sliceView.bentoBoxSection?.primaryCardThree?.link &&
      (sliceView.bentoBoxSection.primaryCardThree.link.attributes = {
        'da-category': daCategory,
        'da-action': AnalyticsUtility.formatAction(daActions.primaryCardThree),
        'da-label': AnalyticsUtility.formatLabel(
          sliceView.bentoBoxSection.primaryCardThree.heading?.text,
        ),
      });
  }

  if (slice?.version === 'v3') {
    sliceView.bentoBoxSection?.primaryCardFour?.link &&
      (sliceView.bentoBoxSection.primaryCardFour.link.attributes = {
        'da-category': daCategory,
        'da-action': AnalyticsUtility.formatAction(daActions.primaryCardFour),
        'da-label': AnalyticsUtility.formatLabel(
          sliceView.bentoBoxSection.primaryCardFour.heading?.text,
        ),
      });
  }

  return <BentoBoxHomepage {...sliceView} />;
};
