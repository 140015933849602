export const executeFunctions_GREX163 = (pathname: string, variant: string) => {
  if (variant === 'control') {
    if (pathname === '/us/pdp/a') {
      // Select the node that will be observed for mutations
      /* Masthead Slice update */
      const slice_one = document.getElementById(
        'slice-id--a0088984-c1ff-4f0d-a11c-d94db349f1e3',
      );
      /* "Get First Month of Membership Free" Slice */
      const slice_two = document.getElementById(
        'slice-id--e420c533-691a-4299-8c43-d528ba679a86',
      );
      /* "Ready to Lose Weight" Slice */
      const slice_three = document.getElementById(
        'slice-id--cd43971c-1b78-4a68-9182-30e2ec4298f9',
      );

      // Options for the observer (which mutations to observe)
      const config = { attributes: true, childList: true, subtree: true };

      // Callback function to execute when mutations are observed
      const callback_one = (mutationList: any, observer_one: any) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            const searchParam = window.location.search;

            if (
              !document
                .querySelectorAll(
                  '#slice-id--a0088984-c1ff-4f0d-a11c-d94db349f1e3 .Button_button__dGIsp.Tout_ctaButton__GgP4j.Button_primary__u7Dex.Button_large__4KMSr',
                )[0]
                .getAttribute('href')
                ?.includes(`https://app.joinsequence.com/welcome${searchParam}`)
            ) {
              document
                .querySelectorAll(
                  '#slice-id--a0088984-c1ff-4f0d-a11c-d94db349f1e3 .Button_button__dGIsp.Tout_ctaButton__GgP4j.Button_primary__u7Dex.Button_large__4KMSr',
                )[0]
                .setAttribute(
                  'href',
                  `https://app.joinsequence.com/welcome${searchParam}`,
                );
            }
          }
        }
      };

      const callback_two = (mutationList: any, observer_two: any) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            const searchParam = window.location.search;

            if (
              !document
                .querySelectorAll(
                  '#slice-id--e420c533-691a-4299-8c43-d528ba679a86 .Button_button__dGIsp.Tout_ctaButton__GgP4j.Button_primary__u7Dex.Button_large__4KMSr',
                )[0]
                .getAttribute('href')
                ?.includes(`https://app.joinsequence.com/welcome${searchParam}`)
            ) {
              document
                .querySelectorAll(
                  '#slice-id--e420c533-691a-4299-8c43-d528ba679a86 .Button_button__dGIsp.Tout_ctaButton__GgP4j.Button_primary__u7Dex.Button_large__4KMSr',
                )[0]
                .setAttribute(
                  'href',
                  `https://app.joinsequence.com/welcome${searchParam}`,
                );
            }
          }
        }
      };

      const callback_three = (mutationList: any, observer_three: any) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            const searchParam = window.location.search;

            if (
              !document
                .querySelectorAll(
                  '#slice-id--cd43971c-1b78-4a68-9182-30e2ec4298f9 .Button_button__dGIsp.Tout_ctaButton__GgP4j.Button_primary__u7Dex.Button_large__4KMSr',
                )[0]
                .getAttribute('href')
                ?.includes(`https://app.joinsequence.com/welcome${searchParam}`)
            ) {
              document
                .querySelectorAll(
                  '#slice-id--cd43971c-1b78-4a68-9182-30e2ec4298f9 .Button_button__dGIsp.Tout_ctaButton__GgP4j.Button_primary__u7Dex.Button_large__4KMSr',
                )[0]
                .setAttribute(
                  'href',
                  `https://app.joinsequence.com/welcome${searchParam}`,
                );
            }
          }
        }
      };

      if (slice_one && slice_two && slice_three) {
        // Create an observer instance linked to the callback function
        const observer_one = new MutationObserver(callback_one);
        const observer_two = new MutationObserver(callback_two);
        const observer_three = new MutationObserver(callback_three);

        // Start observing the target node for configured mutations
        observer_one.observe(slice_one, config);
        observer_two.observe(slice_two, config);
        observer_three.observe(slice_three, config);
      }

      // Later, you can stop observing
      //observer.disconnect();
    }
  }
};
