import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import type { MarketContextType } from '../../../context/market.context';

import { BrowserOnly } from '@ww-digital/web-palette-react/dist/components/Utility/BrowserOnly/BrowserOnly';
import { ContentRoute } from '../ContentRoute/ContentRoute.tsx';
import { FourOhFourRoute } from '../FourOhFourRoute/FourOhFourRoute.tsx';
import { MarketContext } from '../../../context/market.context.ts';
import { MarketUtility } from '../../Utility/MarketUtility.ts';
import wwUtility from '../../../ww.utility.ts';

export const CoachBrowserRoute = (): JSX.Element => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const coachesUrl = wwUtility.getMarketField(country, language, 'coachesUrl');

  const containerDivAttributes = {
    'data-path': coachesUrl ? coachesUrl : null,
  };

  const path = MarketUtility.getCoachPath(country, language);
  if (!path) {
    return <Route component={FourOhFourRoute} />;
  }

  return (
    <>
      <BrowserOnly>
        <Helmet>
          <script src="https://answers.us.weightwatchers.com.pagescdn.com/iframe.js" />
        </Helmet>
        <div id="answers-container" {...containerDivAttributes} />
      </BrowserOnly>
      <ContentRoute path={path} slicesRequired={false} />
    </>
  );
};
