export function JSONSafeParse<T>(
  jsonString: string,
  reviverFunc?: (key: string, value: any) => any,
): T | { error: any } {
  try {
    return JSON.parse(jsonString, reviverFunc) as T;
  } catch (error: any) {
    console.warn('Error parsing JSON:', error);
    return { error } as { error: any };
  }
}
