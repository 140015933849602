import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import * as ApplangaJS from '@ww-digital/applanga-js';
import _ from 'lodash';

import type { AnalyticsProps, MetaProps } from '../ContentRoute/ContentRoute';
import type { MarketContextType } from '../../../context/market.context';
import type { ConfigContextType } from '../../../context/config.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { HreflangType } from '../../Metadata/Hreflang/Hreflang.tsx';

import { Article } from '@ww-digital/web-palette-react/dist/components/Article/Article';
import { AdsContainer } from '../../AdsContainer/AdsContainer.tsx';
import { GTM } from '../../GTM/GTM.tsx';
import { Hreflang } from '../../Metadata/Hreflang/Hreflang.tsx';
import { JSONLD } from '../../Metadata/JSONLD/JSONLD.tsx';
import { RouteMetadata } from '../../Metadata/RouteMetadata/RouteMetadata.tsx';
import { SlicesContainer } from '../../Slices/SlicesContainer/SlicesContainer.tsx';
import { getCitationJsonLd } from '../../Slices/SourcesSliceContainer/SourcesSliceContainer.tsx';
import { AppUtility } from '../../Utility/AppUtility.ts';
import { getBreadcrumb } from '../../Utility/Breadcrumb.ts';
import { ArticleSuggestionsContainer } from '../../Regions/ArticleSuggestionsContainer/ArticleSuggestionsContainer.tsx';
import { MarketContext } from '../../../context/market.context.ts';
import { ConfigContext } from '../../../context/config.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { MarketUtility } from '../../Utility/MarketUtility.ts';

interface Person {
  name: string;
  slug: string;
  type?: string;
  isOrganization?: boolean;
}

interface JsonLdData {
  '@type': 'Article';
  headline: string;
  image: string;
  dateModified: string;
  datePublished: string;
  url: string;
  author:
    | Record<string, never>
    | {
        '@type': 'Organization' | 'Person';
        name: string | null;
        url?: string;
      };
  citation?: {
    '@type': string;
    citation: string;
  }[];
}

interface ArticleRouteProps {
  data: {
    content: {
      changed: string;
      created: string;
      masthead: {
        headline: string;
        subheading: string;
        imageDesktop: string;
        imageMobile: string;
        imageAltText: string;
        imageCaption: string;
        imageByline: string;
      };
      id: string;
      taxonomies: string[];
      analytics: AnalyticsProps;
      section: {
        name: string;
      };
      authors: Person[];
      medReviewers: Person[];
      factCheckers: Person[];
      sciReviewers: Person[];
      breadcrumb: [
        {
          name: string;
          slug: string;
        },
      ];
      publishedBlog: string;
      updatedBlog: string;
      meta: MetaProps;
      sliceData: string;
      footerSliceData: string;
      hreflangs: HreflangType[];
    };
  };
  path: string;
}

const noImageContent =
  'https://v.cdn.ww.com/media/system/cms/us/no-image-content.png?auto=webp&optimize=medium';

export const ArticleRoute = ({
  data,
  path,
}: ArticleRouteProps): JSX.Element => {
  const entitlementContext =
    useContext<EntitlementContextType>(EntitlementContext);
  const marketContext = useContext<MarketContextType>(MarketContext);
  const { entitlement, entitlementBasePath } = entitlementContext;
  const { country, language } = marketContext;

  const { config, translations } = useContext<ConfigContextType>(ConfigContext);
  const location = useLocation();
  const { marketBasePath } = useContext<MarketContextType>(MarketContext);
  const { content } = data;

  // Return content data needed for DFP Ads.
  const getAdsContentData = () => {
    const { id, taxonomies } = data.content;

    return {
      id,
      type: 'article',
      tags: taxonomies,
    };
  };

  const getDigitalData = () => {
    const { topLevelCategory, ...analytics } = data.content.analytics;
    return analytics;
  };

  const getTopLevelCategory = () => {
    return data.content.analytics?.topLevelCategory || '';
  };

  const getURL = () => {
    return `${AppUtility.getDomain(country, true)}${location.pathname}`;
  };

  const getJSONLD = (authors: Person[], slices: any, footerSlices: any) => {
    const { changed, created, masthead } = data.content;

    const jsonData: JsonLdData = {
      '@type': 'Article',
      headline: masthead.headline,
      image: masthead.imageDesktop,
      dateModified: changed,
      datePublished: created,
      url: getURL(),
      author: {},
    };

    const byLine =
      authors && authors.length > 0 && authors[0].name ? authors[0].name : null;

    if (byLine) {
      jsonData.author = {
        '@type': authors[0]?.isOrganization ? 'Organization' : 'Person',
        name: byLine,
        url: `${AppUtility.getDomain(country, true)}${getNameHref(authors[0])}`,
      };
    }

    let sourcesSlice = null;
    if (slices?.length && slices.length > 0) {
      sourcesSlice = slices.find((s: any) => s.__typename === 'SourcesSlice');
    }
    if (!sourcesSlice && footerSlices?.length && footerSlices.length > 0) {
      sourcesSlice = footerSlices.find(
        (s: any) => s.__typename === 'SourcesSlice',
      );
    }
    if (sourcesSlice) {
      jsonData.citation = getCitationJsonLd(sourcesSlice);
    }

    return jsonData;
  };

  const getNameHref = (person: Person) => {
    return (
      person.type &&
      `${marketBasePath}${entitlementBasePath}${MarketUtility.getContributorPath(
        person.type,
        country,
        language,
      )}/${person.slug}`
    );
  };

  const getNameFormatted = (person: Person) => {
    if (!config.contributor.enabledContributors) {
      return person.name;
    }

    if (!person.slug) {
      return person.name;
    }

    const url = getNameHref(person);

    return `<a href='${url}'>${person.name}</a>`;
  };

  const getNamesFormatted = (persons: Person[]) => {
    if (!persons) {
      return '';
    }

    if (!persons.length) {
      return '';
    }

    const names: string[] = [];
    persons.forEach((person) => names.push(getNameFormatted(person)));

    if (names.length === 1) {
      return names[0];
    }

    const lastName = names.slice(-1);
    const allNamesExceptLast = names.slice(0, -1);

    const allNamesExceptLastFormatted = allNamesExceptLast.join(
      translations.ARTICLE_NAMES_SEPARATOR,
    );
    const lastNameFormatted = `${translations.ARTICLE_LAST_NAME_SEPARATOR}${lastName}`;

    return `${allNamesExceptLastFormatted}${lastNameFormatted}`;
  };

  const getByline = (authorsMessage: string, authors: Person[]): string => {
    if (!authors || !authorsMessage) {
      return '';
    }

    const authorsFormatted = getNamesFormatted(authors);

    if (!authorsFormatted) {
      return '';
    }

    return ApplangaJS.get(authorsMessage || '', {
      names: authorsFormatted,
    });
  };

  const getByLineFormatted = (
    authors: Person[],
    medReviewers: Person[],
    factCheckers: Person[],
    sciReviewers: Person[],
  ) => {
    const translationsMapping = [
      'ARTICLE_AUTHORS',
      'ARTICLE_MED_REVIEWERS',
      'ARTICLE_FACT_CHECKERS',
      'ARTICLE_SCI_REVIEWERS',
    ];

    const messagesFormated: string[] = [];
    [authors, medReviewers, factCheckers, sciReviewers].forEach(
      (persons, index) =>
        messagesFormated.push(
          getByline(_.get(translations, translationsMapping[index]), persons),
        ),
    );

    return messagesFormated.filter((byLine) => byLine).join(' | ');
  };

  const getAuthors = (authors: Person[]) => {
    return authors
      ? authors
      : [
          {
            name: translations.ARTICLE_BYLINE_DEFAULT_AUTHOR
              ? translations.ARTICLE_BYLINE_DEFAULT_AUTHOR
              : 'WeightWatchers',
            slug: '',
            isOrganization: true,
          },
        ];
  };

  const getDateFormatted = (message: string, date: string): string => {
    if (!message || !date) {
      return '';
    }

    return ApplangaJS.get(message || '', {
      date,
    });
  };

  const { facebook, twitter, pinterest, linkedin } =
    config.socialNetworks || {};

  let enabledCategories = false;
  if (config.blog) {
    enabledCategories = config.blog.enabledCategories;
  }

  const socialLabels = {
    facebookLabel: translations.GLOBAL_FACEBOOK_LABEL,
    twitterLabel: translations.GLOBAL_TWITTER_LABEL,
    pinterestLabel: translations.GLOBAL_PINTEREST_LABEL,
    linkedinLabel: translations.GLOBAL_LINKEDIN_LABEL,
  };

  const {
    section,
    masthead,
    authors,
    medReviewers,
    factCheckers,
    sciReviewers,
    breadcrumb,
    publishedBlog,
    updatedBlog,
    meta,
    sliceData,
    footerSliceData,
    hreflangs,
  } = data.content;

  const processedAuthors = getAuthors(authors);

  const { name: sectionName } = section || {};

  const digitalData = getDigitalData();
  const topLevelCategory = getTopLevelCategory();
  const fallback = `${AppUtility.getDomain(country)}${noImageContent}`;

  // Use capitalized entitlement to check ads config is enabled. ie enabledGuest.
  const showAds =
    config.ads && _.get(config.ads, `enabled${_.upperFirst(entitlement)}`);

  // If no ads sidebar expects null.
  const ads = showAds ? <AdsContainer content={getAdsContentData()} /> : null;

  const articleMastheadProps = {
    linksBar: enabledCategories
      ? getBreadcrumb(
          breadcrumb,
          translations.ARTICLE_BLOG_BREADCRUMB_TITLE,
          marketContext,
          entitlementContext,
        )
      : null,
    section: sectionName,
    headline: masthead.headline,
    subheading: masthead.subheading,
    byLine: getByLineFormatted(
      processedAuthors,
      medReviewers,
      factCheckers,
      sciReviewers,
    ),
    published: getDateFormatted(translations.ARTICLE_PUBLISHED, publishedBlog),
    updated: getDateFormatted(translations.ARTICLE_UPDATED, updatedBlog),
    social: {
      media: masthead.imageDesktop || fallback,
      url: getURL(),
      title: masthead.headline,
      socialLabels,
      facebook: entitlement === 'Guest' && facebook.enabled,
      twitter: entitlement === 'Guest' && twitter.enabled,
      pinterest: entitlement === 'Guest' && pinterest.enabled,
      linkedin: entitlement === 'Guest' && linkedin.enabled,
    },
    mainLayoutWithSidebar: showAds,
  };

  const articleMastheadImageProps = {
    imageDesktop: masthead.imageDesktop,
    imageMobile: masthead.imageMobile,
    alt: masthead.imageAltText,
    caption: masthead.imageCaption,
    byLine: masthead.imageByline,
  };

  const slices = sliceData ? JSON.parse(sliceData) : null;
  const footerSlices = footerSliceData ? JSON.parse(footerSliceData) : null;

  const jsonLD = getJSONLD(processedAuthors, slices, footerSlices);
  return (
    <>
      <GTM key={`gtm-${content.id}`} {...digitalData} />
      <RouteMetadata {...meta} />
      {entitlement === 'Guest' && <Hreflang hreflangs={hreflangs} />}
      <JSONLD key="jsonLd" content={jsonLD} />
      <Article
        masthead={articleMastheadProps}
        mastheadImage={articleMastheadImageProps}
        main={
          <SlicesContainer
            slices={slices}
            type="article"
            region="body"
            category={digitalData.category}
            topLevelCategory={topLevelCategory}
            contentId={content.id}
          />
        }
        footer={
          <>
            <SlicesContainer
              slices={footerSlices}
              type="article"
              region="footer"
              category={digitalData.category}
              topLevelCategory={topLevelCategory}
              contentId={content.id}
            />
            {config?.latestContent.status && (
              <ArticleSuggestionsContainer
                path={path}
                topLevelCategory={topLevelCategory}
              />
            )}
          </>
        }
        sidebar={ads}
      />
    </>
  );
};
