import accounting from 'accounting';
import * as ApplangaJS from '@ww-digital/applanga-js';
import _ from 'lodash';

import type { OfferBundle, ContentItem } from '../../hooks/useProducts';
import type { QueryStringContextType } from '../../context/querystring.context';
import type { PlansDurationSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/PlansDurationSlice/PlansDurationSlice';
import type { ChangeDurationProps } from '@ww-digital/web-palette-react/dist/components/Price/ChangeDuration/ChangeDuration';
import type { ChangeDurationItemProps } from '@ww-digital/web-palette-react/dist/components/Price/ChangeDurationItem/ChangeDurationItem';
import type { ChangePlanProps } from '@ww-digital/web-palette-react/dist/components/Price/ChangePlan/ChangePlan';
import type { PriceBreakdownProps } from '@ww-digital/web-palette-react/dist/components/Price//PriceBreakdown/PriceBreakdown';
import type { AttributeTypes } from '@ww-digital/web-palette-react/dist/types/Attributes';
import type { TextTypogs } from '@ww-digital/web-palette-react/dist/components/Text/Text/Text';
import type { TextPriceProps } from '@ww-digital/web-palette-react/dist/components/Price/TextPrice/TextPrice';
import type { EntitlementContextType } from '../../context/entitlement.context';
import type { ConfigTranslationsLocale } from '../Config/ConfigTranslations/ConfigTranslations';
import type { StorageValue } from '@ww-digital/web-palette-react/dist/components/Utility/Storage';

import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import wwUtility from '../../ww.utility.ts';
import { AppUtility } from './AppUtility.ts';
import { MarketUtility } from './MarketUtility.ts';
import { PricingUtility } from './PricingUtility.ts';
import { AnalyticsUtility } from './AnalyticsUtility.ts';

export type ChangePlanItemType = {
  name?: string;
  value?: string;
  label?: string;
  switcherLabel?: string;
  switcherAttributes?: AttributeTypes;
  labelTypog?: TextTypogs;
  textPrice?: TextPriceProps;
  description?: string;
  offerBundleId?: string | null;
  subscriptionType?: string;
};

export interface PriceDetails {
  label: string;
  price: string;
  rawPrice: number;
  strikethrough: boolean;
  productType: string;
}

// Original data from ppm/v1/offers/plans
export interface OfferPlan {
  id: string;
  owner: OfferPlanOwner;
  ltc: boolean;
  systemName: string;
  subtotal: number;
  total: number;
  discount: number;
  currency: string;
  plan: OfferPlanPlan;
  addons: OfferPlanAddon[];
  contents: OfferPlanContents[];
  qualifiers: any[]; // n/a
  coupons: any[]; // n/a
  categories: any[]; // n/a
}

export interface OfferPlanOwner {
  id: number;
  name: string;
}

export interface OfferPlanPlan {
  id: string;
  name: string;
  freeTrialDays: number;
  renewalOptionType: string;
  state: string;
  retailPrice: number;
  salePrice: number;
  discount: number;
  basePlan: OfferPlanBasePlan;
  contents: OfferPlanContents[];
  planRamps: any[]; // n/a
  planLocaleOverrides: any[]; // n/a
  templateContents: OfferPlanTemplateContents[];
}

export interface OfferPlanBasePlan {
  id: string;
  code: string;
  name: string;
  intervalLength: number;
  intervalUnit: string;
  trialLength: number;
  trialRequiresBillingInfo: boolean;
  totalBillingCycles: number;
  customFields: OfferPlanCustomField[];
  accountingCode: string | null;
  currencies: OfferPlanCurrency[];
}

export interface OfferPlanCustomField {
  name: string;
  value: string;
}

export interface OfferPlanCurrency {
  currency: string;
  unitAmount: number;
}

export interface OfferPlanContents {
  id: string;
  offerPlanId: string;
  contentKeyId: string;
  contentKey: string;
  contentName: string;
  contentValue: string;
  locale: string;
  state: string;
}

export interface OfferPlanTemplateContents {
  templateContentKeyId: string;
  templateContentKeyName: string;
  templateContent: OfferPlanTemplateContentsTemplateContent;
  archived: boolean;
  dirty: false;
  promoted: false;
  approved: false;
}

export interface OfferPlanTemplateContentsTemplateContent {
  templateContentId: string;
  templateValue: string;
  locale: string;
}

export interface OfferPlanAddon {
  id: string;
  offerPlanId: string;
  state: string;
  retailPrice: number;
  salePrice: number;
  discount: number;
  addonItem: OfferPlanAddonItem;
}

export interface OfferPlanAddonItem {
  id: string;
  name: string;
  state: string;
  baseItem: OfferPlanAddonItemBaseItem;
  pricings: OfferPlanAddonItemPricings[];
}

export interface OfferPlanAddonItemBaseItem {
  id: string;
  code: string;
  name: string;
  accountingCode: string;
  currencies: OfferPlanCurrency[];
}

export interface OfferPlanAddonItemPricings {
  id: string;
  parentId: string;
  localId: string;
  discountPercent: number;
  salePrice: number;
  state: string;
}

export interface NormalizedOfferPlan {
  contents: Record<string, string>;
  disclaimer: string;
  id: string;
  intervalLength: number;
  intervalUnit?: string;
  isFranchise: boolean;
  isFreeTrial: boolean;
  isLTC: boolean;
  isPaymentRequired: boolean;
  isShippingRequired: boolean;
  ltcDisclaimer: string;
  name: string;
  offerBundleId: string;
  offerPlanName: string;
  ownerId: string;
  priceDetails: NormalizedOfferPlanPriceDetails[];
  priceTotal: NormalizedOfferPlanPriceTotal;
  promotionContent: string;
  salesPitch: string; // VS Only
  subscriptionType: string;
  terms: string;
  title: string;
  totalBillingCycles: number;
  totalSavings: string;
}

export interface NormalizedOfferPlanPriceTotal {
  label: string;
  price: string;
  original?: string;
}

export interface NormalizedOfferPlanPriceDetails {
  code?: string;
  crossOut?: boolean;
  label: string;
  sublabel?: string;
  line?: number;
  price: PriceObj;
  original?: PriceObj;
}

export interface AddonsMapItem {
  code: string;
  name: string;
  retailPrice: number;
  salePrice: number;
  discount: number;
}

export interface PriceObj {
  raw: number | null;
  formatted: string;
}

export interface PriceBreakdownContentKeyObj {
  label: string;
  sublabel: string;
  price?: string;
  original?: string;
}

export type AssesssmentCookieDetails = {
  aid: string;
  eligibleSequence: boolean;
  eligibleWw: boolean;
};

export type AssessmentCookie = Record<string, AssesssmentCookieDetails>;

export type IAFCookie = {
  iafToken: string;
  iafReferrerOC: string;
  iafCC: string;
};

const baseChangePlanItem: ChangePlanItemType = {
  name: '',
  value: '',
  label: '',
  textPrice: {
    text: '',
    price: '',
  },
  description: '',
};

const bundleNameSpecialChars = '‡†^';

const coreTypes = [
  'core',
  'online_plus',
  'digital',
  'standard',
  'basico',
  'bas',
];
const premiumTypes = ['premium', 'meetings', 'workshop'];
const pcTypes = ['pc', 'personal_coaching', 'p-coaching'];
const clinicalTypes = ['clinical'];

// constants also used by LPF
const STARTER_FEE = 'starterfee';
const CLINICAL_CONSULTATION_FEE = 'clinical-consultationfee';

const addonsMap: Record<string, string> = {
  [STARTER_FEE]: 'STARTER_FEE_LABEL',
};

const isStarterFee = (addon: AddonsMapItem) =>
  addon.code?.includes(STARTER_FEE);
const isClinicalConsultationFee = (addon: AddonsMapItem) =>
  addon.code?.includes(CLINICAL_CONSULTATION_FEE);

export class ProductsUtility {
  // Determine if two subscriptionType values match.
  static stEquals(st1: string, st2: string): boolean {
    return (
      ProductsUtility.subscriptionType(st1) ===
      ProductsUtility.subscriptionType(st2)
    );
  }

  // Standardize subscriptionType.
  static subscriptionType(st: string): string {
    if (!st) {
      return '';
    }
    if (coreTypes.includes(st.toLowerCase())) {
      return 'core';
    }
    if (premiumTypes.includes(st.toLowerCase())) {
      return 'premium';
    }
    if (pcTypes.includes(st.toLowerCase())) {
      return 'pc';
    }
    if (clinicalTypes.includes(st.toLowerCase())) {
      return 'clinical';
    }
    return '';
  }

  // Determine if this is a Checkout type page.  True if:
  // supplied path is the main pricing page, AND
  // supplied queryArgs includes either an ob arg, or both an st arg and an own arg.
  static isCheckoutPage(
    queryArgs: QueryStringContextType,
    path: string,
    translations: ConfigTranslationsLocale,
    country: string,
    language = '',
  ) {
    const workshopsPath = translations.PRODUCTS_WORKSHOPS_PATH;
    const keys = Object.keys(queryArgs);
    // For now, add the current US /main-workshps page as another Checkout C page.
    return (
      (path === PricingUtility.getRecurlyPricingUrl(country, language) ||
        path === workshopsPath) &&
      (keys.includes('ob') || (keys.includes('st') && keys.includes('own')))
    );
  }

  static getCheckoutUrl(
    env: string,
    marketContext: { country: string; marketBasePath: string },
    op: string,
    own: string,
    ob: string,
    returnPath: string,
    st = '',
    returnOB = false,
    iafToken?: string,
    iafReferrerOC?: string,
    iafCC?: string,
    aid?: string,
    ma?: string,
  ) {
    const forcePublic = env === 'local';
    const signupDomain = AppUtility.getDomain(
      marketContext.country,
      true,
      forcePublic,
    );
    const queryParams: Record<string, string> = {
      op,
      own,
      ob,
      returnPath,
    };
    if (st) {
      queryParams.st = st;
    }
    if (returnOB) {
      queryParams.returnOB = '1';
    }

    // If invite a friend (iaf) query params exist,
    // set a session cookie and use the values later.
    if (iafToken || iafReferrerOC || iafCC) {
      Storage.setCookieValue(
        'ww_iaf',
        {
          iafToken,
          iafReferrerOC,
          iafCC,
        } as StorageValue,
        0,
        marketContext.marketBasePath,
      );
    }

    // If iaf query params don't exist,
    // read session cookie value and use those.
    const iafCookie = Storage.getCookieValue(
      'ww_iaf',
      true,
    ) as IAFCookie | null;
    const iafTokenValue = iafToken || iafCookie?.iafToken || '';
    const iafReferrerOCValue = iafReferrerOC || iafCookie?.iafReferrerOC || '';
    const iafCCValue = iafCC || iafCookie?.iafCC || '';

    if (iafTokenValue) {
      queryParams.iafToken = iafTokenValue;
    }
    if (iafReferrerOCValue) {
      queryParams.iafReferrerOC = iafReferrerOCValue;
    }
    if (iafCCValue) {
      queryParams.iafCC = iafCCValue;
    }

    // Assessment ID query-arg for Sequence
    if (aid) {
      queryParams.aid = aid;
    }

    // Medication Access query-arg for Sequence
    if (ma) {
      queryParams.ma = ma;
    }

    const queryParamsString = new URLSearchParams(queryParams);
    return `${signupDomain}${marketContext.marketBasePath}/signup/r/user-account?${queryParamsString}`;
  }

  // OfferBundle retrieved either by offerBundleId, or else by subscriptionType
  static getOfferBundle(
    offerBundles: OfferBundle[],
    offerBundleId: string | null = '',
    subscriptionType: string | null = '',
  ): OfferBundle | null {
    const offerBundle = offerBundles.find(
      (offerBundle) =>
        (offerBundleId && offerBundle.offerBundleId === offerBundleId) ||
        (!offerBundleId &&
          ProductsUtility.stEquals(
            offerBundle.subscriptionType,
            subscriptionType || '',
          )),
    );
    return offerBundle || null;
  }

  static getContentByKey(contents: ContentItem[], key: string): string {
    const content =
      contents?.find((item) => {
        return item.contentKey.toUpperCase() === key.toUpperCase();
      })?.contentValue || '';

    // Remove QuillJS-generated content in blank fields until fixed in PPM
    return content === '<p><br></p>' ? '' : `${content}`;
  }

  static getChangePlan = (
    offerBundles: OfferBundle[],
    pricingTranslations: Record<string, string>,
    enableChangeMembershipSwitch: boolean,
  ): ChangePlanProps => {
    if (!offerBundles) {
      return {};
    }

    const items = offerBundles
      .map((offerBundle) => {
        const plan = Object.assign({}, baseChangePlanItem);
        plan.name = ProductsUtility.subscriptionType(
          offerBundle?.subscriptionType,
        );
        plan.value = ProductsUtility.subscriptionType(
          offerBundle?.subscriptionType,
        );
        plan.label = ProductsUtility.getContentByKey(
          offerBundle?.contents,
          'TITLE',
        );
        plan.textPrice = {
          text: ProductsUtility.getContentByKey(
            offerBundle?.contents,
            'STARTING_AT_PRICE',
          ),
          price: '', // Assume no price token support in MVP
        };
        plan.description = ProductsUtility.getContentByKey(
          offerBundle?.contents,
          'MODAL_DESCRIPTION',
        );

        return plan;
      })
      .filter((item) => item !== null) as ChangePlanItemType[];

    // @TODO Get real current item, based on state.  For now, the first.
    const defaultItemName = (items.length && items[0].value) || '';
    const headline = pricingTranslations.modalTitle;
    const version = enableChangeMembershipSwitch ? 'toggleV2' : 'modal';

    return {
      defaultItemName,
      headline,
      version,
      items,
    };
  };

  // Return content headline if present, else return content title.
  static getHeadline = (offerBundle: OfferBundle | null): string => {
    if (!offerBundle) {
      return '';
    }
    const title = ProductsUtility.getContentByKey(
      offerBundle.contents,
      'TITLE',
    );
    const headline = ProductsUtility.getContentByKey(
      offerBundle.contents,
      'HEADLINE',
    );
    return headline ? headline : title;
  };

  // Return content title if headline is present, else return blank string.
  static getSubheadline = (offerBundle: OfferBundle | null): string => {
    if (!offerBundle) {
      return '';
    }
    const title = ProductsUtility.getContentByKey(
      offerBundle.contents,
      'TITLE',
    );
    const headline = ProductsUtility.getContentByKey(
      offerBundle.contents,
      'HEADLINE',
    );
    return headline ? title : '';
  };

  static getDescription = (offerBundle: OfferBundle | null): string => {
    return offerBundle
      ? ProductsUtility.getContentByKey(offerBundle?.contents, 'DESCRIPTION')
      : '';
  };

  static getDescriptionFeatures = (
    offerBundle: OfferBundle | null,
    idx = 1,
  ): PlansDurationSliceProps['descriptionFeatures'] => {
    const field = idx === 2 ? 'FEATURES_DESCRIPTION_2' : 'FEATURES_DESCRIPTION';
    try {
      const descriptionFeatures = offerBundle
        ? ProductsUtility.getContentByKey(offerBundle?.contents, field)
        : '[]';
      return JSON.parse(descriptionFeatures);
    } catch {
      return [];
    }
  };

  static getFeaturesTitle = (
    offerBundle: OfferBundle | null,
    idx = 1,
  ): string => {
    if (!offerBundle) {
      return '';
    }
    const field = idx === 2 ? 'FEATURES_TITLE_2' : 'FEATURES_TITLE';
    return ProductsUtility.getContentByKey(offerBundle.contents, field)
      .replace('<p>', '')
      .replace('</p>', '');
  };

  static getAddonsBlock = (
    offerBundle: OfferBundle | null,
    translations: ConfigTranslationsLocale,
    entitlement: EntitlementContextType['entitlement'],
  ): ChangeDurationProps['addonsBlock'] | null => {
    // If no data, return null.
    if (!offerBundle) {
      return null;
    }

    // If OfferBundle does not enable Addons Block, return null.
    if (
      ProductsUtility.getContentByKey(offerBundle.contents, 'ADDONS_SHOW') !==
      'true'
    ) {
      return null;
    }

    // If Visitor has been assesseed ineligible, return null.
    if (
      ProductsUtility.isSequenceAssessed() &&
      !ProductsUtility.isSequenceEligible()
    ) {
      return null;
    }

    const addonsBlock: ChangeDurationProps['addonsBlock'] = {
      headline: translations.PRODUCTS_ADDONS_HEADLINE,
      description: ProductsUtility.getContentByKey(
        offerBundle.contents,
        'ADDONS_DESCRIPTION',
      )
        .replace('<p>', '')
        .replace('</p>', ''),
      addonCards: [
        {
          headline: translations.PRODUCTS_ADDON_CLINICAL_HEADLINE,
          description: ProductsUtility.getContentByKey(
            offerBundle.contents,
            'ADDON_CLINICAL_DESCRIPTION',
          )
            .replace('<p>', '')
            .replace('</p>', ''),
          cta: {
            url: '',
            text: '',
            attributes: {},
          },
          ribbonText: translations.PRODUCTS_ADDON_CLINICAL_RIBBON,
        },
      ],
    };

    // Adjust the labels and URLs of the Core Addons card.
    // (If Assessed but not Eligible, the card is already removed.)

    // Analytics.
    const assessment_url = ProductsUtility.getContentByKey(
      offerBundle.contents,
      'ADDON_CLINICAL_ASSESSMENT_URL',
    );
    const add_url = ProductsUtility.getContentByKey(
      offerBundle.contents,
      'ADDON_CLINICAL_ADD_URL',
    );

    addonsBlock.addonCards[0].cta.attributes['da-category'] =
      ProductsUtility.getDACategory(entitlement, 'pricing');

    if (ProductsUtility.isSequenceAssessed()) {
      addonsBlock.addonCards[0].statusMessage =
        translations.PRODUCTS_ADDON_CLINICAL_YOU_QUALIFY;
      addonsBlock.addonCards[0].cta.text =
        translations.PRODUCTS_ADDON_CLINICAL_ADD_LINK_LABEL;
      addonsBlock.addonCards[0].cta.url = add_url
        ? add_url
        : translations.PRODUCTS_ADDON_CLINICAL_ADD_URL;

      // Qualified for Sequence analytics da-action.
      addonsBlock.addonCards[0].cta.attributes['da-action'] = 'clinical_add';
    } else {
      addonsBlock.addonCards[0].statusMessage = '';
      addonsBlock.addonCards[0].cta.text =
        translations.PRODUCTS_ADDON_CLINICAL_ASSESSMENT_LINK_LABEL;
      addonsBlock.addonCards[0].cta.url = assessment_url
        ? assessment_url
        : translations.PRODUCTS_ADDON_CLINICAL_ASSESSMENT_URL;

      // Add Sequence analytics da-action.
      addonsBlock.addonCards[0].cta.attributes['da-action'] =
        'clinical_qualification';
    }

    return addonsBlock;
  };

  static getChangeDuration = (
    offerBundle: OfferBundle | null,
    translations: ConfigTranslationsLocale,
    marketContext: { country: string; language: string },
    entitlement: EntitlementContextType['entitlement'],
    offerPlanId: string,
    isClinicalPage = false,
  ): ChangeDurationProps => {
    if (!offerBundle) {
      return { items: [], headline: '', defaultItemName: '' };
    }
    let isTemplateD = false;

    const { country, language } = marketContext;
    const changeDuration: ChangeDurationProps = {
      items: [],
      headline: '',
      defaultItemName: '',
    };

    const normalizedOfferPlans =
      offerBundle.offerPlans?.map((plan) =>
        plan.offerPlanData
          ? ProductsUtility.getNormalizedOffer(
              plan.offerPlanData,
              country,
              language,
              offerBundle.offerBundleId,
              offerBundle.ownerId,
            )
          : null,
      ) || [];

    const items: ChangeDurationItemProps[] = [];
    normalizedOfferPlans.forEach((offerPlan) => {
      if (!offerPlan) {
        return;
      }

      const template = ProductsUtility.getTemplate(
        offerPlan.contents,
        country,
        language,
      );

      // If any Offer Plan is template D, set slice formatting for D
      if (template === 'D') {
        isTemplateD = true;
      }

      // Here we convert line item data structures from fields shared
      // with LPF (original and price) to PLT fields (price and price2).
      const priceBreakdownItems = offerPlan.priceDetails.map((item) => {
        const label = item.label;
        let label2 = undefined;
        let price = item.price?.formatted || '';
        let price2 = undefined;
        let strikethrough = item.crossOut;

        if (template === 'D') {
          label2 = item.sublabel;
          price2 = price;
          price = item.original ? item.original.formatted : ' ';
          strikethrough = !!item.original;
        }

        return {
          label,
          label2,
          price,
          price2,
          strikethrough,
        };
      });

      // Set up Total Line for either template.
      let totalLabel = offerPlan.priceTotal.label;
      let totalLabel2 = undefined;
      let totalPrice = offerPlan.priceTotal.label
        ? offerPlan.priceTotal.price
        : '';
      let totalPrice2 = undefined;
      let totalStrikethrough = undefined;
      if (template === 'D') {
        totalLabel = offerPlan.contents.TOTAL_LABEL;
        totalLabel2 = offerPlan.contents.TOTAL_SUBLABEL;
        totalPrice2 = offerPlan.priceTotal.price;
        totalPrice = offerPlan.priceTotal.original
          ? offerPlan.priceTotal.original
          : ' ';
        totalStrikethrough = !!offerPlan.priceTotal.original;
      }

      const priceBreakdown: PriceBreakdownProps = {
        headline: translations.PRODUCTS_PRICE_BREAKDOWN_HEADLINE || '',
        items: priceBreakdownItems,
        totalLabel,
        totalLabel2,
        totalPrice,
        totalPrice2,
        totalStrikethrough,
        savingsLabel:
          template === 'A' && offerPlan.contents.SAVINGS_LABEL
            ? `(${
                offerPlan.contents.SAVINGS_LABEL
              } : ${ProductsUtility.formatCurrency(
                offerPlan.totalSavings,
                country,
                language,
              )})`
            : '',
        marketingMessage:
          template === 'D' ? offerPlan.contents.STARTER_FEE_SUBLABEL : '',
      };

      const item: ChangeDurationItemProps = {
        name: `${offerPlan.id}`,
        value: `${offerPlan.id}`,
        label: ProductsUtility.formatSpecialCharacters(offerPlan.offerPlanName),
        flag: offerPlan.salesPitch,
        textPrice: [
          {
            text: offerPlan.contents.DURATION_LABEL
              ? `[price:token]/${offerPlan.contents.DURATION_LABEL}`
              : `[price:token]`,
            price: template === 'D' ? '' : offerPlan.priceTotal.price,
            offer: offerPlan.contents.SPECIAL_OFFER,
          },
        ],
        tooltip: {
          content: offerPlan.promotionContent,
          accessibilityLabel: ApplangaJS.get(
            translations.PRICING_TOOLTIP_LABEL || '',
            {
              name: offerPlan.name,
            },
          ),
          position: offerPlan.contents.SPECIAL_OFFER
            ? 'after_description'
            : 'after_price',
        },
        priceBreakdown,
      };

      items.push(item);
    });

    changeDuration.items = items;
    changeDuration.headline = translations.PRICING_DURATION_TITLE;
    // @TODO Do a check if offerPlanId from slice state is part of current list of bundles
    changeDuration.defaultItemName = offerPlanId
      ? offerPlanId
      : items.length
        ? items[0].value
        : '';

    const addonsBlock = ProductsUtility.getAddonsBlock(
      offerBundle,
      translations,
      entitlement,
    );
    if (!isClinicalPage && addonsBlock) {
      changeDuration.addonsBlock = addonsBlock;
    }

    if (isClinicalPage) {
      changeDuration.addonToggle = {
        headline: translations.PRODUCTS_CLINICAL_TOGGLE_LABEL || '',
        isEnabled: true,
        accessibilityLabel: '',
      };
    }

    if (isTemplateD) {
      changeDuration.version = 'V5';
    }

    return changeDuration;
  };

  static getDisclaimer = (
    offerBundle: OfferBundle | null,
    marketContext: { country: string; language: string },
  ): string => {
    if (!offerBundle) {
      return '';
    }

    const { country, language } = marketContext;
    const normalizedOfferPlans =
      offerBundle.offerPlans?.map((plan) =>
        plan.offerPlanData
          ? ProductsUtility.getNormalizedOffer(
              plan.offerPlanData,
              country,
              language,
              offerBundle.offerBundleId,
              offerBundle.ownerId,
            )
          : null,
      ) || [];

    const disclaimers = normalizedOfferPlans
      .map((offerPlan, idx) => {
        if (!offerPlan) {
          return '';
        }
        const offerName = ProductsUtility.formatSpecialCharacters(
          offerPlan.offerPlanName,
        );
        const offerDisclaimer = offerPlan.disclaimer;
        return !!offerName && !!offerDisclaimer
          ? `<p style="margin: 1em 0"}><strong>${offerName}</strong></p>${offerDisclaimer}`
          : '';
      })
      .filter((item) => !!item);

    return disclaimers.length ? disclaimers.join('\n') : '';
  };

  static formatSpecialCharacters = (name: string): string => {
    if (!name) {
      return name;
    }
    let formattedName = name;
    for (const c of bundleNameSpecialChars) {
      formattedName = formattedName.replace(c, `<sup>${c}</sup>`);
    }
    return formattedName;
  };

  static formatCurrency = (
    currencyToFormat: number | string,
    country: string,
    language: string,
    removeDecimals = false,
  ): string => {
    const wwLocale = MarketUtility.wwLocale(country, language);

    const decimalSeparator = wwLocale.decimalSeparator;
    const thousandSeparator = wwLocale.thousandSeparator;
    const symbol = wwLocale.currency;

    let currencyFormat: string | null = null;
    let currencyWithoutSymbol: string[] | null = null;
    let currency: string | null = null;

    if (
      typeof currencyToFormat === 'string' &&
      currencyToFormat.includes(symbol)
    ) {
      // If currency is already formatted, return the same
      return currencyToFormat;
    } else {
      let decimals = 2;
      const currencyNumber = Number(currencyToFormat);
      if (removeDecimals && Number.isInteger(currencyNumber)) {
        decimals = 0;
      }

      // Original signup-web code used Angular decimalPipe for this.
      currencyWithoutSymbol = accounting
        .formatNumber(Math.abs(currencyNumber), decimals)
        .split('.');

      // to add and format thousand separator for the currency value
      if (currencyWithoutSymbol[0].length > 3) {
        currencyWithoutSymbol[0] = currencyWithoutSymbol[0]
          .split(',')
          .join(thousandSeparator);
      }

      if (wwLocale.market === 'sv-SE') {
        // truncate the decimal part for SE
        currency = currencyWithoutSymbol[0];
      } else {
        // format decimal part accordingly for all other locales
        if (['de-CH', 'fr-CH'].includes(wwLocale.market)) {
          currencyWithoutSymbol[1] = currencyWithoutSymbol[1]?.replace(
            '00',
            '—',
          );
        }
        currency = currencyWithoutSymbol.join(decimalSeparator);
      }

      currencyFormat = wwLocale.currencyFormat || '';
      currency = !currency.length
        ? ''
        : currencyFormat
          ? currencyFormat
              .replace('%symbol', symbol)
              .replace('%number', currency)
          : '';
      if (Number(currencyToFormat) < 0) {
        currency = `-${
          ['de-CH', 'fr-CH'].includes(wwLocale.market) ? ' ' : ''
        }${currency}`;
      }

      return currency.trimEnd();
    }
  };

  static hideStarterFee = (country: string) => {
    const hideStartFeeCountries = ['au', 'nz'];
    return hideStartFeeCountries.includes(country.toLowerCase());
  };

  static getPriceObj = (
    price: number | null,
    country: string,
    language: string,
    removeDecimals = false,
  ): PriceObj => ({
    raw: price,
    formatted: ProductsUtility.formatCurrency(
      price?.toString() || '',
      country,
      language,
      removeDecimals,
    ),
  });

  static isNumeric = (val: string | null | undefined) =>
    // (val == null) is equivalent to _.isNil
    val !== '' && !(val == null) && !isNaN(val as any);

  // @TODO content types.   Get specific keys into a Type.
  static getNewRampLineItems = (
    keys: PriceBreakdownContentKeyObj[],
    contents: any,
    country: string,
    language: string,
  ) => {
    return keys.map((contentKey, index: number) => {
      const { label, price, original, sublabel } = contentKey;
      const originalPrice =
        original && ProductsUtility.isNumeric(contents[original])
          ? Number(contents[original])
          : null;
      const salePrice =
        price && ProductsUtility.isNumeric(contents[price])
          ? Number(contents[price])
          : null;
      const priceDiff =
        originalPrice === null || salePrice === null
          ? 0
          : originalPrice - salePrice;
      const line = index + 2;
      return {
        label: contents[label],
        price: ProductsUtility.getPriceObj(salePrice, country, language, true),
        line,
        ...(originalPrice &&
          priceDiff > 0 && {
            original: ProductsUtility.getPriceObj(
              originalPrice,
              country,
              language,
              true,
            ),
          }),
        ...(contents[sublabel] && { sublabel: contents[sublabel] }),
      };
    });
  };

  static getCrossOutObj = (
    addon: AddonsMapItem,
    hasRamp1: boolean,
    country: string,
    language: string,
  ) => {
    const templateB =
      wwUtility.getMarketField(country, language, 'priceDetailsTemplate') ===
      'B';

    let crossOutObj = {};
    if (hasRamp1 && country === 'us') {
      const priceDiff = addon.retailPrice - addon.salePrice;
      crossOutObj = {
        ...(priceDiff > 0 && {
          original: ProductsUtility.getPriceObj(
            addon.retailPrice,
            country,
            language,
            hasRamp1,
          ),
        }),
      };
    } else if (isStarterFee(addon)) {
      crossOutObj = {
        [templateB ? 'starterFeeWaived' : 'crossOut']:
          addon.retailPrice > 0 && addon.salePrice === 0,
      };
    } else {
      crossOutObj = {
        crossOut: addon.retailPrice > 0 && addon.salePrice === 0,
      };
    }
    return crossOutObj;
  };

  static getPriceLineItem = (
    contents: Record<string, string>,
    addon: AddonsMapItem,
    line: number,
    hasRamp1: boolean,
    country: string,
    language: string,
  ): NormalizedOfferPlanPriceDetails => {
    const useSalePrice = hasRamp1 && country === 'us';
    // @TODO validate addon prices first?
    const price = useSalePrice ? addon.salePrice : addon.retailPrice;

    return {
      label:
        contents[addonsMap[isStarterFee(addon) ? STARTER_FEE : addon.code]] ||
        addon.name,
      price: ProductsUtility.getPriceObj(price, country, language, hasRamp1),
      line,
      code: addon.code,
      ...ProductsUtility.getCrossOutObj(addon, hasRamp1, country, language),
    };
  };

  static getTemplate = (
    contents: Record<string, string>,
    country: string,
    language: string,
  ): string => {
    const hasRamp1 = !!contents.RAMP1_PROGRAM_LABEL;
    if (hasRamp1 && country === 'us') {
      return 'D';
    }

    return wwUtility.getMarketField(country, language, 'priceDetailsTemplate');
  };

  static getPriceDetailsArr = (
    offer: OfferPlan,
    contents: Record<string, string>,
    subscriptionType: string,
    addons: AddonsMapItem[],
    country: string,
    language: string,
  ): NormalizedOfferPlanPriceDetails[] => {
    const { plan, discount: offerDiscount } = offer;
    const hasRamp1 = !!contents.RAMP1_PROGRAM_LABEL;
    const priceDetailsTemplate: string = wwUtility.getMarketField(
      country,
      language,
      'priceDetailsTemplate',
    );
    const template = ProductsUtility.getTemplate(contents, country, language);

    // return price as 0, if free trial or flex plan
    if (plan.freeTrialDays > 0) {
      return [
        {
          label: contents.SUBSCRIPTION_PLAN_DISPLAY_NAME,
          price: ProductsUtility.getPriceObj(0, country, language),
        },
      ].filter((priceDetail) => !!priceDetail.label);
    }

    let priceBreakdown = [];
    // plan price
    const planPrice = {
      label: contents.SUBSCRIPTION_PLAN_DISPLAY_NAME,
      price: ProductsUtility.getPriceObj(
        priceDetailsTemplate === 'B' ? plan.salePrice : plan.retailPrice,
        country,
        language,
      ),
      line: 1,
    };

    // all addons
    const getLineNo = (addon: AddonsMapItem) =>
      isStarterFee(addon) ? 2 : addons.indexOf(addon) + 3;

    const { addonsArr } = addons.reduce(
      (acc: any, addon) => {
        const hideStarterFeeLineItem =
          ProductsUtility.hideStarterFee(country) && isStarterFee(addon);
        if (hideStarterFeeLineItem || isClinicalConsultationFee(addon)) {
          return acc;
        }

        if (addon.salePrice !== null && addon.salePrice !== undefined) {
          acc.addonsArr.push(
            ProductsUtility.getPriceLineItem(
              contents,
              addon,
              getLineNo(addon),
              hasRamp1,
              country,
              language,
            ),
          );
        }

        return acc;
      },
      { addonsArr: [] },
    );

    let discountLineNo;
    let discount;
    let lineItems: NormalizedOfferPlanPriceDetails[] = [];

    // get first starter fee addon
    const stFee = addonsArr.find((addon: AddonsMapItem) => isStarterFee(addon));
    switch (template) {
      case 'A':
        discountLineNo = addons.length + 2;
        discount = {
          label: contents.DISCOUNT_LABEL,
          price: ProductsUtility.getPriceObj(
            -1 * plan.discount,
            country,
            language,
          ),
          line: discountLineNo,
        };
        lineItems = [planPrice, ...addonsArr, discount];
        break;
      case 'B':
        // FR logic for starterfee
        // insert a discounted starter fee line
        if (stFee?.price && stFee?.starterFeeWaived) {
          const stDiscounted = {
            ...stFee,
            label: contents.DISCOUNT_LABEL,
            price: ProductsUtility.getPriceObj(
              -1 * stFee.price.raw,
              country,
              language,
            ),
            line: stFee.line + 1,
          };
          addonsArr.splice(addonsArr.indexOf(stFee) + 1, 0, stDiscounted);
        }
        lineItems = [planPrice, ...addonsArr];
        break;
      case 'C':
        // DE & CH logic for starterfee
        // don't strikethrough starter fee
        if (stFee) stFee.crossOut = false;
        discountLineNo = stFee?.line + 1 || 1;
        discount = {
          label: contents.DISCOUNT_LABEL,
          price: ProductsUtility.getPriceObj(
            -1 * offerDiscount,
            country,
            language,
          ),
          line: discountLineNo,
        };
        lineItems = [planPrice, ...addonsArr, discount];
        break;
      case 'D':
        {
          const consultationAddon = addons.find((addon) =>
            isClinicalConsultationFee(addon),
          );
          const contentKeys: PriceBreakdownContentKeyObj[] = [
            {
              label: 'RAMP1_PROGRAM_LABEL',
              price: 'RAMP1_PROGRAM_SALE_PRICE',
              original: 'RAMP1_PROGRAM_RETAIL_PRICE',
              sublabel: 'RAMP1_PROGRAM_SUBLABEL',
            },
            {
              label: 'CONSULT_FEE_LABEL',
              sublabel: 'CONSULT_FEE_SUBLABEL',
            },
            {
              label: 'RAMP1_MEDS_LABEL',
              price: 'RAMP1_MEDS_SALE_PRICE',
              original: 'RAMP1_MEDS_RETAIL_PRICE',
              sublabel: 'RAMP1_MEDS_SUBLABEL',
            },
          ];

          const items = ProductsUtility.getNewRampLineItems(
            contentKeys,
            contents,
            country,
            language,
          );

          if (consultationAddon) {
            const { salePrice, retailPrice } = consultationAddon;
            const price = ProductsUtility.getPriceObj(
              consultationAddon.salePrice,
              country,
              language,
              true,
            );
            const original = ProductsUtility.getPriceObj(
              consultationAddon.retailPrice,
              country,
              language,
              true,
            );
            const priceDiff = retailPrice - salePrice;

            items[1] = {
              ...items[1],
              price,
              ...(priceDiff > 0 && { original }),
            };
          } else {
            items.splice(1, 1);
          }

          lineItems = [...addonsArr, ...items];
        }
        break;
    }

    priceBreakdown = _.sortBy(lineItems, 'line').filter(
      (priceDetail) => !!priceDetail.label,
    );
    return priceBreakdown;
  };

  static getNormalizedOffer = (
    offer: OfferPlan,
    country: string,
    language: string,
    offerBundleId: string,
    ownerId: string,
  ): NormalizedOfferPlan => {
    const {
      plan: { basePlan, contents: planContents, templateContents },
      addons,
      contents,
    } = offer;
    const getSubscriptionType = (basePlan: OfferPlanBasePlan) =>
      _.filter(basePlan.customFields, ['name', 'subscriptionType'])[0]?.value;

    const subscriptionType = getSubscriptionType(basePlan);

    const mapContents = (
      contents: OfferPlanContents[],
      locale: string,
    ): Record<string, string> =>
      contents.reduce((acc: Record<string, string>, content) => {
        if (content?.locale !== locale) return acc;
        acc[content.contentKey] = content.contentValue || '';
        return acc;
      }, {});

    const mapTemplateContents = (
      contents: OfferPlanTemplateContents[],
      locale: string,
    ): Record<string, string> =>
      contents.reduce((acc: Record<string, string>, content) => {
        if (content?.templateContent?.locale !== locale) return acc;
        acc[content.templateContentKeyName] =
          content.templateContent.templateValue || '';
        return acc;
      }, {});

    const addonsMap: AddonsMapItem[] = addons.map((addon: OfferPlanAddon) => {
      const aa = {
        ..._.pick(addon, ['retailPrice', 'salePrice', 'discount']),
        code: addon.addonItem.baseItem.code,
        name: addon.addonItem.name,
      };
      return aa;
    });

    const locale: string = wwUtility.getMarketField(
      country,
      language,
      'hreflang',
    );
    const contentsMap =
      contents || planContents
        ? mapContents([...contents, ...planContents], locale)
        : {};
    const { RAMP1_PROGRAM_LABEL } = contentsMap;
    const templateContentsMap = templateContents
      ? mapTemplateContents(templateContents, locale)
      : {};

    const total = offer.plan.freeTrialDays > 0 ? 0 : offer.total;
    const subtotalAmt =
      !!RAMP1_PROGRAM_LABEL && country === 'us' && contentsMap.SAVINGS_LABEL
        ? contentsMap.SAVINGS_LABEL
        : offer.subtotal;

    const showSubtotal =
      !!RAMP1_PROGRAM_LABEL && !!subtotalAmt && Number(subtotalAmt) > total;

    const offerFormatted = {
      id: offer.id,
      offerBundleId: offerBundleId,
      contents: contentsMap,
      name: basePlan.name,
      title: contentsMap.OFFER_PLAN_NAME,
      intervalLength: basePlan.intervalLength,
      intervalUnit: basePlan.intervalUnit,
      priceDetails: ProductsUtility.getPriceDetailsArr(
        offer,
        contentsMap,
        subscriptionType,
        addonsMap,
        country,
        language,
      ),
      priceTotal: {
        label: contentsMap.TOTAL_LABEL,
        price: ProductsUtility.formatCurrency(
          total,
          country,
          language,
          !!RAMP1_PROGRAM_LABEL,
        ),
        ...(showSubtotal && {
          original: ProductsUtility.formatCurrency(
            subtotalAmt,
            country,
            language,
            true,
          ),
        }),
      },
      totalSavings: ProductsUtility.formatCurrency(
        offer.discount,
        country,
        language,
      ),
      isPaymentRequired: basePlan.trialRequiresBillingInfo,
      isShippingRequired: contentsMap.SHIPPING_REQUIRED === 'YES', // double check
      ownerId: ownerId,
      terms: templateContentsMap.TERMS_AND_CONDITIONS,
      totalBillingCycles: basePlan.totalBillingCycles, // old commitment length, analytics duration
      isLTC: basePlan.intervalLength === 1 && basePlan.totalBillingCycles > 1,
      isFreeTrial: basePlan.trialLength > 0,
      isFranchise: ownerId !== '37',
      ltcDisclaimer: contentsMap.LTC_LEGAL_DISCLOSURE,
      disclaimer: contentsMap.DISCLAIMER,
      promotionContent: contentsMap.PROMOTION_CONTENT,
      emailTemplate: contentsMap.RECEIPT_EMAIL_NAME,
      subscriptionType,
      offerPlanName: contentsMap.OFFER_PLAN_NAME,
      durationUnit: contentsMap.DURATION_LABEL,

      // Not part of RSW normalizedOffer
      salesPitch: contentsMap.SALES_PITCH,
    };
    return offerFormatted;
  };

  // Save the products data in local Storage
  static storeOfferPlanData(
    offerData: OfferPlan,
    stg: boolean,
    country: string,
    language: string,
  ) {
    const wwLocale = MarketUtility.wwLocale(country, language);

    // Safe versions of localStorage function
    const safeSetItem = (name: string, item: string) => {
      if (wwUtility.isBrowser() && window.localStorage) {
        try {
          window.localStorage.setItem(name, item);
        } catch (e) {
          console.log('OfferData localStorage.setItem error.');
        }
      }
    };

    // Prepare products data JSON value with meta, params, and data
    const storedOfferPlanData = {
      meta: {
        timestamp: Date.now(),
        market: wwLocale.market,
        stg,
        version: 1,
      },
      data: offerData,
    };

    // Save products data JSON
    safeSetItem('wwPpmOffer', JSON.stringify(storedOfferPlanData));
  }

  static getDACategory = (
    entitlement: EntitlementContextType['entitlement'],
    suffix: string,
  ) => {
    return AnalyticsUtility.formatCategory(entitlement, suffix);
  };

  static isSequenceEligible = (): boolean => {
    const assesssmentCookie = Storage.getCookieValue(
      'ww_assessment',
      true,
    ) as AssessmentCookie | null;

    if (!!assesssmentCookie && typeof assesssmentCookie === 'object') {
      return !!assesssmentCookie[
        'wwQuizResults_/us/personalassessment/clinical-vip'
      ]?.eligibleSequence;
    }

    return false;
  };

  static isSequenceAssessed = (): boolean => {
    const assesssmentCookie = Storage.getCookieValue(
      'ww_assessment',
      true,
    ) as AssessmentCookie | null;

    return !!assesssmentCookie;
  };

  static getAssessmentId = (): string => {
    const assesssmentCookie = Storage.getCookieValue(
      'ww_assessment',
      true,
    ) as AssessmentCookie | null;

    if (!!assesssmentCookie && typeof assesssmentCookie === 'object') {
      return assesssmentCookie[
        'wwQuizResults_/us/personalassessment/clinical-vip'
      ]?.aid;
    }

    return '';
  };

  // Retrieve any redirect related to sequence pricing pages
  static getSequenceRedirectUrl = (
    offerBundle: OfferBundle | null,
    translations: ConfigTranslationsLocale,
  ): string => {
    if (!wwUtility.isBrowser() || !window) {
      return '';
    }

    if (!offerBundle) {
      return '';
    }

    // Use paths from Offer Bundle (or lastly the translation fallback)
    if (!ProductsUtility.isSequenceAssessed()) {
      return (
        ProductsUtility.getContentByKey(
          offerBundle.contents,
          'CLINICAL_UNASSESSED_URL',
        ) || translations.PRODUCTS_CLINICAL_UNASSESSED_URL
      );
    }
    if (!ProductsUtility.isSequenceEligible()) {
      return (
        ProductsUtility.getContentByKey(
          offerBundle.contents,
          'CLINICAL_INELIGIBLE_URL',
        ) || translations.PRODUCTS_CLINICAL_INELIGIBLE_URL
      );
    }

    return '';
  };
}
