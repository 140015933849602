import React from 'react';

export type EntitlementContextType = {
  entitlement: 'Guest' | 'Member' | 'Registered';
  entitlementBasePath: '/r/cms' | '/m/cms' | '';
};

export const defaultEntitlementContext: EntitlementContextType = {
  entitlement: 'Guest',
  entitlementBasePath: '',
};

export const EntitlementContext = React.createContext<EntitlementContextType>(
  defaultEntitlementContext,
);
