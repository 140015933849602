import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { useLocation } from 'react-router';

import type { FoodType } from '../../Utility/FoodUtility';
import type { MarketContextType } from '../../../context/market.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';

import { MarketContext } from '../../../context/market.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { EntitlementUtility } from '../../Utility/EntitlementUtility.ts';
import { FoodUtility } from '../../Utility/FoodUtility.ts';
import { Redirect } from '../Redirect/Redirect.tsx';

import UserAuthQuery from './graphql/UserAuthQuery.graphql';

interface EntitlementRedirectProps {
  food: FoodType;
}

export const EntitlementRedirectVisible = ({
  food,
}: EntitlementRedirectProps): JSX.Element | null => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const { loading, error, data } = useQuery(UserAuthQuery, { ssr: false });

  if (loading || error || !data || !data.userAuth) {
    return null;
  }

  // If the user is registered, redirect to /r/cms version of page
  if (data.userAuth.userType === 'registered') {
    return (
      <Redirect
        entitlement={EntitlementUtility.entitlements.registered}
        to={FoodUtility.getURL(food, country, language)}
      />
    );
  }

  // If the user is a member, redirect to cmx version of page
  if (data.userAuth.userType === 'member') {
    window.location.href = FoodUtility.getMemberURL(food, country, language);
  }

  return null;
};

export const EntitlementRedirect = ({
  food,
}: EntitlementRedirectProps): JSX.Element | null => {
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const entitlementRedirect = params.get('redirect');

  // If ?redirect=true is a query param and user is a guest.
  const isVisible =
    entitlementRedirect === 'true' &&
    entitlement === EntitlementUtility.entitlements.guest;

  return isVisible ? <EntitlementRedirectVisible food={food} /> : null;
};
