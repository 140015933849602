import React from 'react';
import _ from 'lodash';

import type { MastheadGridSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/MastheadGridSlice/MastheadGridSlice';

import { MastheadGridSlice } from '@ww-digital/web-palette-react/dist/components/Slice/MastheadGridSlice/MastheadGridSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface MastheadGridSliceContainerProps {
  daCategory: string;
  slice: MastheadGridSliceProps;
}

export const MastheadGridSliceContainer = ({
  daCategory,
  slice,
}: MastheadGridSliceContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);

  sliceView.cta &&
    (sliceView.cta.buttonAttributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        'masthead_grid_cta',
        sliceView.cta.text,
      ),
      'da-label': AnalyticsUtility.formatLabel(sliceView.cta.text),
    });

  sliceView.mastheadCards?.forEach((mastheadCard, i) => {
    mastheadCard.cardActionAttributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        `masthead_grid_card_position${i + 1}`,
        'mastheadCard',
      ),
      'da-label': AnalyticsUtility.formatLabel(mastheadCard.image.alt),
    };
  });

  return <MastheadGridSlice {...sliceView} />;
};
