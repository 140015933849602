import React, { ReactNode } from 'react';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import type { AppProps } from '../components/App';

interface RouterProps {
  children: ReactNode;
  ssr?: AppProps['ssr'];
  url?: AppProps['url'];
}

export let context = {};

export const Router = ({
  children,
  ssr = false,
  url,
}: RouterProps): JSX.Element => {
  if (!ssr) {
    return <BrowserRouter>{children}</BrowserRouter>;
  }

  // Clear out any context info from previous requests.
  context = {};

  const staticRouterLocation = {
    pathname: url?.pathname,
    search: url?.search,
    hash: url?.hash,
  };

  return (
    <StaticRouter location={staticRouterLocation} context={context}>
      {children}
    </StaticRouter>
  );
};
