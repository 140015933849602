import wwUtility from '../../ww.utility.ts';
import { subnavUpdates } from './GREX-278-subnav-updates.tsx';
import { navUpdates } from './GREX-278-remove-joinNow-cta.tsx';
import { hidePricingNavItem } from './GREX-278-pricing-nav-updates.tsx';
import { FireExposure_GREX278 } from './GREX-278-fireExposure.tsx';

export const ExecuteFunctions_GREX278_CLINIC_PDP = (
  variant: string,
  variables: any,
) => {
  if (wwUtility.isBrowser() && localStorage.getItem('hpVisit') === 'true') {
    // redirect
    if (window.location.pathname === variables.clinic_pdp) {
      if (variant === 'control' || variant === 'v3') {
        FireExposure_GREX278(variables.testId);
      } else if (variant === 'v1' || variant === 'v2') {
        window.location.pathname = variables.clinic_pricing_pdp;
      }
    } else if (window.location.pathname === variables.clinic_pricing_pdp) {
      // remove pricing nav item
      if (variant === 'v1') {
        hidePricingNavItem();
        navUpdates();
      }

      if (variant === 'v1' || variant === 'v2') {
        FireExposure_GREX278(variables.testId);
      }
    }
  } else if (wwUtility.isBrowser()) {
    subnavUpdates(variables.workshops_text);
  }
};
