import _ from 'lodash';

import type { EntitlementContextType } from '../../context/entitlement.context';

type EntitlementPaths = '' | 'r/cms' | 'm/cms';
interface EntitlementsMapping {
  guest: EntitlementContextType['entitlement'];
  registered: EntitlementContextType['entitlement'];
  member: EntitlementContextType['entitlement'];
}
type EntitlementsInfo = Partial<
  Record<
    EntitlementContextType['entitlement'],
    EntitlementContextType | undefined
  >
>;

export class EntitlementUtility {
  static entitlements: EntitlementsMapping = {
    guest: 'Guest',
    registered: 'Registered',
    member: 'Member',
  };

  static pathToEntitlementMap: Record<
    EntitlementPaths,
    EntitlementContextType['entitlement']
  > = {
    '': EntitlementUtility.entitlements.guest,
    'r/cms': EntitlementUtility.entitlements.registered,
    'm/cms': EntitlementUtility.entitlements.member,
  };

  static entitlementToPathMap = _.invert(
    EntitlementUtility.pathToEntitlementMap,
  );

  static getEntitlementFromPath(
    path: EntitlementPaths,
  ): EntitlementContextType['entitlement'] {
    return (
      EntitlementUtility.pathToEntitlementMap[path] ||
      EntitlementUtility.entitlements.guest
    );
  }

  static getEntitlementBasePath(
    entitlement: EntitlementContextType['entitlement'],
  ): string {
    const entitlementPath =
      EntitlementUtility.entitlementToPathMap[entitlement] ||
      EntitlementUtility.entitlementToPathMap[
        EntitlementUtility.entitlements.guest
      ];

    // Add a leading / if it is not empty.
    return entitlementPath === '' ? entitlementPath : `/${entitlementPath}`;
  }

  // This is needed to prevent re-renders caused by reconstructing this object on every render.
  static entitlementsInfo: EntitlementsInfo = {};

  static getEntitlementInfo(entitlementPath: EntitlementPaths) {
    const entitlement =
      EntitlementUtility.getEntitlementFromPath(entitlementPath);

    if (!EntitlementUtility.entitlementsInfo[entitlement]) {
      // Add the leading slash to the entitlement base path if the path is not empty.
      const entitlementBasePath: EntitlementContextType['entitlementBasePath'] =
        entitlementPath === '' ? entitlementPath : `/${entitlementPath}`;

      EntitlementUtility.entitlementsInfo[entitlement] = {
        entitlement,
        entitlementBasePath,
      };
    }

    return EntitlementUtility.entitlementsInfo[entitlement];
  }
}
