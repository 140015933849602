import { useContext } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import _ from 'lodash';

import type { MarketContextType } from '../context/market.context';

import { PricingUtility } from '../components/Utility/PricingUtility.ts';
import { MarketContext } from '../context/market.context.ts';

import UpdateUserPostalCodeMutation from './graphql/UpdateUserPostalCodeMutation.graphql';
import PostalCodeAndLocationQuery from './graphql/PostalCodeAndLocationQuery.graphql';

interface PostalCodeMutation {
  variables: {
    postalCode: string;
  };
}

export interface PostalCodeAndLocationType {
  loading: boolean;
  postalCode: string;
  locationId: string;
  locationName: string;
  updateUserPostalCode: (
    options: PostalCodeMutation,
  ) => Promise<Record<string, any>>;
}

export const usePostalCodeAndLocation = (): PostalCodeAndLocationType => {
  const { country } = useContext<MarketContextType>(MarketContext);
  const { loading, data } = useQuery(PostalCodeAndLocationQuery, {
    ssr: false,
  });
  const [updateUserPostalCode] = useMutation(UpdateUserPostalCodeMutation);

  const locationId = _.get(data, 'pricingLocation.locationId', null);
  const locationName = _.get(data, 'pricingLocation.locationName', null);
  const userPostalCode = _.get(data, 'userPostalCode.postalCode', null);
  const userLocationZipCode = _.get(data, 'userLocation.zipCode', null);
  let postalCode = userPostalCode || userLocationZipCode;
  postalCode =
    postalCode && postalCode !== '0'
      ? postalCode
      : PricingUtility.fallbackZip(country);

  return {
    loading,
    postalCode,
    locationId,
    locationName,
    updateUserPostalCode,
  };
};
