import { QueryStringUtility } from '../QueryStringUtility.ts';
import type { NormalizedExperiments } from '@ww-digital/xs-sdk';
import wwUtility from '../../../ww.utility.ts';

function getCookie(key: string, cookies: string) {
  const name = `${key}=`;

  const decodedCookie = decodeURIComponent(cookies);
  const splitCookies = decodedCookie.split(';');
  let cookieValue = null;

  splitCookies.forEach((item) => {
    const cookie = item.trim();

    if (cookie.indexOf(name) === 0) {
      const strValue = cookie.substring(name.length, cookie.length);
      cookieValue = strValue;
    }
  });

  return cookieValue;
}

export const getExperimentsPreviewHeader = (
  experimentsHeader: string | undefined,
  normalizedExperiments: NormalizedExperiments,
  urlSearchParams: string,
  cookies: undefined | string,
): string => {
  let experimentsHeaderOverride = '';
  const queryString = QueryStringUtility.getQueryParams(urlSearchParams);
  const date = new Date();
  date.setDate(date.getDate() + 2);

  if (cookies?.includes('xsPreviewCookie')) {
    const currentXSPreviewCookie = getCookie('xsPreviewCookie', cookies);

    if (typeof currentXSPreviewCookie === 'string') {
      experimentsHeaderOverride = currentXSPreviewCookie;
    }
  }

  if (queryString.exptextid && queryString.varid) {
    const expTextid = queryString.exptextid;
    const varid = queryString.varid;

    if (!normalizedExperiments[expTextid]) {
      return experimentsHeaderOverride;
    }

    const expId = normalizedExperiments[expTextid].experimentId;
    experimentsHeaderOverride = `${expId}:${varid}`;

    if (queryString.override && queryString.override === 'false') {
      experimentsHeaderOverride += experimentsHeader
        ? `,${experimentsHeader}`
        : experimentsHeader;
    }

    if (wwUtility.isBrowser()) {
      document.cookie = `xsPreviewCookie=${experimentsHeaderOverride};path=/;expires=${date};`;
    }
  }

  if (queryString.xspreviewtests) {
    experimentsHeaderOverride = queryString.xspreviewtests;

    if (wwUtility.isBrowser()) {
      document.cookie = `xsPreviewCookie=${experimentsHeaderOverride};path=/;expires=${date};`;
    }
  }

  if (queryString.clearxspreview) {
    if (wwUtility.isBrowser()) {
      document.cookie =
        'xsPreviewCookie=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    experimentsHeaderOverride = '';
  }

  return experimentsHeaderOverride;
};
