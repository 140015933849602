import wwUtility from '../../ww.utility.ts';

export const ExecuteFunctions_GREX235 = (variant: string, variables: any) => {
  if (wwUtility.isBrowser()) {
    // PlansDurationSlice ID
    const slice_one = document.getElementById(variables?.elementId);

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    if (variant === 'v1') {
      if (variables?.pathname.indexOf(window.location.pathname) !== -1) {
        if (
          slice_one !== null &&
          slice_one !== undefined &&
          slice_one.getAttribute('class') !== variables?.limiterClass
        ) {
          // CSS Injection Settings
          const cssSetting = variables?.constructedCSS,
            headElement =
              document.head || document.getElementsByTagName('head')[0],
            styleElement = document.createElement('style'),
            linkElement = document.createElement('link');

          // Callback function to execute when mutations are observed
          const callback_one = (mutationList: any, observer_one: any) => {
            for (const mutation of mutationList) {
              if (mutation.type === 'attributes') {
                // Check if slice to be modified exists and hasn't already been modified...
                if (
                  slice_one !== null &&
                  slice_one !== undefined &&
                  slice_one.getAttribute('class') !== variables?.limiterClass &&
                  variables?.pathname.indexOf(window.location.pathname) !== -1
                ) {
                  // Add CSS Injected tag into head of document
                  headElement.appendChild(styleElement);
                  styleElement.id = variables?.constructedCSSIdHashless;
                  styleElement.appendChild(document.createTextNode(cssSetting));

                  // Add New CSS Stylesheet
                  headElement.appendChild(linkElement);
                  linkElement.href = variables?.styleInjection;
                  linkElement.id = variables?.injectedCSSIdHashless;
                  linkElement.as = 'style';
                  linkElement.rel = 'preload';
                  linkElement.onload = function () {
                    if (
                      document.querySelectorAll(variables?.querySelectorOne)
                        .length !== 0
                    ) {
                      document
                        .querySelectorAll(variables?.querySelectorOne)[0]
                        .remove();
                    }

                    if (
                      document.querySelectorAll(variables?.querySelectorTwo)
                        .length !== 0
                    ) {
                      document
                        .querySelectorAll(variables?.querySelectorTwo)[0]
                        .remove();
                    }
                    linkElement.rel = 'stylesheet';
                  };

                  // Add Unicorn checkout cookie
                  document.cookie = variables?.cookieCheckOne;

                  // Enact the Injected CSS Styles. Styles will not appear until this is injected as a safety mechanism.
                  slice_one.classList.add(variables?.limiterClass);
                } else if (
                  slice_one !== null &&
                  slice_one !== undefined &&
                  slice_one.getAttribute('class') === variables?.limiterClass &&
                  variables?.pathname.indexOf(window.location.pathname) === -1
                ) {
                  // Add old Main.css back into the header AND remove Refresh Main.css file...
                  if (
                    document.querySelectorAll(variables?.injectedCSSId)
                      .length !== 0
                  ) {
                    const linkElementReset = document.createElement('link');
                    headElement.appendChild(linkElementReset);
                    linkElementReset.href = variables?.originalCSSLink;
                    linkElementReset.as = 'style';
                    linkElementReset.rel = 'preload';
                    linkElementReset.onload = function () {
                      if (
                        document.querySelectorAll(variables?.injectedCSSId)
                          .length !== 0
                      ) {
                        document
                          .querySelectorAll(variables?.injectedCSSId)[0]
                          .remove();
                      }

                      if (
                        document.querySelectorAll(variables?.constructedCSSId)
                          .length !== 0
                      ) {
                        document
                          .querySelectorAll(variables?.constructedCSSId)[0]
                          .remove();
                      }

                      linkElementReset.rel = 'stylesheet';
                    };
                  }

                  if (
                    slice_one !== null &&
                    slice_one !== undefined &&
                    slice_one.className.includes(variables?.limiterClass)
                  ) {
                    slice_one.classList.remove(variables?.limiterClass);

                    document.cookie = variables?.cookieCheckTwo;

                    observer_one.disconnect();
                  }
                }
              }
            }
          };

          // Check for both Plans Duration Slice AND slice beneath Plans Duration Slice. Check for second slice to start mutationObserver as to not get stuck in an infinite loop...
          if (
            slice_one !== null &&
            slice_one !== undefined &&
            slice_one.getAttribute('class') !== variables?.limiterClass
          ) {
            // Create an observer instance linked to the callback function
            const observer_one = new MutationObserver(callback_one);

            // Start observing the target node for configured mutations
            observer_one.observe(slice_one, config);
          }
        }
      }
    }
  }
};
