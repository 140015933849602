import React from 'react';
import _ from 'lodash';

import type { ColorBlockMastheadSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/ColorBlockMastheadSlice/ColorBlockMastheadSlice';

import { ColorBlockMastheadSlice } from '@ww-digital/web-palette-react/dist/components/Slice/ColorBlockMastheadSlice/ColorBlockMastheadSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface ColorBlockMastheadSliceContainerProps {
  daCategory: string;
  slice: ColorBlockMastheadSliceProps;
}

export const ColorBlockMastheadSliceContainer = ({
  daCategory,
  slice,
}: ColorBlockMastheadSliceContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);

  const daAction = AnalyticsUtility.formatAction(
    'color_block_masthead_cta',
    sliceView?.cta?.text,
  );
  const daLabel = AnalyticsUtility.formatLabel(sliceView?.headline?.text);

  if (sliceView?.cta) {
    sliceView.cta.attributes = {};
    sliceView.cta.attributes['da-category'] = daCategory;
    sliceView.cta.attributes['da-action'] = daAction;
    sliceView.cta.attributes['da-label'] = daLabel;
  }

  if (sliceView?.ctaTwo) {
    const daActionTwo = AnalyticsUtility.formatAction(
      'color_block_masthead_second_cta',
      slice?.ctaTwo?.text,
    );

    sliceView.ctaTwo.attributes = {};
    sliceView.ctaTwo.attributes['da-category'] = daCategory;
    sliceView.ctaTwo.attributes['da-action'] = daActionTwo;
    sliceView.ctaTwo.attributes['da-label'] = daLabel;
  } else {
    sliceView.ctaTwo = {};
  }

  return <ColorBlockMastheadSlice {...sliceView} />;
};
