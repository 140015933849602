import { useContext } from 'react';
import XS from '@ww-digital/xs-sdk';

import { MarketUtility } from '../../components/Utility/MarketUtility.ts';
import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import wwUtility from '../../ww.utility.ts';
import GUA from '@ww-digital/xs-plugin-gua';
import type { MarketContextType } from '../../context/market.context.ts';
import { MarketContext } from '../../context/market.context.ts';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

export const ScrollExposure = (variables: any) => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const visitorId = Storage.getCookieValue('ww_browser_id', false);
  const gua = new GUA();
  const { pathname } = useLocation();

  // Select the node that will be observed for mutations
  /* Masthead Slice update */
  const slice_one = document.getElementById(variables?.elementId);

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true };

  let observer_one: MutationObserver;

  let scrollDepthEventFound = false,
    disableExposure = false;

  function RunExposure() {
    if (scrollDepthEventFound) {
      disableExposure = true;

      if (pathname === variables?.pathname) {
        (async () => {
          const xs = new XS({
            user: {
              visitorId: typeof visitorId === 'string' ? visitorId : '',
              locale: MarketUtility.getXSLocale(country, language),
            },
            baseUrl: wwUtility.getAPIDomain(),
            plugins: [gua],
          });

          await xs.fetchUserExperiments();

          // Run Exposure for Test
          await xs.exposeExperiment(variables?.testId);
        })();
      } else {
        // Remove the Scroll event if on incorrect page at time of firing
        observer_one.disconnect();
      }
    } else if (disableExposure) {
      // Remove the Scroll event once the point on the page is hit
      observer_one.disconnect();
    }
  }

  const recheckGAEvents = () => {
    scrollDepthEventFound = !!_.findKey(
      window.dataLayer,
      function (dataLayerItem) {
        return dataLayerItem['gtm.scrollThreshold'] === 50;
      },
    );

    if (window.dataLayer && scrollDepthEventFound) {
      RunExposure();
    } else {
      if (pathname === variables?.pathname) {
        setTimeout(function () {
          recheckGAEvents();
        }, 100);
      } else {
        return;
      }
    }
  };

  // Callback function to execute when mutations are observed
  const callback_one = (mutationList: any) => {
    for (const mutation of mutationList) {
      if (
        mutation.type === 'attributes' &&
        slice_one !== null &&
        !slice_one.getAttribute('class')?.includes('grex-223')
      ) {
        // Prevent duplicate Event Listeners from being added to element by running a conditional check against the existence of this ID
        slice_one.classList.add('grex-223');

        recheckGAEvents();
      }
    }
  };

  if (slice_one !== null) {
    // Create an observer instance linked to the callback function
    observer_one = new MutationObserver(callback_one);
    // Start observing the target node for configured mutations
    observer_one.observe(slice_one, config);
  }
  return;
};

export const ExecuteFunctions_GREX223 = (
  pathname: string,
  variant: string,
  variables?: any,
) => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const visitorId = Storage.getCookieValue('ww_browser_id', false);
  const gua = new GUA();

  if (variant === 'control') {
    if (pathname === variables?.pathname) {
      (async () => {
        const xs = new XS({
          user: {
            visitorId: typeof visitorId === 'string' ? visitorId : '',
            locale: MarketUtility.getXSLocale(country, language),
          },
          baseUrl: wwUtility.getAPIDomain(),
          plugins: [gua],
        });

        await xs.fetchUserExperiments();

        // Run Exposure for Test
        await xs.exposeExperiment(variables?.testId);
      })();
    }
  }

  if (variant === 'v1') {
    if (pathname === variables?.pathname) {
      ScrollExposure(variables);
    }
  }
};
