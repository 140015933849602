import React, { useEffect, useRef, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Browser from '@ww-digital/web-palette-react/dist/components/Utility/Browser';

interface ScrollRestorationProps {
  children: ReactNode;
}

interface JumpLink {
  id: string;
  method: 'scroll' | 'hash' | '';
  element?: HTMLElement | null;
}

export const ScrollRestoration = ({
  children,
}: ScrollRestorationProps): JSX.Element => {
  const location = useLocation();
  const history = useHistory();

  const { hash } = location;

  const timerHandle = useRef<null | number | ReturnType<typeof setTimeout>>(
    null,
  );

  useEffect(() => {
    const { action } = history;

    // Clear any setTimeout Running
    clearTimer();

    // If navigating back and the path exists in history,
    // scroll window to where we left off.
    // Otherwise if visiting a new page, scroll to the top.
    if (Browser.isBrowser()) {
      if (action === 'POP') {
        handleJumpLink(hash);
      } else {
        if (hash) {
          handleJumpLink(hash);
        } else {
          window.scrollTo(0, 0);
        }
      }
    }

    // Clear any setTimeout Running
    return () => {
      clearTimer();
    };
  });

  async function setTimer() {
    if (typeof timerHandle === 'number') {
      return;
    }

    timerHandle.current = setTimeout(() => {
      timerHandle.current = 0;
    }, 5000);
  }

  async function clearTimer() {
    // Check if Timer is Running
    if (typeof timerHandle === 'number') {
      // Clear Timer
      clearTimeout(timerHandle);
    }
  }

  async function handleJumpLink(hash: string, duration = 2500) {
    // Get jumplink/ID from URL
    if (!hash) {
      window.scrollTo(0, 0);
      return;
    }

    const jumpLink: JumpLink = { id: '', method: '' };

    if (hash.indexOf('/scrollto/') === 1) {
      jumpLink.method = 'scroll';
      jumpLink.id = hash.split('/scrollto/')[1];
    } else {
      jumpLink.method = 'hash';
      jumpLink.id = hash.split('#')[1];
    }

    // Wait for Element to Appear
    await new Promise((resolve, reject) => {
      setTimer();

      const getElement = () => {
        jumpLink.element = document.getElementById(jumpLink.id);
        if (jumpLink.element) {
          clearTimer();
          resolve(jumpLink.element);
        } else {
          if (timerHandle) {
            window.requestAnimationFrame(getElement);
          } else {
            reject();
          }
        }
      };
      getElement();
    });

    if (jumpLink.element) {
      // Implement logic for either hash or scrollTo element
      const stickyClass = '[class*="NavigationSlice_sticky"]';
      const stickyNav = document.querySelector(stickyClass) as HTMLElement;
      const hashOffset = jumpLink.element.getAttribute('data-hash-offset');

      if (jumpLink.method === 'hash' && stickyNav) {
        jumpLink.element.setAttribute('data-hash-offset', '70');
        jumpLink.element.style.paddingTop = `${stickyNav.offsetHeight}px`;
        jumpLink.element.style.marginTop = `-${stickyNav.offsetHeight}px`;

        // Handle Scroll to Element
        const elementY = jumpLink.element.getBoundingClientRect().y;
        const startingY = window.pageYOffset;
        window.scrollTo(0, startingY + elementY);
      } else if (jumpLink.method === 'scroll') {
        // Delay slightly to let page format (esp. CardGrid).
        await new Promise((resolve) => setTimeout(resolve, 100));

        let elementY = jumpLink.element.getBoundingClientRect().y;
        elementY =
          hashOffset || !stickyNav
            ? elementY
            : elementY - stickyNav.offsetHeight;
        const startingY = window.pageYOffset;
        const easing = (t: number) => {
          return t < 0.5
            ? 4 * t * t * t
            : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        };

        let start: number;

        window.requestAnimationFrame(function step(timestamp) {
          if (!start) start = timestamp;
          const time = timestamp - start;
          let percent = Math.min(time / duration, 1);
          percent = easing(percent);
          window.scrollTo(0, startingY + elementY * percent);

          if (time < duration) {
            window.requestAnimationFrame(step);
          }
        });
      }
    }
  }

  return <>{children}</>;
};
