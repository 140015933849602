import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { QueryStringContext } from '../../context/querystring.context.ts';
import { QueryStringUtility } from '../Utility/QueryStringUtility.ts';

interface QueryStringProviderProps {
  children: ReactNode;
}

export const QueryStringProvider = ({
  children,
}: QueryStringProviderProps): JSX.Element | null => {
  const location = useLocation();

  return (
    <QueryStringContext.Provider
      value={QueryStringUtility.getQueryParams(location.search)}
    >
      {children}
    </QueryStringContext.Provider>
  );
};
