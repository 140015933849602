import { WeightHealthHomepageProps } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/WeightHealthHomepage';

export const sectionFour: WeightHealthHomepageProps['sectionFour'] = {
  heading: {
    ariaLevel: 2,
    typog: 'headline3',
    text: 'What WeightWatchers is all about',
  },
  subheading: {
    ariaLevel: 3,
    typog: 'subhead2',
    text: 'The #1 doctor-recommended weight-loss program†',
  },
  button: {
    url: 'https://www.weightwatchers.com/us/personalassessment/default',
    children: 'Take our quiz',
    size: 'large',
    type: 'primary',
  },
  images: [
    {
      image: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_4_1_d.jpg',
          alt: '',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_4_1_m.jpg',
          alt: '',
        },
      },
      caption:
        '[{"type":"p","children":[{"text":"Our trusted "},{"type":"a","attributes":{"href":"https://www.weightwatchers.com/us/how-it-works/points-program","da-category":"visitor:page:body:home","da-action":"card_link_points-program","da-label":"points program"},"children":[{"text":"Points Program"}]},{"text":" now offers more science-backed ways and "},{"type":"a","attributes":{"href":"https://www.weightwatchers.com/us/clinic","da-category":"visitor:page:body:home","da-action":"card_link_affordable-medication-access","da-label":"affordable medication access"},"children":[{"text":"affordable medication access"}]},{"text":" to help you lose weight for better health."}]}]',
    },
    {
      image: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_4_2_d.jpg',
          alt: '',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_4_2_m.jpg',
          alt: '',
        },
      },
      caption:
        '[{"type":"p","children":[{"text":"Simplify shopping, cooking, and eating healthy with practical tools in "},{"type":"a","attributes":{"href":"https://www.weightwatchers.com/us/how-it-works/ww-app","da-category":"visitor:page:body:home","da-action":"card_link_our-award-winning-app","da-label":"our award winning app"},"children":[{"text":"our award-winning app"}]},{"text":"–now upgraded with "},{"type":"a","attributes":{"href":"https://www.weightwatchers.com/us/how-it-works/whats-new","da-category":"visitor:page:body:home","da-action":"card_link_new-smarter-features","da-label":"new smarter features"},"children":[{"text":"new, smarter features"}]},{"text":"."}]}]',
    },
    {
      image: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_4_3_d.jpg',
          alt: '',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_4_3_m.jpg',
          alt: '',
        },
      },
      caption:
        '[{"type":"p","children":[{"text":"Get expert guidance from "},{"type":"a","attributes":{"href":"https://www.weightwatchers.com/us/clinic","da-category":"visitor:page:body:home","da-action":"card_link_doctors","da-label":"doctors"},"children":[{"text":"doctors"}]},{"text":", "},{"type":"a","attributes":{"href":"https://www.weightwatchers.com/us/registered-dietitians","da-category":"visitor:page:body:home","da-action":"card_link_registered-dietitians","da-label":"registered dietitians"},"children":[{"text":"Registered Dietitians"}]},{"text":", and "},{"type":"a","attributes":{"href":"https://www.weightwatchers.com/us/how-it-works/points-program/workshops","da-category":"visitor:page:body:home","da-action":"card_link_coaches-trained","da-label":"coaches trained"},"children":[{"text":"coaches trained"}]},{"text":" in weight-loss techniques."}]}]',
    },
  ],
  footerText:
    '†Based on a 2020 IQVIA survey of 14,000 doctors who recommend weight-loss programs to patients.',
};

export const sectionFive: WeightHealthHomepageProps['sectionFive'] = {
  heading: {
    ariaLevel: 2,
    typog: 'headline3',
    text: 'How our weight-loss program works',
  },
  footerText: '',
  sections: [
    {
      image: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_5_1_d.jpg',
          alt: '',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_5_1_m.jpg',
          alt: '',
        },
      },
      number: '1',
      text: 'Take our 5-minute quiz to find the right path for you, then sign up for your membership.',
    },
    {
      image: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_5_2_d.jpg',
          alt: '',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_5_2_m.jpg',
          alt: '',
        },
      },
      number: '2',
      text: 'Download the WeightWatchers app and answer some quick questions. You\'ll immediately get a nutrition plan and Points to "spend" each day.',
    },
    {
      image: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_5_3_d.jpg',
          alt: '',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_5_3_m.jpg',
          alt: '',
        },
      },
      number: '3',
      text: 'Start making healthier choices: our weight-loss program guides you to eat more nutritious foods and helps you find portion sizes that are right for you.',
    },
    {
      image: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_5_4_d.jpg',
          alt: '',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/nhp/nhp_5_4_m.jpg',
          alt: '',
        },
      },
      number: '4',
      text: 'Get the most out of what your plan offers. Meet members in the app or at Workshops. Book a 1:1 with your WeightWatchers clinician or Registered Dietitian.',
    },
  ],
  button: {
    url: 'https://www.weightwatchers.com/us/personalassessment/default',
    children: 'Take our quiz',
    size: 'large',
    type: 'primary',
  },
};

export const sectionSix: WeightHealthHomepageProps['sectionSix'] = {
  heading: {
    ariaLevel: 2,
    typog: 'headline3',
    text: 'Join millions of members on their weight-loss journeys',
  },
  slides: [
    {
      image: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whh-6-3_1280_x1114.jpg',
          alt: 'WeightWatchers member Rachel smiling and looking at the viewer.',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whhm-6-3_658x474.jpg',
          alt: 'WeightWatchers member Rachel smiling and looking at the viewer.',
        },
      },
      name: 'WW Member Rachel K.',
      tagline: 'lost 115 lbs^',
      quote:
        'My labs are a testament that weight loss improved my health: I’ve gone off of blood pressure, cholesterol, and thyroid meds.',
      signature: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whh-6-3-s.png',
          alt: 'Rachel',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whh-6-3-s.png',
          alt: 'Rachel',
        },
      },
    },
    {
      image: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whh-6-4_1280_x1114.jpg',
          alt: 'WeightWatchers member August in a hoody, holding a volleyball and smiling at the viewer.',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whhm-6-4_658x474.jpg',
          alt: 'WeightWatchers member August in a hoody, holding a volleyball and smiling at the viewer.',
        },
      },
      name: 'WW Member August M.',
      tagline: 'lost 164 lbs^',
      quote:
        'I’m more comfortable in my own skin. I was always doubting where I went—I don’t have that fear anymore.',
      signature: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whh-6-2-s.png',
          alt: 'August',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whh-6-2-s.png',
          alt: 'August',
        },
      },
    },
    {
      image: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whh-6-1_1280_x1114.jpg',
          alt: 'Dana',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whhm-6-1_658x474.jpg',
          alt: 'Dana',
        },
      },
      name: 'WW Member Dana R.',
      tagline: 'lost 37 lbs^',
      quote:
        'I lost weight and I’ve lowered my blood glucose, and created a lifestyle that will support a long-term, normal A1C.',
      signature: {
        desktop: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whh-6-1-s.png',
          alt: 'WeightWatchers member Dana laughing and looking at the viewer.',
        },
        mobile: {
          src: 'https://v.cdn.ww.com/media/system/cms/us/whh-6-1-s.png',
          alt: 'WeightWatchers member Dana laughing and looking at the viewer.',
        },
      },
    },
  ],
  footerText:
    '^At 6 months, participants in a clinical trial of the WW program lost an average of 9.7 lbs (5% of body weight).',
};

export const weightHealthHomepageData: WeightHealthHomepageProps = {
  sectionFour,
  sectionFive,
  sectionSix,
};
