import React, { useContext, useRef } from 'react';
import _ from 'lodash';

import type { SmallProductSquareSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/SmallProductSquareSlice/SmallProductSquareSlice';
import type { SmallProductSquareProps } from '@ww-digital/web-palette-react/dist/components/Square/SmallProductSquare/SmallProductSquare';
import type { MarketContextType } from '../../../context/market.context';

import { SmallProductSquareSlice } from '@ww-digital/web-palette-react/dist/components/Slice/SmallProductSquareSlice/SmallProductSquareSlice';
import { MarketContext } from '../../../context/market.context.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { PricingUtility } from '../../Utility/PricingUtility.ts';

interface SmallProductSquareSlicePropsExtended
  extends SmallProductSquareSliceProps {
  productSquareList?: SmallProductSquarePropsExtended[];
}
interface SmallProductSquareSliceContainerProps {
  daCategory: string;
  slice: SmallProductSquareSlicePropsExtended;
}

interface SmallProductSquarePropsExtended extends SmallProductSquareProps {
  overridePricingUrl?: boolean;
  pricingText?: string;
}

const ProductSquareRecurly = (
  productSquareInit: SmallProductSquarePropsExtended,
  productSquareIndex: number,
  productSquareListPricing: React.MutableRefObject<
    SmallProductSquarePropsExtended[]
  >,
): void => {
  const marketContext = useContext<MarketContextType>(MarketContext);
  const productSquare = _.cloneDeep(productSquareInit);

  // If Pricing Enabled, use standard recurly checkout URL.
  if (productSquare.cta && productSquare.pricing) {
    productSquare.cta.url = PricingUtility.getRecurlyPricingUrl(
      marketContext.country,
      marketContext.language,
    );
  }

  // Clean out any price tokens.
  if (
    productSquare.cta &&
    PricingUtility.hasPricingToken(productSquare.cta.text)
  ) {
    productSquare.cta.text = '';
  }
  if (PricingUtility.hasPricingToken(productSquare.subheadingPrice)) {
    productSquare.subheadingPrice = '';
  }

  productSquareListPricing.current[productSquareIndex] = productSquare;
};

export const SmallProductSquareSliceContainer = ({
  daCategory,
  slice,
}: SmallProductSquareSliceContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);
  const productSquareListPricing = useRef<SmallProductSquarePropsExtended[]>(
    sliceView.productSquareList || [],
  );

  if (sliceView.productSquareList) {
    sliceView.productSquareList.forEach((productSquare, productIndex) => {
      ProductSquareRecurly(
        productSquare,
        productIndex,
        productSquareListPricing,
      );
    });

    sliceView.productSquareList = productSquareListPricing.current.map(
      (productSquare) => {
        // Analytics tracking for CTA button.
        if (productSquare.cta) {
          productSquare.cta.attributes = {};
          productSquare.cta.attributes['da-category'] = daCategory;
          productSquare.cta.attributes['da-action'] =
            AnalyticsUtility.formatAction(
              'small_product_squares_item_cta',
              productSquare.cta.text,
            );
          productSquare.cta.attributes['da-label'] =
            AnalyticsUtility.formatLabel(productSquare.headline);
        }

        // Analytics tracking for second CTA button.
        if (productSquare.ctaTwo) {
          productSquare.ctaTwo.attributes = {};
          productSquare.ctaTwo.attributes['da-category'] = daCategory;
          productSquare.ctaTwo.attributes['da-action'] =
            AnalyticsUtility.formatAction(
              'small_product_squares_item_second_cta',
              productSquare.ctaTwo.text,
            );
          productSquare.ctaTwo.attributes['da-label'] =
            AnalyticsUtility.formatLabel(productSquare.headline);
        }

        // Analytics tracking for Popup CTA button.
        if (productSquare.popupContent?.cta) {
          productSquare.popupContent.cta.attributes = {};
          productSquare.popupContent.cta.attributes['da-category'] = daCategory;
          productSquare.popupContent.cta.attributes['da-action'] =
            AnalyticsUtility.formatAction(
              'small_product_squares_item_popup_cta',
              productSquare.popupContent.cta.text,
            );
          productSquare.popupContent.cta.attributes['da-label'] =
            AnalyticsUtility.formatLabel(productSquare.popupContent.headline);
        }

        return productSquare;
      },
    );
  }

  // Analytics tracking for optional 'slice-level' CTA.
  if (sliceView.cta) {
    sliceView.cta.attributes = {};
    sliceView.cta.attributes['da-category'] = daCategory;
    sliceView.cta.attributes['da-action'] = AnalyticsUtility.formatAction(
      'small_product_square_slice_cta',
      sliceView.cta.text,
    );
    sliceView.cta.attributes['da-label'] = AnalyticsUtility.formatLabel(
      sliceView.cta.text,
    );
  }

  return <SmallProductSquareSlice {...sliceView} />;
};
