import React, { useContext } from 'react';
import { __RouterContext } from 'react-router';
import { Redirect as RRRedirect, useLocation } from 'react-router-dom';

import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { QueryStringContextType } from '../../../context/querystring.context';
import type { ConfigContextType } from '../../../context/config.context';
import type { MarketContextType } from '../../../context/market.context';
import type { HreflangType } from '../../Metadata/Hreflang/Hreflang';

import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { QueryStringContext } from '../../../context/querystring.context.ts';
import { ConfigContext } from '../../../context/config.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import {
  getBreadcrumb,
  getSubmenu,
  getSiblings,
  getBlogRoute,
} from '../../Utility/Breadcrumb.ts';
import { GTM } from '../../GTM/GTM.tsx';
import { Hreflang } from '../../Metadata/Hreflang/Hreflang.tsx';
import { SlicesContainer } from '../../Slices/SlicesContainer/SlicesContainer.tsx';
import { RouteMetadata } from '../../Metadata/RouteMetadata/RouteMetadata.tsx';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

import { PrimaryMenuSubNav } from '@ww-digital/web-palette-react/dist/components/Navigation/PrimaryMenuSubNav/PrimaryMenuSubNav';
import { CategoryMasthead } from '@ww-digital/web-palette-react/dist/components/Masthead/CategoryMasthead/CategoryMasthead';
import { SmallCardProps } from '@ww-digital/web-palette-react/dist/components/Card/SmallCard/SmallCard';

type SmallCardPropsExtended = SmallCardProps & {
  id: string;
};

interface CategoryBreadcrumb {
  name: string;
  slug: string;
}

interface Category {
  id: string;
  name: string;
  description: string;
  breadcrumb: CategoryBreadcrumb[];
  submenu: CategoryBreadcrumb[];
  siblings: CategoryBreadcrumb[];
  hreflangs: HreflangType[];
  sliceData: string;
  gridAutoTitle?: string;
  gridAuto: {
    cardList: SmallCardPropsExtended[];
    totalPages: number;
  };
  meta: {
    og: {
      type: string;
    };
  };
  footerSliceData: string;
}

interface CategoryRouteProps {
  category: Category;
  path: string;
}

export const CategoryRoute = ({
  category,
  path,
}: CategoryRouteProps): JSX.Element => {
  const { pathname, search } = useLocation();
  const { staticContext } = useContext(__RouterContext);
  const { page } = useContext<QueryStringContextType>(QueryStringContext) || {};
  const { translations } = useContext<ConfigContextType>(ConfigContext);
  const entitlementContext =
    useContext<EntitlementContextType>(EntitlementContext);
  const marketContext = useContext<MarketContextType>(MarketContext);
  const activePage = Number.parseInt(page || '', 10) || 0;
  const pathLowerCased = pathname.toLowerCase();
  const blogBasePath = getBlogRoute(marketContext) + '/';

  // Delete blog/ from the begining.
  let pathWithoutBlogBasePath = path;
  if (path.indexOf(blogBasePath) === 0) {
    pathWithoutBlogBasePath = pathWithoutBlogBasePath.substring(
      blogBasePath.length - 1,
    );
  }

  const gridAutoProps = {
    id: 'category-page-card-grid',
    __typename: 'ContentCardGridAutoSlice',
    tids: [category.id],
    excludedNids: [],
    forcedLimit: 24,
    sort: 'created_desc',
    analyticsData: {
      functionName: 'formatCategoryPageCardAnalytics',
      otherParams: {
        slug: pathWithoutBlogBasePath,
        type: AnalyticsUtility.cardTypes.TYPE_CONTENT_CARD_GRID_CATEGORY_PAGE,
      },
    },
    align: 'left',
    button: {
      type: 'primary',
      url: '',
      text: 'CTA',
    },
    heading: {
      align: 'left',
      ariaLevel: 2,
      text: category.gridAutoTitle ? category.gridAutoTitle : '',
      typog: 'headline4',
      variant: 'default',
    },
    subTitle: '',
    cardList: category.gridAuto.cardList,
    totalPages: category.gridAuto.totalPages,
    background: {
      color: 'none',
      mobile: {
        image: {
          image1x: '',
          image2x: '',
          image2xHeight: '',
          padding: 'none',
          position: 'bottom',
        },
      },
      desktop: {
        image: {
          image1x: '',
          image2x: '',
          image2xHeight: '',
          position: {
            x: '',
            y: '',
          },
        },
      },
      alt: '',
      height: 0,
      theme: 'lightBackground',
    },
  };

  if (pathname !== pathLowerCased) {
    if (staticContext) {
      staticContext.statusCode = 301;
    }

    return (
      <RRRedirect
        to={{
          pathname: pathLowerCased,
          search,
        }}
      />
    );
  }

  // Prepare slices.
  const slices = [];

  if (activePage === 0 && category.sliceData) {
    const sliceData = JSON.parse(category.sliceData);
    slices.push(...sliceData);
  }

  if (category?.gridAuto?.cardList?.length && category.gridAuto.totalPages) {
    slices.push(gridAutoProps);
  }

  const { meta } = category;
  if (meta?.og) {
    meta.og.type = 'category';
  }

  if (activePage === 0 && category.footerSliceData) {
    const footerSliceData = JSON.parse(category.footerSliceData);
    slices.push(...footerSliceData);
  }

  return (
    <>
      <GTM key={`gtm-${category.id}`} category="category" />
      <RouteMetadata {...meta} />
      {entitlementContext.entitlement === 'Guest' && (
        <Hreflang hreflangs={category.hreflangs} />
      )}
      <PrimaryMenuSubNav
        primaryMenuSubNavItems={getSiblings(
          category.siblings,
          marketContext,
          entitlementContext,
          pathWithoutBlogBasePath,
        )}
      />
      <CategoryMasthead
        bodyText={category.description}
        breadcrumbs={getBreadcrumb(
          category.breadcrumb,
          translations.ARTICLE_BLOG_BREADCRUMB_TITLE,
          marketContext,
          entitlementContext,
        )}
        headline={category.name}
        navigation={getSubmenu(
          category.submenu,
          marketContext,
          entitlementContext,
        )}
      />
      <SlicesContainer slices={slices} type="category" region="body" />
    </>
  );
};
