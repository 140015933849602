import React from 'react';
import _ from 'lodash';

import type { HomepageCarouselSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/HomepageCarouselSlice/HomepageCarouselSlice';

import { HomepageCarouselSlice } from '@ww-digital/web-palette-react/dist/components/Slice/HomepageCarouselSlice/HomepageCarouselSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface GalleryCarouselSliceContainerProps {
  daCategory: string;
  slice: HomepageCarouselSliceProps;
}

export const GalleryCarouselSliceContainer = ({
  daCategory,
  slice,
}: GalleryCarouselSliceContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);

  sliceView.slides = sliceView?.slides?.map((slide) => {
    const buttonAttributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        'gallery_carousel_cta',
        slide.cta.text,
      ),
      'da-label': AnalyticsUtility.formatLabel(slide.cta.text),
    };

    return {
      ...slide,
      cta: {
        ...slide.cta,
        buttonAttributes,
      },
    };
  });

  return <HomepageCarouselSlice {...sliceView} />;
};
