import wwUtility from '../../ww.utility.ts';

export const subnavUpdates = (workshops_text = 'Get Workshops') => {
  if (wwUtility.isBrowser() && localStorage.getItem('hpVisit') !== 'true') {
    //hp not visited
    //remove the clinic subnav item & change the workshops subnav item copy
    const handlePrimarySubnavUpdates = () => {
      const workshopsSubnavItemPrimary = document.querySelector(
        '[class*="Menu_list__"] [class*="Menu_listItem__"] [da-label="Workshops"][href*="/main-workshops"] span[class*="MenuItem_menuItemInnerWrapper__"] span',
      );

      if (workshopsSubnavItemPrimary as HTMLElement) {
        (workshopsSubnavItemPrimary as HTMLElement).innerText = workshops_text;
      }

      if (window.location.pathname !== '/us/plans/clinic') {
        const weightLossMedsSubnavItemPrimary = document.querySelector(
          '[class*="Menu_list__"] [class*="Menu_listItem__"] [da-label="Weight-Loss Medications"]',
        );
        if ((weightLossMedsSubnavItemPrimary as HTMLElement)?.style) {
          (weightLossMedsSubnavItemPrimary as HTMLElement).style.display =
            'none';
        }

        if (
          (workshopsSubnavItemPrimary as HTMLElement)?.innerText ===
            workshops_text &&
          (weightLossMedsSubnavItemPrimary as HTMLElement)?.style?.display ===
            'none'
        ) {
          primarySubnavObserver.disconnect();
        }
      } else {
        if (
          (workshopsSubnavItemPrimary as HTMLElement)?.innerText ===
          workshops_text
        ) {
          primarySubnavObserver.disconnect();
        }
      }
    };

    const handleHamburgerMenuUpdates = () => {
      const workshopsSubnavItemMobile = document.querySelector(
        '[class*="MobileMenuItem_subMenu__"] [class*="MobileMenuItem_container__"] [da-label="Workshops"][href*="/main-workshops"] span[class*="MobileMenuItem_subMenuText__"] span',
      );
      if (workshopsSubnavItemMobile as HTMLElement) {
        (workshopsSubnavItemMobile as HTMLElement).innerText = workshops_text;
      }

      if (window.location.pathname !== '/us/plans/clinic') {
        const weightLossMedsSubnavItemMobile = document.querySelector(
          '[class*="MobileMenuItem_subMenu__"] [class*="MobileMenuItem_container__"] [da-label="Weight-Loss Medications"]',
        );

        if ((weightLossMedsSubnavItemMobile as HTMLElement)?.style) {
          (weightLossMedsSubnavItemMobile as HTMLElement).style.display =
            'none';
        }

        if (
          (workshopsSubnavItemMobile as HTMLElement)?.innerText ===
            workshops_text &&
          (weightLossMedsSubnavItemMobile as HTMLElement)?.style?.display ===
            'none'
        ) {
          hamburgerObserver.disconnect();
        }
      } else {
        if (
          (workshopsSubnavItemMobile as HTMLElement)?.innerText ===
          workshops_text
        ) {
          hamburgerObserver.disconnect();
        }
      }
    };

    const rootElement = document.querySelector('#root');
    const config = { attributes: true, childList: true, subtree: true };
    const hamburgerObserver = new MutationObserver(() => {
      handleHamburgerMenuUpdates();
    });

    rootElement && handleHamburgerMenuUpdates();
    rootElement && hamburgerObserver.observe(rootElement, config);

    const primarySubnavObserver = new MutationObserver(() => {
      handlePrimarySubnavUpdates();
    });

    rootElement && handlePrimarySubnavUpdates();
    rootElement && primarySubnavObserver.observe(rootElement, config);
  }
};
