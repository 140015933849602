import React from 'react';
import _ from 'lodash';

import type { VideoSlideshowSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/VideoSlideshowSlice/VideoSlideshowSlice';

import { VideoSlideshowSlice } from '@ww-digital/web-palette-react/dist/components/Slice/VideoSlideshowSlice/VideoSlideshowSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface VideoSlideshowSliceContainerProps {
  daCategory: string;
  slice: VideoSlideshowSliceProps;
}

export const VideoSlideshowSliceContainer = ({
  daCategory,
  slice,
}: VideoSlideshowSliceContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);

  if (sliceView.intro?.cta) {
    sliceView.intro.cta.attributes = {};
    sliceView.intro.cta.attributes['da-category'] = daCategory;
    sliceView.intro.cta.attributes['da-action'] = AnalyticsUtility.formatAction(
      'video_slideshow_cta',
      sliceView.intro.cta.text,
    );
    sliceView.intro.cta.attributes['da-label'] = AnalyticsUtility.formatLabel(
      sliceView.intro.cta.text,
    );
  }

  return <VideoSlideshowSlice {...sliceView} />;
};
