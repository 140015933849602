import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import type { MarketContextType } from '../../../context/market.context';
import type { ConfigContextType } from '../../../context/config.context';

import { ConfigContext } from '../../../context/config.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { AppUtility } from '../../Utility/AppUtility.ts';

// @TODO Join JSONLD Types
interface JSONLDProps {
  content?: any;
  nestedKey?: string;
  addWebsiteName?: boolean; // Flag so that slice-specific JSONLD can be added without the website name being duplicated.
}

export const JSONLD = ({
  content,
  nestedKey = 'publisher',
  addWebsiteName = true,
}: JSONLDProps): JSX.Element => {
  const { config } = useContext<ConfigContextType>(ConfigContext);
  const { country } = useContext<MarketContextType>(MarketContext);
  const sameAs = content && content.sameAs !== undefined; // Social links to be added to the website name.

  const getPublisherName = () => {
    switch (country) {
      case 'se':
        return 'ViktVäktarna';
      default:
        return 'WeightWatchers';
    }
  };

  const getAlternateName = () => {
    switch (country) {
      case 'se':
        return ['WW'];
      default:
        return ['WW', 'Weight Watchers'];
    }
  };

  const publisher = {
    '@type': 'Organization',
    name: getPublisherName(),
    logo: config.jsonLD.logo,
    url: AppUtility.getBaseUrl(country),
  };

  const ldJson = {
    '@context': 'https://schema.org/',
    [nestedKey]: { ...publisher },
    ...content,
  };

  const name = {
    '@context': 'https://schema.org/',
    '@graph': [
      {
        ...publisher,
        alternateName: getAlternateName(),
        ...(sameAs && { sameAs: content.sameAs }),
      },
      {
        '@type': 'WebSite',
        name: 'WeightWatchers',
        alternateName: getAlternateName(),
        url: AppUtility.getBaseUrl(country),
      },
    ],
  };

  return (
    <Helmet>
      {addWebsiteName && (
        <script type="application/ld+json">{JSON.stringify(name)}</script>
      )}
      {content && !sameAs && (
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      )}
    </Helmet>
  );
};
