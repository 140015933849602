import React from 'react';

import type { CalloutSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/CalloutSlice/CalloutSlice';

import { CalloutSlice } from '@ww-digital/web-palette-react/dist/components/Slice/CalloutSlice/CalloutSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface CalloutSliceContainerProps {
  daCategory: string;
  slice: CalloutSliceProps;
}

export const CalloutSliceContainer = ({
  daCategory,
  slice,
}: CalloutSliceContainerProps): JSX.Element => {
  if (slice.primary) {
    slice.primary.attributes = {};
    slice.primary.attributes['da-category'] = daCategory;
    slice.primary.attributes['da-action'] = AnalyticsUtility.formatAction(
      'callout_cta',
      slice.primary.text,
    );
    slice.primary.attributes['da-label'] = AnalyticsUtility.formatLabel(
      slice.primary.text,
    );
  }

  return <CalloutSlice {...slice} />;
};
