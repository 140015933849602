import React, { useContext } from 'react';

import type { BmiCalculatorSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/BMICalculatorSlice/BMICalculatorSlice';
import type { ConfigContextType } from '../../../context/config.context';

import { BmiCalculatorSlice } from '@ww-digital/web-palette-react/dist/components/Slice/BMICalculatorSlice/BMICalculatorSlice';
import { ConfigContext } from '../../../context/config.context.ts';

interface BMICalculatorSliceContainerProps {
  slice: BmiCalculatorSliceProps;
}

export const BMICalculatorSliceContainer = ({
  slice,
}: BMICalculatorSliceContainerProps): JSX.Element => {
  const { translations } = useContext<ConfigContextType>(ConfigContext);

  const unitLabels = {
    standard: translations.BMI_STANDARD,
    metric: translations.BMI_METRIC,
    imperial: translations.BMI_IMPERIAL,
  };

  const mergedData = {
    ...slice,
    heightLabel: translations.BMI_HEIGHT_LABEL,
    weightLabel: translations.BMI_WEIGHT_LABEL,
    valueLable: translations.BMI_VALUE_LABEL,
    unitLabels,
  };

  return <BmiCalculatorSlice {...mergedData} />;
};
