export const isMatchedDate = (
  startDate: string,
  endDate: string,
  timezone: string,
  dateStringFormat: string,
) => {
  const currentDate = new Date().toLocaleString(dateStringFormat, {
    timeZone: timezone,
  });
  return (
    Date.parse(currentDate) >= Date.parse(startDate) &&
    Date.parse(currentDate) <= Date.parse(endDate)
  );
};
