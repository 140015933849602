import React, { useContext } from 'react';

import type { MarketContextType } from '../../../context/market.context';
import type { ConfigContextType } from '../../../context/config.context';

import { SkipLinks } from '@ww-digital/web-palette-react/dist/components/Navigation/SkipLinks/SkipLinks';
import { MarketContext } from '../../../context/market.context.ts';
import { ConfigContext } from '../../../context/config.context.ts';

export const SkipLinksContainer = (): JSX.Element => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const { translations } = useContext<ConfigContextType>(ConfigContext);

  return (
    <SkipLinks
      skipToMainText={translations.NAVIGATION_SKIP_TO_MAIN}
      skipToMainTextAttributes={{
        'da-category': 'navigation',
        'da-action': 'skip_to_main_content',
        'da-label': `${language}-${country}`,
      }}
      skipToFooterText={translations.NAVIGATION_SKIP_TO_FOOTER}
      skipToFooterAttributes={{
        'da-category': 'navigation',
        'da-action': 'skip_to_footer',
        'da-label': `${language}-${country}`,
      }}
    />
  );
};
