import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';

import { EmailCaptureModal } from '@ww-digital/web-palette-react/dist/components/Modal/EmailCaptureModal/EmailCaptureModal';

import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { ConfigContextType } from '../../../context/config.context';

import { EntitlementUtility } from '../../Utility/EntitlementUtility.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { ConfigContext } from '../../../context/config.context.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

import image from './images/EmailCapturePizza.jpg';

import EmailCaptureModalQuery from './graphql/EmailCaptureModalQuery.graphql';
import EmailCaptureModalCloseMutation from './graphql/EmailCaptureModalCloseMutation.graphql';
import EmailCaptureModalSubscribeMutation from './graphql/EmailCaptureModalSubscribeMutation.graphql';

export const EmailCaptureModalContainerVisible = (): JSX.Element | null => {
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { config, translations } = useContext<ConfigContextType>(ConfigContext);

  const modalDisplayedEventFired = useRef<boolean>(false);
  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);
  const [show, setShow] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const {
    loading: loadingQuery,
    error: errorQuery,
    data: dataQuery,
  } = useQuery(EmailCaptureModalQuery, { ssr: false });
  const [closeEmailCaptureMutation] = useMutation(
    EmailCaptureModalCloseMutation,
  );
  const [
    subscribeToNewsletterMutation,
    { loading: loadingMutation, error: errorMutation, data: dataMutation },
  ] = useMutation(EmailCaptureModalSubscribeMutation);

  // How long to wait until showing the modal.
  const timeout = 10000;

  // Get the error message to display for the email form based on the result.
  const getErrorMessage = () => {
    if (errorMutation) {
      return translations.NEWSLETTER_SYSTEM_UNAVAILABLE_LABEL;
    } else if (dataMutation) {
      if (dataMutation.subscribeToNewsletter.alreadySubscribed) {
        return translations.NEWSLETTER_EMAIL_EXISTS_LABEL;
      } else if (dataMutation.subscribeToNewsletter.invalidEmail) {
        return translations.NEWSLETTER_EMAIL_INVALID_LABEL;
      } else if (!dataMutation.subscribeToNewsletter.success) {
        return translations.NEWSLETTER_SYSTEM_UNAVAILABLE_LABEL;
      }
    }

    return '';
  };

  // Fire an analytics event.
  const fireEvent = (category: string, action: string) => {
    AnalyticsUtility.fireEvent(category, `email_capture_modal_${action}`);
  };

  const daCategory = AnalyticsUtility.formatCategory(
    entitlement,
    'email_capture',
  );

  const onShow = () => {
    setShow(true);
  };

  const onClose = () => {
    setShow(false);
  };

  const onSubmit = (email: string) => {
    subscribeToNewsletterMutation({ variables: { email } }).then(
      ({ data, errors }) => {
        if (errors) {
          // Log an event to analytics for API failure.
          fireEvent(daCategory, 'api_down');
        } else if (data) {
          if (data.subscribeToNewsletter.success) {
            // Set a state to keep the modal open.
            setSuccess(true);

            fireEvent(daCategory, 'success');

            fireEvent('registration', 'email_registration');

            // Close email capture for the future.
            closeEmailCaptureMutation();
          } else if (data.subscribeToNewsletter.alreadySubscribed) {
            fireEvent(daCategory, 'already_subscribed');
          }
        }
      },
    );
  };

  useEffect(() => {
    timer.current = setTimeout(onShow, timeout);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  if (loadingQuery || errorQuery || !dataQuery) {
    return null;
  }

  // If email capture has been closed, do not show it again.
  // But keep it open if it was a successful submission just now.
  if (dataQuery.closed && !success) {
    return null;
  }

  // If we found a user, then exit now because email capture is only for non-users.
  if (dataQuery.user) {
    return null;
  }

  if (!show) {
    return null;
  }

  // Log an event to analytics for modal displayed if we have not already.
  if (!modalDisplayedEventFired.current) {
    modalDisplayedEventFired.current = true;
    fireEvent(daCategory, 'displayed');
  }

  // If none of the above, the user gets the modal.
  return (
    <EmailCaptureModal
      modal={{
        onClose: () => {
          // Store that the email capture is done.
          closeEmailCaptureMutation();

          // Fire an event for the modal being closed.
          fireEvent(daCategory, 'close');

          // Close the modal.
          // NOTE: This is need to get it closed after a successful submission.
          onClose();
        },
      }}
      block={{
        image: config.emailCaptureModal.imageURL || image,
        headline: config.emailCaptureModal.headline,
        body: config.emailCaptureModal.body,
        email: {
          onSubmit,
          placeholder: translations.NEWSLETTER_EMAIL_INPUT_PLACEHOLDER_TEXT,
          regexErrorMessage: translations.NEWSLETTER_EMAIL_INVALID_LABEL,
          errorMessage: getErrorMessage(),
          buttonText: translations.NEWSLETTER_EMAIL_INPUT_SUBMIT_BUTTON_TEXT,
          loading: loadingMutation,
          success: !!dataMutation && dataMutation.subscribeToNewsletter.success,
          successMessage: translations.NEWSLETTER_SUBSCRIPTION_CONFIRMED_LABEL,
          buttonAttributes: {
            'da-category': daCategory,
            'da-action': 'email_capture_modal_submit',
          },
        },
        disclaimer: config.emailCaptureModal.disclaimer,
      }}
    />
  );
};

export const EmailCaptureModalContainer = (): JSX.Element | null => {
  const { config } = useContext<ConfigContextType>(ConfigContext);
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { enabled } = config.emailCaptureModal || {};
  const isVisible =
    enabled &&
    entitlement &&
    entitlement === EntitlementUtility.entitlements.guest;

  return isVisible ? <EmailCaptureModalContainerVisible /> : null;
};
