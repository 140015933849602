import React, { useRef, useContext } from 'react';
import _ from 'lodash';

import type {
  VideoGridSliceProps,
  VideoListItemType,
} from '@ww-digital/web-palette-react/dist/components/Slice/VideoGridSlice/VideoGridSlice';
import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { ConfigContextType } from '../../../context/config.context';

import { VideoGridSlice } from '@ww-digital/web-palette-react/dist/components/Slice/VideoGridSlice/VideoGridSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { Counter } from '../../Utility/Counter.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { ConfigContext } from '../../../context/config.context.ts';

interface VideoGridSliceContainerProps {
  slice: VideoGridSliceProps;
  topLevelCategory?: string;
}

export const VideoGridSliceContainer = ({
  slice,
  topLevelCategory = '',
}: VideoGridSliceContainerProps): JSX.Element => {
  const sliceIndex = useRef<number>(
    Counter.getCounter('card-grids-slice-number'),
  );
  const { entitlement } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { translations } = useContext<ConfigContextType>(ConfigContext);

  const processVideo = (card: VideoListItemType, index: number) => {
    if (!card) {
      return card;
    }

    card.cardActionAttributes = AnalyticsUtility.formatCardAnalytics({
      type: AnalyticsUtility.cardTypes.TYPE_VIDEO_CARD_GRID_SMALL,
      pageIndex: 1, // always 1 as there is no pager for this type of slice.
      sliceIndex: sliceIndex.current, // card grid slice index on the page
      cardIndex: index, // card index in slice
      topLevelCategory,
      cardId: card.video.videoId,
      cardTitle: card.title,
      entitlement: entitlement,
    });

    return card;
  };

  const mergedData = {
    ...slice,
    showText: {
      more: translations.VIDEO_MORE,
      less: translations.VIDEO_LESS,
    },
  };

  const videoList = _.cloneDeep(slice.videoList).map((card, index) =>
    processVideo(card, ++index),
  );

  const largeCardActionAttributes =
    videoList.length > 0
      ? AnalyticsUtility.formatCardAnalytics({
          type: AnalyticsUtility.cardTypes.TYPE_VIDEO_CARD_GRID_LARGE,
          pageIndex: 1, // always 1 as there is no pager for this type of slice.
          sliceIndex: sliceIndex.current, // card grid slice index on the page
          cardIndex: 1, // card index in slice
          topLevelCategory,
          cardId: videoList[0].video.videoId,
          cardTitle: videoList[0].title,
          entitlement: entitlement,
        })
      : {};

  return (
    <VideoGridSlice
      {...mergedData}
      videoList={videoList}
      largeCardActionAttributes={largeCardActionAttributes}
    />
  );
};
