import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import { Button } from '@ww-digital/web-palette-react/dist/components/Button/Button/Button';
import { FoodRoute } from './FoodRoute.tsx';

export const DemoRoute = (): JSX.Element => {
  const { url, path } = useRouteMatch();

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <div style={{ padding: '10px', display: 'inline-block' }}>
          <Button url={`${url}/food`}>Food Search</Button>
        </div>
        <div style={{ padding: '10px', display: 'inline-block' }}>
          <Button url={`${url}/pricing`}>Pricing</Button>
        </div>
      </div>
      <Route exact path={`${path}/food`} component={FoodRoute} />
    </>
  );
};
