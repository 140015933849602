import { NormalizedAttributes } from '@ww-digital/xs-sdk';
import { AttributeType } from './types.ts';
import { JSONSafeParse } from './JSONSafeParse.ts';

export const mapAttributes = (
  rawAttributes: NormalizedAttributes,
): AttributeType => {
  const mappedAttributes: AttributeType = {
    attributes: {},
  };
  for (const attribute in rawAttributes) {
    const parsedAttributeValue: AttributeType | any = JSONSafeParse(
      rawAttributes[attribute].attributeValue,
    );
    // if the attributeValue is not a valid JSON, skip it
    if (parsedAttributeValue.error) {
      continue;
    }
    if (parsedAttributeValue.description) {
      delete parsedAttributeValue.description;
    }
    if (
      attribute === 'configuration' ||
      attribute === 'path' ||
      attribute === 'forceExposure' ||
      attribute === 'redirects'
    ) {
      mappedAttributes[attribute] = parsedAttributeValue;
    } else if (attribute !== 'autoRoute') {
      mappedAttributes.attributes[attribute] = parsedAttributeValue;
    }
  }
  return mappedAttributes;
};
