export class FormatUtility {
  static escapeJsonString(str = '') {
    const escapedChar: Record<string, string> = {
      '<': '\\u003C',
      '>': '\\u003E',
      '/': '\\u002F',
      '\u2028': '\\u2028',
      '\u2029': '\\u2029',
    };

    return str.replace(/[<>/\u2028\u2029]/g, (unsafeChar) => {
      return escapedChar[unsafeChar];
    });
  }

  static sanitizeUrl(str = '') {
    return str.replace(/[^A-Za-z0-9-_/]+/g, '');
  }

  // Test header is only of format 123:456, 789:123
  static sanitizeTestHeader(str = '') {
    return str.replace(/[^0-9:, ]+/g, '');
  }

  // Franchise header is either Y/y or N/n
  static sanitizeFranchiseHeader(str = '') {
    return str.match(/^[YyNn]$/g) ? str : '';
  }
}
