import {
  AttributeType,
  TemplatesAttributeType,
} from '../../../hooks/useExperiments';
import { SlicesContainerProps } from '../../Slices/SlicesContainer/SlicesContainer';
import { SliceContainerProps } from '../../Slices/SliceContainer/SliceContainer';

import { skipAttribute } from './skipAttribute.ts';
import { alterSlice } from './alterSlice.ts';

export const getAlteredSlices = (
  slices: SlicesContainerProps['slices'],
  attributes: AttributeType['attributes'],
  configuration: AttributeType['configuration'],
  experimentTextId: string,
  pathname: string,
  market: string,
  region?: string,
): SlicesContainerProps['slices'] => {
  for (const attributeName in attributes) {
    const attribute: TemplatesAttributeType = attributes[attributeName];

    if (skipAttribute(attribute.config, market, pathname, region)) {
      continue;
    }

    const attributeSliceSelector = attribute.config.sliceSelector.targetBy
      ? attribute.config.sliceSelector.targetBy
      : 'id';

    const alteredSlice = slices.find(
      (slice: SliceContainerProps['slice']) =>
        slice[attributeSliceSelector] ===
        attribute.config.sliceSelector[attributeSliceSelector],
    );

    if (alteredSlice) {
      alterSlice(
        alteredSlice,
        attribute,
        configuration,
        experimentTextId,
        slices,
      );
    }
  }
  return slices;
};
