import { TemplatesAttributeType } from '../../../hooks/useExperiments';

export const skipAttribute = (
  attributeConfig: TemplatesAttributeType['config'],
  market: string,
  pathname: string,
  region?: string,
): boolean => {
  // skip attribute if it doesn't alter slices based on templates (doesn't have a config/ not TemplatesAttributeType)
  if (!attributeConfig) {
    return true;
  }
  // skip attribute if it's not in the region
  if (region && attributeConfig.region && attributeConfig.region !== region) {
    return true;
  }
  // skip attribute if it's not in the targeted market
  if (
    attributeConfig.markets &&
    attributeConfig.markets.length &&
    !attributeConfig.markets.includes(market)
  ) {
    return true;
  }
  // skip attribute if it's not in the targeted pathname
  if (
    attributeConfig.targetedPathnames &&
    attributeConfig.targetedPathnames.strings &&
    attributeConfig.targetedPathnames.strings.length &&
    !attributeConfig.targetedPathnames.strings.includes(pathname)
  ) {
    return true;
  }
  // skip attribute if it's not in the targeted pathname regex
  if (
    attributeConfig.targetedPathnames &&
    attributeConfig.targetedPathnames.regex &&
    attributeConfig.targetedPathnames.regex.length &&
    !attributeConfig.targetedPathnames.regex.some((regex) =>
      new RegExp(regex).test(pathname),
    )
  ) {
    return true;
  }

  return false;
};
