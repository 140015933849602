import wwUtility from '../../ww.utility.ts';

export const ExecuteFunctions_GREX278_HP = (
  variant: string,
  variables: any,
) => {
  if (wwUtility.isBrowser()) {
    localStorage.setItem('hpVisit', 'true');
  }
};
