import React, { useContext } from 'react';
import { __RouterContext } from 'react-router';

import { ContentRoute } from '../ContentRoute/ContentRoute.tsx';

export const FourOhFourRoute = (): JSX.Element => {
  const { staticContext } = useContext(__RouterContext);

  if (staticContext) {
    staticContext.statusCode = 404;
  }

  return <ContentRoute path="404" />;
};
