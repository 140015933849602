import wwUtility from '../../ww.utility.ts';
import { subnavUpdates } from './GREX-278-subnav-updates.tsx';
import { navUpdates } from './GREX-278-remove-joinNow-cta.tsx';
import { hidePricingNavItem } from './GREX-278-pricing-nav-updates.tsx';
import { FireExposure_GREX278 } from './GREX-278-fireExposure.tsx';

export const ExecuteFunctions_GREX278_PLANS_WORKSHOPS = (
  variant: string,
  variables: any,
) => {
  if (wwUtility.isBrowser() && localStorage.getItem('hpVisit') === 'true') {
    // remove pricing nav item
    if (variant === 'v1') {
      hidePricingNavItem();
      navUpdates();
    }

    FireExposure_GREX278(variables.testId);
  } else if (wwUtility.isBrowser()) {
    subnavUpdates(variables.workshops_text);
  }
};
