import _ from 'lodash';

import type { ConfigType } from '../Config/Config';

import {
  setExternalCmsPathsRegex,
  getAllExternalPathsRegexs,
  legacyPatterns,
  localizedPath,
  getPatterns,
  isAbsoluteURL,
  domainRegex,
  isAppURL,
} from './ConfigPathUtility.ts';

export interface ConfigPathType {
  getAllExternalPathsRegexs: typeof getAllExternalPathsRegexs;
  legacyPatterns: typeof legacyPatterns;
  localizedPath: typeof localizedPath;
  getPatterns: typeof getPatterns;
  isAbsoluteURL: typeof isAbsoluteURL;
  domainRegex: typeof domainRegex;
  isAppURL: typeof isAppURL;
}

const defaultConfig = {
  getAllExternalPathsRegexs,
  legacyPatterns,
  localizedPath,
  getPatterns,
  isAbsoluteURL,
  domainRegex,
  isAppURL,
};

export const ConfigPath = {
  getDefaultValue: () => defaultConfig,
  getValue: (data: { config: ConfigType }) => {
    const externalPaths: string[] = _.get(data, 'config.routes.externalPaths');
    setExternalCmsPathsRegex(externalPaths);

    return defaultConfig;
  },
};
