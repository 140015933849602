import { useContext } from 'react';
import XS from '@ww-digital/xs-sdk';

import { MarketUtility } from '../../components/Utility/MarketUtility.ts';
import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import wwUtility from '../../ww.utility.ts';
import GUA from '@ww-digital/xs-plugin-gua';
import type { MarketContextType } from '../../context/market.context.ts';
import { MarketContext } from '../../context/market.context.ts';

export const ModifyButton = (
  cssSelector: string,
  variables: any,
  variant: string,
) => {
  const visitorId = Storage.getCookieValue('ww_browser_id', false);
  const gua = new GUA();
  const { country, language } = useContext<MarketContextType>(MarketContext);

  // Select the node that will be observed for mutations
  /* Masthead Slice update */
  const slice_one = document.getElementById(variables?.elementId);

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true };

  // Callback function to execute when mutations are observed
  const callback_one = (mutationList: any, observer_one: any) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        if (
          slice_one !== null &&
          slice_one.querySelectorAll(`${cssSelector}`)[0] !== undefined &&
          slice_one.querySelectorAll(`${cssSelector}`)[0].getAttribute('id') !==
            'grex-177'
        ) {
          // Prevent duplicate Event Listeners from being added to element by running a conditional check against the existence of this ID
          slice_one
            .querySelectorAll(`${cssSelector}`)[0]
            .setAttribute('id', 'grex-177');

          slice_one
            .querySelectorAll(`${cssSelector}`)[0]
            .addEventListener('contextmenu', function (e) {
              e.preventDefault();
            });

          slice_one
            .querySelectorAll(`${cssSelector}`)[0]
            .addEventListener('click', function (e) {
              e.preventDefault();

              (async () => {
                const xs = new XS({
                  user: {
                    visitorId: typeof visitorId === 'string' ? visitorId : '',
                    locale: MarketUtility.getXSLocale(country, language),
                  },
                  baseUrl: wwUtility.getAPIDomain(),
                  plugins: [gua],
                });

                await xs.fetchUserExperiments();

                // Run Exposure for Test
                await xs.exposeExperiment(variables?.testId).then(function (e) {
                  if (wwUtility.isBrowser()) {
                    if (variant === 'control') {
                      window.location.href = variables?.redirectOne;
                    } else if (variant === 'v1') {
                      window.location.href = variables?.redirectTwo;
                    }
                  }
                });
              })();
            });
        }
      }
    }
  };

  if (slice_one !== null) {
    // Create an observer instance linked to the callback function
    const observer_one = new MutationObserver(callback_one);

    // Start observing the target node for configured mutations
    observer_one.observe(slice_one, config);
  }

  return;
};

export const ExecuteFunctions_GREX177 = (
  pathname: string,
  variant: string,
  variables?: any,
) => {
  if (variant === 'control' || variant === 'v1') {
    if (pathname === variables?.pathnameOne) {
      ModifyButton(variables?.cssTargetPlans, variables, variant);
    } else if (pathname === variables?.pathnameTwo) {
      ModifyButton(variables?.cssTargetWLMOne, variables, variant);
      ModifyButton(variables?.cssTargetWLMTwo, variables, variant);
      ModifyButton(variables?.cssTargetWLMThree, variables, variant);
    }
  }
};
