import { useContext } from 'react';
import { useQuery } from 'react-apollo';

import type { QueryStringContextType } from '../context/querystring.context';
import type { OfferPlan } from '../components/Utility/ProductsUtility';

import { AppUtility } from '../components/Utility/AppUtility.ts';
import { ProductsUtility } from '../components/Utility/ProductsUtility.ts';
import { QueryStringContext } from '../context/querystring.context.ts';

import OfferBundlesQuery from './graphql/OfferBundlesQuery.graphql';

export interface OfferBundleInput {
  subscriptionType?: string;
  offerBundleId?: string;
}

export interface ContentItem {
  contentKey: string;
  contentValue: string;
}

export interface OfferBundle {
  offerBundleId: string;
  ownerId: string;
  subscriptionType: string;
  locale: string;
  offerBundleName: string;
  bestOfferPlanId: string;
  preSelectedOfferPlanId: string;
  contents: ContentItem[];
  offerPlans: OfferBundleOfferPlan[];
  isFallback: boolean;
}

export interface OfferBundleOfferPlan {
  offerPlanId: string;
  offerPlanData: OfferPlan | null;
}

export interface OfferBundleQueryData {
  offerBundles: OfferBundle[];
}

export interface UseProductsResponse {
  offerBundles: OfferBundle[];
  loading: boolean;
  error: Error | null;
}

export const useProducts = (
  offerBundleInputs: OfferBundleInput[],
  ownerId: string | null,
  stg: boolean,
): UseProductsResponse => {
  const queryStringContext =
    useContext<QueryStringContextType>(QueryStringContext);
  const env = AppUtility.getEnv();
  const forceQA = !!queryStringContext.ppmqa && env !== 'prod';

  // Create GQL variables, standardizing st values to core and premium if necessary.
  const variables = {
    ownerId: ownerId || '37',
    offerBundles:
      offerBundleInputs?.map((obi) =>
        obi.subscriptionType
          ? {
              ...obi,
              subscriptionType: ProductsUtility.subscriptionType(
                obi.subscriptionType,
              ),
            }
          : obi,
      ) || [],
    stg: stg || undefined,
    forceQA: forceQA || undefined,
  };

  const {
    data,
    loading: productsLoading,
    error: productsError,
  } = useQuery<OfferBundleQueryData>(OfferBundlesQuery, {
    variables,
    fetchPolicy: 'cache-first',
  });

  let offerBundles = data?.offerBundles?.filter((ob) => !!ob) || [];
  offerBundles = offerBundles.map((ob) => {
    ob.offerPlans =
      ob.offerPlans?.map((plan, idx) => {
        const newPlan = { ...plan };
        if (plan.offerPlanData && typeof plan.offerPlanData === 'string') {
          try {
            const parsedOfferPlanData = JSON.parse(plan.offerPlanData);
            newPlan.offerPlanData = parsedOfferPlanData;
          } catch (e) {
            // Cannot parse JSON, so clear out the value.
            newPlan.offerPlanData = null;
          }
        }
        return newPlan;
      }) || [];
    return ob;
  });

  return {
    offerBundles,
    loading: productsLoading || false,
    error: productsError || null,
  };
};
