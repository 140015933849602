import { useQuery } from 'react-apollo';
import LocationsSearchQuery from './graphql/LocationsSearch.graphql';

export interface UseHasLocationsResponse {
  hasLocations: boolean;
  loading: boolean;
  error: Error | null;
}

export const useHasLocations = (zip: string): UseHasLocationsResponse => {
  const {
    data,
    error: hasLocationsError,
    loading: hasLocationsLoading,
  } = useQuery(LocationsSearchQuery, {
    variables: { searchText: zip, limit: '1' },
    skip: zip === '99999',
    fetchPolicy: 'no-cache',
  });

  // We can be optimistic and default to true, even on loading/error/no-data conditions.
  const hasLocations =
    !hasLocationsLoading && data?.locationsSearch?.locations
      ? data.locationsSearch.locations.length > 0
      : true;

  return {
    hasLocations,
    loading: hasLocationsLoading,
    error: hasLocationsError as Error | null,
  };
};
