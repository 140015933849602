import React, { ReactNode } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import _ from 'lodash';

import {
  EntitlementContext,
  defaultEntitlementContext,
} from '../../context/entitlement.context.ts';
import { EntitlementUtility } from '../Utility/EntitlementUtility.ts';

interface EntitlementProviderProps {
  children: ReactNode;
}

export const EntitlementProvider = ({
  children,
}: EntitlementProviderProps): JSX.Element | null => {
  const parentMatch = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${parentMatch.path}/:entitlementPath([mr]/cms)?`}
        render={({ match }) => {
          const entitlementPath = _.get(match, 'params.entitlementPath');

          // If we have no path, then it must be the Guest path of empty string.
          const entitlementInfo =
            EntitlementUtility.getEntitlementInfo(entitlementPath || '') ||
            defaultEntitlementContext;

          return (
            <EntitlementContext.Provider value={entitlementInfo}>
              {children}
            </EntitlementContext.Provider>
          );
        }}
      />
    </Switch>
  );
};
