import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import type { LinkProps } from '@ww-digital/web-palette-react/dist/components/Button/Link/Link';
import type { MarketContextType } from '../../context/market.context';
import type { ConfigContextType } from '../../context/config.context';
import type { QueryStringContextType } from '../../context/querystring.context';

import { QueryStringUtility } from '../Utility/QueryStringUtility.ts';
import { AppUtility } from './AppUtility.ts';
import { MarketContext } from '../../context/market.context.ts';
import { ConfigContext } from '../../context/config.context.ts';
import { QueryStringContext } from '../../context/querystring.context.ts';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const wwConfig = require('../../ww.config.ts');

interface VSLinkProps extends Omit<LinkProps, 'ref'> {
  to: string;
}

export const VSLink = ({
  to,
  children,
  preserveUrlQuery,
  excludeFromUrlQuery = [],
  accessibilityLabel,
  title,
  ...otherProps
}: VSLinkProps): JSX.Element => {
  const { country, language } = useContext<MarketContextType>(MarketContext);
  const { paths } = useContext<ConfigContextType>(ConfigContext);
  const { isAbsoluteURL, domainRegex, isAppURL } = paths;
  const urlQueryParams = useContext<QueryStringContextType>(QueryStringContext);

  /**
   * Adds to provided linkTo url
   * only whitelisted query string parameters
   * existing in currently requested/viewed url.
   *
   * If linkTo and currently requested/viewed url
   * both contain some query string parameter,
   * returned url contains the one from linkTo url.
   */
  const updateLinkWithQueryString = (linkTo: string) => {
    const url = new URL(linkTo);
    const linkQueryParams = QueryStringUtility.getQueryParams(url.search);

    const allowedQueryStringParameterKeys = urlQueryParams && [
      // Get only unique keys via [...new Set()].
      ...new Set([
        // Allow only whitelisted query string parameters.
        ...Object.keys(urlQueryParams).filter(
          (urlQueryParam) =>
            !excludeFromUrlQuery.includes(urlQueryParam) &&
            wwConfig.allowedQueryStringParameters.includes(urlQueryParam),
        ),
        // Allow only whitelisted query string parameters.
        ...Object.keys(linkQueryParams).filter((linkQueryParam) =>
          wwConfig.allowedQueryStringParameters.includes(linkQueryParam),
        ),
      ]),
    ];

    const resultQueryStringObj: Record<string, string> = {};

    allowedQueryStringParameterKeys &&
      allowedQueryStringParameterKeys.forEach((queryParamName: string) => {
        const urlParam = urlQueryParams[queryParamName];
        // In case query string param exists in both provided link and url,
        // then always use the one from provided link.
        if (
          Object.prototype.hasOwnProperty.call(linkQueryParams, queryParamName)
        ) {
          resultQueryStringObj[queryParamName] =
            linkQueryParams[queryParamName];
        } else if (
          urlQueryParams
            ? Object.prototype.hasOwnProperty.call(
                urlQueryParams,
                queryParamName,
              )
            : false
        ) {
          if (urlParam) {
            resultQueryStringObj[queryParamName] = urlParam;
          }
        }
      });

    let resultQueryString =
      QueryStringUtility.getQueryString(resultQueryStringObj);

    if (resultQueryString) {
      resultQueryString = '?' + resultQueryString;
    }

    return AppUtility.getUrlFromUrlObject(url, resultQueryString, false);
  };

  const renderATag = (
    isAbsoluteURLValue: boolean,
    domain: string,
    to: string,
  ) => (
    <a
      href={!isAbsoluteURLValue ? domain + to : to}
      title={accessibilityLabel}
      {...otherProps}
    >
      {children}
    </a>
  );

  if (to === '<nolink>') {
    return renderATag(true, '', '#');
  }

  const isAbsoluteURLValue = isAbsoluteURL(to);
  const isAppURLValue = isAppURL(country, language, to);

  // Used to make qat2 links to non-app pages (like D7) work from the AWS domains.
  const domain = AppUtility.getDomain(country, true);

  let toProcessed = to;
  if (preserveUrlQuery) {
    toProcessed = updateLinkWithQueryString(toProcessed);
  }
  const linkTo = toProcessed.replace(domainRegex, '');

  if (isAppURLValue) {
    return (
      <Link to={linkTo} title={accessibilityLabel} {...otherProps}>
        {children}
      </Link>
    );
  } else {
    return renderATag(isAbsoluteURLValue, domain, to);
  }
};
