import React, { useContext } from 'react';
import _ from 'lodash';

import type { RedesignContextType } from '@ww-digital/web-palette-react/dist/context/redesign.context';
import type { FooterMenuSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/FooterMenuSlice/FooterMenuSlice';
import type { ExtendedMenuItemType } from '@ww-digital/web-palette-react/dist/components/Navigation/Menu/Menu';
import type { MenuItemType } from '@ww-digital/web-palette-react/dist/components/Navigation/MenuItem/MenuItem';
import type { ConfigContextType } from '../../../context/config.context';

import { FooterMenuSlice } from '@ww-digital/web-palette-react/dist/components/Slice/FooterMenuSlice/FooterMenuSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { ConfigContext } from '../../../context/config.context.ts';

interface FooterMenuSliceContainerProps {
  slice: {
    menu?: {
      links?: FooterMenuSliceProps['menu'];
    };
    redesignVersion?: RedesignContextType;
  };
  daCategory: string;
}

export const FooterMenuSliceContainer = ({
  slice,
  daCategory,
}: FooterMenuSliceContainerProps): JSX.Element | null => {
  const configContext = useContext<ConfigContextType>(ConfigContext);

  const addLinkAttributes = (linkData: MenuItemType, daCategory: string) => {
    // Discard Drupal-entered link.attributes for now (only used by FooterLegalSlice)
    const link = Object.assign({}, linkData);
    delete link.attributes;

    // Do not add attributes if url is empty.
    if (!link.url) {
      return { ...link };
    }

    const attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction('nav_footer', link.text),
      'da-label': AnalyticsUtility.formatLabel(link.text),
    };

    return {
      ...link,
      attributes,
    };
  };

  const sliceView = _.cloneDeep(slice);

  // Add analytics attributes to only
  // 2nd level links of footer menu.
  const preparedMenu =
    sliceView.menu && sliceView.menu.links
      ? sliceView.menu.links.map(
          (firstLevelMenuItemData: ExtendedMenuItemType) => {
            // Discard Drupal-entered link.attributes for now (only used by FooterLegalSlice)
            const firstLevelMenuItem = Object.assign(
              {},
              firstLevelMenuItemData,
            );
            delete firstLevelMenuItem.attributes;

            firstLevelMenuItem.subMenu = firstLevelMenuItem.subMenu
              ? firstLevelMenuItem.subMenu.map(
                  (secondLevelMenuItem: MenuItemType) =>
                    addLinkAttributes(secondLevelMenuItem, daCategory),
                )
              : [];

            return firstLevelMenuItem;
          },
        )
      : [];

  // Social networks.
  const { socialNetworks } = configContext.config;

  // Filter enabled networks and add analytics attributes to social network links.
  // TODO
  const enabledSocialNetworks = _.pickBy(socialNetworks, 'enabled');
  const socialNetworkLinks = _.mapValues(
    enabledSocialNetworks,
    (socialNetwork, name) => {
      const { label, link } = socialNetwork || {};

      const attributes = {
        'da-category': daCategory,
        'da-action': AnalyticsUtility.formatAction('social-media', name),
        'da-label': AnalyticsUtility.formatLabel(name),
      };

      return {
        label,
        link,
        attributes,
      };
    },
  );

  return <FooterMenuSlice social={socialNetworkLinks} menu={preparedMenu} />;
};
