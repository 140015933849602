import { QueryStringUtility } from '../QueryStringUtility.ts';

export const isDisableXS = (urlSearchParams: string): boolean => {
  const queryString = QueryStringUtility.getQueryParams(urlSearchParams);

  if (queryString.xs_disable && queryString.xs_disable === 'true') {
    return true;
  }
  return false;
};
