import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';

import type { SectionContextType } from '../../../context/section.context';

import { SectionContext } from '../../../context/section.context.ts';
import { SlicesContainer } from '../../Slices/SlicesContainer/SlicesContainer.tsx';

import HeaderQuery from './graphql/HeaderQuery.graphql';

export const headerContainerType = 'global';

const HeaderContainerVisible = (): JSX.Element | null => {
  const section = useContext<SectionContextType>(SectionContext);

  const { loading, error, data } = useQuery(HeaderQuery, {
    variables: { section },
  });

  if (loading || error || !data?.section?.headerSliceData) {
    return null;
  }

  return (
    <>
      <SlicesContainer
        slices={JSON.parse(data.section.headerSliceData)}
        type={headerContainerType}
        region="header"
      />
    </>
  );
};

export const HeaderContainer = (): JSX.Element | null => {
  const section = useContext<SectionContextType>(SectionContext);

  return section ? <HeaderContainerVisible /> : null;
};
