import { executeFunctions_GREX9999 } from './experiments/GREX-9999.tsx';
import { executeFunctions_UPF2027 } from './experiments/UPF-2027.tsx';
import { executeFunctions_GREX163 } from './experiments/GREX-163.tsx';
import { ExecuteFunctions_GREX170 } from './experiments/GREX-170.tsx';
import { ExecuteFunctions_GREX176 } from './experiments/GREX-176.tsx';
import { ExecuteFunctions_GREX154 } from './experiments/GREX-154.tsx';
import { ExecuteFunctions_GREX177 } from './experiments/GREX-177.tsx';
import { ExecuteFunctions_GREX204 } from './experiments/GREX-204.tsx';
import { ExecuteFunctions_GREX223 } from './experiments/GREX-223.tsx';
import { ExecuteFunctions_GREX235 } from './experiments/GREX-235.tsx';
import { ExecuteFunctions_GREX278_HP } from './experiments/GREX-278-hp.tsx';
import { ExecuteFunctions_GREX278_POINTS_PDP } from './experiments/GREX-278-points-pdp.tsx';
import { ExecuteFunctions_GREX278_CLINIC_PDP } from './experiments/GREX-278-clinic-pdp.tsx';
import { ExecuteFunctions_GREX278_PLANS } from './experiments/GREX-278-plans.tsx';
import { ExecuteFunctions_GREX278_PLANS_WORKSHOPS } from './experiments/GREX-278-plans-workshops.tsx';
import { ExecuteFunctions_GREX278_PLANS_CLINIC } from './experiments/GREX-278-plans-clinic.tsx';

export const xsFunctionLookupTable = (
  testID: string,
  pathname: string,
  variant: string,
  variables?: any,
) => {
  /* Add what this test does and when it will end... */
  /* DECSRIPTION: This is a dummy test that proves that injections can be run by variant and page */
  /* DATE: No End Date, will be used for Demonstration */
  if (testID === 'GREX-9999') {
    executeFunctions_GREX9999(pathname, variant);
  }
  /* DESCRIPTION: This is the Garbage can test, this function just feeds the user a Session Storage cookie */
  /* DATE: Not being used, we can pull this code out after Demonstrations */
  if (testID === 'UPF-2027') {
    executeFunctions_UPF2027(pathname, variant);
  }
  /* DESCRIPTION: This is a reproduction of the Inbound/Outbound UTM test. This build would allow us to run the 3 tests we have currently running in 1 test. */
  /* DATE: Not being used, we can pull this code out after Demonstrations */
  if (testID === 'GREX-163') {
    executeFunctions_GREX163(pathname, variant);
  }
  /* DESCRIPTION: This is a build that manipulates the display of pricing cards on /us/pricing/b */
  /* DATE: Test is live */
  if (testID === 'GREX-170') {
    ExecuteFunctions_GREX170(pathname, variant);
  }
  /* DESCRIPTION: This is a reproduction of the Inbound/Outbound UTM test. Will allow query params to pass directly through dynamically. */
  /* DATE: Test is live */
  if (testID === 'GREX-176') {
    ExecuteFunctions_GREX176(pathname, variant);
  }
  /* DESCRIPTION: This is a Demo and A/A test to confirm Click-Based Exposures are possible */
  /* DATE: Will run for A/A test */
  if (testID === 'GREX-154') {
    ExecuteFunctions_GREX154(pathname, variant, variables);
  }
  /* DESCRIPTION: User bucketing/entry approach for Longer Eligibility Quiz */
  /* DATE: February 19th - TBD */
  if (testID === 'GREX-177') {
    ExecuteFunctions_GREX177(pathname, variant, variables);
  }
  /* DESCRIPTION: Scroll Exposure Test Build */
  /* DATE: TDB */
  if (testID === 'GREX-204') {
    ExecuteFunctions_GREX204(pathname, variant, variables);
  }
  /* DESCRIPTION: Scroll Exposure Test: Keying off of GA Event in DataLayer */
  /* DATE: TDB */
  if (testID === 'GREX-223') {
    ExecuteFunctions_GREX223(pathname, variant, variables);
  }
  /* DESCRIPTION: Brand Refresh*/
  /* DATE: TDB */
  if (testID === 'GREX-235') {
    ExecuteFunctions_GREX235(variant, variables);
  }
  /* HP IA */
  if (testID === 'GREX-278-hp') {
    ExecuteFunctions_GREX278_HP(variant, variables);
  }

  if (testID === 'GREX-278-points-pdp') {
    ExecuteFunctions_GREX278_POINTS_PDP(variant, variables);
  }

  if (testID === 'GREX-278-clinic-pdp') {
    ExecuteFunctions_GREX278_CLINIC_PDP(variant, variables);
  }

  if (testID === 'GREX-278-plans') {
    ExecuteFunctions_GREX278_PLANS(variant, variables);
  }

  if (testID === 'GREX-278-plans-workshops') {
    ExecuteFunctions_GREX278_PLANS_WORKSHOPS(variant, variables);
  }

  if (testID === 'GREX-278-plans-clinic') {
    ExecuteFunctions_GREX278_PLANS_CLINIC(variant, variables);
  }
};
