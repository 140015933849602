import React, { useContext } from 'react';
import _ from 'lodash';

import type { MarketCountries } from '@ww-digital/web-palette-react/dist/components/Utility/Market';
import type { FooterLegalSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/FooterLegalSlice/FooterLegalSlice';
import type { MarketContextType } from '../../../context/market.context';
import type { ConfigContextType } from '../../../context/config.context';

import { FooterLegalSlice } from '@ww-digital/web-palette-react/dist/components/Slice/FooterLegalSlice/FooterLegalSlice';
import { Market } from '@ww-digital/web-palette-react/dist/components/Utility/Market';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { ConfigContext } from '../../../context/config.context.ts';

interface FooterLegalSliceContainerProps {
  daCategory: string;
  slice: FooterLegalSliceProps;
}

export const FooterLegalSliceContainer = ({
  daCategory,
  slice,
}: FooterLegalSliceContainerProps): JSX.Element => {
  const getProcessedAttributes = (attributes: string) => {
    try {
      const parsed = JSON.parse(attributes);
      return parsed;
    } catch (e) {
      return {};
    }
  };

  const sliceLegal = _.isArray(slice.legal)
    ? slice.legal.map((link) => {
        const analyticsAttributes = {
          'da-category': daCategory,
          'da-action': AnalyticsUtility.formatAction(
            'nav_footer_legal',
            link.text,
          ),
          'da-label': AnalyticsUtility.formatLabel(link.text),
        };

        const attributes = getProcessedAttributes(link.attributes);

        return {
          ...link,
          attributes: {
            ...analyticsAttributes,
            ...attributes,
          },
        };
      })
    : [];

  const { country, language, marketBasePath } =
    useContext<MarketContextType>(MarketContext);
  const { translations } = useContext<ConfigContextType>(ConfigContext);
  const countryName = Market.getCountryName(
    country as MarketCountries,
    language,
  );

  return (
    <FooterLegalSlice
      {...slice}
      legal={sliceLegal}
      country={{
        countryCode: country,
        countryName,
        countryLink: `${marketBasePath}/international`,
        title: translations.GLOBAL_FOOTER_FLAG_LABEL,
        attributes: {
          'da-category': 'navigation',
          'da-action': 'footer_international_click',
          'da-label': `${language}-${country}`,
        },
      }}
      legitScript={country === 'us'}
    />
  );
};
