import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import type { TabsLinkSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/TabsLinkSlice/TabsLinkSlice';
import type { SlicesContainerProps } from '../SlicesContainer/SlicesContainer';

import { TabsLinkSlice } from '@ww-digital/web-palette-react/dist/components/Slice/TabsLinkSlice/TabsLinkSlice';
import { SlicesContainer } from '../SlicesContainer/SlicesContainer.tsx';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { AppUtility } from '../../Utility/AppUtility.ts';
import { PricingUtility } from '../../Utility/PricingUtility.ts';

interface TabsLinkSliceContainerProps {
  daCategory: string;
  slice: TabsLinkSliceProps & {
    hero: SlicesContainerProps['slices'];
  };
  category?: string;
  region?: string;
  type: string;
}

const TabPricingRecurly = (tabItemInit: TabsLinkSliceProps['tabs'][0]) => {
  const tab = _.cloneDeep(tabItemInit);

  // Clean out any price tokens.
  if (PricingUtility.hasPricingToken(tab.subtitle)) {
    tab.subtitle = '';
  }

  return tab;
};

export const TabsLinkSliceContainer = ({
  daCategory,
  slice,
  category = '',
  region,
  type,
}: TabsLinkSliceContainerProps): JSX.Element => {
  const { hero } = slice;
  const sliceView = _.cloneDeep(slice);
  const { pathname } = useLocation();

  // Pricing for individual tabs.
  const tabItems: ReactNode[] = [];

  if (sliceView.tabs) {
    sliceView.tabs.forEach((tab) => {
      const pr = TabPricingRecurly(tab);
      tabItems.push(pr || tab);
    });

    sliceView.tabs = tabItems;
  }

  if (sliceView.tabs) {
    sliceView.tabs = sliceView.tabs.map((tab) => {
      // Analytics tracking for CTA buttons.
      tab.button.attributes = {};
      tab.button.attributes['da-category'] = daCategory;
      tab.button.attributes['da-action'] = AnalyticsUtility.formatAction(
        'tabs_links_item_cta',
        tab.button.text,
      );
      tab.button.attributes['da-label'] = AnalyticsUtility.formatLabel(
        tab.title,
      );

      // Set tab to 'active' if tab.url is current path.
      tab.active = AppUtility.matchActivePath(pathname, tab.url);

      return tab;
    });
  }

  return (
    <TabsLinkSlice {...sliceView}>
      <SlicesContainer
        slices={hero}
        category={category}
        type={type}
        region={region}
      />
    </TabsLinkSlice>
  );
};
