import React from 'react';
import _ from 'lodash';

import type {
  FaqSliceProps,
  FaqDataType,
} from '@ww-digital/web-palette-react/dist/components/Slice/FAQSlice/FAQSlice';

import { FaqSlice } from '@ww-digital/web-palette-react/dist/components/Slice/FAQSlice/FAQSlice';
import { JSONLD } from '../../Metadata/JSONLD/JSONLD.tsx';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

type JSONLDType = {
  '@type': 'Question';
  name: FaqDataType['question'];
  acceptedAnswer: {
    '@type': 'Answer';
    text?: string;
  };
  author: {
    '@type': 'Person' | 'Organization';
    name: string;
    jobTitle?: string;
  };
};

type JSONLDArrayType = (JSONLDType | JSONLDType[])[];

interface FAQSliceContainerProps {
  slice: FaqSliceProps;
  daCategory: string;
}

export const FAQSliceContainer = ({
  slice,
  daCategory,
}: FAQSliceContainerProps): JSX.Element => {
  const getJSONLD = () => {
    const { faqData } = slice;
    if (!faqData) return;

    const faqs: JSONLDArrayType = [];
    faqData.forEach((faq) => {
      const { answerType, answers, question } = faq;
      if (answerType === 'memberAnswers' && answers?.memberAnswers) {
        // generate duplicate objects with same questions and different answers
        faqs.push(
          answers?.memberAnswers?.map((answer) => {
            return {
              '@type': 'Question',
              name: question,
              acceptedAnswer: {
                '@type': 'Answer',
                text: AnalyticsUtility.toPlainText(answer.quote),
              },
              author: {
                '@type': 'Person',
                name: answer.headline,
                jobTitle: answer.subhead,
              },
            };
          }),
        );
      } else if (answerType === 'twoColumnAnswer') {
        faqs.push({
          '@type': 'Question',
          name: question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: AnalyticsUtility.toPlainText(
              answers?.twoColumnAnswer.body.text,
            ),
          },
          author: {
            '@type': 'Organization',
            name: 'WeightWatchers',
          },
        });
      }
    });

    const data = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: _.flattenDeep(faqs),
    };

    return data;
  };

  const processQuestion = (questionData: FaqDataType, index: number) => {
    if (!questionData) {
      return questionData;
    }

    const daLabel = AnalyticsUtility.formatLabel(slice?.heading?.text);
    const daAction = AnalyticsUtility.formatAction(
      'faq_click',
      `question ${index + 1} ${questionData.question}`,
    );

    questionData.buttonAttributes = {
      'da-category': daCategory,
      'da-action': daAction,
      'da-label': daLabel,
    };

    return questionData;
  };

  const jsonLD = getJSONLD();
  const faqData = _.cloneDeep(slice.faqData).map(processQuestion);

  return (
    <>
      <JSONLD key="jsonLd" content={jsonLD} addWebsiteName={false} />
      <FaqSlice {...slice} faqData={faqData} />
    </>
  );
};
