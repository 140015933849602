import { useContext } from 'react';

import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import wwUtility from '../../ww.utility.ts';

import { ExperimentContext } from '../../context/experiment.context.ts';
import { ExperimentContextType } from '../../context/experiment.context.ts';

export const ExecuteFunctions_GREX170 = (pathname: string, variant: string) => {
  // CSS Injection Settings
  const cssSetting = `
    /* Mains */
    .grex-170_v1 [class*="ChangeDurationItem_content__"] [class*="Text_typog-bodycopy__"] {
        font-family: Dashiell Bright, Dashiell Fine, Georgia, Times New Roman, Times, serif;
        color: #0e111d;
        font-weight: 700;
        font-size: 1.375rem;
        line-height: 1.75rem;
    }

    .grex-170_v1 [class*="RadioInput_container__"] [class*="Text_typog-headline5__"] {
        font-family: URW Form SemiCond, Helvetica, Arial, sans-serif;
        color: #0e111d;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .grex-170_v1 [class*="RadioInput_descriptionExpanded__"] [class*="PriceBreakdown_item__"],
    .grex-170_v1 [class*="RadioInput_descriptionExpanded__"] [class*="PriceBreakdown_horizontalRule__"],
    .grex-170_v1 [class*="RadioInput_descriptionExpanded__"] [class*="PriceBreakdown_total__"] {
      display: none;
    }

    .grex-170_v1 span.grex-170_v1.crossOutPrice {
      text-decoration: line-through;
      color: #c3c5c8;
    }

    .grex-170_v1 span.grex-170_v1.bolden {
      font-weight: bold;
    }

    /* Responsives */
    @media only screen and (min-width: 769px) {
        
        .grex-170_v1 [class*="ChangeDurationItem_content__"] [class*="Text_typog-bodycopy__"] {
            color: #0e111d;
            font-size: 1.5rem;
            line-height: 2rem;
        }
        
        .grex-170_v1 [class*="RadioInput_container__"] [class*="Text_typog-headline5__"] {
            color: #454545;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
  `,
    headElement = document.head || document.getElementsByTagName('head')[0],
    styleElement = document.createElement('style');

  // PlansDurationSlice ID
  const slice_one = document.getElementById(
    'slice-id--b1fb702c-9701-4c4c-8b4f-c95e21256544',
  );

  // Grabs cookies for us...
  const xsAudienceCookies = Storage.getCookieValue('xsAudienceCookies', false);

  const experimentData: ExperimentContextType = useContext(ExperimentContext);

  // Options for the observer (which mutations to observe)
  const config = { attributes: true, childList: true, subtree: true };

  // Run in both V1 and Control for Weight Loss Medication Page...
  if (
    (variant === 'control' || variant === 'v1') &&
    (pathname === '/us/clinic/weight-loss-medication' ||
      pathname === '/us/plans')
  ) {
    if (
      wwUtility.isBrowser() &&
      typeof xsAudienceCookies === 'string' &&
      !xsAudienceCookies.includes('XS-Template--GREX-170--1')
    ) {
      const xsCookiesListGeneration =
        xsAudienceCookies + '.XS-Template--GREX-170--1';
      document.cookie = `xsAudienceCookies=${xsCookiesListGeneration};expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
    }
    if (wwUtility.isBrowser() && xsAudienceCookies === null) {
      document.cookie = `xsAudienceCookies=XS-Template--GREX-170--1;expires=Thu, 01 Jan 2070 00:00:01 GMT;path=/;`;
    }
  }

  // Run for V1 only if the user has been to Weight Loss Medication page...
  if (variant === 'v1' && pathname === '/us/pricing/b') {
    if (experimentData?.cookies.includes('XS-Template--GREX-170--1')) {
      // Add CSS Injected tag into head of document
      headElement.appendChild(styleElement);
      styleElement.id = 'grex-170_v1';
      styleElement.appendChild(document.createTextNode(cssSetting));

      // Callback function to execute when mutations are observed
      const callback_one = (mutationList: any, observer_one: any) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            // Check if slice to be modified exists and hasn't already been modified...
            if (
              slice_one !== null &&
              !slice_one
                .querySelectorAll(
                  "[class*='ChangeDurationItem_content__'] [class*='Text_typog-bodycopy__'] span span",
                )[0]
                .classList.contains('grex-170_v1') &&
              // Checks for each item modified existing
              slice_one.querySelectorAll(
                "[class*='RadioInput_flag__'] [class*='RadioInput_flagText__'] span",
              )[0] !== undefined &&
              slice_one.querySelectorAll(
                "[class*='RadioInput_container__'] [class*='Text_typog-headline5__'] div",
              )[0] !== undefined &&
              slice_one.querySelectorAll(
                "[class*='RadioInput_container__'] [class*='Text_typog-headline5__'] div",
              )[1] !== undefined &&
              slice_one.querySelectorAll(
                "[class*='ChangeDurationItem_content__'] [class*='Text_typog-bodycopy__'] span",
              )[0] !== undefined &&
              slice_one.querySelectorAll(
                "[class*='ChangeDurationItem_content__'] [class*='Text_typog-bodycopy__']",
              )[1] !== undefined &&
              slice_one
                .querySelectorAll(
                  "[class*='ChangeDurationItem_content__'] [class*='Text_typog-bodycopy__']",
                )[1]
                .querySelectorAll('span')[0] !== undefined &&
              slice_one.querySelectorAll(
                "[class*='RadioInput_descriptionExpanded__'] [class*='PriceBreakdown_priceBreakdownHeader__']",
              )[0] !== undefined &&
              slice_one.querySelectorAll(
                "[class*='RadioInput_descriptionExpanded__'] [class*='PriceBreakdown_priceBreakdownHeader__']",
              )[1] !== undefined &&
              slice_one.querySelectorAll(
                "[class*='RadioInput_descriptionExpanded__'] [class*='PriceBreakdown_marketingMessage__']",
              )[0] !== undefined &&
              slice_one.querySelectorAll(
                "[class*='RadioInput_descriptionExpanded__'] [class*='PriceBreakdown_marketingMessage__']",
              )[1] !== undefined
            ) {
              // Updates to Offer Flag...
              if (
                slice_one.querySelectorAll(
                  "[class*='RadioInput_flag__'] [class*='RadioInput_flagText__'] span",
                )[0] !== undefined
              ) {
                slice_one.querySelectorAll(
                  "[class*='RadioInput_flag__'] [class*='RadioInput_flagText__'] span",
                )[0].innerHTML = 'Best value: $168 total savings';
              }

              // Updates to Plan Duration...
              slice_one.querySelectorAll(
                "[class*='RadioInput_container__'] [class*='Text_typog-headline5__'] div",
              )[0].innerHTML = '3-MONTH PLAN';
              slice_one.querySelectorAll(
                "[class*='RadioInput_container__'] [class*='Text_typog-headline5__'] div",
              )[1].innerHTML = 'MONTHLY PLAN';

              // Updates to both Price Per Month values...
              slice_one.querySelectorAll(
                "[class*='ChangeDurationItem_content__'] [class*='Text_typog-bodycopy__'] span",
              )[0].innerHTML =
                '<span class="grex-170_v1 crossOutPrice">$99</span> $84/month';
              slice_one
                .querySelectorAll(
                  "[class*='ChangeDurationItem_content__'] [class*='Text_typog-bodycopy__']",
                )[1]
                .querySelectorAll('span')[0].innerHTML =
                '<span>$99/month</span>';

              // Updates to both Incentive values...
              slice_one.querySelectorAll(
                "[class*='RadioInput_descriptionExpanded__'] [class*='PriceBreakdown_priceBreakdownHeader__']",
              )[0].innerHTML =
                '<span class="grex-170_v1 bolden">PLUS:</span> $0 for your first month';
              slice_one.querySelectorAll(
                "[class*='RadioInput_descriptionExpanded__'] [class*='PriceBreakdown_priceBreakdownHeader__']",
              )[1].innerHTML = '$0 for your first month';

              // Updates to Legal Disclaimer in each product box...
              slice_one.querySelectorAll(
                "[class*='RadioInput_descriptionExpanded__'] [class*='PriceBreakdown_marketingMessage__']",
              )[0].innerHTML =
                '$49 one-time Clinician consultation fee required. $84 billed every month until completion of 3-month plan and thereafter until you cancel.';
              slice_one.querySelectorAll(
                "[class*='RadioInput_descriptionExpanded__'] [class*='PriceBreakdown_marketingMessage__']",
              )[1].innerHTML =
                '$49 one-time Clinician consultation fee required. Plan auto renews at $99/mo until you cancel.';

              // Enact the Injected CSS Styles. Styles will not appear until this is injected as a safety mechanism.
              slice_one.classList.add('grex-170_v1');
            }
          }
        }
      };

      // Check for both Plans Duration Slice AND slice beneath Plans Duration Slice. Check for second slice to start mutationObserver as to not get stuck in an infinite loop...
      if (slice_one !== null) {
        // Create an observer instance linked to the callback function
        const observer_one = new MutationObserver(callback_one);

        // Start observing the target node for configured mutations
        observer_one.observe(slice_one, config);
      }
    }
  }
};
