import React, { ReactNode, useState } from 'react';

import { DisclaimerContext } from '../../context/disclaimer.context.ts';

interface DisclaimerProviderProps {
  children: ReactNode;
}

export const DisclaimerProvider = ({
  children,
}: DisclaimerProviderProps): JSX.Element | null => {
  const [disclaimer, setDisclaimer] = useState('');

  return (
    <DisclaimerContext.Provider value={{ disclaimer, setDisclaimer }}>
      {children}
    </DisclaimerContext.Provider>
  );
};
