import React from 'react';

import type { PromotionBannerSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/PromotionBannerSlice/PromotionBannerSlice';

import { PromotionBannerSlice } from '@ww-digital/web-palette-react/dist/components/Slice/PromotionBannerSlice/PromotionBannerSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface PromotionBannerSliceContainerProps {
  daCategory: string;
  slice: PromotionBannerSliceProps;
}

export const PromotionBannerSliceContainer = ({
  daCategory,
  slice,
}: PromotionBannerSliceContainerProps): JSX.Element => {
  if (slice.cta) {
    slice.cta.attributes = {};
    slice.cta.attributes['da-category'] = daCategory;
    slice.cta.attributes['da-action'] = AnalyticsUtility.formatAction(
      'promotion_banner_cta',
      slice.cta.text,
    );
    slice.cta.attributes['da-label'] = AnalyticsUtility.formatLabel(
      slice.cta.text,
    );
  }

  if (slice.floatingCTA?.cta) {
    slice.floatingCTA.cta.attributes = {};
    slice.floatingCTA.cta.attributes['da-category'] = daCategory;
    slice.floatingCTA.cta.attributes['da-action'] =
      AnalyticsUtility.formatAction(
        'promotion_banner_floatingCTA',
        slice.floatingCTA.cta.text,
      );
    slice.floatingCTA.cta.attributes['da-label'] = AnalyticsUtility.formatLabel(
      slice.floatingCTA.cta.text,
    );
  }

  if (slice.stickyBanner?.cta) {
    slice.stickyBanner.cta.attributes = {};
    slice.stickyBanner.cta.attributes['da-category'] = daCategory;
    slice.stickyBanner.cta.attributes['da-action'] =
      AnalyticsUtility.formatAction(
        'promotion_banner_stickyCTA',
        slice.stickyBanner.cta.text,
      );
    slice.stickyBanner.cta.attributes['da-label'] =
      AnalyticsUtility.formatLabel(slice.stickyBanner.cta.text);
  }

  return <PromotionBannerSlice {...slice} />;
};
