import React, { useContext } from 'react';
import _ from 'lodash';

import type { SourcesSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/SourcesSlice/SourcesSlice';
import type { SourceProps } from '@ww-digital/web-palette-react/dist/components/Source/Source';
import type { ConfigContextType } from '../../../context/config.context';

import { SourcesSlice } from '@ww-digital/web-palette-react/dist/components/Slice/SourcesSlice/SourcesSlice';
import { ConfigContext } from '../../../context/config.context.ts';
import { JSONLD } from '../../Metadata/JSONLD/JSONLD.tsx';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

export const getCitationJsonLd = (
  slice: SourcesSliceContainerProps['slice'],
) => {
  const { sources } = slice;

  return sources.map((source: SourceProps) => {
    const { text } = source;
    return {
      '@type': 'CreativeWork',
      citation: AnalyticsUtility.toPlainText(text),
    };
  });
};

interface SourcesSliceContainerProps {
  daCategory: string;
  slice: SourcesSliceProps;
  type: string;
}

export const SourcesSliceContainer = ({
  daCategory,
  slice,
  type,
}: SourcesSliceContainerProps): JSX.Element => {
  const { translations } = useContext<ConfigContextType>(ConfigContext);

  const getJSONLD = () => {
    const { sources } = slice;
    if (!sources) {
      return;
    }

    const citationJsonLd = getCitationJsonLd(slice);

    const data = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      name: 'Sources',
      citation: citationJsonLd,
    };

    return data;
  };

  const addButtonAttributes = (sliceData: SourcesSliceProps) => {
    if (!sliceData) {
      return sliceData;
    }

    const daLabel = AnalyticsUtility.formatLabel('See our sources');
    const daAction = AnalyticsUtility.formatAction(
      'souces_click',
      'See our sources',
    );

    sliceData.buttonAttributes = {
      'da-category': daCategory,
      'da-action': daAction,
      'da-label': daLabel,
    };

    return sliceData;
  };

  const jsonLD = getJSONLD();
  const updatedData = addButtonAttributes(_.cloneDeep(slice));
  const { buttonText, ...otherProps } = updatedData;

  return (
    <>
      {type !== 'article' && (
        <JSONLD key="jsonLd" content={jsonLD} addWebsiteName={false} />
      )}
      <SourcesSlice
        buttonText={translations.ARTICLE_SOURCES_BUTTON_TEXT}
        {...otherProps}
      />
    </>
  );
};
