import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import fetch from 'cross-fetch';
import XS from '@ww-digital/xs-sdk';
import _ from 'lodash';

import type { DocumentNode } from 'graphql';
import type { ApolloManagerContext } from './ApolloManager';

import { Authenticator } from '../../Utility/Authenticator.ts';
import { AppUtility } from '../../Utility/AppUtility.ts';
import wwUtility from '../../../ww.utility.ts';

import GetLocationByIpQuery from './graphql/GetLocationByIpQuery.graphql';

interface GeolocationData {
  country?: string;
  zipCode?: string;
  postalCode?: string;
}

interface GeolocationByIdQuery {
  geolocation: {
    country: string;
    zipCode: string;
  };
}

interface SelectedLocationData {
  locationId: string;
  locationName: string;
  locationSlug: string;
  zone: number;
  __typename?: string;
}

interface PricingLocationData {
  pricingLocation: SelectedLocationData;
}

type TestNullable<T> = { [P in keyof T]: T[P] | null };

interface UserPostalCodeData {
  userPostalCode: {
    __typename: string;
    postalCode: string | null;
  };
  pricingLocation: TestNullable<SelectedLocationData>;
}

interface GeolocationClient {
  query: ({
    query,
    variables,
  }: {
    query: DocumentNode;
    variables: { ip: string };
  }) => Promise<{ data: GeolocationByIdQuery }>;
}

const authenticator = new Authenticator();

let ipPromise: Promise<string> | null = null;
const getIpPromise = (country: string) => {
  if (ipPromise) {
    return ipPromise;
  }
  // Use the prod version of iplookup in all cases.
  const domain = AppUtility.getDomain(country, true, true);
  ipPromise = fetch(`${domain}/iplookup`)
    .then((data) => data.json())
    .then((json) => json.ip)
    .catch(() => null);

  return ipPromise;
};

const getUserIp = async (country: string) => {
  const lsIp = Storage.getItem('ip', country);
  if (lsIp !== null) {
    return lsIp;
  }

  return getIpPromise(country).then((ip) => {
    if (ip) {
      Storage.setItem('ip', ip, country);
    }
    return ip;
  });
};

const getGeolocation = async (country: string, client: GeolocationClient) => {
  const lsLocation = Storage.getItem('location', country) as GeolocationData;
  if (lsLocation) {
    return {
      country: lsLocation.country,
      // TODO: Remove fallback to lsLocation.postalCode next release
      // Because local storage value should expire after 24 hours.
      zipCode:
        'zipCode' in lsLocation ? lsLocation.zipCode : lsLocation.postalCode,
    };
  }

  const ip = (await getUserIp(country)) as string;
  if (!ip) {
    return null;
  }

  return client
    .query({
      query: GetLocationByIpQuery,
      variables: { ip },
    })
    .then(({ data }) => {
      const location = data ? _.omit(data.geolocation, '__typename') : null;
      // @ts-expect-error TODO fix null
      Storage.setItem('location', location, country);
      return location;
    })
    .catch(() => null);
};

export default function resolvers(siteContext: ApolloManagerContext) {
  return {
    Query: {
      userAuth: () =>
        authenticator.authenticate(siteContext.country, siteContext.language),

      userLocation: async (
        obj: unknown,
        args: unknown,
        { client }: { client: GeolocationClient },
      ) => {
        const location = await getGeolocation(siteContext.country, client);
        return location ? { ...location, __typename: 'UserLocation' } : null;
      },

      userPostalCode: () => {
        // Postal code used for pricing.
        const lsPostalCode = Storage.getItem('zip', siteContext.country);

        // Check local storage for user's physical location.
        if (lsPostalCode !== null) {
          return { postalCode: lsPostalCode, __typename: 'UserPostalCode' };
        }

        return null;
      },

      userGDPRApproved: () => {
        // If D7 cookie is set, use its value.
        const cookieAgreedValue = Storage.getCookieValue('cookie-agreed');
        if (cookieAgreedValue === 1 || cookieAgreedValue === 2) {
          return true;
        }

        return (
          Storage.getItem('userGDPRApproved', siteContext.country) || false
        );
      },

      geoBannerClosed: () =>
        Storage.getItem('geo_viewed', siteContext.country) || false,

      emailCaptureClosed: () =>
        Storage.getItem('email_capture_closed', siteContext.country) || false,

      pricingLocation: () => {
        // D7-compatible selectedLocation (object of fields, or null)
        const selectedLocation =
          (Storage.getItem(
            'selectedLocation',
            siteContext.country,
          ) as unknown as SelectedLocationData) || null;

        // Query data pricingLocation (always object of fields).
        const pricingLocation = selectedLocation
          ? selectedLocation
          : {
              locationId: null,
              locationName: null,
              locationSlug: null,
              zone: null,
            };

        return {
          ...pricingLocation,
          __typename: 'PricingLocation',
        };
      },
      xsExperiments: async () => {
        const baseUrl = wwUtility.getAPIDomain();

        let data = {
          experiments: {},
          __typename: 'XsExperiments',
        };

        try {
          const rawExperimentsRes = await fetch(
            `${baseUrl}/xs/all-variations?owner=UPF`,
          );
          const rawExperiments = await rawExperimentsRes.json();
          const normalizedExperiments = XS.normalizeExperiments(rawExperiments);

          data = {
            ...data,
            experiments: normalizedExperiments,
          };
        } catch (error) {
          console.error('Error fetching XS experiments', error);
        }

        return data;
      },
    },
    Mutation: {
      updateUserPostalCode: (
        obj: unknown,
        { postalCode }: { postalCode: string },
        {
          cache,
        }: {
          cache: {
            writeData: ({ data }: { data: UserPostalCodeData }) => void;
          };
        },
      ) => {
        const postalCodeValue = postalCode === '' ? null : postalCode;

        // Postal code used for pricing
        // @ts-expect-error TODO fix null
        Storage.setItem('zip', postalCodeValue, siteContext.country);

        // Clear location.
        // @ts-expect-error TODO fix null
        Storage.setItem('selectedLocation', null, siteContext.country);

        const data = {
          userPostalCode: {
            __typename: 'UserPostalCode',
            postalCode: postalCodeValue,
          },
          pricingLocation: {
            __typename: 'PricingLocation',
            locationId: null,
            locationName: null,
            locationSlug: null,
            zone: null,
          },
        };

        cache.writeData({ data });
        return data;
      },
      updateUserGDPRApproved: (
        obj: unknown,
        { userGDPRApproved }: { userGDPRApproved: string },
        {
          cache,
        }: {
          cache: {
            writeData: ({
              data,
            }: {
              data: { userGDPRApproved: string };
            }) => void;
          };
        },
      ) => {
        // TTL is 100 days.
        const ttl = 86400 * 100;

        // Save GDPR approval to Local Storage.
        Storage.setItem(
          'userGDPRApproved',
          userGDPRApproved,
          siteContext.country,
          ttl,
        );

        // For D7 compatibility, also set cookie named cookie-agreed
        // with path set to "/country".
        Storage.setCookieValue(
          'cookie-agreed',
          `${2}`,
          Storage.getExpireTime(ttl),
          `/${siteContext.country}`,
        );

        const data = {
          userGDPRApproved,
        };

        cache.writeData({ data });
        return userGDPRApproved;
      },
      updateGeoBannerClosed: (
        obj: unknown,
        { closed }: { closed: string },
        {
          cache,
        }: {
          cache: {
            writeData: ({
              data,
            }: {
              data: { geoBannerClosed: string };
            }) => void;
          };
        },
      ) => {
        Storage.setItem('geo_viewed', closed, siteContext.country);
        const data = {
          geoBannerClosed: closed,
        };

        cache.writeData({ data });
        return closed;
      },
      updateEmailCaptureClosed: (
        obj: unknown,
        { closed }: { closed: string },
        {
          cache,
        }: {
          cache: {
            writeData: ({
              data,
            }: {
              data: { emailCaptureClosed: string };
            }) => void;
          };
        },
      ) => {
        // Expire after 1 week = 7 * 24 * 60 * 60
        Storage.setItem(
          'email_capture_closed',
          closed,
          siteContext.country,
          604800,
        );

        const data = {
          emailCaptureClosed: closed,
        };

        cache.writeData({ data });
        return closed;
      },
      updateMeteredContentItems: (
        obj: unknown,
        { type, id, limit }: { type: string; id: string; limit: string },
        {
          cache,
        }: {
          cache: { writeData: ({ data }: { data: { count: number } }) => void };
        },
      ) => {
        const meteredContentItems =
          (Storage.getItem('metered_content', siteContext.country) as any) ||
          {};

        if (_.isUndefined(meteredContentItems[type])) {
          meteredContentItems[type] = [];
        }

        let count = 0;
        _.forEach(meteredContentItems, (value) => {
          count += value.length;
        });

        // Add item to storage if it isn't already there,
        // and we haven't reached the limit total.
        if (
          !_.includes(meteredContentItems[type], id) &&
          count <= parseInt(limit, 10)
        ) {
          meteredContentItems[type].push(id);
          count += 1;

          // Metered content storage expires at the end of the current month.
          // Resulting TTL is in seconds.
          const today = new Date();
          const lastDayOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0,
            23,
            59,
            59,
            999,
          );
          const ttl = Math.round(
            (lastDayOfMonth.valueOf() - today.valueOf()) / 1000,
          );

          Storage.setItem(
            'metered_content',
            meteredContentItems,
            siteContext.country,
            ttl,
          );

          const data = {
            count,
          };

          cache.writeData({ data });
        }

        return count;
      },
      updatePricingLocation: (
        obj: unknown,
        { locationId, locationName, locationSlug, zone }: SelectedLocationData,
        {
          cache,
        }: {
          cache: {
            writeData: ({ data }: { data: PricingLocationData }) => void;
          };
        },
      ) => {
        const location = locationId
          ? { locationId, locationName, locationSlug, zone }
          : null;

        // @ts-expect-error TODO fix null
        Storage.setItem('selectedLocation', location, siteContext.country);

        const data = {
          pricingLocation: {
            __typename: 'PricingLocation',
            locationId,
            locationName,
            locationSlug,
            zone,
          },
        };

        cache.writeData({ data });
        return data;
      },
    },
  };
}
