import React, { useContext, useRef } from 'react';
import _ from 'lodash';

import type { MarketContextType } from '../../../context/market.context';
import type { EntitlementContextType } from '../../../context/entitlement.context';
import type { ConfigContextType } from '../../../context/config.context';
import type { CardGridSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/CardGridSlice/CardGridSlice';

import Colors from '@ww-digital/web-palette-react/dist/components/Color/Colors';
import { CardGridSlice } from '@ww-digital/web-palette-react/dist/components/Slice/CardGridSlice/CardGridSlice';
import { Breakpoint } from '@ww-digital/web-palette-react/dist/components/Responsive/Breakpoint/Breakpoint';
import { ConfigContext } from '../../../context/config.context.ts';
import { MarketContext } from '../../../context/market.context.ts';
import { EntitlementContext } from '../../../context/entitlement.context.ts';
import { Counter } from '../../Utility/Counter.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { FoodUtility } from '../../Utility/FoodUtility.ts';

export interface FoodSuggestion {
  id: string;
  name: string;
  type: string;
  points: number;
  pointsInfo: {
    maxPointsLow: number;
    maxPointsHigh: number;
  };
  cardImage: {
    url: string;
  };
  slug: string;
}

export interface FoodSuggestionsContainerProps {
  suggestions: FoodSuggestion[];
}

export const FoodSuggestionsContainer = ({
  suggestions = [],
}: FoodSuggestionsContainerProps): JSX.Element | null => {
  const { country, language, marketBasePath } =
    useContext<MarketContextType>(MarketContext);
  const { entitlement, entitlementBasePath } =
    useContext<EntitlementContextType>(EntitlementContext);
  const { translations } = useContext<ConfigContextType>(ConfigContext);
  const sliceIndex = useRef<number>(
    Counter.getCounter('card-grids-slice-number'),
  );

  const recircSlice: CardGridSliceProps = {
    align: 'center',
    heading: {
      text: translations.FOOD_RECIRC_HEADING_TEXT,
      typog: 'headline4',
      variant: 'default',
      ariaLevel: 2,
    },
    background: {
      color: Colors.colorWW510,
    },
    cardList: [],
  };

  if (suggestions) {
    recircSlice.cardList = suggestions.map((item, index) => {
      const cardActionAttributes = AnalyticsUtility.formatCardAnalytics({
        type: AnalyticsUtility.cardTypes.TYPE_FOOD_CARD_GRID_RECOMMENDATION,
        pageIndex: 1, // always 1 as there is no pager for this type of slice.
        sliceIndex: sliceIndex.current, // card grid slice index on the page
        cardIndex: ++index, // card index in slice
        topLevelCategory: '', // top level category is absent here
        cardId: item.id ? item.id : '',
        cardTitle: item.name ? item.name : '',
        entitlement,
      });

      const pointsValues = { points: item.points };

      const pointsAriaLabels = {
        valueLabel: translations.POINTS_POINTS_COIN_ARIA_LABEL,
      };

      // Get Food Points Values and Labels
      const pointsData = FoodUtility.getPointsData(
        pointsValues,
        pointsAriaLabels,
      );

      return {
        image: _.get(item, 'cardImage.url', ''),
        link: `${marketBasePath}${entitlementBasePath}${FoodUtility.getURL(
          item,
          country,
          language,
        )}`,
        title: item.name,
        type: item.type,
        personalPoints: pointsData,
        cardActionAttributes,
      };
    });
  }

  return recircSlice.cardList && recircSlice.cardList.length ? (
    <Breakpoint breakpoint="mobile">
      <CardGridSlice
        type="food"
        cardList={recircSlice.cardList}
        heading={recircSlice.heading}
        background={recircSlice.background}
      />
      <CardGridSlice
        type="food"
        cardList={recircSlice.cardList.slice(0, 3)}
        heading={recircSlice.heading}
        background={recircSlice.background}
      />
    </Breakpoint>
  ) : null;
};
