import _ from 'lodash';

export interface ConfigTranslationsLocale {
  ARTICLE_RECIRC_HEADING_TEXT: string;
  ARTICLE_AUTHORS: string;
  ARTICLE_MED_REVIEWERS: string;
  ARTICLE_SCI_REVIEWERS: string;
  ARTICLE_FACT_CHECKERS: string;
  ARTICLE_NAMES_SEPARATOR: string;
  ARTICLE_LAST_NAME_SEPARATOR: string;
  ARTICLE_PUBLISHED: string;
  ARTICLE_UPDATED: string;
  ARTICLE_BLOG_BREADCRUMB_TITLE: string;
  ARTICLE_SOURCES_BUTTON_TEXT: string;
  ARTICLE_BYLINE_DEFAULT_AUTHOR: string;
  BMI_HEIGHT_LABEL: string;
  BMI_WEIGHT_LABEL: string;
  BMI_VALUE_LABEL: string;
  BMI_STANDARD: string;
  BMI_IMPERIAL: string;
  BMI_METRIC: string;
  CATEGORY_GRID_TITLE: string;
  CONTRIBUTOR_GRID_TITLE: string;
  CONTRIBUTOR_GRID_TITLE_REVIEWER: string;
  CONTRIBUTOR_AUTHOR_OVERVIEW_LINK_TEXT: string;
  CONTRIBUTOR_REVIEWER_OVERVIEW_LINK_TEXT: string;
  COUNTDOWN_DAY_SINGULAR: string;
  COUNTDOWN_DAY_PLURAL: string;
  COUNTDOWN_HOUR_SINGULAR: string;
  COUNTDOWN_HOUR_PLURAL: string;
  COUNTDOWN_MINUTE_SINGULAR: string;
  COUNTDOWN_MINUTE_PLURAL: string;
  EMAIL_CAPTURE_EMAIL_ADDRESS_PLACEHOLDER: string;
  EMAIL_CAPTURE_FIRST_NAME_PLACEHOLDER: string;
  EMAIL_CAPTURE_LAST_NAME_PLACEHOLDER: string;
  EMAIL_CAPTURE_NAME_PLACEHOLDER: string;
  EMAIL_CAPTURE_TELEPHONE_PLACEHOLDER: string;
  EMAIL_CAPTURE_EMAIL_ADDRESS_ERROR: string;
  EMAIL_CAPTURE_EMAIL_ADDRESS_ERROR_VALID: string;
  EMAIL_CAPTURE_NAME_ERROR: string;
  EMAIL_CAPTURE_TELEPHONE_ERROR: string;
  EMAIL_CAPTURE_CHECKBOX_ERROR: string;
  FOOD_COOK_LABEL: string;
  FOOD_DIFFICULTY_LABEL: string;
  FOOD_IMAGE_OF_TEXT: string;
  FOOD_INGREDIENTS_LABEL: string;
  FOOD_INSTRUCTIONS_LABEL: string;
  FOOD_MEAL_ITEMS_LABEL: string;
  FOOD_MIN_FORMAT: string;
  FOOD_HOUR_FORMAT: string;
  FOOD_TIME_FORMAT: string;
  FOOD_NOTES_LABEL: string;
  FOOD_PREP_LABEL: string;
  FOOD_RECIRC_HEADING_TEXT: string;
  FOOD_SERVES_LABEL: string;
  FOOD_TOTAL_TIME_LABEL: string;
  FOOD_DESCRIPTION_READ_MORE: string;
  FOOD_DESCRIPTION_READ_LESS: string;
  FOOD_ALT_TAG: string;
  FOOD_INFO_LABEL: string;
  GDPR_AGREE_BUTTON_TEXT: string;
  GDPR_BODY_TEXT: string;
  GDPR_DISAGREE_BUTTON_TEXT: string;
  GDPR_HEADING_TEXT: string;
  GEOBANNER_CLOSE_LABEL: string;
  GEOBANNER_CONTENT_LABEL: string;
  GEOBANNER_FLAG_LABEL: string;
  GEOBANNER_OR_LABEL: string;
  GLOBAL_FACEBOOK_LABEL: string;
  GLOBAL_TWITTER_LABEL: string;
  GLOBAL_PINTEREST_LABEL: string;
  GLOBAL_LINKEDIN_LABEL: string;
  GLOBAL_FOOTER_FLAG_LABEL: string;
  GLOBAL_PLAY_TITLE: string;
  GLOBAL_PAUSE_TITLE: string;
  INTERNATIONAL_PAGE_HEADING: string;
  INTERNATIONAL_PAGE_FOOTNOTE: string;
  LOCATION_SELECTION_MODAL_HEADLINE: string;
  LOCATION_SELECTION_MODAL_INPUT_PLACEHOLDER: string;
  LOCATION_SELECTION_MODAL_SUBMIT_BUTTON: string;
  METATAG_SITE_NAME: string;
  METATAG_RECIPE_TITLE: string;
  METATAG_RECIPE_DESCRIPTION: string;
  METATAG_MEAL_TITLE: string;
  METATAG_MEAL_DESCRIPTION: string;
  METERED_CONTENT_ASIDE_TEXT: string;
  METERED_CONTENT_CTA_TEXT: string;
  METERED_CONTENT_FOOTNOTE: string;
  METERED_CONTENT_HEADLINE: string;
  METERED_CONTENT_LOGIN_TEXT: string;
  METERED_CONTENT_PRIMARY_LABEL: string;
  NAVIGATION_CLOSE_LABEL: string;
  NAVIGATION_CLOSE_SUB_MENU_LABEL: string;
  NAVIGATION_OPEN_LABEL: string;
  NAVIGATION_OPEN_SUB_MENU_LABEL: string;
  NAVIGATION_SKIP_TO_FOOTER: string;
  NAVIGATION_SKIP_TO_MAIN: string;
  NAVIGATION_PREV_LABEL: string;
  NAVIGATION_NEXT_LABEL: string;
  NAVIGATION_PREV_TITLE: string;
  NAVIGATION_NEXT_TITLE: string;
  NAVIGATION_ITEM_TITLE: string;
  NEWSLETTER_EMAIL_EXISTS_LABEL: string;
  NEWSLETTER_EMAIL_INPUT_PLACEHOLDER_TEXT: string;
  NEWSLETTER_EMAIL_INPUT_SUBMIT_BUTTON_TEXT: string;
  NEWSLETTER_EMAIL_INVALID_LABEL: string;
  NEWSLETTER_SUBSCRIPTION_CONFIRMED_LABEL: string;
  NEWSLETTER_SYSTEM_UNAVAILABLE_LABEL: string;
  POINTS_POINTS_COIN_ARIA_LABEL: string;
  POINTS_POINTS_COIN_SERVING_TEXT: string;
  POINTS_POINTS_COIN_ARIA_LABEL_PLURAL: string;
  POINTS_POINTS_COIN_SERVING_TEXT_PLURAL: string;
  POINTS_POINTS_TOOLTIP_CONTENT: string;
  POINTS_POINTS_TOOLTIP_LINK_TEXT: string;
  PRICING_ENTER_ZIP_LABEL: string;
  PRICING_INPUT_BUTTON_TEXT: string;
  PRICING_INPUT_ERROR: string;
  PRICING_INPUT_PLACEHOLDER: string;
  PRICING_LOCATION_UNAVAILABLE_LABEL: string;
  PRICING_PROMO_UNAVAILABLE_LABEL: string;
  PRICING_ZIP_TEXT: string;
  PRICING_MODAL_TITLE: string;
  PRICING_MODAL_CANCEL: string;
  PRICING_MODAL_OPEN_LINK: string;
  PRICING_DURATION_TITLE: string;
  PRICING_DURATION_NEXT: string;
  PRICING_TOOLTIP_LABEL: string;
  PRICING_PROMO_CODE_CHECKOUT_LABEL: string;
  PRODUCTS_ADDONS_HEADLINE: string;
  PRODUCTS_ADDON_CLINICAL_HEADLINE: string;
  PRODUCTS_ADDON_CLINICAL_ASSESSMENT_LINK_LABEL: string;
  PRODUCTS_ADDON_CLINICAL_ADD_LINK_LABEL: string;
  PRODUCTS_ADDON_CLINICAL_YOU_QUALIFY: string;
  PRODUCTS_ADDON_CLINICAL_RIBBON: string;
  PRODUCTS_ADDON_CLINICAL_ADD_URL: string;
  PRODUCTS_ADDON_CLINICAL_ASSESSMENT_URL: string;
  PRODUCTS_CLINICAL_TOGGLE_LABEL: string;
  PRODUCTS_CLINICAL_TOGGLE_ADD: string;
  PRODUCTS_CLINICAL_TOGGLE_REMOVE: string;
  PRODUCTS_CLINICAL_UNASSESSED_URL: string;
  PRODUCTS_CLINICAL_INELIGIBLE_URL: string;
  PRODUCTS_PRICE_BREAKDOWN_HEADLINE: string;
  PRODUCTS_WORKSHOPS_PATH: string;
  VIDEO_MORE: string;
  VIDEO_LESS: string;
}

export const ConfigTranslations = {
  getDefaultValue: () => ({}),
  getValue: (data?: ConfigTranslationsLocale) => {
    return data as ConfigTranslationsLocale;
  },
};
