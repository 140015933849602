import React, { useContext, useRef } from 'react';
import _ from 'lodash';

import type { VideoMastheadSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/VideoMastheadSlice/VideoMastheadSlice';
import type { MarketContextType } from '../../../context/market.context';

import { VideoMastheadSlice } from '@ww-digital/web-palette-react/dist/components/Slice/VideoMastheadSlice/VideoMastheadSlice';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { PricingUtility } from '../../Utility/PricingUtility.ts';
import { MarketContext } from '../../../context/market.context.ts';

interface VideoMastheadSliceContainerProps {
  slice: VideoMastheadSliceProps;
  daCategory: string;
}

const VideoMastheadPricingRecurly = (
  toutInit: VideoMastheadSliceProps['tout'],
  toutPricing: React.MutableRefObject<VideoMastheadSliceProps['tout']>,
): void => {
  const marketContext = useContext<MarketContextType>(MarketContext);
  const tout = _.cloneDeep(toutInit);

  if (!tout) {
    return;
  }

  // If Pricing Enabled, use standard recurly checkout URL.
  if (tout.pricing && tout.button?.text) {
    tout.button.url = PricingUtility.getRecurlyPricingUrl(
      marketContext.country,
      marketContext.language,
    );
  }

  // Clean out any price tokens.
  if (
    PricingUtility.hasPricingToken(tout.pricingText) ||
    PricingUtility.hasPricingToken(tout.subheadingPrice)
  ) {
    tout.subheadingPrice = '';
  }

  toutPricing.current = tout;
};

export const VideoMastheadSliceContainer = ({
  slice,
  daCategory,
}: VideoMastheadSliceContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);
  const toutPricing = useRef<VideoMastheadSliceProps['tout']>();

  // Pricing for the intro.
  VideoMastheadPricingRecurly(sliceView.tout, toutPricing);
  sliceView.tout = toutPricing.current || sliceView.tout;

  const daAction = AnalyticsUtility.formatAction(
    'tout_cta',
    slice.tout.button.text,
  );
  const daLabel = AnalyticsUtility.formatLabel([
    slice.tout.heading.text,
    slice.tout.bodyText,
  ]);

  if (sliceView?.tout?.button) {
    sliceView.tout.button.attributes = {};
    sliceView.tout.button.attributes['da-category'] = daCategory;
    sliceView.tout.button.attributes['da-action'] = daAction;
    sliceView.tout.button.attributes['da-label'] = daLabel;
  }

  if (sliceView?.tout?.secondButton) {
    const daAction2 = AnalyticsUtility.formatAction(
      'tout_second_cta',
      slice.tout.secondButton.text,
    );

    sliceView.tout.secondButton.attributes = {};
    sliceView.tout.secondButton.attributes['da-category'] = daCategory;
    sliceView.tout.secondButton.attributes['da-action'] = daAction2;
    sliceView.tout.secondButton.attributes['da-label'] = daLabel;
  }

  return <VideoMastheadSlice {...sliceView} />;
};
