import React, { ReactNode } from 'react';
import { useQuery } from 'react-apollo';
import { useLocation, useRouteMatch } from 'react-router-dom';
import pathToRegexp from 'path-to-regexp';

import {
  SectionContext,
  defaultSectionContext,
} from '../../../context/section.context.ts';

import SectionByPathQuery from './graphql/SectionByPathQuery.graphql';

interface SectionProviderProps {
  children: ReactNode;
}

export const SectionProvider = ({
  children,
}: SectionProviderProps): JSX.Element | null => {
  const match = useRouteMatch();
  const location = useLocation();

  // Get the relative URL path by stripping off the country and entitlements.
  const getRelativePath = () => {
    const pathRegex = pathToRegexp(match.path, [], { end: false });

    return location.pathname.replace(pathRegex, '').replace(/^\/+/, '');
  };
  const path = getRelativePath();

  const { loading, error, data } = useQuery(SectionByPathQuery, {
    variables: { path },
  });

  if (loading || error || !data) {
    return null;
  }

  const section = data?.sectionByPath?.machineName || defaultSectionContext;

  return (
    <SectionContext.Provider value={section}>
      {children}
    </SectionContext.Provider>
  );
};
