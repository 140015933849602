import React from 'react';

import type { ConfigPathType } from '../components/Config/ConfigPath/ConfigPath';
import type { ConfigType } from '../components/Config/Config/Config';
import type { ConfigTranslationsLocale } from '../components/Config/ConfigTranslations/ConfigTranslations';

import { ConfigPath } from '../components/Config/ConfigPath/ConfigPath.tsx';
import { Config } from '../components/Config/Config/Config.tsx';
import { ConfigTranslations } from '../components/Config/ConfigTranslations/ConfigTranslations.tsx';

export type ConfigContextType = {
  paths: ConfigPathType;
  config: ConfigType;
  translations: ConfigTranslationsLocale;
};

const defaultConfigContext: ConfigContextType = {
  paths: ConfigPath.getDefaultValue(),
  config: Config.getDefaultValue() as ConfigType,
  translations:
    ConfigTranslations.getDefaultValue() as ConfigTranslationsLocale,
};

export const ConfigContext =
  React.createContext<ConfigContextType>(defaultConfigContext);
