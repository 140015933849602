import {
  AttributeType,
  TemplatesAttributeType,
} from '../../../hooks/useExperiments';
import { SliceContainerProps } from '../../Slices/SliceContainer/SliceContainer';
import { SlicesContainerProps } from '../../Slices/SlicesContainer/SlicesContainer';

import { getAlterPropsByDate } from './getAlterPropsByDate.ts';
import { getDynamicAlterProps } from './getDynamicAlterProps.ts';
import _ from 'lodash';

export const SLICE_ALTER_METHODS = {
  update: 'update',
  updateMerge: 'updateMerge',
  replace: 'replace',
  remove: 'remove',
  inject: 'inject',
  shift: 'shift',
};

export const alterSlice = (
  alteredSlice: SliceContainerProps['slice'],
  attribute: TemplatesAttributeType,
  configuration: AttributeType['configuration'],
  experimentTextId: string,
  slices?: SlicesContainerProps['slices'],
) => {
  let alterProps = attribute.alterProps;

  // get conditional alterProps if there are conditions
  if (attribute.conditionalAlterProps) {
    const conditionalAlterProps = attribute.conditionalAlterProps;
    for (const alterPropPath in conditionalAlterProps) {
      const conditionalAlterProp = conditionalAlterProps[alterPropPath];
      const { conditions } = conditionalAlterProp;
      const defaultValue = _.get(alteredSlice, alterPropPath);
      if (conditions.isTruthy === true && !!defaultValue) {
        alterProps[alterPropPath] = conditionalAlterProp.value;
      }
    }
  }

  // get date-altered props if there are dates
  if (attribute.dates?.length && configuration?.globalCalendar) {
    const dateAlteredProps = getAlterPropsByDate(
      attribute.dates,
      configuration.globalCalendar,
    );
    // if dateAlteredProps.alterProps is null: date matched, no changes - don't alter the slice props
    // if dateAlteredProps is undefined: no date matched - use the default alterProps
    // if dateAlteredProps.alterProps is an object: date matched, with changes - alter the slice props with dateAlteredProps
    if (dateAlteredProps?.alterProps === null) {
      return;
    } else if (dateAlteredProps?.alterProps) {
      alterProps = dateAlteredProps.alterProps;
      if (dateAlteredProps.variableProps) {
        getDynamicAlterProps(attribute.variableProps, alterProps, slices);
      }
    } else if (
      dateAlteredProps === undefined &&
      attribute.config.hasVariableProps
    ) {
      getDynamicAlterProps(attribute.variableProps, alterProps, slices);
    }
  } else if (attribute.config.hasVariableProps) {
    getDynamicAlterProps(attribute.variableProps, alterProps, slices);
  }
  // alter the slice props based on the alterMethod
  switch (attribute.alterMethod) {
    case SLICE_ALTER_METHODS.update:
      for (const propPath in alterProps) {
        _.set(alteredSlice, propPath, alterProps[propPath]);
      }
      if (
        !!attribute.config.fireExposure ||
        attribute.config.fireExposure === undefined
      ) {
        alteredSlice.xsExperimentTextId = experimentTextId;
      }
      break;
    case SLICE_ALTER_METHODS.updateMerge:
      for (const propPath in alterProps) {
        _.merge(alteredSlice, { [propPath]: alterProps[propPath] });
      }
      if (
        !!attribute.config.fireExposure ||
        attribute.config.fireExposure === undefined
      ) {
        alteredSlice.xsExperimentTextId = experimentTextId;
      }
      break;
    case SLICE_ALTER_METHODS.replace:
      slices && slices.splice(slices.indexOf(alteredSlice), 1, alterProps);
      if (
        !!attribute.config.fireExposure ||
        attribute.config.fireExposure === undefined
      ) {
        alteredSlice.xsExperimentTextId = experimentTextId;
      }
      break;
    case SLICE_ALTER_METHODS.remove:
      slices && slices.splice(slices.indexOf(alteredSlice), 1);
      if (
        !!attribute.config.fireExposure ||
        attribute.config.fireExposure === undefined
      ) {
        alteredSlice.xsExperimentTextId = experimentTextId;
      }
      break;
    case SLICE_ALTER_METHODS.inject:
      slices &&
        typeof attribute.injectionIndex === 'number' &&
        slices.splice(
          slices.indexOf(alteredSlice) + attribute.injectionIndex,
          0,
          alterProps,
        );
      if (
        !!attribute.config.fireExposure ||
        attribute.config.fireExposure === undefined
      ) {
        alteredSlice.xsExperimentTextId = experimentTextId;
      }
      break;
    case SLICE_ALTER_METHODS.shift: {
      const sliceToShiftIndex = slices?.findIndex(
        (slice: any) => slice.id === alterProps.id,
      );
      const alteredSliceIndex = slices?.indexOf(alteredSlice);

      if (
        sliceToShiftIndex !== -1 &&
        alteredSliceIndex !== -1 &&
        sliceToShiftIndex !== alteredSliceIndex
      ) {
        const [sliceToShift] = slices.splice(sliceToShiftIndex, 1); // Remove sliceToShift from its original position
        const newAlteredSliceIndex = slices.indexOf(alteredSlice); // Find the new index of alteredSlice
        slices.splice(newAlteredSliceIndex, 0, sliceToShift); // Insert sliceToShift at the new index of alteredSlice
        if (
          !!attribute.config.fireExposure ||
          attribute.config.fireExposure === undefined
        ) {
          sliceToShift.xsExperimentTextId = experimentTextId;
        }
      }
      break;
    }
  }
};
