import wwUtility from '../../ww.utility.ts';

export const navUpdates = () => {
  if (wwUtility.isBrowser() && localStorage.getItem('hpVisit') === 'true') {
    //hp not visited
    //remove the clinic subnav item & change the workshops subnav item copy
    const hideJoinNowCta = () => {
      // update Workshops
      const joinNowCta = document.querySelector(
        '[class*="NavigationSlice_containerRight__"] [class*="NavigationSlice_cta__"][href*="/us/plans"]',
      );

      const joinNowCtaMobile = document.querySelector(
        '[class*="NavigationSlice_mobileWrapper__"] [class*="Slideout_slideout__"] [class*="Slideout_content__"] [da-label*="Join now"]',
      );

      if ((joinNowCta as HTMLElement)?.style) {
        (joinNowCta as HTMLElement).style.display = 'none';
      }

      if ((joinNowCtaMobile as HTMLElement)?.style) {
        (joinNowCtaMobile as HTMLElement).style.display = 'none';
      }

      if (
        (joinNowCtaMobile as HTMLElement)?.style?.display === 'none' &&
        (joinNowCta as HTMLElement)?.style?.display === 'none'
      ) {
        observer.disconnect();
      }
    };

    const rootElement = document.querySelector('#root');
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(() => {
      hideJoinNowCta();
    });

    rootElement && hideJoinNowCta();
    rootElement && observer.observe(rootElement, config);
  }
};
