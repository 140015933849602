import { BentoBoxHomepageProps } from '@ww-digital/web-palette-react/dist/components/BentoBoxHomepage/BentoBoxHomepage';
import {
  headingSection,
  primaryCardOne,
  primaryCardTwo,
} from './BentoBoxSliceData.ts';

export const footerSection: BentoBoxHomepageProps['footerSection'] = {
  secondaryButton: {
    children: 'View pricing',
    type: 'secondary',
    size: 'medium',
    url: 'https://www.weightwatchers.com/us/plans',
    accessibilityLabel: 'View pricing',
  },
  version: 'v2',
};

export const bentoBoxHomepageData: BentoBoxHomepageProps = {
  headingSection,
  footerSection,
  bentoBoxSectionV2: {
    primaryCardOne,
    primaryCardTwo,
  },
  version: 'v2',
};
