import React, { useContext, useRef } from 'react';
import _ from 'lodash';

import type { LargeProductSquareSliceProps } from '@ww-digital/web-palette-react/dist/components/Slice/LargeProductSquareSlice/LargeProductSquareSlice';
import type { LargeProductSquareProps } from '@ww-digital/web-palette-react/dist/components/Square/LargeProductSquare/LargeProductSquare';
import type { MarketContextType } from '../../../context/market.context';

import { LargeProductSquareSlice } from '@ww-digital/web-palette-react/dist/components/Slice/LargeProductSquareSlice/LargeProductSquareSlice';

import { MarketContext } from '../../../context/market.context.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';
import { PricingUtility } from '../../Utility/PricingUtility.ts';

interface LargeProductSquareSliceContainerProps {
  daCategory: string;
  slice: LargeProductSquareSliceProps;
}

const ProductSquareRecurly = (
  productSquareInit: LargeProductSquareProps,
  productSquareIndex: number,
  productSquaresPricing: React.MutableRefObject<LargeProductSquareProps[]>,
): void => {
  const marketContext = useContext<MarketContextType>(MarketContext);
  const productSquare = _.cloneDeep(productSquareInit);

  // If Pricing Enabled, use standard recurly checkout URL.
  if (productSquare.cta && productSquare.pricing) {
    productSquare.cta.url = PricingUtility.getRecurlyPricingUrl(
      marketContext.country,
      marketContext.language,
    );
  }

  // Clean out any price tokens.
  if (PricingUtility.hasPricingToken(productSquare.subhead)) {
    productSquare.subhead = '';
  }

  productSquaresPricing.current[productSquareIndex] = productSquare;
};

export const LargeProductSquareSliceContainer = ({
  daCategory,
  slice,
}: LargeProductSquareSliceContainerProps): JSX.Element => {
  const sliceView = _.cloneDeep(slice);
  const productSquaresPricing = useRef<LargeProductSquareProps[]>(
    sliceView.productSquares || [],
  );

  if (sliceView.productSquares) {
    sliceView.productSquares.forEach((productSquare, productIndex) => {
      ProductSquareRecurly(productSquare, productIndex, productSquaresPricing);

      if (productSquare.cta) {
        // Analytics tracking for CTA buttons.
        productSquare.cta.attributes = {};
        productSquare.cta.attributes['da-category'] = daCategory;
        productSquare.cta.attributes['da-action'] =
          AnalyticsUtility.formatAction(
            'large_product_squares_item_cta',
            productSquare.cta.text,
          );
        productSquare.cta.attributes['da-label'] = AnalyticsUtility.formatLabel(
          productSquare.title,
        );
      }

      if (productSquare.learn) {
        // Analytics tracking for Learn buttons.
        productSquare.learn.attributes = {};
        productSquare.learn.attributes['da-category'] = daCategory;
        productSquare.learn.attributes['da-action'] =
          AnalyticsUtility.formatAction(
            'large_product_squares_item_learn',
            productSquare.learn.text,
          );
        productSquare.learn.attributes['da-label'] =
          AnalyticsUtility.formatLabel(productSquare.title);
      }
    });

    sliceView.productSquares = productSquaresPricing.current;
  }

  return <LargeProductSquareSlice {...sliceView} />;
};
