import React, { useContext } from 'react';

import type { DisclaimerContextType } from '../../../context/disclaimer.context';

import { BodySlice } from '@ww-digital/web-palette-react/dist/components/Slice/BodySlice/BodySlice';
import { DisclaimerContext } from '../../../context/disclaimer.context.ts';

import styles from './PlansDisclaimerSliceContainer.module.scss';

export const PlansDisclaimerSliceContainer = (): JSX.Element => {
  const { disclaimer } = useContext<DisclaimerContextType>(DisclaimerContext);

  const sliceView = {
    headline: {
      align: 'left',
      ariaLevel: 3,
      text: null,
      typog: 'headline5',
      variant: 'default',
    },
    body: disclaimer,
  };

  return (
    <div className={styles.container}>
      <BodySlice {...sliceView} />
    </div>
  );
};
