import { MarketContextType } from './context/market.context';
export interface VariationItemType {
  path?: string;
  xs?: string;
  excludeFranchise?: boolean;
  excludeNACO?: boolean;
}
export interface TestItemType {
  path: string;
  tests?: VariationItemType[];
}

export type TestConfig = Record<
  MarketContextType['country'],
  Record<string, TestItemType[]>
>;

export const wwTests: TestConfig = {
  au: {
    en: [],
  },
  be: {
    nl: [],
    fr: [],
  },
  ca: {
    en: [],
    fr: [],
  },
  ch: {
    de: [],
    fr: [],
  },
  de: {
    de: [],
  },
  fr: {
    fr: [],
  },
  nl: {
    nl: [],
  },
  nz: {
    en: [],
  },
  se: {
    sv: [],
  },
  uk: {
    en: [],
  },
  us: {
    en: [],
  },
};
