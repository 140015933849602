import wwUtility from '../../ww.utility.ts';

export const hidePricingNavItem = () => {
  if (wwUtility.isBrowser() && localStorage.getItem('hpVisit') === 'true') {
    const hidePricingNavItemCallback = () => {
      const pricingNavItems = document.querySelectorAll(
        '[class*="NavigationSlice_container__"] [da-label="Pricing"]',
      );

      pricingNavItems.forEach((pricingNavItem) => {
        if ((pricingNavItem as HTMLElement)?.style) {
          (pricingNavItem as HTMLElement).style.display = 'none';
        }
      });

      if (
        pricingNavItems.length === 3 &&
        Array.from(pricingNavItems).every(
          (item) => (item as HTMLElement)?.style?.display === 'none',
        )
      ) {
        observer.disconnect();
      }
    };

    const rootElement = document.querySelector('#root');
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(() => {
      hidePricingNavItemCallback();
    });

    rootElement && hidePricingNavItemCallback();
    rootElement && observer.observe(rootElement, config);
  }
};
