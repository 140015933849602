import _ from 'lodash';
import decode from 'unescape';

import type { EntitlementContextType } from '../../context/entitlement.context';

import { EntitlementUtility } from '../Utility/EntitlementUtility.ts';

/**
 * Replace any character that is not a letter, number, or unicode 00A1-FFFF
 * https://api.drupal.org/api/drupal/includes%21common.inc/function/drupal_clean_css_identifier/7.x
 * @param {string} text             String to format
 * @param {boolean} replaceHyphens  Whether to replace consecutive hyphens.
 */
function formatIdentifier(text?: string, replaceHyphens = true) {
  if (!text) {
    return '';
  }
  let newText = text
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9\u00A1-\uFFFF]/g, (c) => {
      if (_.includes([' ', '/', '_', '-', '['], c)) return '-';
      return '';
    });

  if (replaceHyphens) {
    newText = newText.replace(/-+/gi, '-');
  }

  return newText;
}

function toPlainText(label?: string) {
  return htmlToPlainText(richTextJSONToPlainText(label));
}

/**
 * Return human-readable text from html
 */
function htmlToPlainText(label?: string) {
  if (typeof label !== 'string') {
    return label;
  }

  // Strip HTML tags.
  const sanitizedHTML = label.replace(/<[^>]+>/g, '');

  // Using NPM package unescape. Docs can be found here:
  // https://www.npmjs.com/package/unescape

  return decode(sanitizedHTML, 'all');
}

function isRichTextJSON(str?: string) {
  if (typeof str !== 'string') {
    return false;
  }
  // All RichText JSON starts with "[{", so check that
  // first for better performance than full JSON parse.
  if (!str.startsWith('[{')) {
    return false;
  }

  return isJSON(str);
}

/**
 * Determine if string is JSON
 */
function isJSON(str?: string) {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Return human-readable text from JSON, if JSON
 */
function richTextJSONToPlainText(jsonString?: string) {
  if (!isRichTextJSON(jsonString)) {
    return jsonString;
  }

  const parsedData = JSON.parse(jsonString || '');
  // Get all values from JSON whose key is 'text'.
  return getPropertyRecursive(parsedData, 'text')
    .map((str: string) => (typeof str === 'string' ? str.trim() : ''))
    .join(' ');
}

// Recursively traverse object to get all values with specified key.
function getPropertyRecursive(obj: Record<string, any>, prop: string): any {
  return _.reduce(
    obj,
    function (result: Record<string, any>, value, key) {
      if (key === prop) {
        result.push(value);
      } else if (_.isObjectLike(value)) {
        return result.concat(getPropertyRecursive(value, prop));
      }
      return result;
    },
    [],
  );
}

/**
 * Format the da-category attribute.
 */
function formatCategory(
  entitlement: EntitlementContextType['entitlement'],
  type: string,
  region = '',
  category = '',
) {
  // For legacy tracking purposes,
  // entitlement in category should be visitor not guest.
  const categoryEntitlement =
    entitlement === EntitlementUtility.entitlements.guest
      ? 'visitor'
      : entitlement.toLowerCase();

  // Filter out empty items and then join in a certain order.
  return [categoryEntitlement, type, region, category]
    .filter((item) => item)
    .join(':');
}

/**
 * Format the da-action attribute.
 */
function formatAction(prefix: string, title?: string) {
  let action = prefix;
  if (title) {
    const safeTitle = formatIdentifier(toPlainText(title));
    action = `${prefix}_${safeTitle}`;
  }

  // Grab the first 100 characters of the action and clean dangling special chars.
  return action.slice(0, 100).replace(/-+$/g, '');
}

/**
 * Format the da-label attribute
 * with specified length.
 */
function formatLabelWithLength(length: number, labels?: string | string[]) {
  // Get the first non empty label.
  const label =
    typeof labels === 'string'
      ? labels
      : _.find<string>(labels, (item) => !!item);

  // Grab the first length number of characters of the label.
  return label ? toPlainText(label).slice(0, length) : '';
}

/**
 * Format the da-label attribute.
 */
function formatLabel(labels?: string | string[]) {
  return formatLabelWithLength(50, labels);
}

const cardTypes = {
  TYPE_CONTENT_CARD_GRID_AUTO: 'contentauto',
  TYPE_CONTENT_CARD_GRID_AUTO_LARGE: 'contentauto_large',
  TYPE_CONTENT_CARD_GRID_MANUAL_LARGE: 'contentmanual_large',
  TYPE_CONTENT_CARD_GRID_MANUAL_SMALL: 'contentmanual_small',
  TYPE_CONTENT_CARD_GRID_RECOMMENDATION: 'articlerecommendation',
  TYPE_CONTENT_CARD_GRID_CATEGORY_PAGE: 'category',
  TYPE_CONTENT_CARD_GRID_CONTRIBUTOR_PAGE: 'contributor',
  TYPE_FOOD_CARD_GRID: 'recipe_manual',
  TYPE_FOOD_CARD_GRID_RECOMMENDATION: 'reciperecommendation',
  TYPE_VIDEO_CARD_GRID_LARGE: 'video_large',
  TYPE_VIDEO_CARD_GRID_SMALL: 'video_small',
};

interface CardAnalytics {
  type: string;
  pageIndex: number;
  sliceIndex: number;
  cardIndex: number;
  topLevelCategory: string;
  cardId: string;
  cardTitle: string;
  entitlement: EntitlementContextType['entitlement'];
}

/**
 * Format da-category, da-action and da-label
 * attributes for all card grids.
 */
function formatCardAnalytics({
  type,
  pageIndex,
  sliceIndex,
  cardIndex,
  topLevelCategory,
  cardId,
  cardTitle,
  entitlement,
}: CardAnalytics) {
  const analyticsAttributes: Record<string, string> = {
    'da-category': formatCategory(entitlement, 'cardgrid'),
    'da-action': formatAction('card_click', type),
  };

  const safeTopLevelCategory = formatIdentifier(
    htmlToPlainText(topLevelCategory),
  );
  const topLevelCategoryProcessed = safeTopLevelCategory
    ? safeTopLevelCategory
    : 'nullcateg';

  const safeCardTitle = cardTitle
    ? formatIdentifier(htmlToPlainText(cardTitle))
    : 'nulltitle';

  const safeId = formatIdentifier(htmlToPlainText(cardId));
  const cardIdProcessed = safeId ? safeId : 'nullid';

  const label = `${pageIndex}_${sliceIndex}_${cardIndex}_${topLevelCategoryProcessed}_${cardIdProcessed}_${safeCardTitle}`;

  analyticsAttributes['da-label'] = formatLabelWithLength(100, label);

  return analyticsAttributes;
}

interface CategoryPageCardAnalytics {
  otherParams?: {
    [key: string]: string | boolean | number | undefined;
  };
  pageIndex: number;
  sliceIndex: number;
  cardIndex: number;
  cardId: string;
  cardTitle: string;
  entitlement: EntitlementContextType['entitlement'];
}

/**
 * Format da-category, da-action and da-label
 * attributes for automatic card grid on Category page.
 */
function formatCategoryPageCardAnalytics({
  otherParams,
  pageIndex,
  sliceIndex,
  cardIndex,
  cardId,
  cardTitle,
  entitlement,
}: CategoryPageCardAnalytics) {
  let type = `${otherParams?.type}`;
  if (otherParams?.slug) {
    type = `${type}_${otherParams.slug}`;
  }

  const analyticsAttributes: Record<string, string> = {
    'da-category': formatCategory(entitlement, 'cardgrid'),
    'da-action': formatAction('card_click', type),
  };

  const safeCardTitle = cardTitle
    ? formatIdentifier(htmlToPlainText(cardTitle))
    : 'nulltitle';

  const safeId = formatIdentifier(htmlToPlainText(cardId));
  const cardIdProcessed = safeId ? safeId : 'nullid';

  const label = `${pageIndex}_${sliceIndex}_${cardIndex}_${cardIdProcessed}_${safeCardTitle}`;

  analyticsAttributes['da-label'] = formatLabelWithLength(100, label);

  return analyticsAttributes;
}

function fireEvent(category: string, action: string, label = '') {
  if (window.dataLayer) {
    const event = {
      event_category: category,
      event_action: action,
      event: 'non_click_event',
    };

    if (label) {
      const eventLabel = { event_label: label };
      Object.assign(event, eventLabel);
    }

    window.dataLayer.push(event);
  }
}

export const AnalyticsUtility = {
  formatIdentifier,
  formatCategory,
  formatAction,
  formatLabel,
  cardTypes,
  formatCardAnalytics,
  formatCategoryPageCardAnalytics,
  toPlainText,
  fireEvent,
};
