import React from 'react';
import _ from 'lodash';

import { SectionOneProps } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionOne/SectionOne';
import { SectionOneV2Props } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionOneV2/SectionOneV2';
import { SectionTwoProps } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionTwo/SectionTwo';

import { SectionOne } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionOne/SectionOne';
import { SectionOneV2 } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionOneV2/SectionOneV2';
import { SectionTwo } from '@ww-digital/web-palette-react/dist/components/WeightHealthHomepage/SectionTwo/SectionTwo';
import { weightHealthHomepageData } from './weightHealthHomepageSliceData.ts';
import { AnalyticsUtility } from '../../Utility/AnalyticsUtility.ts';

interface WeightHealthHomepageSliceProps {
  daCategory: string;
  slice: {
    sectionOne?: Partial<SectionOneProps>;
    sectionOneV2?: Partial<SectionOneV2Props>;
    sectionTwo?: Partial<SectionTwoProps>;
  };
}

export const WeightHealthHomepageSliceContainer = ({
  daCategory,
  slice,
}: WeightHealthHomepageSliceProps): JSX.Element => {
  const sliceView = _.merge({}, weightHealthHomepageData, slice);

  // Button analytics
  if (sliceView.sectionOne?.button) {
    sliceView.sectionOne.button.attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        'tout_cta',
        sliceView.sectionOne.button.children,
      ),
      'da-label': AnalyticsUtility.formatLabel([
        sliceView.sectionOne.heading.text,
      ]),
    };
  }
  if (sliceView.sectionOneV2?.cards) {
    sliceView.sectionOneV2?.cards.forEach((card, index) => {
      if (!sliceView.sectionOneV2) {
        return;
      }

      sliceView.sectionOneV2.cards[index].link.attributes = {
        'da-category': daCategory,
        'da-action': AnalyticsUtility.formatAction(
          'tout_cta',
          sliceView.sectionOneV2.cards[index].button.children,
        ),
        'da-label': AnalyticsUtility.formatLabel([
          sliceView.sectionOneV2.cards[index].heading.text,
        ]),
      };
    });
  }
  if (sliceView.sectionTwo?.left?.link) {
    sliceView.sectionTwo.left.link.attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        'tout_cta',
        sliceView.sectionTwo.left.button.children,
      ),
      'da-label': AnalyticsUtility.formatLabel([
        sliceView.sectionTwo.left.heading.text,
      ]),
    };
  }
  if (sliceView.sectionTwo?.right?.link) {
    sliceView.sectionTwo.right.link.attributes = {
      'da-category': daCategory,
      'da-action': AnalyticsUtility.formatAction(
        'tout_cta',
        sliceView.sectionTwo.right.button.children,
      ),
      'da-label': AnalyticsUtility.formatLabel([
        sliceView.sectionTwo.right.heading.text,
      ]),
    };
  }

  return (
    <>
      {sliceView.sectionOne && <SectionOne {...sliceView.sectionOne} />}
      {sliceView.sectionOneV2 && <SectionOneV2 {...sliceView.sectionOneV2} />}
      {sliceView.sectionTwo && <SectionTwo {...sliceView.sectionTwo} />}
    </>
  );
};
