import React, { useContext } from 'react';
import { useQuery, useMutation } from 'react-apollo';

import type { ConfigContextType } from '../../../context/config.context';

import { ConfigContext } from '../../../context/config.context.ts';
import { GDPRBanner } from '@ww-digital/web-palette-react/dist/components/GDPR/GDPRBanner/GDPRBanner';

import GDPRClientQuery from './graphql/GDPRClientQuery.graphql';
import UpdateUserGDPRApproved from './graphql/UpdateUserGDPRApproved.graphql';

const GDPRBannerContainerVisible = (): JSX.Element | null => {
  const { config, translations } = useContext<ConfigContextType>(ConfigContext);

  const {
    loading: loadingQuery,
    error: errorQuery,
    data: dataQuery,
  } = useQuery(GDPRClientQuery, { ssr: false });
  const [updateGDPR] = useMutation(UpdateUserGDPRApproved);

  if (loadingQuery || errorQuery || dataQuery.userGDPRApproved) {
    return null;
  }

  return (
    <GDPRBanner
      // @ts-expect-error TODO
      agreeButtonText={translations.GDPR_AGREE_BUTTON_TEXT}
      disagreeButtonText={translations.GDPR_DISAGREE_BUTTON_TEXT}
      disagreeButtonUrl={config.gdpr.disagreeButtonUrl}
      headingText={translations.GDPR_HEADING_TEXT}
      showHeadingText={config.gdpr.showHeadingText}
      bodyText={translations.GDPR_BODY_TEXT}
      onClose={() => updateGDPR({ variables: { approved: true } })}
    />
  );
};

export const GDPRBannerContainer = (): JSX.Element | null => {
  const { config } = useContext<ConfigContextType>(ConfigContext);

  return config.gdpr.enabled ? <GDPRBannerContainerVisible /> : null;
};
