import Storage from '@ww-digital/web-palette-react/dist/components/Utility/Storage';
import wwUtility from '../../ww.utility.ts';

export const executeFunctions_UPF2027 = (pathname: string, variant: string) => {
  const xsAudienceCookiesTemp = Storage.getCookieValue(
    'xsAudienceCookiesTemp',
    false,
  );

  if (variant === 'control' || variant === 'v1') {
    if (pathname === '/us/clinic/weight-loss-medication') {
      if (
        wwUtility.isBrowser() &&
        typeof xsAudienceCookiesTemp === 'string' &&
        !xsAudienceCookiesTemp.includes('XS-Template--Will--UPF-2027--1')
      ) {
        const xsCookiesListGeneration =
          xsAudienceCookiesTemp + '.XS-Template--Will--UPF-2027--1';
        document.cookie = `xsAudienceCookiesTemp=${xsCookiesListGeneration};path=/;`;
      }
      if (wwUtility.isBrowser() && xsAudienceCookiesTemp === null) {
        document.cookie = `xsAudienceCookiesTemp=XS-Template--Will--UPF-2027--1;path=/;`;
      }
    }
  }
};
