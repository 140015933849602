import { useLocation } from 'react-router-dom';
import { Experiment } from '@ww-digital/xs-sdk-react';

export const ExecuteFunctions_GREX176 = (pathname: string, variant: string) => {
  const searchParam = useLocation().search;

  if (variant === 'control') {
    if (pathname === '/us/pdp/a') {
      // Check Query Params for valud entry...
      if (
        searchParam.includes('utm_medium=marketingemail') ||
        (searchParam.includes('utm_source=google') &&
          searchParam.includes('utm_medium=cpc')) ||
        (searchParam.includes('utm_source=facebook') &&
          searchParam.includes('utm_medium=paidsocial'))
      ) {
        // Select the node that will be observed for mutations
        /* Masthead Slice update */
        const slice_one = document.getElementById(
          'slice-id--a0088984-c1ff-4f0d-a11c-d94db349f1e3',
        );
        /* "Get First Month of Membership Free" Slice */
        const slice_two = document.getElementById(
          'slice-id--356b325e-5f20-4927-bd71-6a2235790824',
        );
        /* "Ready to Lose Weight" Slice */
        const slice_three = document.getElementById(
          'slice-id--cd43971c-1b78-4a68-9182-30e2ec4298f9',
        );

        // Options for the observer (which mutations to observe)
        const config = { attributes: true, childList: true, subtree: true };

        // Callback function to execute when mutations are observed
        const callback_one = (mutationList: any, observer_one: any) => {
          for (const mutation of mutationList) {
            if (mutation.type === 'childList') {
              if (
                slice_one !== null &&
                slice_one.querySelectorAll(
                  '[class*="Button_button__"][class*="Tout_ctaButton__"][class*="Button_primary__"][class*="Button_large__"]',
                )[0] !== undefined
              ) {
                slice_one
                  .querySelectorAll(
                    '[class*="Button_button__"][class*="Tout_ctaButton__"][class*="Button_primary__"][class*="Button_large__"]',
                  )[0]
                  .setAttribute(
                    'href',
                    `https://app.joinsequence.com/welcome${searchParam}`,
                  );
              }
            }
          }
        };

        const callback_two = (mutationList: any, observer_two: any) => {
          for (const mutation of mutationList) {
            if (mutation.type === 'childList') {
              if (
                slice_two !== null &&
                slice_two.querySelectorAll(
                  '[class*="Button_button__"][class*="Tout_ctaButton__"][class*="Button_primary__"][class*="Button_large__"]',
                )[0] !== undefined
              ) {
                slice_two
                  .querySelectorAll(
                    '[class*="Button_button__"][class*="Tout_ctaButton__"][class*="Button_primary__"][class*="Button_large__"]',
                  )[0]
                  .setAttribute(
                    'href',
                    `https://app.joinsequence.com/welcome${searchParam}`,
                  );
              }
            }
          }
        };

        const callback_three = (mutationList: any, observer_three: any) => {
          for (const mutation of mutationList) {
            if (mutation.type === 'childList') {
              if (
                slice_three !== null &&
                slice_three.querySelectorAll(
                  '[class*="Button_button__"][class*="Tout_ctaButton__"][class*="Button_primary__"][class*="Button_large__"]',
                )[0] !== undefined
              ) {
                slice_three
                  .querySelectorAll(
                    '[class*="Button_button__"][class*="Tout_ctaButton__"][class*="Button_primary__"][class*="Button_large__"]',
                  )[0]
                  .setAttribute(
                    'href',
                    `https://app.joinsequence.com/welcome${searchParam}`,
                  );
              }
            }
          }
        };

        if (slice_one !== null && slice_two !== null && slice_three !== null) {
          // Create an observer instance linked to the callback function
          const observer_one = new MutationObserver(callback_one);
          const observer_two = new MutationObserver(callback_two);
          const observer_three = new MutationObserver(callback_three);

          // Start observing the target node for configured mutations
          observer_one.observe(slice_one, config);
          observer_two.observe(slice_two, config);
          observer_three.observe(slice_three, config);

          // Run Exposure for Test
          <Experiment name="XS-Template--GREX-176">
            <></>
          </Experiment>;
        }
      }
    }
  }
};
